import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {getAccessToken} from './authHelper';
import {jsonToCSV} from './utils';
import 'bootstrap/dist/css/bootstrap.min.css';
import './tables.css'; // Create and import a CSS file for custom styles

import styles from './DownloadButton.module.css';
import frameIcon from './static_files/download.svg';
import Footer from './footer/Footer'
import Header from "./header/Header";
import hoverSort from "./static_files/sort-arrow-green.svg"
import arrowSort from "./static_files/sort-arrow-grey.svg"
import monitorIcon from "./static_files/Monitor.svg"
import ConnectWalletHeader from "./header/ConnectWalletHeader";

const formatToPercentage = (value) => {
    // 如果传入值为 null 或 undefined，则显示 N/A
    if (value === null || value === undefined) {
        return 'N/A';
    }
    // 其余情况格式化成百分比
    return `${(value * 100).toFixed(2)}%`;
};

const formatDate = (dateString) => {
    const d = new Date(dateString.replace(' GMT', ''));
    const date = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
    const options = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Format the date without the day of the week
    const formattedDate = date.toLocaleDateString('en-US', options).replace(/,/g, '');
    const formattedTime = date.toLocaleTimeString('it-IT');
    const [month, day, year] = formattedDate.split(' ');

    // Return only the date and time without the day of the week
    return `${day} ${month} ${year} ${formattedTime}`;
};


const Monitor = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [sortConfig, setSortConfig] = useState({key: 'expected_apy', direction: 'descending'});
    const [remainingLoading, setRemainingLoading] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 600);
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth > 600);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchMonitorData = async (offset = 0, limit = 20, access_token) => {
        try {
            const response = await axios.get(`https://max1-funding-arb.uc.r.appspot.com/monitor_apr_attribute?offset=${offset}&limit=${limit}`, {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching monitor data:', error);
            return [];
        }
    };

    const fetchMonitor = async () => {
        setRemainingLoading(true);
        const access_token = await getAccessToken();
        setLoading(true);
        let offset = 0;
        const limit = 100;
        let fetchedData = [];
        let moreData = true;

        // 先获取前 20 条数据
        fetchedData = await fetchMonitorData(offset, 20, access_token);
        setData(fetchedData);
        setLoading(false);
        offset += 20;

        // 再批量获取后续数据
        while (moreData) {
            const newData = await fetchMonitorData(offset, limit, access_token);
            if (newData.length === 0) {
                setRemainingLoading(false);
                moreData = false;
            } else {
                fetchedData = [...fetchedData, ...newData];
                offset += limit;
            }
        }

        setData(fetchedData);
        setRemainingLoading(false);
        // Start the monitor process without awaiting its completion
        runMonitor(access_token);
    };

    const fetchUpdatedMonitor = async () => {
        try {
            const access_token = await getAccessToken();
            const trackRecordsResponse = await axios.get('https://max1-funding-arb.uc.r.appspot.com/monitor_apr_attribute', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });

            setData(trackRecordsResponse.data);
        } catch (error) {
            console.error('Error fetching updated monitor data:', error);
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) {
            return <img className="descending-sort" src={arrowSort} alt=''/>;
        }
        return sortConfig.direction === 'ascending' ? (
            <div className="icon-container">
                <img className="ascending-sort" src={hoverSort} alt=''/>
            </div>
        ) : (
            <div className="icon-container">
                <img className="descending-sort" src={hoverSort} alt=''/>
            </div>
        );
    };

    const sortedData = React.useMemo(() => {
        let sortableData = [...data];
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // 如果是数字字段，确保可以正确进行比较
                if (!isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue))) {
                    aValue = parseFloat(aValue);
                    bValue = parseFloat(bValue);
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [data, sortConfig]);

    const runMonitor = async (access_token) => {
        try {
            setMessage("trying to run backend monitor")
            const trackRecordsResponse2 = await axios.get('https://max1-funding-arb.uc.r.appspot.com/run_monitor', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                },
            });
            console.log('Monitor response:', trackRecordsResponse2.data);
            const responseData = JSON.parse(trackRecordsResponse2.data.message);

            if (responseData.message === "Data fetched and stored successfully") {
                setMessage("Data fetched and stored successfully. Updating data...");
                await fetchUpdatedMonitor();
                setMessage("Data updated.");
            } else {
                setMessage(responseData.message);
            }
        } catch (error) {
            console.error('Error running monitor:', error);
            setMessage('Error running monitor.');
        }
    };

    useEffect(() => {
        fetchMonitor();
    }, []);

    const handleDownload = () => {
        const csv = jsonToCSV(data);
        const blob = new Blob([csv], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'track_records.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    // for loading page gray box
    const rows = 25;
    // !!! 这里改为 10 列，包含 Symbol、Expected APY、Staking APY、Implied APR、Next Funding APR、Previous Funding APR、3 Day Cum Funding APR、7 Day Cum Funding APR、30 Day Cum Funding APR、Target Allocation %
    const cells = 10;

    // 当页面还在加载数据时，显示灰色占位
    if (loading) {
        return (
            <div className="container ">
                <div style={{marginTop: '7px'}}>
                    <ConnectWalletHeader
                        isAdminHeader={true}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                    />
                </div>
                <div className="title-box d-flex justify-content-between align-items-center">
                    <img className="monitor green-title" src={monitorIcon} alt=""/>
                </div>
                <div className="table-responsive noto">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                        <tr>
                            <th className="symbol-column">Symbol</th>
                            <th>Expected APY</th>
                            <th>Staking APY</th>
                            <th>Implied APR</th>
                            <th>Next Funding APR</th>
                            <th>Previous Funding APR</th>
                            <th>3 Day Cum Funding APR</th>
                            <th>7 Day Cum Funding APR</th>
                            <th>30 Day Cum Funding APR</th>
                            <th>Target Allocation %</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Array.from({length: rows}).map((_, rowIndex) => (
                            <tr key={rowIndex} style={{borderBottom: "1px solid #FFFFFF1A"}}>
                                {Array.from({length: cells}).map((_, cellIndex) => (
                                    <td key={cellIndex}>
                                        <div className="gray-box"></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {remainingLoading && <div>Loading more data...</div>}
                <div className={styles.parent}>
                    <div className={styles.div}>「</div>
                    <div className={styles.downloadCsvParent}>
                        <div onClick={handleDownload} className={styles.downloadCsv}>Download CSV</div>
                        <img className={styles.frameIcon} alt="" src={frameIcon}/>
                    </div>
                    <div className={styles.div1}>」</div>
                </div>
                <Footer>{message}</Footer>
            </div>
        );
    }

    // 如果不处于加载状态，渲染真正的数据表格
    return (
        <div className="container">
            <div style={{marginTop: '7px'}}>
                <ConnectWalletHeader
                    isAdminHeader={true}
                    showPanel={showPanel}
                    setShowPanel={setShowPanel}
                />
            </div>
            <div className="title-box d-flex justify-content-between align-items-center">
                <img className="monitor green-title" src={monitorIcon} alt=""/>
                {sortedData[0] != null && (
                    <div className="time-stamp">
                        {formatDate(sortedData[0].time)}
                    </div>
                )}
            </div>
            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th className="symbol-column" onClick={() => handleSort('symbol')}>
                            Symbol
                        </th>
                        {/* 新增列：Expected APY */}
                        <th onClick={() => handleSort('expected_apy')}>
                            Expected APY {getSortIcon('expected_apy')}
                        </th>
                        {/* 新增列：Staking APY */}
                        <th onClick={() => handleSort('staking_apy')}>
                            Staking APY {getSortIcon('staking_apy')}
                        </th>

                        <th onClick={() => handleSort('apr_score')}>
                            Implied APR {getSortIcon('apr_score')}
                        </th>
                        <th onClick={() => handleSort('next_funding_rate')}>
                            Next Funding APR {getSortIcon('next_funding_rate')}
                        </th>
                        <th onClick={() => handleSort('previous_funding_rate')}>
                            Previous Funding APR {getSortIcon('previous_funding_rate')}
                        </th>
                        <th onClick={() => handleSort('3_day_cum_funding')}>
                            3 Day Cum Funding APR {getSortIcon('3_day_cum_funding')}
                        </th>
                        <th onClick={() => handleSort('7_day_cum_funding')}>
                            7 Day Cum Funding APR {getSortIcon('7_day_cum_funding')}
                        </th>
                        <th onClick={() => handleSort('30_day_cum_funding')}>
                            30 Day Cum Funding APR {getSortIcon('30_day_cum_funding')}
                        </th>
                        <th onClick={() => handleSort('allocation_percentage')}>
                            Target Allocation % {getSortIcon('allocation_percentage')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((record, index) => (
                        <tr key={index}>
                            <td className="symbol-column">{record.symbol}</td>
                            {/* 如果为 null，则显示 N/A，否则以百分比格式化 */}
                            <td>{formatToPercentage(record.expected_apy)}</td>
                            <td>{formatToPercentage(record.staking_apy)}</td>

                            <td>{formatToPercentage(record.apr_score)}</td>
                            <td>{formatToPercentage(record.next_funding_rate)}</td>
                            <td>{formatToPercentage(record.previous_funding_rate)}</td>
                            <td>{formatToPercentage(record['3_day_cum_funding'])}</td>
                            <td>{formatToPercentage(record['7_day_cum_funding'])}</td>
                            <td>{formatToPercentage(record['30_day_cum_funding'])}</td>
                            <td>{formatToPercentage(record.allocation_percentage)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {remainingLoading && <div>Loading more data...</div>}
            <div className={styles.parent}>
                <div className={styles.div}>「</div>
                <div className={styles.downloadCsvParent}>
                    <div onClick={handleDownload} className={styles.downloadCsv}>Download CSV</div>
                    <img className={styles.frameIcon} alt="" src={frameIcon}/>
                </div>
                <div className={styles.div1}>」</div>
            </div>
            <Footer>{message}</Footer>
        </div>
    );
};

export default Monitor;