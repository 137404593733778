import React, {useEffect, useState} from 'react';
import {digitFormat, moneyFormat, moneyFormat4decimals} from '../utils';
import './USDIStats.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';

const HolderInfoTableSolana = ({
                                   selectedChain,
                                   setAmount,
                                   setToAddress,
                                   setTransactionTrigger,
                                   usdiBalance,
                                   isTransferComplete,
                                   setIsTransferComplete
                               }) => {
    // ------------------ 原有 State ------------------
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [updatingDWS, setUpdatingDWS] = useState(false);
    const [calculating, setCalculating] = useState(false);
    const [error, setError] = useState(null);
    const [historicalData, setHistoricalData] = useState({});
    const [hoveredAddress, setHoveredAddress] = useState(null);

    //  States for interest distribution:
    const [finalDistributionAmountAfterManagementFees, setFinalDistributionAmountAfterManagementFees] = useState(null);
    const [usdiInterestDistributionWalletPublicKey, setUsdiInterestDistributionWalletPublicKey] = useState('');
    const [interestDistributing, setInterestDistributing] = useState(false);

    // ------------------ Endpoint  ------------------
    const USDI_API = 'https://usdi-api-jlukzyz7wa-ew.a.run.app';
    const Distribution_USDI = 'https://distribution-calculator-256611876551.europe-west1.run.app';

    const holdersInfoUrl = `${USDI_API}/usdi_holders_info_table_solana`;
    const rawBlockchainTxUrl = `${USDI_API}/raw_blockchain_transactions_solana`;
    const getUserBalanceEndpoint = `${Distribution_USDI}/get_user_balance_solana`;
    const adminTriggerEndpoint = `${Distribution_USDI}/admin-trigger_solana`;
    const userBalanceBlocksUpdateEndpoint = `${Distribution_USDI}/user_balance_blocks_update_solana`;
    const calculationTriggerEndpoint = `${Distribution_USDI}/calculation-trigger_solana`;
    const overallUsdiPayoutSolanaUrl = 'https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout_solana';
    const usdiInterestDistributionWalletPublicKeyUrl =
        'https://usdi-api-256611876551.europe-west1.run.app/usdi_interest_distribution_wallet_public_key';

    // ------------------ 原有函数：fetchData ------------------
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(holdersInfoUrl);
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            setData(result);

            const rawResponse = await fetch(rawBlockchainTxUrl);
            if (!rawResponse.ok) throw new Error('Network response was not ok');
            const rawResult = await rawResponse.json();

            // Build a map of address => numeric userId
            const addressMap = {};
            let userId = 1;
            rawResult.forEach((transaction) => {
                const normalizedAddress = transaction.to_address.toLowerCase().slice(0, 42);
                if (!addressMap[normalizedAddress]) {
                    addressMap[normalizedAddress] = userId++;
                }
            });

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // ------------------ NEW: fetchOverallUsdiPayoutSolana ------------------
    const fetchOverallUsdiPayoutSolana = async () => {
        try {
            const response = await fetch(overallUsdiPayoutSolanaUrl);
            if (!response.ok) throw new Error('Failed to fetch overall_usdi_payout_solana');
            const payoutData = await response.json();

            if (payoutData && payoutData.length > 0) {
                let maxItem = payoutData[0];
                for (const item of payoutData) {
                    if (item.distribution_id > maxItem.distribution_id) {
                        maxItem = item;
                    }
                }
                setFinalDistributionAmountAfterManagementFees(
                    maxItem.final_distribution_amount_after_management_fees
                );
            }
        } catch (err) {
            console.error('Error fetching overall_usdi_payout_solana:', err);
        }
    };

    // ------------------ FetchUsdiInterestDistributionWalletPublicKey ------------------
    // 点击distribute的时候找到要给他转账的地址
    const fetchUsdiInterestDistributionWalletPublicKey = async () => {
        try {
            const response = await fetch(usdiInterestDistributionWalletPublicKeyUrl);
            if (!response.ok) throw new Error('Failed to fetch distribution wallet public key');
            const result = await response.json();
            if (result && result.USDi_Interest_Distribution_Wallet_Public_Key) {
                setUsdiInterestDistributionWalletPublicKey(result.USDi_Interest_Distribution_Wallet_Public_Key);
            }

        } catch (error) {
            console.error(
                'Error fetching usdi_interest_distribution_wallet_public_key:',
                error
            );
        }
    };

    // ------------------ fetchHistoricalData -> cum_dws table ------------------
    const fetchHistoricalData = async (address) => {
        try {
            const url = `${getUserBalanceEndpoint}?user_address=${address}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch historical data');
            const result = await response.json();

            setHistoricalData((prevData) => ({
                ...prevData,
                [address]: result.data,
            }));
        } catch (error) {
            console.error('Error fetching historical data:', error);
        }
    };

    // ------------------ 侧边表(cum_dws)的「下载CSV」函数 ------------------
    const handleDownloadSideTable = (address) => {
        const sideTable =
            historicalData[address]
                ?.slice()
                ?.sort((a, b) => b.block_number - a.block_number) || []; // Descending order

        if (!sideTable || sideTable.length === 0) {
            alert('No historical data to download for this address.');
            return;
        }

        const headers = [
            'Block Number',
            'Cum DWS',
            'Balance',
            'DWS',
            'BNP',
            'Undistributed USDI',
            'Cumulative Distributed USDI',
            'Distribution Transaction Hash'
        ];
        const csvRows = sideTable.map((item) => {
            const rowArr = [
                digitFormat(item.block_number),
                digitFormat(item.cum_dws),
                moneyFormat(item.balance),
                digitFormat(item.dws),
                digitFormat(item.bnp),
                item.undistributed_usdi !== null && item.undistributed_usdi !== undefined
                    ? moneyFormat4decimals(item.undistributed_usdi)
                    : '--',
                item.cum_distributed_usdi !== null && item.cum_distributed_usdi !== undefined
                    ? moneyFormat4decimals(item.cum_distributed_usdi)
                    : '--',
                item.distribution_transaction_hash && item.distribution_transaction_hash.trim() !== ''
                    ? item.distribution_transaction_hash
                    : '--'
            ];
            return rowArr.map((field) => `"${field}"`).join(',');
        });
        const csvString = [headers.join(','), ...csvRows].join('\n');
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', `cum_dws_table_for_${address}.csv`);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // ------------------ 挂载时或切换链时 ------------------
    useEffect(() => {
        fetchData();
        handleUpdate();
        fetchOverallUsdiPayoutSolana();
        fetchUsdiInterestDistributionWalletPublicKey();
        // eslint-disable-next-line
    }, [selectedChain]);

    // ------------------Admin-trigger ------------------
    const handleUpdate = async () => {
        try {
            setUpdating(true);
            const response = await fetch(adminTriggerEndpoint, {
                method: 'POST',
            });
            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to trigger distribution calculation');
            }
        } catch (err) {
            setError(err);
        } finally {
            setUpdating(false);
        }
    };

    // ------------------HandleUpdateDWS 要输入起始block number ------------------
    const handleUpdateDWS = async () => {
        let start_block = prompt('Enter Start Block Number:');
        let end_block = prompt('Enter End Block Number:');

        start_block = start_block ? start_block.replace(/,/g, '') : '';
        end_block = end_block ? end_block.replace(/,/g, '') : '';

        if (!start_block || !end_block) {
            alert('Both start block and end block numbers are required!');
            return;
        }

        try {
            setUpdatingDWS(true);
            const response = await fetch(userBalanceBlocksUpdateEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start_block: start_block,
                    end_block: end_block,
                }),
            });

            if (response.ok) {
                alert('DWS updated successfully.');
            } else {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }
        } catch (err) {
            setError(err);
            alert(`Error: ${err.message}`);
        } finally {
            setUpdatingDWS(false);
        }
    };

    // ------------------ HandleCalculate 点击后计算undistributed USDI并且填入表格 ------------------
    const handleCalculate = async () => {
        let block_number = prompt('Enter Block Number:');
        let final_distribution_amount = prompt('Enter Final Distribution Amount:');

        block_number = parseInt(block_number.replace(/,/g, ''), 10);
        final_distribution_amount = parseFloat(final_distribution_amount.replace(/,/g, ''));

        if (!block_number || !final_distribution_amount) {
            alert('Both fields are required!');
            return;
        }

        try {
            setCalculating(true);
            const response = await fetch(calculationTriggerEndpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    block_number: Number(block_number),
                    final_distribution_amount: Number(final_distribution_amount),
                }),
            });
            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to calculate distribution');
            }
        } catch (err) {
            setError(err);
        } finally {
            setCalculating(false);
        }
    };

    // ------------------点击distribute button ------------------
    const handleClickInterestDistribute = () => {
        if (!finalDistributionAmountAfterManagementFees) {
            alert('Please distribute in overall USDI payout table first');
            return;
        }
        if (!usdiInterestDistributionWalletPublicKey) {
            alert('No distribution wallet public key found. Please try again');
            return;
        }
        if (usdiBalance < finalDistributionAmountAfterManagementFees) {
            const amountAsNumber = parseFloat(finalDistributionAmountAfterManagementFees);
            const truncatedValue = Math.floor(amountAsNumber * 1e6) / 1e6;

            const formattedValue = truncatedValue.toLocaleString('en-US', {
                maximumFractionDigits: 6
            });
            alert(
                `Insufficient funds or wallet not connected. Please try again. You need a balance greater than ${formattedValue.toLocaleString('en-US', {maximumFractionDigits: 6})}, current USDi balance ${usdiBalance}`
            );
            return;
        }

        const userConfirmed = window.confirm(
            `Are you sure you want to distribute ${finalDistributionAmountAfterManagementFees} USDI to the USDI Holders?`
        );
        if (userConfirmed) {
            setInterestDistributing(true);
            setAmount(finalDistributionAmountAfterManagementFees);
            setToAddress(usdiInterestDistributionWalletPublicKey);
            setTransactionTrigger((prev) => prev + 1);
            setInterestDistributing(false);
        }
    };

    // ------------------ 分页逻辑  ------------------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const getPageNumbers = (curPage, totalPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            let left = curPage - 1;
            let right = curPage + 1;

            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }

            if (right < totalPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };
    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };

    // ------------------ 下载整表CSV函数 ------------------
    const handleDownloadCSV = () => {
        const headers = [
            'User Unique ID',
            'Address',
            'Current Block Number',
            'Last Block Number',
            'Status',
            'BNP',
            'Cum DWS',
            'Balance of USDI',
            'DWS',
            'New Cumulative DWS',
            'CumDWS Weight %',
            'Accrued Interest',
            'User Undistributed USDI',
            'Cumulative Distributed USDI',
            'New Cumulative DWS After Distribution'
        ];
        const csvRows = sortedData.map((row) => {
            return [
                row.userId !== Infinity ? row.userId : 'N/A',
                row.address,
                row.current_block_number,
                row.last_block_number,
                row.status,
                row.bnp,
                row.cum_dws,
                row.balance_of_usdi,
                row.dws,
                row.new_cum_dws,
                row.cum_dws_weight_percent,
                row.accrued_interest !== undefined
                    ? moneyFormat4decimals(row.accrued_interest)
                    : '',
                row.user_undistributed_usdi,
                row.cum_distributed_usdi,
                0.0
            ].map((field) => `"${field ?? ''}"`).join(',');
        });
        const csvString = [headers.join(','), ...csvRows].join('\n');
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'holder_info_solana.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // ------------------ 排序逻辑  ------------------
    let sortedData = data.slice().sort((a, b) => {
        const aBal = Number(a.balance_of_usdi) || 0;
        const bBal = Number(b.balance_of_usdi) || 0;
        return bBal - aBal;
    });
    sortedData = sortedData.map((row, index) => ({
        ...row,
        userId: index + 1,
    }));
    const totalItems = sortedData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = sortedData.slice(startIndex, endIndex);

    // ------------------ NEW CODE START ------------------
    // 1) 用于 Modal 显示的 state
    const [showVerifyModal, setShowVerifyModal] = useState(false);          // 是否显示  让admin 确认地址的Modal
    const [verifyDistributionData, setVerifyDistributionData] = useState([]); // GET 回来的待分发数据
    const [distributionVerifyError, setDistributionVerifyError] = useState(null);
    const [confirmingDistribution, setConfirmingDistribution] = useState(false);
    const [distributionConfirmError, setDistributionConfirmError] = useState(null);
    const [distributionConfirmSuccess, setDistributionConfirmSuccess] = useState([]);
    // 正在distribute时的状态
    const [distributionProgress, setDistributionProgress] = useState("0/0");
    const [startStatusCheck, setStartStatusCheck] = useState(false);
    // 2) 当 分发完成后，自动获取待分发表
    useEffect(() => {
        if (isTransferComplete) {
            fetchDistributionVerifyData();
        }
    }, [isTransferComplete]);

    // 3) GET 获取待分发信息
    const fetchDistributionVerifyData = async () => {
        try {
            setDistributionVerifyError(null);
            const response = await fetch(`${Distribution_USDI}/interest_distribute_verify_info`);
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to fetch verify info: ${errorText}`);
            }
            const result = await response.json();
            if (result && result.data) {
                setVerifyDistributionData(result.data);
                setShowVerifyModal(true); // 打开 Modal
            }
        } catch (error) {
            console.error('Error fetching distribution verify data:', error);
            setDistributionVerifyError(error.message);
            setShowVerifyModal(true); // 如果出错也显示 Modal
        }
    };

    // 4) 下载待分发信息表
    const handleDownloadVerifyCSV = () => {
        if (!verifyDistributionData || verifyDistributionData.length === 0) {
            alert("No distribution data to download.");
            return;
        }
        const headers = ["Address", "Amount"];
        const csvRows = verifyDistributionData.map(item =>
            [item.address, item.amount].map(field => `"${field}"`).join(',')
        );
        const csvString = [headers.join(','), ...csvRows].join('\n');
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "interest_distribute_verify_info.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // 5) Confirm 分发 (POST)
    const handleConfirmDistribution = async () => {
        if (!verifyDistributionData || verifyDistributionData.length === 0) {
            alert("No distribution data to confirm. Please click the update button next to undistributed USDI to calculate first ");
            return;
        }
        setConfirmingDistribution(true);
        setDistributionConfirmError(null);
        setDistributionConfirmSuccess([]);

        try {
            const response = await fetch(`${Distribution_USDI}/interest_distribute_confirmed`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({data: verifyDistributionData})
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to confirm distribution: ${errorText}`);
            }
            const result = await response.json();
            if (result.success) {
                // 显示所有 txSignatures
                if (Array.isArray(result.txSignatures)) {
                    setDistributionConfirmSuccess(result.txSignatures);
                } else {
                    setDistributionConfirmSuccess([JSON.stringify(result.txSignatures)]);
                }
            }
            if (result.error) {
                setDistributionConfirmError(result.error);
            }
        } catch (err) {
            setDistributionConfirmError(err.message);
        } finally {
            setConfirmingDistribution(false);
            // TODO delete set timeout
            setTimeout(() => {
                setStartStatusCheck(false);
            }, 60000);
        }
    };

    // ------------------ 查询分发状态 ------------------
    useEffect(() => {
        let intervalId;
        if (startStatusCheck) {
            fetchDistributionVerifyStatus();
            // 每间隔10秒查询一次
            intervalId = setInterval(() => {
                fetchDistributionVerifyStatus();
            }, 10000);
        }

        // 当组件卸载 或 startStatusCheck 变回 false 时，清除轮询
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [startStatusCheck]);
    const fetchDistributionVerifyStatus = async () => {
        try {
            const response = await fetch(`${Distribution_USDI}/interest_distribute_verify_status`);
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to fetch distribution verify status: ${errorText}`);
            }
            const result = await response.json();
            if (result && result.data) {
                // 假设 result.data 是一个数组，每个元素有 distribution_transaction_hash
                const total = result.data.length;
                const distributed = result.data.filter(
                    item =>
                        item.distribution_transaction_hash &&
                        item.distribution_transaction_hash.trim() !== ''
                ).length;
                setDistributionProgress(`${distributed}/${total}`);
            }
        } catch (error) {
            console.error('Error fetching distribution verify status:', error);
        }
    };
    // ------------------ 渲染 ------------------
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <div className="button_wrapper">
                <h3>USDI Holders Info</h3>
                <div style={{display: "flex"}}>
                    <button
                        className={`button ${interestDistributing ? 'updating' : ''}`}
                        onClick={handleClickInterestDistribute}
                        disabled={interestDistributing}
                        style={{marginRight: '20px'}}
                    >
                        {interestDistributing ? 'Distributing' : 'Distribute'}
                    </button>
                    <button
                        className={`button ${updating ? 'updating' : ''}`}
                        onClick={handleUpdate}
                        disabled={updating}
                    >
                        {updating ? 'Updating' : 'Update'}
                    </button>
                </div>
            </div>

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>User Unique ID</th>
                        <th>Address</th>
                        <th>Current Block Number</th>
                        <th>Last Block Number</th>
                        <th>Status</th>
                        <th>BNP</th>
                        <th>
                            Cum DWS
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                spin={updatingDWS}
                                onClick={handleUpdateDWS}
                                className="update-icon"
                            />
                        </th>
                        <th>Balance of USDI</th>
                        <th>DWS</th>
                        <th>New Cumulative DWS</th>
                        <th>CumDWS Weight %</th>
                        <th>Accrued Interest</th>
                        <th>
                            User Undistributed USDI
                            <FontAwesomeIcon
                                icon={faSyncAlt}
                                spin={calculating}
                                onClick={handleCalculate}
                                className="update-icon"
                            />
                        </th>
                        <th>Cumulative Distributed USDI</th>
                        <th>New Cumulative DWS After Distribution</th>
                    </tr>
                    </thead>

                    <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td>{row.userId !== Infinity ? row.userId : 'N/A'}</td>
                            <td>{row.address}</td>
                            <td>{digitFormat(row.current_block_number)}</td>
                            <td>{digitFormat(row.last_block_number)}</td>
                            <td>{row.status}</td>
                            <td>{digitFormat(row.bnp)}</td>

                            <td
                                onMouseEnter={() => {
                                    setHoveredAddress(row.address);
                                    if (!historicalData[row.address]) {
                                        fetchHistoricalData(row.address);
                                    }
                                }}
                                onMouseLeave={() => setHoveredAddress(null)}
                                style={{position: 'relative', cursor: 'pointer'}}
                            >
                                {digitFormat(row.cum_dws)}
                                {hoveredAddress === row.address && historicalData[row.address] && (
                                    <div className="popup">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <strong>
                                                Cum Dws table for "{row.address}"
                                            </strong>
                                            <button
                                                style={{
                                                    width: '200px',
                                                    marginBottom: '20px',
                                                    marginRight: '20px',
                                                    marginTop: '20px',
                                                }}
                                                className="tableDownloadButton"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDownloadSideTable(row.address);
                                                }}
                                            >
                                                Download CSV
                                            </button>
                                        </div>

                                        <table className="table table-sm">
                                            <thead>
                                            <tr>
                                                <th>Block Number</th>
                                                <th>Cum DWS</th>
                                                <th>Balance</th>
                                                <th>DWS</th>
                                                <th>BNP</th>
                                                <th>Undistributed USDI</th>
                                                <th>Cumulative Distributed USDI</th>
                                                <th>Distribution Transaction Hash</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                historicalData[row.address]
                                                    .slice()
                                                    .sort((a, b) => b.block_number - a.block_number) // Descending order
                                                    .map((histRow, idx) => (
                                                        <tr key={idx}>
                                                            <td>{digitFormat(histRow.block_number)}</td>
                                                            <td>{digitFormat(histRow.cum_dws)}</td>
                                                            <td>{moneyFormat(histRow.balance)}</td>
                                                            <td>{digitFormat(histRow.dws)}</td>
                                                            <td>{digitFormat(histRow.bnp)}</td>
                                                            <td>
                                                                {histRow.undistributed_usdi !== null &&
                                                                histRow.undistributed_usdi !== undefined
                                                                    ? moneyFormat4decimals(histRow.undistributed_usdi)
                                                                    : '--'}
                                                            </td>
                                                            <td>
                                                                {histRow.cum_distributed_usdi !== null &&
                                                                histRow.cum_distributed_usdi !== undefined
                                                                    ? moneyFormat4decimals(histRow.cum_distributed_usdi)
                                                                    : '--'}
                                                            </td>
                                                            <td>
                                                                {histRow.distribution_transaction_hash &&
                                                                histRow.distribution_transaction_hash.trim() !== ''
                                                                    ? histRow.distribution_transaction_hash
                                                                    : '--'}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </td>

                            <td>{moneyFormat(row.balance_of_usdi)}</td>
                            <td>{digitFormat(row.dws)}</td>
                            <td>{digitFormat(row.new_cum_dws)}</td>
                            <td>{moneyFormat4decimals(row.cum_dws_weight_percent)}%</td>
                            <td>
                                {row.accrued_interest !== undefined
                                    ? moneyFormat4decimals(row.accrued_interest)
                                    : '--'}
                            </td>
                            <td>{moneyFormat4decimals(row.user_undistributed_usdi)}</td>
                            <td>{moneyFormat4decimals(row.cum_distributed_usdi)}</td>
                            <td>0.00</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {totalPages > 1 && (
                <div className="pagination-outer-container">
                    <div className="pagination-container" style={{marginTop: '10px'}}>
                        <button
                            className="pagination-button"
                            id="first-page-button"
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            First
                        </button>
                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>

                        {getPageNumbers(currentPage, totalPages).map((p, i) =>
                            p === '...' ? (
                                <span key={i} className="pagination-ellipsis">
                                    ...
                                </span>
                            ) : (
                                <button
                                    key={i}
                                    className={`pagination-button ${
                                        p === currentPage ? 'active-page' : ''
                                    }`}
                                    onClick={() => handlePageChange(p)}
                                >
                                    {p}
                                </button>
                            )
                        )}

                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                        <button
                            className="pagination-button"
                            id="last-page-button"
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            Last
                        </button>
                    </div>
                    <button className="tableDownloadButton" onClick={handleDownloadCSV}>
                        Download CSV
                    </button>
                </div>
            )}

            {/*
              --- Modal 用来展示验证分发信息 ---
            */}
            {showVerifyModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div style={{display: 'flex', justifyContent: "space-between"}}>
                            <h4>
                                Distribution Verify Info ({distributionProgress})
                            </h4>

                            <span style={{width: "30px", height: '30px', cursor: "pointer"}}
                                  onClick={() => {
                                      setShowVerifyModal(false);
                                      setIsTransferComplete(false); // 让父组件或自身State重置
                                  }}>
                            x
                        </span>
                        </div>
                        <div style={{marginTop: '20px'}}>
                            <button
                                className="button"
                                onClick={handleDownloadVerifyCSV}
                            >
                                Download CSV
                            </button>
                            <button
                                className="button"
                                onClick={handleConfirmDistribution}
                                disabled={confirmingDistribution}
                                style={{marginLeft: '10px'}}
                            >
                                {confirmingDistribution ? 'Confirming...' : 'Confirm'}
                            </button>

                        </div>

                        {distributionVerifyError && (
                            <div style={{color: 'red', marginBottom: '10px'}}>
                                Error fetching verify info: {distributionVerifyError}
                            </div>
                        )}
                        {/* 显示Confirm结果 */}
                        {distributionConfirmError && (
                            <div style={{marginTop: '10px', color: 'red'}}>
                                {distributionConfirmError}
                            </div>
                        )}
                        {distributionConfirmSuccess && distributionConfirmSuccess.length > 0 && (
                            <div style={{marginTop: '10px', color: 'green'}}>
                                <div>Distribution Success! Transaction Signatures:</div>
                                <ul>
                                    {distributionConfirmSuccess.map((sig, i) => (
                                        <li key={i}>{sig}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {verifyDistributionData && verifyDistributionData.length > 0 && (
                            <table className="table table-striped table-bordered mt-3">
                                <thead>
                                <tr>
                                    <th>Address</th>
                                    <th>Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {verifyDistributionData.map((item, idx) => (
                                    <tr key={idx}>
                                        <td>{item.address}</td>
                                        <td>{item.amount}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}


                    </div>
                </div>
            )}
        </>
    );
};

export default HolderInfoTableSolana;