import React, {useEffect, useState} from 'react';
import styles from './TransactionDetail.module.css';
import usdiIcon from '../static_files/usdi.svg';
import USDIcon from '../static_files/usd.svg';
import inProgressIcon from '../static_files/InProgress.png';
import AddEmailModal from "../AddEmailModal/AddEmailModal";
import Tooltip from "../Tooltip/Tooltip"

function TransactionDetailModal({
                                    fromAmount,
                                    fromCurrency,
                                    toCurrency,
                                    transactionType,
                                    network,
                                    networkFeePercent,
                                    rate,
                                    eta,
                                    onConfirm,
                                    onClose,
                                    localTime,
                                    onNetworkValuesUpdate,
                                    email,
                                    walletAddress
                                }) {
    const [networkFeeAmount, setNetworkFeeAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);

    // If true, we won't show AddEmailModal again.
    const [skipAddEmailPrompt, setSkipAddEmailPrompt] = useState(false);

    // Whether we're currently showing the AddEmailModal.
    const [showAddEmailModal, setShowAddEmailModal] = useState(false);

    useEffect(() => {
        async function loadFee() {
            const feePercent = parseFloat(networkFeePercent.replace("%", ""));
            const toAmountTemp = Number(
                (Math.floor(fromAmount * (100 - feePercent)) / 100).toFixed(2)
            );
            setToAmount(toAmountTemp);
            const feeAmount = (fromAmount - toAmountTemp).toFixed(2);
            setNetworkFeeAmount(feeAmount);

            if (onNetworkValuesUpdate) {
                onNetworkValuesUpdate(feeAmount, toAmountTemp, feePercent, eta);
            }
        }

        loadFee();
    }, [fromAmount, networkFeePercent, onNetworkValuesUpdate, eta]);

    // Called on the main "Confirm" button
    const handleConfirmClick = () => {
        // If we haven't skipped prompt, have a walletAddress, and no valid email
        if (!skipAddEmailPrompt && walletAddress && (!email || email === "--")) {
            setShowAddEmailModal(true);
        } else {
            onConfirm();
        }
    };

    /**
     * Close the AddEmailModal
     * @param {boolean} skipPrompt - whether to skip showing again
     */
    const handleCloseModal = (skipPrompt) => {
        setShowAddEmailModal(false);
        if (skipPrompt) {
            // If user chooses "No thanks" or "Add", skip next time
            setSkipAddEmailPrompt(true);
        }
    };

    /**
     * Close the AddEmailModal AND do the normal "Confirm" flow
     * (Called after a successful Add email)
     */
    const handleAddEmailSuccess = () => {
        setShowAddEmailModal(false);
        setSkipAddEmailPrompt(true);
        onConfirm();
    };
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };


    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>

            {/* Show the Transaction Detail only if AddEmailModal is NOT open */}
            {!showAddEmailModal && (
                <div className={styles.transactionDetailModal}>
                    <div className={styles.modalHeader}>
                        <h2>Transaction detail</h2>
                        <div
                            className={styles.closeBtn}
                            onClick={onClose}
                            aria-label="Close"
                        >
                            ×
                        </div>
                    </div>

                    <div className={styles.lineWrapper}>
                        <div className={styles.dividerLineFull}></div>
                    </div>

                    <div className={styles.modalBody}>
                        <div className={styles.statusSection}>
                            <div className={styles.statusImageContainer}>
                                <img
                                    src={inProgressIcon}
                                    alt="Status complete"
                                    className={styles.statusIcon}
                                />
                            </div>
                            <div className={styles.statusText}>In progress</div>
                        </div>

                        <div className={styles.transactionAmounts}>
                            <div className={styles.transactionFrom}>
                                <span className={styles.label}>From</span>
                                <div className={styles.amountRow}>
                                    <img src={usdiIcon} alt="From currency"/>
                                    <span className={styles.amount}>
                    -{fromAmount} {fromCurrency}
                  </span>
                                </div>
                            </div>

                            <div className={styles.transactionTo}>
                                <span className={styles.label}>To</span>
                                <div className={styles.amountRow}>
                                    <img src={USDIcon} alt="To currency"/>
                                    <span className={styles.greenAmount}>
                    +{Number(toAmount.toFixed(2))} {toCurrency}
                  </span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.transactionDetails}>
                            <div className={styles.detailRow}>
                                <span className={styles.detailLabel}>Transaction type</span>
                                <span className={styles.detailValue}>{transactionType}</span>
                            </div>
                            <div className={styles.detailRow}>
                                <span className={styles.detailLabel}>Time</span>
                                <span className={styles.detailValue}>{localTime}</span>
                            </div>
                            <div className={styles.detailRow}>
                                <span className={styles.detailLabel}>Network</span>
                                <Tooltip text={<>A decentralized crypto-to-cash network <br/>for on-ramp and off-ramp
                                    transactions</>}>
                                <span style={{cursor: "pointer", borderBottom: '1.4px dotted #797979'}}
                                      className={styles.detailValue}>
                                    {network}</span>
                                </Tooltip>
                            </div>
                            <div className={styles.detailRow}>
                                <Tooltip text={<>The total amount charged by the C2C <br/> network for processing this order</>}>
                                <span  style={{cursor: "pointer", borderBottom: '1.4px dotted #797979'}} className={styles.detailLabel}>
                                  Network fee ({networkFeePercent})
                                </span>
                                    </Tooltip>

                                <span className={styles.detailValue}>
                                  {networkFeeAmount} USDi
                                </span>

                            </div>
                            <div className={styles.detailRow}>
                                <span className={styles.detailLabel}>Rate</span>
                                <span className={styles.detailValue}>{rate}</span>
                            </div>
                            <div className={styles.detailRow}>
                                <span className={styles.detailLabel}>ETA</span>
                                <span className={styles.detailValue}>{eta}</span>
                            </div>
                        </div>

                        <div className={styles.lineWrapper}>
                            <div className={styles.dividerLineFull}></div>
                        </div>

                        <div className={styles.transactionNotes}>
                            <p>
                                Funds stay in a secure vault until the operator’s collateral is locked. Funds are
                                released only after the collateral is secured.&nbsp;
                                <a
                                    href="https://docs.kira.trading/kira-club/c2c-network/c2c-redemption-process"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{color: '#6CE3C4', textDecoration: 'underline', fontSize: "10px"}}
                                >
                                    Learn more
                                </a>.
                            </p>
                        </div>
                    </div>

                    <div className={styles.modalFooter}>
                        <button
                            type="button"
                            onClick={handleConfirmClick}
                            className={styles.confirmBtn}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}

            {showAddEmailModal && (
                <AddEmailModal
                    walletAddress={walletAddress}
                    onClose={handleCloseModal}
                    onSuccess={handleAddEmailSuccess}
                />
            )}
        </div>
    );
}

export default TransactionDetailModal;