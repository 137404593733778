import React, {useRef} from 'react';
import styles from './ConnectWalletHeaderAdminNavBar.module.css';
import {NavLink} from 'react-router-dom';
import menu_img from '../static_files/menu.svg';
import close_img from '../static_files/close.svg';
import logo_svg from '../static_files/logo-nobackground.svg';
import line from '../static_files/sideBarLine.svg';

const Header = () => {
    // 用 useRef 获取 sidebar、sideline、menu 三个元素引用
    const sidebarRef = useRef(null);
    const sidelineRef = useRef(null); // 你如果不再需要 sideline，可以保留空用
    const menuRef = useRef(null);

    function showSidebar() {
        // 直接改 style.display，不再使用 querySelector
        if (sidebarRef.current) sidebarRef.current.style.display = 'flex';
        if (sidelineRef.current) sidelineRef.current.style.display = 'block';
        if (menuRef.current) menuRef.current.style.display = 'none';
    }

    function closeSidebar() {
        if (sidebarRef.current) sidebarRef.current.style.display = 'none';
        if (sidelineRef.current) sidelineRef.current.style.display = 'none';
        if (menuRef.current) menuRef.current.style.display = 'block';
    }

    return (
        <>
            <div className={styles.hideOnDesktop}>
                {/* sideline 如果不需要可保持注释或删掉 */}
                {/* <ul className={styles.sideline} ref={sidelineRef}>
          <img
            src={line}
            alt=""
            className={`absolute left-[8.6%] z-20 ${styles.menu_bar}`}
          />
        </ul> */}

                {/* 给 sidebar 绑定 ref */}
                <ul className={styles.sidebar} ref={sidebarRef}>
                    <li>
                        <img onClick={closeSidebar} className={styles.close} src={close_img} alt=""/>
                    </li>
                    <div style={{display: 'flex', flexDirection: 'row', background: 'transparent'}}>
                        <img src={line} alt="" className={styles.menu_bar}/>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: 'transparent',
                                marginLeft: '15px',
                                marginTop: '45px',
                            }}
                        >
                            <li>
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/usdi-stats"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link mobile-link' : 'mobile-link nav-link'
                                    }
                                >
                                    USDI Stats
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/email-uid-txs-requests"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link mobile-link' : 'mobile-link nav-link'
                                    }
                                >
                                    Email UID Txs Requests
                                </NavLink>
                            </li>

                            <li className="sidebar-link">
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/user-txs"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link' : 'mobile-link nav-link'
                                    }
                                >
                                    User Txs
                                </NavLink>
                            </li>
                            <li className="sidebar-link">
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/token-management"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link' : 'mobile-link nav-link'
                                    }
                                >
                                    Token Management
                                </NavLink>
                            </li>
                            <li className="sidebar-link">
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/track-records"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link mobile-link' : 'mobile-link nav-link'
                                    }
                                >
                                    Track Records
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/portfolio"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link mobile-link' : 'mobile-link nav-link'
                                    }
                                >
                                    Portfolio
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    onClick={closeSidebar}
                                    to="/home/monitor"
                                    className={({isActive}) =>
                                        isActive ? 'nav-link active-link mobile-link' : 'mobile-link nav-link'
                                    }
                                >
                                    Monitor
                                </NavLink>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>

            <a
                href="https://0max1.com/"
                target="_blank"
                style={{display: 'flex', alignItems: 'center'}}
                rel="noreferrer"
            >
                <img className={styles.vectorIcon} alt="" src={logo_svg}/>
            </a>

            <ul className={styles.navBar}>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        to="/home/usdi-stats"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        USDI Stats
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        onClick={closeSidebar}
                        to="/home/email-uid-txs-requests"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        Email UID Txs requests
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>

                    <NavLink
                        onClick={closeSidebar}
                        to="/home/user-txs"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        User Txs
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        to="/home/token-management"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        Token Management
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        to="/home/track-records"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        Track Records
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        to="/home/portfolio"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        Portfolio
                    </NavLink>
                </li>
                <li className={styles.hideOnMobile}>
                    <NavLink
                        to="/home/monitor"
                        className={({isActive}) => (isActive ? 'nav-link active-link' : 'nav-link')}
                    >
                        Monitor
                    </NavLink>
                </li>

                {/* 绑定 menuRef 并在点击时调用 showSidebar */}
                <li className={styles.hideOnDesktop} style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        ref={menuRef}
                        onClick={showSidebar}
                        className={styles.menu}
                        src={menu_img}
                        alt=""
                    />
                </li>
            </ul>
        </>
    );
};

export default Header;