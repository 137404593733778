import React, { useState, useRef, useEffect } from 'react';
import styles from './TooltipRight.module.css';

const TooltipRight = ({ text, children, link, backgroundColor }) => {
  const [hovered, setHovered] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [arrowStyle, setArrowStyle] = useState({});
  const tooltipRef = useRef(null);

  const showTooltip = () => setHovered(true);
  const hideTooltip = () => setHovered(false);

  useEffect(() => {
    if (!hovered || !tooltipRef.current) {
      setTooltipStyle({});
      setArrowStyle({});
      return;
    }

    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const { innerWidth, innerHeight } = window;
    const offset = 8; // 给 tooltip 留点跟元素的间隙

    // ---------------------------
    // 1) 默认：Tooltip 在触发元素右侧，垂直居中
    // ---------------------------
    let newStyle = {
      top: '50%',
      left: `calc(100% + ${offset}px)`, // 右侧偏移 8px
      transform: 'translateY(-50%)',    // 垂直居中
    };

    // 箭头默认放在最左边（外面），同样垂直居中
    let newArrowStyle = {
      top: '50%',
      left: 0,
      transform: 'translate(-100%, -50%)',
    };

    // ---------------------------
    // 2) 判断是否超出屏幕右边界
    // ---------------------------
    // 因为 tooltip 是在右侧，如果 tooltipRect.right 超过了 viewport，可能需要左移
    if (tooltipRect.right > innerWidth - offset) {
      const pushX = tooltipRect.right - (innerWidth - offset);
      // 往左移 pushX
      newStyle.transform = `translateY(-50%) translateX(-${pushX}px)`;
      // 箭头相对于 tooltip 内部往右移 pushX
      newArrowStyle.transform = `translate(-100%, -50%) translateX(${pushX}px)`;
    }

    // ---------------------------
    // 3) 判断是否超出屏幕顶部或底部
    // ---------------------------
    // 如果上方超出
    if (tooltipRect.top < offset) {
      const pushY = offset - tooltipRect.top;
      newStyle.transform += ` translateY(${pushY}px)`;
      newArrowStyle.transform += ` translateY(-${pushY}px)`;
    }
    // 如果下方超出
    else if (tooltipRect.bottom > innerHeight - offset) {
      const pushY = tooltipRect.bottom - (innerHeight - offset);
      newStyle.transform += ` translateY(-${pushY}px)`;
      newArrowStyle.transform += ` translateY(${pushY}px)`;
    }

    setTooltipStyle(newStyle);
    setArrowStyle(newArrowStyle);
  }, [hovered]);

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {hovered && (
        <div
          className={styles.tooltip}
          ref={tooltipRef}
          style={{
            ...tooltipStyle,
            '--tooltip-bg': backgroundColor
          }}
        >
          {text}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={styles.learnMoreLink}
            >
              Learn More
            </a>
          )}
          <div className={styles.arrow} style={arrowStyle} />
        </div>
      )}
    </div>
  );
};

export default TooltipRight;