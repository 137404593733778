import React, { useState, useEffect } from 'react';
import ConnectWalletHeader from "./header/ConnectWalletHeader";
import Footer from './footer/Footer';

import TxsHistoryTable from './usdi_txs_tables/TxsHistoryTable';
import CashC2CTxsHistoryTable from './usdi_txs_tables/CashC2CTxsHistoryTable';

import './usdi_tables/USDIStats.css';

const UserTxs = () => {
  const [data, setData] = useState({
    transaction_history: [],
    cash_redeem: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showPanel, setShowPanel] = useState(false);
  const [selectedChain, setSelectedChain] = useState('eth');

  // 拉取 API 数据
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        'https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_user_history'
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData({
        transaction_history: result.transaction_history || [],
        cash_redeem: result.cash_redeem || []
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChainSelect = (chain) => {
    setSelectedChain(chain);
  };

  // if (loading) return <div></div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container">
      <div style={{ marginTop: '7px' }}>
        <ConnectWalletHeader
          isAdminHeader={true}
          showPanel={showPanel}
          setShowPanel={setShowPanel}
        />
      </div>

      <TxsHistoryTable
        data={data.transaction_history}
        selectedChain={selectedChain}
        onChainSelect={handleChainSelect}
      />

      <CashC2CTxsHistoryTable
        data={data.cash_redeem}
        selectedChain={selectedChain}
        onChainSelect={handleChainSelect}
      />

      <Footer />
    </div>
  );
};

export default UserTxs;