import React, {useState, useEffect, useContext} from 'react';
import styles from './ConnectWalletHeader.module.css';
import {raydiumSwap} from './raydiumSwap/raydium-swap';
import email_svg from '../static_files/connect-email.svg';
import okx_icon from '../static_files/OKX-icon.svg';
import coinbase_icon from '../static_files/Coinbase-icon.svg';
import walletconnect_icon from '../static_files/walletconnect-icon.svg';
import Phantom_icon from '../static_files/Phantom Wallet.svg';
import menu_icon from "../static_files/swap_main_menu_mobile_icon.svg";
import logo_svg from "../static_files/logo-nobackground.svg";
import rejectImg from '../static_files/rejectImg.svg';
import cancle_icon from '../static_files/cancel.svg';
import arrowDownImg from '../static_files/arrow_down_no_background.svg';
import successImg from '../static_files/successImg.svg';
import warningImg from '../static_files/warningImg.svg';
import disconnectImg from '../static_files/disconnect.svg';
import emailLogoutImg from "../static_files/emailLogout.svg";
import {
    fetchBalancesAndNotifyParent,
    getTokenAccountFromWalletAddress,
    sleep2,
    sleep,
    fetchTokenBalances,
    chains,
    metadata,
    projectId,
    // addMemoInstruction
} from '../header/ConnectWalletHeaderOnchainHelper';
import {
    Connection,
    PublicKey,
    Transaction,
    TransactionInstruction
} from '@solana/web3.js';
// Email login
import {AuthContext, useAuth} from '../../AuthContext';
import {useNavigate} from "react-router-dom";

// wallet connect
import {useWeb3Modal, createWeb3Modal, defaultSolanaConfig} from '@web3modal/solana/react';
import {solana} from '@web3modal/solana/chains';
// import {useWalletInfo} from '@web3modal/wagmi/react';
import {useWeb3ModalAccount} from '@web3modal/solana/react';
import {useWeb3ModalProvider} from '@web3modal/solana/react';


import {
    getOrCreateAssociatedTokenAccount,
    createTransferInstruction,
} from '@solana/spl-token';
import SideMenu from "./MobileMenu";
import {SwapMainAppContext} from "../../SwapMainAppContext";
import ConnectWalletHeaderAdminNavBar from "./ConnectWalletHeaderAdminNavBar";


/**
 * Props:
 *  - onWalletConnected : 用来传出 Component， 给parent 告诉连接的地址 + balance
 *  - amount: user input mint/redeem amount 输入金额
 *  - transactionTrigger: to trigger transaction by pressing the button 是否触发
 *  - toAddress: 默认是到squads vault 的账户
 *  - selectedToken: 传入 transaction用了什么token
 *  - selectedPaymentID: 用户cash redeem 的时候选择用什么PaymentMethod的ID
 *  - transactionType：传入 transaction redeem/mint?
 *  - transactionTime: 发生交易的时间
 *  - emailWallet: 从email address 传来的选择的wallet
 *  - onClearTransactions: 用来disconnect wallet时 清空transaction page state
 *  - onClearAccountData, // 接收从 Account.js 传进来的回调: 用来disconnect wallet时 清空 accounts page state
 *  - selectedChainTrigger: email 用户选择不同链的时候，头上的wallet address改变
 *  - isAdminHeader 用在admin page , BOOLEAN
 *  - transferComplete(); usdi transfer complete call back
 */


// For wallet connect modal
createWeb3Modal({
    solanaConfig: defaultSolanaConfig({
        metadata,
        defaultChain: solana,
        enableInjected: true // Optional - defaults to true
    }), // required
    chains, // required
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration,
});

// used to record transaction to db
async function record_transaction(payload) {
    try {
        // 获取当前 UTC 时间并格式化为 MM/DD/YYYY, HH:mm:ss
        const now = new Date();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const year = now.getUTCFullYear();
        const hours = String(now.getUTCHours()).padStart(2, "0");
        const minutes = String(now.getUTCMinutes()).padStart(2, "0");
        const seconds = String(now.getUTCSeconds()).padStart(2, "0");
        const currentUTCTime = `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;

        // 把当前 UTC 时间加入到 payload 中
        const finalPayload = {
            ...payload,
            txs_created_timestamp: currentUTCTime
        };
        // // 发送请求
        const response = await fetch(
            "https://usdi-api-256611876551.europe-west1.run.app/record_transaction",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(finalPayload)
            }
        );

        if (!response.ok) {
            throw new Error(`Server error: ${response.status} - ${response.statusText}`);
        }

        // 如果需要，可进一步处理返回的数据
        const data = await response.json();
        console.log("Transaction recorded successfully:", data);
        return data;
    } catch (error) {
        console.error("Error recording transaction:", error);
        throw error;
    }
}

const ConnectWalletHeader = ({
                                 amount,
                                 transactionTrigger,
                                 toAddress,
                                 onWalletConnected,
                                 selectedToken,
                                 transactionType,
                                 transactionTime,
                                 showPanel,
                                 setShowPanel,
                                 onKiraSent,
                                 onClearTransactions,
                                 onClearAccountData, // 接收从 Account.js 传进来的回调
                                 selectedPaymentId,
                                 selectedPaymentMethod,
                                 selectedChainTrigger,
                                 isAdminHeader = false,  // 新增可选参数，默认 false
                                 transferComplete,
                             }) => {
    const {
        notification, showNotification,
        usdiTokenAddress,
        usdtTokenAddress,
        usdcTokenAddress,
        kraTokenAddress,
        c2csquadReceivingAddress
    } = useContext(SwapMainAppContext);
    const {
        walletAddress: connectedPublicKey,
        // rename setWalletAddress to setConnectedPublicKey locally
        //    connectedPublicKey =  完整的 address
        setWalletAddress: setConnectedPublicKey,
    } = useContext(SwapMainAppContext);
    // Track which wallet is connected
    const [connectedWalletType, setConnectedWalletType] = useState(null);
    // Shorten version address
    const [walletAddress, setWalletAddress] = useState(() => {
        const storedAddress = connectedPublicKey;
        return storedAddress ? shortenAddress(storedAddress) : 'Connect Wallet';
    });

    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('myAssets');


    const [okxSolanaProvider, setOkxSolanaProvider] = useState(null);
    const [coinbaseSolanaProvider, setCoinbaseSolanaProvider] = useState(null);
    const [phantomSolanaProvider, setPhantomSolanaProvider] = useState(null);

    // For wallet Connect
    const [walletConnectProvider, setWalletConnectProvider] = useState(null);
    const {open} = useWeb3Modal();
    const {address: modalAddress} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    // For detecting if the wallet extension has been installed
    const [isOkxDetected, setIsOkxDetected] = useState(false);
    const [isCoinbaseDetected, setIsCoinbaseDetected] = useState(false);
    const [isPhantomDetected, setIsPhantomDetected] = useState(false);

    //Gat way:
    const {logout, gatewayUserData} = useAuth();

    // wallet Connect Use effect
    useEffect(() => {
        if (modalAddress) {
            setWalletAddress(shortenAddress(modalAddress));
            setConnectedPublicKey(modalAddress);
        }
    }, [modalAddress]);
    useEffect(() => {
        if (walletProvider) {
            if (connectedWalletType !== null) {
                handleWalletConnectSolana();
            }
        }
    }, [walletProvider]);

    // const RPC_URL = "https://rpc.ankr.com/solana/80465c767c6a5751c4cadf0778b6917d26c2d0a9ae583d3616ffaae71191a7c4";
    const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f";

    // 从email user 读取出来的address， 好像只是为了display用
    useEffect(() => {
        if ((localStorage.getItem("userEmail") && localStorage.getItem("emailUserWalletAddress") !== "null" && localStorage.getItem("emailUserWalletAddress") !== null && localStorage.getItem("emailUserWalletAddress") !== "requested")) {
            setWalletAddress(shortenAddress(localStorage.getItem("emailUserWalletAddress")));

        } else if (localStorage.getItem("userEmail") && gatewayUserData) {
            setWalletAddress("Create Wallet");
        }
    }, [gatewayUserData, selectedChainTrigger]);

    // -----------------------------------------
    // Use Effect for handling transactions
    // -----------------------------------------
    // Handel Transfer trigger ->
    // 1. cash redeem,
    // 2. amount > 11000 mint/redeem
    // 3. amount <= 11000
    // 4. kira
    // 5. only transfer USDI -> no additional action required, now it's only for admin user send usdi
    useEffect(() => {
        if (selectedToken === 'CASH') {
            const getC2CVaultAddress = async () => {
                try {
                    //找到用户应该先把USDi往那个squad合约发
                    const C2C_USDI_RECEIVING_TOKEN_ACCOUNT = await getTokenAccountFromWalletAddress(c2csquadReceivingAddress, usdiTokenAddress);
                    handleSolanaTransaction(C2C_USDI_RECEIVING_TOKEN_ACCOUNT);
                } catch (err) {
                    console.error('Error recording CASH transaction:', err);
                    showNotification('error', `CASH flow failed: ${err.message}`);
                }
            };
            getC2CVaultAddress();
        } else if (selectedToken === 'KIRA' && Number(amount) > 0) {
            //  If the user wants to send KIRA tokens, use standard on-chain transfer. C2C
            const getC2CVaultAddress = async () => {
                try {
                    const C2C_KIRA_RECEIVING_TOKEN_ACCOUNT = await getTokenAccountFromWalletAddress(
                        c2csquadReceivingAddress,
                        kraTokenAddress
                    );
                    handleSolanaTransaction(C2C_KIRA_RECEIVING_TOKEN_ACCOUNT);
                } catch (err) {
                    console.error('Error transferring Kira :', err);
                    showNotification('error', `transferring Kira failed: ${err.message}`);
                }
            };
            getC2CVaultAddress();
        } else if (selectedToken === 'USDI' && Number(amount) > 0 && transactionType === "transfer") {
            //  If the user wants to send KIRA tokens, use standard on-chain transfer. C2C
            const transferUSDi = async () => {
                try {
                    handleSolanaTransaction();
                } catch (err) {
                    console.error('Error transferring USDi :', err);
                    showNotification('error', `transferring USDi failed: ${err.message}`);
                }
            };
            transferUSDi();

            // amount greater than 11000 use squad
            // to do change it back
        } else if (Number(amount) >= 11000) {
            handleSolanaTransaction();

            //Raydium swap
        } else if (Number(amount) > 0 && transactionTrigger) {
            async function doRaydiumSwap() {
                if (!connectedPublicKey) return;
                let providerToUse = null;
                if (connectedWalletType === 'okx') {
                    providerToUse = okxSolanaProvider;
                } else if (connectedWalletType === 'coinbase') {
                    providerToUse = coinbaseSolanaProvider;
                } else if (connectedWalletType === 'walletconnect') {
                    providerToUse = walletConnectProvider;
                } else if (connectedWalletType === 'phantom') {
                    providerToUse = phantomSolanaProvider;
                }

                const {usdcBalance, usdtBalance, usdiBalance} = await fetchTokenBalances(
                    providerToUse,
                    connectedPublicKey,
                );
                if (!providerToUse || isNaN(amount) || Number(amount) <= 0) {
                    return;
                }
                const canProceed = verifyBalanceBeforeTransaction({
                    selectedToken,
                    transactionType,
                    amount,
                    usdcBalance,
                    usdtBalance,
                    usdiBalance,
                });
                if (!canProceed) {
                    showNotification('warning', "Insufficient wallet balance.");
                    await fetchBalancesAndNotifyParent(providerToUse, connectedPublicKey, onWalletConnected);
                    return;
                }
                const rc = (await import('./raydiumSwap/raydium-config.js')).default;
                rc.ownerAddress = new PublicKey(connectedPublicKey);
                // await open({view:''});
                //  console.log('res',res)
                // close()
                rc.signAllTransactions = async (txs) => {
                    const signedTxs = [];
                    for (const tx of txs) {
                        const signed = await providerToUse.signTransaction(tx);
                        signedTxs.push(signed);
                    }
                    return signedTxs;
                };
                await rc.initSdk();
                const {raydiumSwap} = await import('./raydiumSwap/raydium-swap.js');
                try {

                    const result = await raydiumSwap({
                        transactionType,
                        tokenType: selectedToken,
                        amount: Number(amount)
                    });
                    if (result.success) {
                        const txId = result.txId;
                        console.log("transaction here", result);
                        const explorerLink = `https://solscan.io/tx/${txId}`;
                        showNotification('success', 'Your transaction was successful!');
                        showNotification('success', `${transactionType} of ${amount} ${selectedToken.toUpperCase()} successfully submitted.
                         <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`);
                        // record history

                        const payload = {
                            txs_type: transactionType,
                            method: selectedToken,
                            from_token_amount: amount,
                            from_address: connectedPublicKey,
                            transaction_signature: txId
                        };
                        await record_transaction(payload);
                        // todo 刷新
                        await new Promise(resolve => setTimeout(resolve, 3000));

                        await fetchBalancesAndNotifyParent(providerToUse, connectedPublicKey, onWalletConnected);
                    } else {
                        console.error('Raydium swap error:', result.code, result.message);
                        if (result.code === 4001) {
                            showNotification('error', "User rejected the transaction.");
                        } else {
                            showNotification('error', `Transaction failed: ${result.message}`);
                        }
                    }
                } catch (err) {
                    console.error(`Error transferring token on Solana:`, err);
                    if (err?.code === 4001) {
                        showNotification('error', "User rejected the transaction.");
                    } else {
                        showNotification('error', `Transaction failed: ${err.message}`);
                    }
                }
            }

            doRaydiumSwap();
        }
    }, [transactionTrigger]);

    // 检测  Wallet extension ：是否存在
    useEffect(() => {
        if (window.okxwallet && window.okxwallet.solana) {
            setIsOkxDetected(true);
        }
        if (window.coinbaseSolana) {
            setIsCoinbaseDetected(true);
        }
        let phantomProvider = window.phantom?.solana || window.solana;
        if (phantomProvider && phantomProvider.isPhantom) {
            setIsPhantomDetected(true);
        }
    }, []);


    // Read local storage for last connected wallet
    useEffect(() => {
        const lastWallet = localStorage.getItem('lastConnectedWallet');
        const isEmailLogin = localStorage.getItem('emailUserWalletAddress');
        if (isEmailLogin) {
            // (No action needed: email is already connected)
        } else if (lastWallet === 'okx') {
            handleOKXWalletSolana();
        } else if (lastWallet === 'coinbase') {
            handleCoinbaseWalletSolana();
        } else if (lastWallet === 'phantom') {
            handlePhantomWalletSolana();
        } else if (lastWallet === 'walletconnect') {
            handleWalletConnectSolana();
        }
    }, []);

    // -----------------------------------------
    // UTILITY
    // -----------------------------------------
    const handleConnectWalletClick = (e) => {
        e.stopPropagation();
        setShowPanel((prev) => !prev);
    };
    const closePanel = () => setShowPanel(false);
    const stopPropagation = (e) => e.stopPropagation();
    const handleOutsideClick = () => {
        if (showPanel) closePanel();
    };
    const handleMenuIconClick = (e) => {
        e.stopPropagation();
        setIsSideMenuOpen(true);
    };
    const handleOutsideClickSideMenu = () => {
        setIsSideMenuOpen(false);
    };
    const handleMenuItemClick = (item) => {
        setSelectedMenuItem(item);
        setIsSideMenuOpen(false);
    };
    const handleLoginWithEmail = () => {
        window.location.href = 'https://gateway.0max1.com';
    };

    const handleLogoutWithEmail = () => {

        if (logout()) {
            showNotification('success', "You have successfully logged out of your email account.");

        } else {
            showNotification('error', "An error occurred while logging out of your email account. Please try again.");
        }
        setWalletAddress("Connect Wallet")
    };

    function shortenAddress(address, startLength = 4, endLength = 4) {
        if (!address) return '';
        if (address.length <= startLength + endLength) {
            return address;
        }
        const start = address.slice(0, startLength);
        const end = address.slice(-endLength);
        return `${start}..${end}`;
    }

    // -----------------------------------------
    // OKX CONNECT & FETCH
    // -----------------------------------------
    const handleOKXWalletSolana = async () => {
        // Email 登录过了不能再connect 钱包
        if (localStorage.getItem("userEmail")) {
            setShowPanel(false);
            showNotification('warning', "Please log out of your email account first, then reconnect your wallet.");
            return;
        }
        try {
            const provider = window.okxwallet?.solana;
            if (!provider) {
                return;
            }
            await provider.connect();
            const publicKey = provider.publicKey?.toString();
            if (!publicKey) {
                showNotification('error', 'Unable to retrieve Solana public key from OKX Wallet.');
                return;
            }
            setConnectedWalletType("okx");
            localStorage.setItem('lastConnectedWallet', 'okx');
            setWalletAddress(shortenAddress(publicKey));

            localStorage.setItem('walletAddress', publicKey);
            setConnectedPublicKey(publicKey);
            setOkxSolanaProvider(provider);
            setShowPanel(false);
            await fetchBalancesAndNotifyParent(provider, publicKey, onWalletConnected);
        } catch (error) {
            console.error('Error connecting to OKX Wallet (Solana):', error);
        }
    };

    // -----------------------------------------
    // COINBASE CONNECT & FETCH
    // -----------------------------------------
    const handleCoinbaseWalletSolana = async () => {
        // Email 登录过了不能再connect 钱包
        if (localStorage.getItem("userEmail")) {
            setShowPanel(false);
            showNotification('warning', "Please log out of your email account first, then reconnect your wallet.");
            return;
        }
        try {
            const provider = window.coinbaseSolana;
            if (!provider) {
                window.open('https://www.coinbase.com/wallet', '_blank');
                return;
            }
            await provider.connect();
            const publicKey = provider.publicKey?.toString();
            if (!publicKey) {
                showNotification('error', 'Unable to retrieve Solana public key from Coinbase Wallet.');
                return;
            }
            setConnectedWalletType("coinbase");
            localStorage.setItem('lastConnectedWallet', 'coinbase');
            setWalletAddress(shortenAddress(publicKey));

            localStorage.setItem('walletAddress', publicKey);

            setConnectedPublicKey(publicKey);
            setCoinbaseSolanaProvider(provider);
            setShowPanel(false);
            await fetchBalancesAndNotifyParent(provider, publicKey, onWalletConnected);
        } catch (error) {
            console.error('Error connecting to Coinbase Wallet (Solana):', error);
        }
    };

    // -----------------------------------------
    // WALLETCONNECT CONNECT & FETCH
    // -----------------------------------------
    const handleWalletConnectSolana = async () => {
        // Email 登录过了不能再connect 钱包
        if (localStorage.getItem("userEmail")) {
            setShowPanel(false);
            showNotification('warning', "Please log out of your email account first, then reconnect your wallet.");
            return;
        }
        try {
            setShowPanel(false);
            await open({view: 'Connect'}); // Opens the WalletConnect modal
            await new Promise(resolve => setTimeout(resolve, 1000));
            if (modalAddress) {
                setConnectedWalletType("walletconnect");
                localStorage.setItem('lastConnectedWallet', 'walletconnect');
                localStorage.setItem('walletAddress', modalAddress);

                setWalletAddress(shortenAddress(modalAddress));

                setConnectedPublicKey(modalAddress);
                setWalletConnectProvider(walletProvider);
                if (walletProvider) {
                    console.log("walletProvider trying to fetch balance");
                    await fetchBalancesAndNotifyParent(walletProvider, modalAddress, onWalletConnected);
                }
                setShowPanel(false);
            } else {
                showNotification('warning', 'WalletConnect connection failed: Please try again.');
            }
        } catch (error) {
            console.error('WalletConnect error:', error);
        }
    };

    const getPhantomProvider = () => {
        let provider = window.phantom?.solana;
        if (!provider) {
            provider = window.solana;
        }
        if (provider && provider.isPhantom) {
            return provider;
        }
        window.open('https://phantom.app/', '_blank');
        return null;
    };
    const handlePhantomWalletSolana = async () => {
        // Email 登录过了不能再connect 钱包
        if (localStorage.getItem("userEmail")) {
            setShowPanel(false);
            showNotification('warning', "Please log out of your email account first, then reconnect your wallet.");
            return;
        }
        try {
            const provider = getPhantomProvider();
            if (!provider) {
                return;
            }
            // Request connection to the Phantom Wallet
            await provider.connect();
            const publicKey = provider.publicKey?.toString();
            if (!publicKey) {
                showNotification('error', 'Unable to retrieve Solana public key from Phantom Wallet.');
                return;
            }
            // Update state with Phantom connection details
            setConnectedWalletType("phantom");
            localStorage.setItem('lastConnectedWallet', 'phantom');
            setWalletAddress(shortenAddress(publicKey));
            localStorage.setItem('walletAddress', publicKey);
            setConnectedPublicKey(publicKey);
            setPhantomSolanaProvider(provider);
            setShowPanel(false);
            // Fetch balances (or any other post-connection actions)
            await fetchBalancesAndNotifyParent(provider, publicKey, onWalletConnected);
        } catch (error) {
            console.error('Error connecting to Phantom Wallet (Solana):', error);
        }
    };

    function verifyBalanceBeforeTransaction({
                                                selectedToken,
                                                transactionType,
                                                amount,
                                                usdcBalance,
                                                usdtBalance,
                                                usdiBalance,
                                                kiraBalance
                                            }) {
        const needed = Number(amount) || 0;
        if (transactionType === 'mint') {
            if (selectedToken === 'USDC' && needed > usdcBalance) {
                return false;
            }
            if (selectedToken === 'USDT' && needed > usdtBalance) {
                return false;
            }
        } else if (selectedToken === "KIRA") {
            if (needed > kiraBalance) {
                return false;
            }
        } else if (transactionType === 'redeem') {
            if (needed > usdiBalance) {
                return false;
            }
        } else if (transactionType === "transfer") {
            if (needed > usdiBalance) {
                return false;
            }
        }
        return true;
    }

    // -----------------------------------------
    // TRANSFER LOGIC
    // -----------------------------------------
    const handleSolanaTransaction = async (C2C_RECEIVING_TOKEN_ACCOUNT = null) => {
        if (!connectedPublicKey) {
            return;
        }
        let providerToUse = null;
        if (connectedWalletType === 'okx') {
            providerToUse = okxSolanaProvider;
        } else if (connectedWalletType === 'coinbase') {
            providerToUse = coinbaseSolanaProvider;
        } else if (connectedWalletType === 'phantom') {
            providerToUse = phantomSolanaProvider;
        } else if (connectedWalletType === 'walletconnect') {
            providerToUse = walletConnectProvider;
        }
        const {
            usdcBalance,
            usdtBalance,
            usdiBalance,
            kiraBalance
        } = await fetchTokenBalances(providerToUse, connectedPublicKey);
        if (!providerToUse || isNaN(amount) || Number(amount) <= 0) {
            return;
        }
        const canProceed = verifyBalanceBeforeTransaction({
            selectedToken,
            transactionType,
            amount,
            usdcBalance,
            usdtBalance,
            usdiBalance,
            kiraBalance
        });
        if (!canProceed) {
            showNotification('warning', `Insufficient ${selectedToken} balance.`);
            await fetchBalancesAndNotifyParent(providerToUse, connectedPublicKey, onWalletConnected);
            return;
        }
        if (selectedToken === 'CASH') {
            await transferSplToken(connectedPublicKey, providerToUse, 'USDI', C2C_RECEIVING_TOKEN_ACCOUNT);
        } else if (selectedToken === 'KIRA') {
            await transferSplToken(connectedPublicKey, providerToUse, 'KIRA', C2C_RECEIVING_TOKEN_ACCOUNT);
        } else if (transactionType === 'mint') {
            await transferSplToken(connectedPublicKey, providerToUse, selectedToken, null);
        } else if (transactionType === 'redeem') {
            await transferSplToken(connectedPublicKey, providerToUse, 'USDI', null);
        } else if (transactionType === 'transfer') {
            await transferSplToken(connectedPublicKey, providerToUse, 'USDI', null);
        } else {
            console.log(`Transaction type "${transactionType}" not implemented.`);
        }
    };

    const transferSplToken = async (fromPublicKeyStr, provider, tokenType, C2C_RECEIVING_TOKEN_ACCOUNT) => {
        try {
            const connection = new Connection(RPC_URL, "confirmed");
            const fromPublicKey = new PublicKey(fromPublicKeyStr);
            let tokenMintAddress;
            let toTokenAccountPubkey;
            let readableToken;

            // 有C2C_RECEIVING_TOKEN_ACCOUNT -> cash redeem
            if (C2C_RECEIVING_TOKEN_ACCOUNT !== null && tokenType === "USDI") {
                tokenMintAddress = usdiTokenAddress;
                readableToken = "USDi";
                toTokenAccountPubkey = new PublicKey(C2C_RECEIVING_TOKEN_ACCOUNT);
            } else if (C2C_RECEIVING_TOKEN_ACCOUNT !== null && tokenType === "KIRA") {
                tokenMintAddress = kraTokenAddress;
                readableToken = "Kira";
                toTokenAccountPubkey = new PublicKey(C2C_RECEIVING_TOKEN_ACCOUNT);
            } else if (tokenType === 'USDT') {
                tokenMintAddress = usdtTokenAddress;
                readableToken = "USDT";
                toTokenAccountPubkey = new PublicKey("3snmSNcrZ3CMdCBuLWgWNqmSyDa18EXk7AUCZ9AM1PG3");
            } else if (tokenType === 'USDC') {
                tokenMintAddress = usdcTokenAddress;
                readableToken = "USDC";
                toTokenAccountPubkey = new PublicKey("7iQ1PmuqUD2uWVh6atLB8r42qE8BFk6uFUHX4PNzzcuY");
            } else if (tokenType === 'USDI') {
                tokenMintAddress = usdiTokenAddress;
                readableToken = "USDi";
                if (transactionType === "transfer") {
                    const toTokenAddress = await getTokenAccountFromWalletAddress(toAddress,usdiTokenAddress)
                    toTokenAccountPubkey = new PublicKey(toTokenAddress)
                } else {
                    //TODO 改成 getTokenAccountFromWalletAddress
                    toTokenAccountPubkey = new PublicKey("C4xyFYKJJQwD7sGNmi1av6f9XcPLZFZz25wXC6AH5bvc");
                }
            } else {
                showNotification('error', "Unknown token type. Canceling transaction.");
                return;
            }

            const latestBlockhash = await connection.getLatestBlockhash("confirmed");
            let signature;

            // prepare a signer object
            const payer = {
                publicKey: fromPublicKey,
                signTransaction: async (transaction) => {
                    return await provider.signTransaction(transaction);
                },
            };

            const mintPubkey = new PublicKey(tokenMintAddress);
            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                payer,
                mintPubkey,
                fromPublicKey
            );
            const amountInBaseUnits = Math.floor(Number(amount) * 1_000_000);
            if (isNaN(amountInBaseUnits) || amountInBaseUnits <= 0) {
                showNotification('error', `Invalid ${readableToken} amount. Please provide a positive number.`);
                return;
            }
            // Create the transfer instruction
            const transferIx = createTransferInstruction(
                fromTokenAccount.address,
                toTokenAccountPubkey,
                fromPublicKey,
                amountInBaseUnits
            );

            // --- ADDED MEMO INSTRUCTION --- 这一段不要随意改动， 因为在后台raw blockchain transaction 有parsed data
            const memoText = `Send ${amount} ${readableToken} to ${toTokenAccountPubkey.toBase58()}`;
            const memoIx = new TransactionInstruction({
                keys: [],
                programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
                data: Buffer.from(memoText, 'utf8'),
            });

            // Build the transaction with transfer + memo
            const transaction = new Transaction().add(transferIx).add(memoIx);
            transaction.feePayer = fromPublicKey;
            transaction.recentBlockhash = latestBlockhash.blockhash;

            const result = await provider.signAndSendTransaction(transaction);
            signature = result.signature;
            console.log("signature here", signature);

            const explorerLink = `https://solscan.io/tx/${signature}`;
            showNotification('success', `${transactionType} of ${amount} ${readableToken} successfully submitted.
                 <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`);

            // 等三秒在刷新balance
            await new Promise(resolve => setTimeout(resolve, 3000));
            await fetchBalancesAndNotifyParent(provider, fromPublicKeyStr, onWalletConnected);
            // 有C2C_RECEIVING_TOKEN_ACCOUNT -> cash redeem
            if (transactionType === "transfer") {
                transferComplete();
            } else if (C2C_RECEIVING_TOKEN_ACCOUNT !== null && tokenType === "USDI") {
                try {
                    const response = await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/record_cash", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            requester_wallet_address: fromPublicKeyStr,
                            redeem_amount: amount,
                            utc_time: transactionTime,
                            transfer_signature: signature,
                            payment_info_id: selectedPaymentId,
                            payment_info_eta: selectedPaymentMethod ? selectedPaymentMethod.processing_time : null,
                            payment_info_fees: selectedPaymentMethod ? selectedPaymentMethod.fees : null
                        })
                    });
                    if (!response.ok) {
                        throw new Error(`Server responded with status ${response.status}`);
                    }
                    // record cash 之后设置选择的payment method 设置为默认支付方式
                    await fetch("https://max1-funding-arb.uc.r.appspot.com/set_default_payment", {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify({
                            wallet_address: connectedPublicKey,
                            payment_info_id: selectedPaymentId,
                        })
                    });
                    showNotification('success', `Cash received of ${amount} ${selectedToken.toUpperCase()} .
                         <a href="${explorerLink}" target="_blank" rel="noreferrer">Check on Explorer</a>`);
                } catch (error) {
                    console.error("Cash or redeem error:", error);
                    showNotification('error', `Error: ${error.message}`);
                }
            } else if (C2C_RECEIVING_TOKEN_ACCOUNT !== null && tokenType === "KIRA") {
                if (onKiraSent) {
                    onKiraSent({
                        from: connectedPublicKey,
                        amount,
                        signature
                    });
                }
            } else if (transactionType === 'mint') {
                triggerMintTransferToAddress(signature, fromPublicKeyStr, toAddress, amount);
                const payload = {
                    txs_type: transactionType,
                    method: selectedToken,
                    from_token_amount: amount,
                    from_address: connectedPublicKey,
                    transaction_signature: signature
                };
                await record_transaction(payload);

            } else if (transactionType === 'redeem') {
                triggerRedeemTransferToAddress(signature, fromPublicKeyStr, toAddress, amount, selectedToken);
                const payload = {
                    txs_type: transactionType,
                    method: selectedToken,
                    from_token_amount: amount,
                    from_address: connectedPublicKey,
                    transaction_signature: signature
                };
                await record_transaction(payload);
            }

        } catch (err) {
            console.error(`Error transferring token on Solana:`, err);
            if (err?.code === 4001) {
                showNotification('error', "User rejected the transaction.");
            } else {
                if (err && err.message) {
                    showNotification('error', `Transaction failed: ${err.message}`);
                } else {
                    console.log("error! ", err);
                    showNotification('error', 'Transaction failed, please try again');
                }
            }
        }
    };

    // 金额大于11000， 到后台trigger squads
    const triggerMintTransferToAddress = async (signature, fromAddr, toAddr, amt) => {
        console.log("Mint Transfer triggered. Sending body:", signature, fromAddr, toAddr, amt);
        await sleep(60000);
        const requestBody = {
            "transaction_hash": String(signature),
            "from_address": String(fromAddr),
            "to_address": String(toAddr),
            "amount": String(amt)
        };
        fetch("https://usdi-api-256611876551.europe-west1.run.app/trigger-mint-transfer-to-address", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(requestBody)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                console.log("API response:", data);
            })
            .catch((err) => {
                console.error("Error sending to server:", err);
            });
    };

    // 金额大于11000， 到后台trigger squads
    async function triggerRedeemTransferToAddress(signature, fromAddr, toAddr, amt, tokenType) {
        console.log("Manual redeem triggered. Sending body:", signature, fromAddr, toAddr, amt, tokenType);
        await sleep2(60000);
        const requestBody = {
            transaction_hash: String(signature),
            from_address: String(fromAddr),
            to_address: String(toAddr),
            amount: String(amt),
            token_type: String(tokenType)
        };
        fetch("https://usdi-api-256611876551.europe-west1.run.app/trigger-redeem-transfer-to-address", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(requestBody)
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`Server responded with status: ${res.status}`);
                }
                return res.json();
            })
            .then((data) => {
                console.log("Redeem API response:", data);
            })
            .catch((err) => {
                console.error("Error calling redeem API:", err);
            });
    }

    // DISCONNECT
    const disconnectWallet = async () => {
        try {
            if (okxSolanaProvider && okxSolanaProvider.disconnect) {
                okxSolanaProvider.disconnect();
            }
            if (coinbaseSolanaProvider) {
                await window.coinbaseSolana.disconnect();
            }
            if (walletConnectProvider && walletConnectProvider.disconnect) {
                await walletConnectProvider.disconnect();
            }
            if (phantomSolanaProvider && phantomSolanaProvider.disconnect) {
                await phantomSolanaProvider.disconnect();
            }
            setConnectedPublicKey(null);
            setWalletAddress("Connect Wallet");

            setOkxSolanaProvider(null);
            setCoinbaseSolanaProvider(null);
            setWalletConnectProvider(null);
            setPhantomSolanaProvider(null);
            setShowPanel(false);
            setConnectedWalletType(null);
            localStorage.removeItem('lastConnectedWallet');
            localStorage.removeItem('walletAddress');

            if (onWalletConnected) {
                onWalletConnected(null, 0, 0, 0);
            }
            showNotification('success', 'Your wallet has been successfully disconnected.')
            // 用来删除 别的page 的state：
            if (onClearAccountData) {
                onClearAccountData();
            }
            if (onClearTransactions) {
                onClearTransactions();
            }
        } catch (err) {
            console.error("Error disconnecting wallet:", err);
        }
    };


    return (
        <div
            className={
                isAdminHeader
                    ? `${styles.outerContainer} ${styles.outerContainer2}`
                    : styles.outerContainer
            }
            onClick={handleOutsideClick}
        >
            {!isAdminHeader && (
                <img
                    className={styles.vectorIcon}
                    src={logo_svg}
                    style={{width: '65px', height: '20px'}}
                    alt="0MAX1"
                />
            )}

            {/*for admin page onClick only */}
            {isAdminHeader && (
                <ConnectWalletHeaderAdminNavBar/>
            )}
            {notification.message ? (
                <div className={styles.transactionErrorBoxWapper}>
                    <div className={styles.transactionErrorBox}>
                        <div className={styles.transactionErrorContent}>
                            <img
                                src={
                                    notification?.status === 'error'
                                        ? rejectImg
                                        : notification?.status === 'warning'
                                            ? warningImg
                                            : successImg
                                }
                                alt={notification.status || 'status'}
                                className={styles.rejectIcon}
                            />
                            <span
                                className={styles.errorMessage}
                                dangerouslySetInnerHTML={{__html: notification.message}}
                            />
                        </div>
                        <img
                            className={styles.cancelBtn}
                            src={cancle_icon}
                            alt="x"
                            onClick={() => showNotification(null, null)}
                        />
                    </div>
                </div>
            ) : (
                <div className={
                    isAdminHeader
                        ? `${styles.container} ${styles.container2}`
                        : styles.container
                }>
                    {isSideMenuOpen ? (
                        <SideMenu
                            isOpen={isSideMenuOpen}
                            selectedMenuItem={selectedMenuItem}
                            onMenuItemClick={handleMenuItemClick}
                            onOverlayClick={handleOutsideClickSideMenu}
                            stopPropagation={(e) => e.stopPropagation()}
                        />
                    ) : (
                        <div
                            className={styles.userIconContainer}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div
                                className={
                                    `${styles.userIcon} ` +
                                    (
                                        walletAddress === "Connect Wallet" || walletAddress === "Create Wallet"
                                            ? styles.userIconNoHover
                                            : styles.userIconRedHover
                                    ) +
                                    (isAdminHeader ? ` ${styles.userIcon2}` : "")
                                }
                                onClick={handleConnectWalletClick}
                                style={{
                                    background:
                                        walletAddress === "Connect Wallet" || walletAddress === "Create Wallet"
                                            ? "white"
                                            : "#32363D",
                                    color:
                                        walletAddress === "Connect Wallet" || walletAddress === "Create Wallet"
                                            ? "black"
                                            : "white",
                                    borderRadius: "10px",
                                    width: "151px"
                                }}
                            >
                                {walletAddress === "Connect Wallet" || walletAddress === "Create Wallet"
                                    ? ""
                                    : connectedWalletType === "okx"
                                        ? (
                                            <img
                                                style={{
                                                    borderRadius: '50%',
                                                    width: '18px',
                                                    height: '18px',
                                                    marginRight: '8px',
                                                    marginLeft: '0px'
                                                }}
                                                src={okx_icon}
                                                alt="OKX"
                                            />
                                        ) : connectedWalletType === "coinbase"
                                            ? (
                                                <img
                                                    style={{
                                                        borderRadius: '50%',
                                                        width: '18px',
                                                        height: '18px',
                                                        marginRight: '8px',
                                                        marginLeft: '0px'
                                                    }}
                                                    src={coinbase_icon}
                                                    alt="Coinbase"
                                                />
                                            ) : connectedWalletType === "walletconnect"
                                                ? (
                                                    <img
                                                        style={{
                                                            borderRadius: '50%',
                                                            width: '18px',
                                                            height: '18px',
                                                            marginRight: '8px',
                                                            marginLeft: '0px'
                                                        }}
                                                        src={walletconnect_icon}
                                                        alt="WalletConnect"
                                                    />
                                                ) : connectedWalletType === "phantom"
                                                    ? (
                                                        <img
                                                            style={{
                                                                borderRadius: '50%',
                                                                width: '18px',
                                                                height: '18px',
                                                                marginRight: '8px',
                                                                marginLeft: '0px'
                                                            }}
                                                            src={Phantom_icon}
                                                            alt="Phantom"
                                                        />
                                                    ) : null
                                }
                                {walletAddress}
                                {walletAddress === "Connect Wallet" || walletAddress === "Create Wallet" ? '' : (
                                    <img
                                        style={{
                                            borderRadius: '50%',
                                            width: '12px',
                                            height: '12px',
                                            marginRight: '0px',
                                            marginLeft: '8px',
                                            background: 'none'
                                        }}
                                        src={arrowDownImg}
                                        alt="arrow"
                                    />
                                )}
                            </div>

                            <img
                                className={
                                    isAdminHeader
                                        ? `${styles.menu_mobile_icon} ${styles.menu_mobile_icon2}`
                                        : styles.menu_mobile_icon
                                }
                                src={menu_icon}
                                style={{width: '13.41', height: '15px'}}
                                alt="Menu"
                                onClick={handleMenuIconClick}
                            />

                            {showPanel && (
                                <div
                                    className={styles.overlay}
                                    onClick={handleOutsideClick}
                                ></div>
                            )}

                            <div
                                className={
                                    `${styles.sidePanel} ` +
                                    (showPanel
                                        ? (connectedPublicKey ? styles.openConnected : styles.openNotConnected)
                                        : '')
                                }
                                style={{"width": "352px"}}
                                onClick={stopPropagation}
                            >
                                <div className={styles.connectHeader}>
                                    <h3>Connect</h3>
                                    <div
                                        className={styles.closeBtn}
                                        onClick={closePanel}
                                        aria-label="Close"
                                    >
                                        ×
                                    </div>
                                </div>

                                <div className={styles.lineWrapper}>
                                    <div className={styles.dividerLineFull}></div>
                                </div>

                                <div style={{background: "#1c1c1c"}}>
                                    {/* OKX */}
                                    <div className={styles.connectOption} onClick={handleOKXWalletSolana}>
                                        <img className={styles.walletIcon} src={okx_icon} alt="OKX"/>
                                        <div className={styles.walletType}><p>OKX Wallet</p>
                                            {isOkxDetected && <div className={styles.detect}>detected</div>}
                                        </div>
                                    </div>
                                    {/* WalletConnect：此处不需要检测 */}
                                    <div className={styles.connectOption} onClick={handleWalletConnectSolana}>
                                        <img className={styles.walletIcon} src={walletconnect_icon}
                                             alt="WalletConnect"/>
                                        <p>WalletConnect</p>
                                    </div>

                                    {/* Coinbase */}
                                    <div className={styles.connectOption} onClick={handleCoinbaseWalletSolana}>
                                        <img className={styles.walletIcon} src={coinbase_icon} alt="Coinbase"/>
                                        <div className={styles.walletType}><p>Coinbase Wallet</p>
                                            {isCoinbaseDetected && <div className={styles.detect}>detected</div>}
                                        </div>
                                    </div>

                                    {/* Phantom */}
                                    <div className={styles.connectOption} onClick={handlePhantomWalletSolana}>
                                        <img className={styles.walletIcon} src={Phantom_icon} alt="Phantom"/>
                                        <div className={styles.walletType}><p>Phantom Wallet</p>
                                            {isPhantomDetected && <div className={styles.detect}>detected</div>}
                                        </div>
                                    </div>
                                    {connectedPublicKey && (
                                        <div className={styles.lineWrapper}>
                                            <div className={styles.dividerLineFullLower}></div>
                                        </div>
                                    )}

                                    {connectedPublicKey && (
                                        <div
                                            className={styles.connectOption}
                                            onClick={disconnectWallet}
                                            style={{paddingTop: "10px", paddingBottom: '10px'}}
                                        >
                                            <img style={{width: "30px", height: "30px"}}
                                                 className={styles.walletIcon}
                                                 src={disconnectImg} alt="disconnect"/>
                                            <p style={{color: 'red', lineHeight: "30px"}}>Disconnect</p>
                                        </div>
                                    )}

                                    <div className={styles.lineWrapper}>
                                        <div className={styles.dividerLine}></div>
                                        <div className={styles.or}>Or</div>
                                        <div className={styles.dividerLine}></div>
                                    </div>

                                    {/* Email */}
                                    {localStorage.getItem("userEmail") ? (
                                        <div className={styles.connectOption}>
                                            <img
                                                style={{background: '#1C1C1C', marginRight: '17px', width: '30px'}}
                                                src={email_svg}
                                                alt="email"
                                            />
                                            <div className={styles.emailType} onClick={handleLogoutWithEmail}>
                                                <p>{localStorage.getItem("userEmail")}</p>
                                                <img
                                                    style={{background: '#1C1C1C', marginRight: '17px'}}
                                                    src={emailLogoutImg}
                                                    alt="email"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.connectOption}
                                            onClick={handleLoginWithEmail}
                                        >
                                            <img
                                                style={{background: '#1C1C1C', marginRight: '17px', width: '30px'}}
                                                src={email_svg}
                                                alt="email"
                                            />
                                            <p>Log in with Email</p>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ConnectWalletHeader;