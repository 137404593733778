import React, { createContext, useEffect, useState } from 'react';
import { fetchC2CNetworkConfig } from './tables/utils';
import { getConfig } from '../src/configs/config';

export const SwapMainAppContext = createContext(null);

export function SwapMainAppProvider({ children }) {
  const [notification, setNotification] = useState({
    status: null,
    message: null,
  });

    // for error message
  function showNotification(status, message) {
    setNotification({ status, message });
    setTimeout(() => {
      setNotification({ status: null, message: null });
    }, 5000);
  }

  // --- for data config from backend
  const [networkFeePercent, setNetworkFeePercent] = useState(0);
  const [collateralRatio, setCollateralRatio] = useState(0);
// 2) Create state for the config data from config.js
  const [squadsPublicKey, setSquadsPublicKey] = useState('');
  const [usdiTokenAddress, setUsdiTokenAddress] = useState('');
  const [usdtTokenAddress, setUsdtTokenAddress] = useState('');
  const [usdcTokenAddress, setUsdcTokenAddress] = useState('');
  const [kraTokenAddress, setKraTokenAddress] = useState('');
  const [c2cRedeemNetworkFeePctFromConfig, setC2cRedeemNetworkFeePctFromConfig] = useState('');
  const [c2csquadReceivingAddress, setC2cSquadReceivingAddress] = useState('')
  useEffect(() => {
    async function loadConfig() {
      try {
        const configData = await getConfig();
        setSquadsPublicKey(configData.squadsPublicKey || '');
        setUsdiTokenAddress(configData.usdiTokenAddress || '');
        setUsdtTokenAddress(configData.usdtTokenAddress || '');
        setUsdcTokenAddress(configData.usdcTokenAddress || '');
        setKraTokenAddress(configData.kraTokenAddress || '');
        setC2cRedeemNetworkFeePctFromConfig(
          configData.c2c_redeem_network_fee_percentage || '0'
        );
        setC2cSquadReceivingAddress(
          configData.c2c_squad_receiving_address || '0'
        );
      } catch (err) {
        console.error('Error loading config.js data:', err);
      }
    }
    loadConfig();
  }, []);


  useEffect(() => {
    async function loadFee() {
      try {
        const feePercent = await fetchC2CNetworkConfig();
        setNetworkFeePercent(feePercent.network_fee_percentage);
        setCollateralRatio(feePercent.collateral_ratio);
      } catch (err) {
        console.error('Error fetching network fee:', err);
      }
    }
    loadFee();
  }, []);

  const [walletAddress, setWalletAddress] = useState(() => {
    return localStorage.getItem('walletAddress') || '';
  });


  useEffect(() => {
    if (walletAddress) {
      localStorage.setItem('walletAddress', walletAddress);
    } else {
      localStorage.removeItem('walletAddress');
    }
  }, [walletAddress]);


  return (
    <SwapMainAppContext.Provider
      value={{
        notification,
        showNotification,
        networkFeePercent,
        collateralRatio,
        walletAddress,
        setWalletAddress,
        // New config-based values
        squadsPublicKey,
        usdiTokenAddress,
        usdtTokenAddress,
        usdcTokenAddress,
        kraTokenAddress,
        c2cRedeemNetworkFeePctFromConfig,
        c2csquadReceivingAddress
      }}
    >
      {children}
    </SwapMainAppContext.Provider>
  );
}