import React, {useState, useEffect, useRef, useContext} from 'react';
import styles from './SwapMain.module.css';
import LineChart from "./linechart/LineCharts";
import usdiImg from "./static_files/USDi.png";
import usdtImg from "./static_files/usdt 1.svg";
import usdcImg from "./static_files/usdc.svg";
import approxImg from "./static_files/Text.svg";
import {fetchPortfolioTrackRecordsRoundFourDigit} from "./linechart/fetchDailyPortfolioData";
import TransactionDetailModal from "./transaction_detail/TransactionDetail";
/* Normal copy icon & hover version */
import copySelectorImg from "./static_files/copy-selector.svg";
import copySelectorHoverImg from "./static_files/copy-selector-hover.svg";
import copyConfirmImg from "./static_files/checkmark.svg";
import shareSelectorImg from "./static_files/share-selector.svg";
import shareSelectorHoverImg from "./static_files/share-selector-hover.svg";
import copyInnerImg from "./static_files/copy inner.svg";
import shareInnerImg from "./static_files/share inner.svg";
import copyInnerHoverImg from "./static_files/copy inner hover.svg";
import shareInnerHoverImg from "./static_files/share inner hover.svg";
import VenmoIcon from './static_files/Venmo.svg';
import ZelleIcon from './static_files/Zelle.svg';
import AlipayIcon from './static_files/Alipay.svg';
import WireIcon from './static_files/Wire.svg';
import USDIcon from './static_files/usd.svg';

import dropDownRightImg from "./static_files/dropdownright.svg"
import dropDownRightHoverImg from "./static_files/dropdownrightHover.svg"

import dropDownImg from "./static_files/dropdown.svg"
import dropDownHoverImg from "./static_files/dropdownHover.svg"

import ConnectWalletHeader from "./header/ConnectWalletHeader";
import LeftPanel from "./SwapLeftPanel/LeftPanel";
import {SwapMainAppContext} from "../SwapMainAppContext";

import {motion, LayoutGroup} from 'framer-motion';
import {formatNumberWithCommas, shortenAddress} from './utils'


import {USDI_API_URL, ENDPOINT_BASE_URL} from '../configs/config';
import RedeemTab from "./RedeemTab/RedeemTab";
import Tooltip from "./Tooltip/Tooltip"

const SwapMainAPP = () => {
    // Email logged in user data
    const [usdiValue, setUsdiValue] = useState(null);
    // its for Solana USDI holder info table 获取与当前用户的data
    const [usdiHoldersData, setUsdiHoldersData] = useState();
    const [availableUSDI, setAvailableUSDI] = useState(null);
    const [cumulativeInterest, SetCumulativeInterest] = useState(0);
    const [accruedInterest, setAccruedInterest] = useState(0)
    const [holderDataLoading, setHolderDataLoading] = useState(true)
    const [ImpliedApy, setImpliedApr] = useState(0);
    const [displayedApy, setDisplayedApy] = useState("Implied APY");
    const [displayedAPYTooltip, setDisplayedAPYTooltip] = useState("");

    const [selectedTab, setSelectedTab] = useState('mint'); // "mint" | "redeem"

    const [txTrigger, setTxTrigger] = useState(0);

    // 在connect wallet header 连接钱包后的pub key在这
    const [walletAddress, setWalletAddress] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);
    const [usdiBalance, setUsdiBalance] = useState("--");
    // use to open connect wallet side panel
    const [showPanel, setShowPanel] = useState(false);

    // Which token is selected ("USDC" or "USDT" or "CASH" from redeem)
    const [selectedToken, setSelectedToken] = useState("USDC");

    // Show/hide token selector modal
    const [showTokenSelector, setShowTokenSelector] = useState(false);
    const methodItemRef = useRef(null);
    const tokenSelectorRef = useRef(null); // for clicking somewhere else to close it

    // Single input for both Mint and Redeem tabs
    const [typedMintValue, setTypedMintValue] = useState("0");

    // Final “confirmed” amount to pass to ConnectWalletHeader
    const [confirmedAmount, setConfirmedAmount] = useState("0");
    const [justCopiedUSDC, setJustCopiedUSDC] = useState(false);
    const [justCopiedUSDT, setJustCopiedUSDT] = useState(false);

    // Font-size + container ref + hidden measuring span + input ref
    const [dynamicFontSize, setDynamicFontSize] = useState(100);
    const containerRef = useRef(null);
    const spanRef = useRef(null);
    const inputRef = useRef(null);

    const MIN_FONT_SIZE = 30;
    const MAX_FONT_SIZE = 100;

    const [isWalletLoading, setIsWalletLoading] = useState(true);

    const {
        showNotification, networkFeePercent,
        usdtTokenAddress,
        usdcTokenAddress,
        usdiTokenAddress,
        squadsPublicKey,
    } = useContext(SwapMainAppContext);

    // for transaction detail page - > after cash redeem , show modal let user confirm
    const [showTransactionDetailModal, setShowTransactionDetailModal] = useState(false);
    const [redeemValueForModal, setRedeemValueForModal] = useState(0);
    const [localTimeTransaction, setLocalTimeTransaction] = useState(null);
    const [UTCTimeTransaction, setUTCTimeTransaction] = useState(null);

    // 新增payment method 3
    const methodItemRef3 = useRef(null);
    const [paymentInfoData, setPaymentInfoData] = useState([]);
    // 这是用户在第二个下拉里选中的那一条
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    // 用来控制“第二个下拉(Transfer to)”的展开/收起
    const [showTokenSelector3, setShowTokenSelector3] = useState(false);
    const tokenSelectorRef3 = useRef(null);

    const [refetchPaymentMethods, setRefetchPaymentMethods] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [walletUserEmail, setWalletUserEmail] = useState(null);
    const iconMap = {
        'Bank Wire': WireIcon,
        'Cash - Paper': USDIcon,
        'Zelle': ZelleIcon,
        'Venmo': VenmoIcon,
        'Alipay': AlipayIcon,
    };

    function getPaymentInfoDisplay(item) {
        switch (item.payment_method) {
            case 'Zelle':
                return item.email || item.mobile_number || 'No info';
            case 'Venmo':
                return item.account_number || 'No account number';
            case 'Alipay':
            case 'Cash - Paper':
                return item.mobile_number || 'No mobile';
            case 'Bank Wire':
                return item.account_number || 'No account';
            default:
                return 'No data';
        }
    }

    // 切换第二个下拉
    const handleMethodItemClick3 = (e) => {
        e.stopPropagation();
        setShowTokenSelector3(prev => !prev);
    };

    // 用户选中某条 payment
    const handleSelectPaymentMethod = (info) => {
        setSelectedPaymentMethod(info);
        setShowTokenSelector3(false);
    };

    useEffect(() => {
        if (showTokenSelector) {
            setShowTokenSelector3(false);
        }
    }, [showTokenSelector]);

    useEffect(() => {
        if (showTokenSelector3) {
            setShowTokenSelector(false);
        }
    }, [showTokenSelector3]);

    // ------------------------ FETCH DATA  ------------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Promise.any([
                    fetchPortfolioTrackRecordsRoundFourDigit().catch(e => null),
                ]);
                if (data) {
                    const maxApy = Math.max(data.impliedApr, data.ninetyDayApy, data.thirtyDayApy);
                    setUsdiValue(data.totalValue / data.usdiInCirculation);
                    setImpliedApr(maxApy);
                    let maxApyType = "";
                    let tooltip = "";

                    if (maxApy === data.impliedApr) {
                        maxApyType = "Implied APY";
                        tooltip = <>The estimated annual <br/> percentage yield based<br/> on current rates</>;

                    } else if (maxApy === data.ninetyDayApy) {
                        maxApyType = "90-Day APY";
                        tooltip =
                            <>The annual percentage yield<br/> calculated based on the past<br/> 90 days'
                                performance</>;
                    } else {
                        maxApyType = "30-Day APY";
                        tooltip =
                            <>The annual percentage yield<br/> calculated based on the past<br/> 30 days'
                                performance</>;
                    }

                    setDisplayedApy(maxApyType)
                    setDisplayedAPYTooltip(tooltip);
                } else {
                    console.error("Failed to fetch one or more data endpoints");
                }

                if (!walletAddress) {
                    setWalletUserEmail("--")
                }
                const response = await fetch(`${USDI_API_URL}/usdi_holders_info_table_solana?address=${walletAddress}`);
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                setUsdiHoldersData(result);
                if (result && result[0]) {
                    setWalletUserEmail(result[0].email)
                } else {
                    setWalletUserEmail("--")
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [walletAddress]);


    // 1) 在 RedeemTab 内部自己 fetchPaymentInfo
    useEffect(() => {
        const fetchPaymentInfo = async () => {
            if (!walletAddress) {
                setPaymentInfoData([]);
                return;
            }
            try {
                let paramKey, paraValue;
                let extraParam = ""; // Will hold the extra parameter when using wallet login


                paramKey = "wallet_address";
                paraValue = walletAddress;
                extraParam = "&email_user_wallet_chain=solana_receive_address";

                const url = `${ENDPOINT_BASE_URL}/get_payment_info?${paramKey}=${paraValue}${extraParam}`;
                const res = await fetch(url);

                if (!res.ok) {
                    console.error(`Error fetching payment info: ${res.status} ${res.statusText}`);
                    setPaymentInfoData([]);
                    return;
                }

                const jsonData = await res.json();
                if (!jsonData.success) {
                    console.error("API returned success=false:", jsonData);
                    setPaymentInfoData([]);
                    return;
                }

                const arr = jsonData.data || [];
                setPaymentInfoData(arr);

                // 若有 default_payment === true
                const defaultRow = arr.find(item => item.default_payment === true);
                if (defaultRow) {
                    setSelectedPaymentMethod(defaultRow);
                }
            } catch (err) {
                console.error("Error fetching payment info:", err);
                setPaymentInfoData([]);
            }
        };

        fetchPaymentInfo();
    }, [walletAddress, refetchPaymentMethods]);


    // 找用户的cum_distributed_usdi 和 Accrued Interest
    useEffect(() => {
        if (usdiHoldersData && usdiHoldersData[0]) {
            SetCumulativeInterest(parseFloat(usdiHoldersData[0].cum_distributed_usdi) || 0);
            setAccruedInterest(parseFloat(usdiHoldersData[0].accrued_interest) || 0)
            setHolderDataLoading(false)
        } else {
            SetCumulativeInterest(0);
            setAccruedInterest(0)
        }
    }, [usdiHoldersData]);


    useEffect(() => {
        function handleClickOutside(e) {
            if (!showTokenSelector) return;
            if (
                methodItemRef.current?.contains(e.target) ||
                tokenSelectorRef.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector(false);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTokenSelector]);
    useEffect(() => {
        function handleClickOutside3(e) {
            if (!showTokenSelector3) return;
            if (
                methodItemRef3.current?.contains(e.target) ||
                tokenSelectorRef3.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector3(false);
        }

        document.addEventListener('mousedown', handleClickOutside3);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside3);
        };
    }, [showTokenSelector3]);

    // ------------------------ MINT & REDEEM ------------------------
    const handleMintUSDi = () => {
        const mintValueAsNumber = parseFloat(typedMintValue);
        if (isNaN(mintValueAsNumber) || mintValueAsNumber <= 0) {
            showNotification('error', "Please enter a valid amount to mint.");
            return;
        }
        setConfirmedAmount(mintValueAsNumber);
        setTxTrigger(prev => prev + 1);
    };

    const handleRedeemUSDi = () => {
        const redeemValueAsNumber = parseFloat(typedMintValue);
        if (isNaN(redeemValueAsNumber) || redeemValueAsNumber <= 0) {
            showNotification('error', "Please enter a valid amount to mint.");
            return;
        }
        // TODO change it back
        // if (selectedToken === "CASH" && confirmedAmount < 100) {
        //     showNotification('warning', `Minimum for cash redemption: 100 USDi`);
        //     return
        // }
        if (selectedToken === "CASH") {
            getLocalAndUTCTime();
            setRedeemValueForModal(redeemValueAsNumber);
            setShowTransactionDetailModal(true);
            return;
        }
        setConfirmedAmount(redeemValueAsNumber);
        setTxTrigger(prev => prev + 1);
    };

    // ------------------------ Show/hide token selector ------------------------
    const handleMethodItemClick = (e) => {
        e.stopPropagation();
        setShowTokenSelector(prev => !prev);
    };

    // ------------------------ COPY FUNCTION ------------------------
    const [justCopied, setJustCopied] = useState(false);

    const handleCopy = (e, address, token) => {
        e.stopPropagation();
        navigator.clipboard.writeText(address).then(() => {
            if (token === "USDC") {
                setJustCopiedUSDC(true);
                setTimeout(() => setJustCopiedUSDC(false), 2000);
            } else if (token === "USDT") {
                setJustCopiedUSDT(true);
                setTimeout(() => setJustCopiedUSDT(false), 2000);
            }
        });
    };
    const handleCopyUSDI = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(usdiTokenAddress).then(() => {
            setJustCopied(true);
            setTimeout(() => setJustCopied(false), 2000);
        });
    };

    // ------------------------ SHARE FUNCTION ------------------------
    const handleShare = (e, address) => {
        e.stopPropagation();
        window.open(`https://solscan.io/token/${address}`, "_blank");
    };

    // ------------------------ SELECT TOKEN LOGIC ------------------------
    const handleSelectToken = (token) => {
        if (!justCopiedUSDC && !justCopiedUSDT) {
            setSelectedToken(token);
            setShowTokenSelector(false);
        }
    };

    // ------------------------ “Max” button & validation ------------------------
    const handleSetMax = () => {
        if (selectedTab === 'mint') {
            if (selectedToken === 'USDT') {
                setTypedMintValue(String(usdtBalance));
            } else {
                setTypedMintValue(String(usdcBalance));
            }
        } else if (selectedTab === 'redeem') {
            setTypedMintValue(String(usdiBalance));
        }
    };

    // ------------------------ Handle input changes (decimal limit) ------------------------
    const handleInputChange = (e) => {
        let val = e.target.value;
        val = val.replace(/[^\d.]/g, '');
        if (val === '.0') {
            val = '0.';
        } else if (val.startsWith('.') && val !== '.') {
            val = '0' + val;
        }
        const firstDotIndex = val.indexOf('.');
        if (firstDotIndex !== -1) {
            val = val.slice(0, firstDotIndex + 1) + val.slice(firstDotIndex + 1).replace(/\./g, '');
        }
        const dotIndex = val.indexOf('.');
        if (dotIndex !== -1) {
            const fractional = val.slice(dotIndex + 1, dotIndex + 1 + 6);
            val = val.slice(0, dotIndex + 1) + fractional;
        }
        if (val === '.') {
            setTypedMintValue('0.');
            return;
        }
        if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
            val = val.replace(/^0+/, '');
            if (val === '') {
                val = '0';
            }
        }
        if (!val.endsWith('.')) {
            const numericVal = parseFloat(val);
            if (!isNaN(numericVal)) {
                let maxVal = 0;
                if (selectedTab === 'mint') {
                    maxVal = selectedToken === 'USDT' ? usdtBalance : usdcBalance;
                } else {
                    maxVal = usdiBalance;
                }
                if (numericVal > maxVal) {
                    val = maxVal.toFixed(6).replace(/\.?0+$/, '');
                }
            } else {
                val = '0';
            }
        }
        setTypedMintValue(val);
    };

    // 打开侧边栏
    const handleConnectWallet = () => {
        setShowPanel(true);
    };

    // 获取本地 & UTC 时间
    function getLocalAndUTCTime() {
        const now = new Date();
        const localYear = now.getFullYear();
        const localMonth = String(now.getMonth() + 1).padStart(2, '0');
        const localDay = String(now.getDate()).padStart(2, '0');
        const localHours = String(now.getHours()).padStart(2, '0');
        const localMinutes = String(now.getMinutes()).padStart(2, '0');
        const localSeconds = String(now.getSeconds()).padStart(2, '0');
        const localTime = `${localMonth}/${localDay}/${localYear}, ${localHours}:${localMinutes}:${localSeconds}`;

        const utcYear = now.getUTCFullYear();
        const utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(now.getUTCDate()).padStart(2, '0');
        const utcHours = String(now.getUTCHours()).padStart(2, '0');
        const utcMinutes = String(now.getUTCMinutes()).padStart(2, '0');
        const utcSeconds = String(now.getUTCSeconds()).padStart(2, '0');
        const utcTime = `${utcMonth}/${utcDay}/${utcYear}, ${utcHours}:${utcMinutes}:${utcSeconds}`;

        setLocalTimeTransaction(localTime);
        setUTCTimeTransaction(utcTime);
    }


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // 若从 mint -> redeem，就 token 变 CASH；反之变 USDC
    useEffect(() => {
        if (selectedTab === "mint") {
            setSelectedToken("USDC");
        } else {
            setSelectedToken("CASH");
        }
    }, [selectedTab]);

    //用来set loading box 状态
    useEffect(() => {
        const lastWallet = localStorage.getItem("lastConnectedWallet")
        if (!lastWallet) {
            setIsWalletLoading(false)
            setHolderDataLoading(false)
        }
    }, []);
    // 动态测量 input 宽度
    useEffect(() => {
        if (!containerRef.current || !spanRef.current || !inputRef.current) return;
        spanRef.current.textContent = typedMintValue === '' ? '0' : typedMintValue;
        spanRef.current.style.fontSize = `${dynamicFontSize}px`;
        spanRef.current.style.fontFamily = getComputedStyle(inputRef.current).fontFamily;
        spanRef.current.style.fontWeight = getComputedStyle(inputRef.current).fontWeight;
        spanRef.current.style.lineHeight = getComputedStyle(inputRef.current).lineHeight;
        spanRef.current.style.letterSpacing = getComputedStyle(inputRef.current).letterSpacing;

        const rect = spanRef.current.getBoundingClientRect();
        spanRef.current.textContent = "USDi";
        const rect_usdi = spanRef.current.getBoundingClientRect();
        const usdi_width = rect_usdi.width;
        // added for comma for 20
        let textWidth
        if (parseFloat(typedMintValue) >= 1000) {
            textWidth = rect.width + 20;
        } else if (parseFloat(typedMintValue) >= 1000000) {
            textWidth = rect.width + 40;
        } else {
            textWidth = rect.width
        }
        const containerWidth = containerRef.current.offsetWidth - usdi_width - 30 - 58 - 40;
        if (textWidth > containerWidth && dynamicFontSize > MIN_FONT_SIZE) {
            const ratio = containerWidth / textWidth;
            let newFont = Math.floor(dynamicFontSize * ratio);
            if (newFont < MIN_FONT_SIZE) newFont = MIN_FONT_SIZE;
            setDynamicFontSize(newFont);
            return;
        }
        if (textWidth < containerWidth * 0.8 && dynamicFontSize < MAX_FONT_SIZE) {
            const newFont = Math.min(MAX_FONT_SIZE, dynamicFontSize + 2);
            setDynamicFontSize(newFont);
            return;
        }
        inputRef.current.style.width = `${textWidth}px`;
    }, [typedMintValue, dynamicFontSize, selectedTab]);

    return (
        <LayoutGroup>
            <div
                className={styles.outerContainer}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: '#04070D',
                    height: '150%',
                    width: '100vw'
                }}
            >
                {showTransactionDetailModal && (
                    <TransactionDetailModal
                        fromAmount={redeemValueForModal}
                        fromCurrency="USDi"
                        toCurrency="USD Cash"
                        transactionType="Redeem"
                        network="C2C"
                        networkFeePercent={selectedPaymentMethod.fees}
                        rate="1 USDi = 1 USD"
                        eta={selectedPaymentMethod.processing_time}
                        notes="Note: First, pay the network fee to initiate the transaction. Then, pay the remaining amount upon delivery."
                        localTime={localTimeTransaction}
                        onConfirm={() => {
                            setConfirmedAmount(redeemValueForModal);
                            setTxTrigger(prev => prev + 1);
                            setTypedMintValue("0");
                            setShowTransactionDetailModal(false);
                        }}
                        onClose={() => setShowTransactionDetailModal(false)}
                        email={walletUserEmail}
                        walletAddress={walletAddress}
                    />
                )}

                {/* LEFT PANEL */}
                <LeftPanel/>

                {/* RIGHT PANEL */}
                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        amount={confirmedAmount}
                        transactionType={selectedTab}
                        transactionTrigger={txTrigger}
                        toAddress={squadsPublicKey} // amount > 11000
                        selectedToken={selectedToken}
                        selectedPaymentId={selectedPaymentMethod ? selectedPaymentMethod.payment_info_id : null}
                        selectedPaymentMethod={selectedPaymentMethod ? selectedPaymentMethod : null}
                        onWalletConnected={(publicKey, usdtLeft, usdcLeft, usdiLeft) => {
                            setWalletAddress(publicKey);
                            setUsdtBalance(usdtLeft);
                            setUsdcBalance(usdcLeft);
                            setUsdiBalance(usdiLeft);
                            setAvailableUSDI(usdiLeft);
                            setIsWalletLoading(false);
                        }}
                        transactionTime={UTCTimeTransaction}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                    />

                    <div className={styles.container}>
                        {/* LEFT */}
                        <div className={styles.leftContainer}>
                            <div className={styles.balanceSection}>
                                <div className={styles.chartWrapper}>
                                    <LineChart

                                        chartBgColor="#1c1c1c"
                                        maxWidth="481px"
                                        availableUSDI={availableUSDI}
                                        usdiValue={usdiValue}
                                        swapMainWalletAddress={walletAddress}
                                        isLoading={isWalletLoading}

                                    />
                                </div>

                                <div className={styles.usdiInfo}>
                                    <div className={styles.usdiHeader}>
                                        <div className={styles.usdiItemLeft}>
                                            <img className={styles.usdiImage} src={usdiImg} alt="USDi"/>
                                            <p>USDi</p>
                                            <span>
                                                {justCopied ? (
                                                    <img
                                                        className={styles.copyImg}
                                                        src={copyConfirmImg}
                                                        alt="Copied!"
                                                        style={{
                                                            marginLeft: '8px',
                                                            marginRight: '2.856px',
                                                        }}
                                                    />
                                                ) : (
                                                    <img
                                                        className={styles.copyImg}
                                                        src={copyInnerImg}
                                                        alt="copy img"
                                                        style={{
                                                            marginLeft: '8px',
                                                            marginRight: '4px',
                                                            cursor: "pointer",
                                                            width: "12.356px",
                                                            height: "15.451px"
                                                        }}
                                                        onClick={(e) => handleCopyUSDI(e)}
                                                        onMouseEnter={(e) =>
                                                            (e.currentTarget.src = copyInnerHoverImg)
                                                        }
                                                        onMouseLeave={(e) =>
                                                            (e.currentTarget.src = copyInnerImg)
                                                        }
                                                    />
                                                )}
                                            </span>
                                            <span>
                                                <img
                                                    className={styles.copyImg}
                                                    src={shareInnerImg}
                                                    alt="share img"
                                                    onClick={(e) => handleShare(e, usdiTokenAddress)}
                                                    onMouseEnter={(e) =>
                                                        (e.currentTarget.src = shareInnerHoverImg)
                                                    }
                                                    onMouseLeave={(e) =>
                                                        (e.currentTarget.src = shareInnerImg)
                                                    }
                                                />
                                            </span>
                                        </div>
                                        <div className={styles.innerUsdiApy}>
                                            <div className={styles.usdiBox}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{marginBottom: '25px'}}
                                                >
                                                    Balance
                                                </div>
                                                <div className={styles.usdiValue2}>
                                                    {isWalletLoading ? <span style={{
                                                            height: "22px",
                                                            marginTop: "0px",
                                                            width: "40px"
                                                        }} className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || availableUSDI === 0
                                                            ? '--' : availableUSDI.toLocaleString('en-US', {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2
                                                            })}
                                                </div>
                                                <div style={{background: '#1c1c1c'}}>
                                                    {isWalletLoading ? <span style={{
                                                            height: "12px",
                                                            borderRadius: '2px',
                                                            width: "35px"
                                                        }} className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || availableUSDI === 0
                                                            ? '--' :
                                                            !(availableUSDI && usdiValue) ? <span style={{
                                                                height: "12px",
                                                                borderRadius: '2px',
                                                                width: "35px"
                                                            }} className={styles.blinkingPlaceholder}/> : (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'end',
                                                                        background: '#1c1c1c'
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{background: '#1c1c1c'}}
                                                                        src={approxImg}
                                                                        alt="≈"
                                                                    />
                                                                    <span className={styles.usdiValue}>
                                                                {`$${(availableUSDI * usdiValue).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}`}
                                                            </span>
                                                                </div>
                                                            )}

                                                </div>
                                            </div>

                                            <div className={styles.usdiItemRight}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "right",
                                                        cursor: 'pointer',
                                                        borderBottom: '1.4px dotted #797979'
                                                    }}
                                                ><Tooltip text={displayedAPYTooltip}>
                                                    {displayedApy}
                                                </Tooltip>
                                                </div>
                                                <h5>
                                                    {ImpliedApy !== undefined && ImpliedApy !== null && !isNaN(ImpliedApy) && ImpliedApy !== 0
                                                        ? `${Number(ImpliedApy).toFixed(2)}%`
                                                        : <span className={styles.blinkingPlaceholder}/>}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.lineSeparator}></div>
                                    <div className={styles.usdiStats}>
                                        <Tooltip
                                            text={<>The current value of one USDi <br/> unit based on total assets
                                            </>}>
                                            <div style={{cursor: 'pointer'}} className={styles.usdiDataWrapper}>
                                                <h4>
                                                    {usdiValue
                                                        ? `${parseFloat(usdiValue).toFixed(2)} `
                                                        : <span className={styles.blinkingPlaceholder}/>
                                                    }
                                                </h4>

                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>NAV per USDi</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>NAV per </span>
                                                        <span className={styles.leftBottomStats}>USDi</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={
                                            <>The total interest earned <br/> and paid out to date </>}>
                                            <div style={{cursor: 'pointer'}}
                                                 className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper2}`}>
                                                <h4>
                                                    {isWalletLoading ? <span className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || cumulativeInterest === 0
                                                            ? '--' : <>
                                                                {parseFloat(cumulativeInterest).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}
                                                                <span className={styles.usdiLabel}> USDi</span>
                                                            </>}
                                                </h4>
                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>Cumulative Interest</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>Cumulative </span>
                                                        <span className={styles.leftBottomStats}>Interest</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={
                                            <>Interest earned but not yet <br/> distributed or reduced by fees </>}>
                                            <div style={{cursor: 'pointer'}}
                                                 className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper3}`}>
                                                <h4>
                                                    {isWalletLoading || holderDataLoading ?
                                                        <span className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || accruedInterest === 0
                                                            ? '--' : !(accruedInterest) ?
                                                                <span className={styles.blinkingPlaceholder}/> :
                                                                `${(accruedInterest).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}`}<span className={styles.usdiLabel}> USDi</span>
                                                </h4>
                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>Accrued Interest</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>Accrued </span>
                                                        <span className={styles.leftBottomStats}>Interest</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* RIGHT */}
                        <div className={styles.rightContainer}>

                            <div className={styles.tabs}>
                                <button
                                    className="relative"
                                    onClick={() => {
                                        if (selectedTab === 'redeem') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('mint');
                                    }}
                                >
                                    {selectedTab === 'mint' && (
                                        <motion.div
                                            layoutId="active-pill"
                                            className={styles.tapPill}
                                            transition={{type: "spring", duration: 0.6}}
                                        />
                                    )}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'mint' ? styles.nonSelectTabText : ''}`}
                                    >
                                        Mint
                                    </span>
                                </button>
                                <button
                                    className="relative"
                                    onClick={() => {
                                        if (selectedTab === 'mint') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('redeem');
                                    }}
                                >
                                    {selectedTab === 'redeem' && (
                                        <motion.div
                                            layoutId="active-pill"
                                            className={styles.tapPill}
                                            transition={{type: "spring", duration: 0.6}}
                                        />
                                    )}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'redeem' ? styles.nonSelectTabText : ''}`}
                                    >
                                        Redeem
                                    </span>
                                </button>
                            </div>

                            {/* -------- MINT TAB -------- */}
                            {selectedTab === 'mint' && (
                                <div className={styles.withdrawSection}>
                                    <div className={styles.withdrawSectionMiddle}>
                                        <div
                                            ref={containerRef}
                                            className={styles.customInputWrapper}
                                            style={{position: 'relative'}}
                                            onClick={() => inputRef.current?.focus()}
                                        >
                                            <input
                                                ref={inputRef}
                                                type="text"
                                                className={styles.withDrawBig}
                                                value={formatNumberWithCommas(typedMintValue)}
                                                onChange={handleInputChange}
                                                style={{
                                                    fontSize: dynamicFontSize,
                                                    border: 'none',
                                                    background: 'transparent',
                                                    caretColor: '#6CE3C4',
                                                }}
                                            />
                                            <span
                                                ref={spanRef}
                                                style={{
                                                    position: 'absolute',
                                                    visibility: 'hidden',
                                                    whiteSpace: 'pre',
                                                    padding: 0,
                                                    margin: 0
                                                }}
                                            />
                                            <span
                                                onChange={handleInputChange}
                                                className={styles.fakePlaceholderFont}
                                                style={{
                                                    width: '12px',
                                                    background: 'transparent',
                                                    color: 'gray',
                                                    fontSize: dynamicFontSize
                                                }}
                                                onClick={() => inputRef.current?.focus()}
                                            >
                                                {' '}USDi
                                            </span>

                                            <div>
                                                <button
                                                    className={styles.maxButton}
                                                    onClick={handleSetMax}
                                                >
                                                    Max
                                                </button>
                                            </div>
                                        </div>

                                        <div className={styles.withDrawAmount}>
                                            {selectedToken === "USDT"
                                                ? usdtBalance
                                                    ? `${usdtBalance.toLocaleString(undefined, {
                                                        maximumFractionDigits: 6
                                                    })} USDT Available`
                                                    : "0.00 USDT Available"
                                                : usdcBalance
                                                    ? `${usdcBalance.toLocaleString(undefined, {
                                                        maximumFractionDigits: 6
                                                    })} USDC Available`
                                                    : "0.00 USDC Available"
                                            }
                                        </div>

                                        <div
                                            className={styles.methodItem}
                                            onClick={handleMethodItemClick}
                                            ref={methodItemRef}
                                        >
                                            <div
                                                onClick={handleMethodItemClick}
                                                ref={methodItemRef}
                                                className={styles.withDrawItemLeft}
                                            >
                                                {selectedToken === "USDT" ? (
                                                    <img
                                                        className={styles.withdrawImg}
                                                        src={usdtImg}
                                                        alt="wireTransferImg"
                                                        onClick={handleMethodItemClick}
                                                        ref={methodItemRef}
                                                    />
                                                ) : (
                                                    <img
                                                        className={styles.withdrawImg}
                                                        src={usdcImg}
                                                        alt="wireTransferImg"
                                                        onClick={handleMethodItemClick}
                                                        ref={methodItemRef}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-around',
                                                        backgroundColor: 'transparent',
                                                    }}
                                                    onClick={handleMethodItemClick}
                                                    ref={methodItemRef}
                                                >
                                                    <h4>Pay with</h4>
                                                    <h5>{shortenAddress(walletAddress)}</h5>
                                                </div>
                                            </div>
                                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                                <div
                                                    onClick={handleMethodItemClick}
                                                    ref={methodItemRef}
                                                    className={styles.withDrawItemRight}
                                                >
                                                    <h5 onClick={handleMethodItemClick} ref={methodItemRef}>
                                                        {(parseFloat(typedMintValue) || 0) < 11000 ? 'Instant' : '1 day'}
                                                    </h5>
                                                    <h5 onClick={handleMethodItemClick} ref={methodItemRef}>
                                                        <span className={styles.payWithValue}>
                                                            {typedMintValue.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                        </span>
                                                        {' '}{selectedToken}
                                                    </h5>
                                                </div>
                                                {showTokenSelector ? (
                                                    <img
                                                        src={dropDownImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                                    />
                                                ) : (
                                                    <img
                                                        src={dropDownRightImg}
                                                        className={styles.arrowDown}
                                                        alt="arrow"
                                                        onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                                        onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {showTokenSelector && (
                                        <div ref={tokenSelectorRef} className={styles.tokenSelectorModal}>
                                            <div className={styles.tokenSelectorTitleContainer}>
                                                <div className={styles.tokenSelectorTitle}>Token</div>
                                                <div className={styles.tokenSelectorTitle}>Balance/Address</div>
                                            </div>
                                            <div className={styles.tokenSelectorContentContainer}
                                                 style={{marginBottom: "5px"}}>
                                                {/* USDC Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDC'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'USDC'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("USDC")}
                                                >
                                                    <div
                                                        className={styles.tokenSelectorContentLeft}
                                                        style={
                                                            selectedToken === 'USDC'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            src={usdcImg}
                                                            alt="wireTransferImg"
                                                            style={
                                                                selectedToken === 'USDC'
                                                                    ? {background: '#2b3336'}
                                                                    : {}
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDC
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USD Coin
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={
                                                            selectedToken === 'USDC'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        {usdcBalance ? (
                                                            <div
                                                                className={styles.tokenSelectorContentRight}
                                                                style={
                                                                    selectedToken === 'USDC'
                                                                        ? {background: '#2b3336'}
                                                                        : {}
                                                                }
                                                            >
                                                                {usdcBalance.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: 'right',
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <span
                                                                className={styles.tokenSelectorContentRightGrey}
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                            >
                                                                {shortenAddress(usdcTokenAddress)}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {justCopiedUSDC ? (
                                                                    <img
                                                                        className={styles.tokenSelectorContentRightGreyIMG}
                                                                        src={copyConfirmImg}
                                                                        alt="Copied!"
                                                                        style={{
                                                                            background: selectedToken === 'USDC'
                                                                                ? '#2b3336'
                                                                                : 'transparent',
                                                                            width: '11px',
                                                                            height: '11px'
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className={styles.tokenSelectorContentRightGreyIMG}
                                                                        src={copySelectorImg}
                                                                        alt="copy img"
                                                                        style={{
                                                                            background: selectedToken === 'USDC'
                                                                                ? '#2b3336'
                                                                                : 'transparent'
                                                                        }}
                                                                        onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                                        onMouseEnter={(e) =>
                                                                            (e.currentTarget.src = copySelectorHoverImg)
                                                                        }
                                                                        onMouseLeave={(e) =>
                                                                            (e.currentTarget.src = copySelectorImg)
                                                                        }
                                                                    />
                                                                )}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={shareSelectorImg}
                                                                    alt="share img"
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    onClick={(e) => handleShare(e, usdcTokenAddress)}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = shareSelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = shareSelectorImg)
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* USDT Row */}
                                                <div
                                                    className={
                                                        selectedToken === 'USDT'
                                                            ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                            : styles.tokenSelectorContent
                                                    }
                                                    style={
                                                        selectedToken === 'USDT'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                    onClick={() => handleSelectToken("USDT")}
                                                >
                                                    <div
                                                        className={styles.tokenSelectorContentLeft}
                                                        style={
                                                            selectedToken === 'USDT'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    >
                                                        <img
                                                            className={styles.selectorImg}
                                                            src={usdtImg}
                                                            alt="wireTransferImg"
                                                            style={
                                                                selectedToken === 'USDT'
                                                                    ? {background: '#2b3336'}
                                                                    : {}
                                                            }
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    marginBottom: '3px',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h4>
                                                            <h5
                                                                style={{
                                                                    marginBottom: '0',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                USDT
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={styles.tokenSelectorWalletContainer}
                                                        style={{
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {usdtBalance ? (
                                                            <div
                                                                className={styles.tokenSelectorContentRight}
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {usdtBalance.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    textAlign: 'right',
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                --
                                                            </div>
                                                        )}
                                                        <div
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <span
                                                                className={styles.tokenSelectorContentRightGrey}
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                            >
                                                                {shortenAddress(usdtTokenAddress)}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                {justCopiedUSDT ? (
                                                                    <img
                                                                        className={styles.tokenSelectorContentRightGreyIMG}
                                                                        src={copyConfirmImg}
                                                                        alt="Copied!"
                                                                        style={{
                                                                            background: selectedToken === 'USDT'
                                                                                ? '#2b3336'
                                                                                : 'transparent',
                                                                            width: '11px',
                                                                            height: '11px'
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        className={styles.tokenSelectorContentRightGreyIMG}
                                                                        src={copySelectorImg}
                                                                        style={{
                                                                            background: selectedToken === 'USDT'
                                                                                ? '#2b3336'
                                                                                : 'transparent'
                                                                        }}
                                                                        alt="copy img"
                                                                        onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                                        onMouseEnter={(e) =>
                                                                            (e.currentTarget.src = copySelectorHoverImg)
                                                                        }
                                                                        onMouseLeave={(e) =>
                                                                            (e.currentTarget.src = copySelectorImg)
                                                                        }
                                                                    />
                                                                )}
                                                            </span>
                                                            <span
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                            >
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={shareSelectorImg}
                                                                    alt="share img"
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    onClick={(e) => handleShare(e, usdtTokenAddress)}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = shareSelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = shareSelectorImg)
                                                                    }
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {walletAddress ? (
                                        <button onClick={handleMintUSDi}>Mint USDi</button>
                                    ) : (
                                        <button onClick={handleConnectWallet}>Connect Wallet</button>
                                    )}
                                </div>
                            )}

                            {/* -------- REDEEM TAB -------- */}
                            {selectedTab === 'redeem' && (
                                <RedeemTab
                                    typedMintValue={typedMintValue}
                                    dynamicFontSize={dynamicFontSize}
                                    containerRef={containerRef}
                                    spanRef={spanRef}
                                    inputRef={inputRef}
                                    handleInputChange={handleInputChange}
                                    handleSetMax={handleSetMax}
                                    usdiBalance={usdiBalance}
                                    usdcBalance={usdcBalance}
                                    usdtBalance={usdtBalance}
                                    selectedToken={selectedToken}
                                    shortenAddress={shortenAddress}
                                    walletAddress={walletAddress}
                                    networkFeePercent={networkFeePercent}
                                    showTokenSelector={showTokenSelector}
                                    methodItemRef={methodItemRef}
                                    handleMethodItemClick={handleMethodItemClick}
                                    tokenSelectorRef={tokenSelectorRef}
                                    handleSelectToken={handleSelectToken}
                                    handleCopy={handleCopy}
                                    justCopiedUSDC={justCopiedUSDC}
                                    justCopiedUSDT={justCopiedUSDT}
                                    handleShare={handleShare}
                                    showTokenSelector3={showTokenSelector3}
                                    methodItemRef3={methodItemRef3}
                                    handleMethodItemClick3={handleMethodItemClick3}
                                    tokenSelectorRef3={tokenSelectorRef3}
                                    paymentInfoData={paymentInfoData}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                                    iconMap={iconMap}
                                    getPaymentInfoDisplay={getPaymentInfoDisplay}
                                    walletAddressExists={!!walletAddress}
                                    handleRedeemUSDi={handleRedeemUSDi}
                                    handleConnectWallet={handleConnectWallet}
                                    usdcTokenAddress={usdcTokenAddress}
                                    usdtTokenAddress={usdtTokenAddress}
                                    refetchPaymentMethods={refetchPaymentMethods}
                                    setRefetchPaymentMethods={setRefetchPaymentMethods}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutGroup>
    );
};

export default SwapMainAPP;