import React, { useState, useEffect } from 'react';
import '../usdi_tables/USDIStats.css';

/**
 * 对一般数值，保留最多6位小数；若是整数则不带小数
 */
function formatNumber(value) {
  if (value == null || value === '') return '';
  const num = parseFloat(value);
  if (isNaN(num)) return '';
  let str = num.toFixed(6);                              // 先保留6位
  str = str.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, ''); // 去掉末尾多余的0
  return str;
}

/**
 * TxsHistoryTable
 * status 逻辑：
 * - completed → #6CE3C4
 * - failed    → #C7345E
 * - 其他       → #15A6F4
 */
const TxsHistoryTable = ({ data }) => {
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);

  // 根据 status 返回对应的颜色
  const getStatusColor = (status) => {
    if (status === 'completed') {
      return '#6CE3C4';   // 绿色
    } else if (status === 'failed') {
      return '#C7345E';   // 红色
    } else {
      return '#15A6F4';   // 蓝色
    }
  };

  // ========== 前端排序(按 txs_id) + 数据初始化 ==========
  useEffect(() => {
    try {
      const sorted = (data || []).slice().sort((a, b) => {
        const aId = parseFloat(a.txs_id) || 0;
        const bId = parseFloat(b.txs_id) || 0;
        return bId - aId;
      });
      setCurrentData(sorted);
    } catch (err) {
      setError(err);
    }
  }, [data]);

  // 数据改变后重置分页
  useEffect(() => {
    setCurrentPage(1);
  }, [currentData]);

  // ========== 分页逻辑 ==========
  const itemsPerPage = 10;
  const totalItems = currentData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = currentData.slice(startIndex, endIndex);

  const getPageNumbers = (curPage, totPages) => {
    const maxPageButtons = 5;
    const pageNumbers = [];
    if (totPages <= maxPageButtons) {
      for (let i = 1; i <= totPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);
      let left = curPage - 1;
      let right = curPage + 1;
      if (left > 2) {
        pageNumbers.push('...');
      } else {
        left = 2;
      }
      if (right < totPages - 1) {
        for (let i = left; i <= right; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('...');
      } else {
        for (let i = left; i <= totPages - 1; i++) {
          pageNumbers.push(i);
        }
      }
      pageNumbers.push(totPages);
    }
    return pageNumbers;
  };
  const pageNumbers = getPageNumbers(currentPage, totalPages);

  const handlePageChange = (page) => {
    if (page < 1) page = 1;
    if (page > totalPages) page = totalPages;
    setCurrentPage(page);
  };


  const csvColumns = [
    {
      header: 'Txs ID',
      getValue: (row) => row.txs_id
    },
        {
      header: 'Status',
      getValue: (row) => row.status
    },
    {
      header: 'Txs Created Timestamp',
      getValue: (row) => row.txs_created_timestamp
    },
    {
      header: 'Txs Type',
      getValue: (row) => row.txs_type
    },
    {
      header: 'From Token Address',
      getValue: (row) => row.from_token_address
    },
    {
      header: 'From Token Amount',
      getValue: (row) => formatNumber(row.from_token_amount)
    },
    {
      header: 'To Token Address',
      getValue: (row) => row.to_token_address
    },
    {
      header: 'To Token Amount',
      getValue: (row) => formatNumber(row.to_token_amount)
    },
    {
      header: 'From Address',
      getValue: (row) => row.from_address
    },
    {
      header: 'To Address',
      getValue: (row) => row.to_address
    },
    {
      header: 'Method',
      getValue: (row) => row.method
    },
    {
      header: 'Chain',
      getValue: (row) => row.chain
    },
    {
      header: 'Network',
      getValue: (row) => row.network
    },
    {
      header: 'Fees Percent',
      getValue: (row) => formatNumber(row.fees_percent)
    },
    {
      header: 'Network Fees',
      getValue: (row) => formatNumber(row.network_fees)
    },
    {
      header: 'Redeem Amount After Fees',
      getValue: (row) => formatNumber(row.redeem_amount_after_fees)
    },
    {
      header: 'Eta',
      getValue: (row) => row.eta
    },

    {
      header: 'Transaction Signature',
      getValue: (row) => row.transaction_signature
    }
  ];

  const handleDownloadCSV = () => {
    // 先拼出表头
    const headerLine = csvColumns.map(col => col.header).join(',');

    // 拼数据行
    const dataLines = currentData.map(row =>
      csvColumns.map(col => {
        const val = col.getValue(row) ?? '';
        // 如果可能包含逗号，可以加个引号包住
        return `"${val}"`;
      }).join(',')
    );

    const csvContent = [headerLine, ...dataLines].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'txs_history.csv');
    link.click();
  };

  // ========== 渲染 ==========
  if (error) {
    return <div>Error in Table: {error.message}</div>;
  }

  return (
    <div style={{ marginBottom: '100px' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h4 className="pb-2">Txs History</h4>
        <button className="tableDownloadButton" onClick={handleDownloadCSV}>
          Download CSV
        </button>
      </div>

      <div className="table-responsive noto mt-3" style={{ paddingTop: '10px' }}>
        <table className="table table-striped table-bordered">
          <thead className="thead-dark">
            <tr>
              <th>Txs ID</th>
                            <th>Status</th>

              <th>Txs Created Timestamp</th>
              <th>Txs Type</th>
              <th>From Token Address</th>
              <th>From Token Amount</th>
              <th>To Token Address</th>
              <th>To Token Amount</th>
              <th>From Address</th>
              <th>To Address</th>
              <th>Method</th>
              <th>Chain</th>
              <th>Network</th>
              <th>Fees Percent</th>
              <th>Network Fees</th>
              <th>Redeem Amount After Fees</th>
              <th>Eta</th>
              <th>Transaction Signature</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((row, idx) => {
              return (
                <tr key={idx}>
                  <td>{row.txs_id}</td>
                                    <td style={{ color: getStatusColor(row.status) }}>{row.status}</td>

                  <td>{row.txs_created_timestamp}</td>
                  <td>{row.txs_type}</td>
                  <td>{row.from_token_address}</td>
                  <td>{formatNumber(row.from_token_amount)}</td>
                  <td>{row.to_token_address}</td>
                  <td>{formatNumber(row.to_token_amount)}</td>
                  <td>{row.from_address}</td>
                  <td>{row.to_address}</td>
                  <td>{row.method}</td>
                  <td>{row.chain}</td>
                  <td>{row.network}</td>
                  <td>{formatNumber(row.fees_percent)}</td>
                  <td>{formatNumber(row.network_fees)}</td>
                  <td>{formatNumber(row.redeem_amount_after_fees)}</td>
                  <td>{row.eta}</td>
                  <td>{row.transaction_signature}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <div className="pagination-container">
          <button
            className="pagination-button"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            id="first-page-button"
          >
            First
          </button>

          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>

          {pageNumbers.map((p, i) =>
            p === '...' ? (
              <span key={i} className="pagination-ellipsis">
                ...
              </span>
            ) : (
              <button
                key={i}
                className={`pagination-button ${p === currentPage ? 'active-page' : ''}`}
                onClick={() => handlePageChange(p)}
              >
                {p}
              </button>
            )
          )}

          <button
            className="pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
          <button
            className="pagination-button"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            id="last-page-button"
          >
            Last
          </button>
        </div>
      )}
    </div>
  );
};

export default TxsHistoryTable;