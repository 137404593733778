import React, { useState, useRef, useEffect } from 'react';
import styles from './Tooltip.module.css';

const Tooltip = ({ text, children, link, backgroundColor }) => {
  const [hovered, setHovered] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const [arrowStyle, setArrowStyle] = useState({});
  const tooltipRef = useRef(null);

  const showTooltip = () => setHovered(true);
  const hideTooltip = () => setHovered(false);

  useEffect(() => {
    if (hovered && tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      // 你也可以根据需要修改 offset（边缘留出的安全距离）
      const offset = 8;

      // 默认样式：居中在触发元素上方
      let newStyle = {
        left: '50%',
        transform: 'translateX(-50%)', // 居中
        bottom: '120%',
        top: 'auto',  // 如果之后需要翻转到下方，就改为 top: '100%' bottom: 'auto'
      };

      // 箭头默认样式：居中
      let newArrowStyle = {
        left: '50%',
        transform: 'translateX(-50%)',
      };

      // ---------------------------
      // 1) 计算水平方向是否溢出
      // ---------------------------
      let shiftX = 0;

      // 如果左侧溢出：tooltipRect.left < offset
      if (tooltipRect.left < offset) {
        // shiftX = 需要往右推多少
        shiftX = offset - tooltipRect.left;
      }
      // 如果右侧溢出：tooltipRect.right > innerWidth - offset
      else if (tooltipRect.right > innerWidth - offset) {
        // shiftX = 需要往左推多少
        shiftX = -(tooltipRect.right - (innerWidth - offset));
      }

      // 将 tooltip 水平平移 shiftX
      // 注：因为最初 transform: translateX(-50%)，向右是正数
      //     所以我们将 shiftX 加到这个 calc 中
      newStyle.transform = `translateX(calc(-50% + ${shiftX}px))`;

      // 箭头和 tooltip 偏移方向相反
      // 如果 tooltip 被推到右边 20px，那么箭头就往左边 20px
      // 从视觉上看，箭头依然对准触发元素中心
      let arrowShiftX = -shiftX;
      newArrowStyle.transform = `translateX(calc(-50% + ${arrowShiftX}px))`;

      // ---------------------------
      // 2) 计算垂直方向是否溢出（可选）
      // ---------------------------
      if (tooltipRect.top < offset) {
        // 如果顶端超出，说明太靠上了，可以把 tooltip 放到下方
        newStyle.bottom = 'auto';
        newStyle.top = '100%'; // 让它在 children 下面
        // 如果需要箭头翻转到指向上方，也要改 arrow 的 border
        // 这里就不细写了，看需求而定
      } else if (tooltipRect.bottom > innerHeight - offset) {
        // 如果底端溢出，可以把 tooltip 翻回上方（这是默认）
        newStyle.top = 'auto';
        newStyle.bottom = '120%';
        // 同理：如果需要箭头翻转，需改 border
      }

      setTooltipStyle(newStyle);
      setArrowStyle(newArrowStyle);
    } else {
      // 如果不显示 tooltip，就重置样式
      setTooltipStyle({});
      setArrowStyle({});
    }
  }, [hovered]);

  return (
    <div
      className={styles.tooltipWrapper}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {/* 触发 tooltip 显示的元素 */}
      {children}

      {/* 只有当 hovered 为 true 时才显示 tooltip */}
      {hovered && (
        <div
          className={styles.tooltip}
          ref={tooltipRef}
        style={{
            // 1) 保持之前计算的定位样式
            ...tooltipStyle,
            // 2) 如果传了 backgroundColor，就注入 --tooltip-bg 变量
            // 没传就用默认 #32363D
            '--tooltip-bg': backgroundColor
          }}
        >
          {text}
          {/* 如果传了 link，就多显示一个“Learn More”的可点击链接 */}
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={styles.learnMoreLink}
            >
              Learn More
            </a>
          )}
          <div
            className={styles.arrow}
            style={arrowStyle}   // 箭头也需要动态样式（修正位置）
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;