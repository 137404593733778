import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getAccessToken } from '../authHelper';
import "./USDIStats.css";
import bs58 from 'bs58';

// 复制按钮图标
import copySelectorImg from "../static_files/copy-selector.svg";
import copySelectorHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/checkmark.svg";

function UsersTable() {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalStep, setModalStep] = useState('form'); // 'form' or 'confirm'
    const [selectedUserEmail, setSelectedUserEmail] = useState('');
    const [selectedAddressType, setSelectedAddressType] = useState('');
    const [inputAddress, setInputAddress] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // ------------------ 新增：管理每个地址的复制按钮状态 ------------------
    // key = email + addressType, value = 'idle' | 'hover' | 'confirm'
    const [copyStates, setCopyStates] = useState({});

    // 不同状态对应不同图标
    const copyIcons = {
        idle: copySelectorImg,
        hover: copySelectorHoverImg,
        confirm: copyConfirmImg,
    };

    // 获取单元格的当前复制状态
    const getCopyState = (email, field) => {
        return copyStates[`${email}-${field}`] || 'idle';
    };

    // 设置单元格的复制状态
    const setCopyState = (email, field, newState) => {
        setCopyStates(prev => ({
            ...prev,
            [`${email}-${field}`]: newState
        }));
    };

    // 当鼠标进入图标时，如果还不是 confirm 状态，则显示 hover 图标
    const handleMouseEnter = (email, field) => {
        const current = getCopyState(email, field);
        if (current !== 'confirm') {
            setCopyState(email, field, 'hover');
        }
    };

    // 当鼠标离开图标时，如果还不是 confirm 状态，则恢复 idle 状态
    const handleMouseLeave = (email, field) => {
        const current = getCopyState(email, field);
        if (current !== 'confirm') {
            setCopyState(email, field, 'idle');
        }
    };

    // ------------------ 复制核心逻辑：含 fallback ------------------
    // 优先使用 navigator.clipboard，否则用 document.execCommand
    const copyToClipboard = async (text) => {
        if (!text) return;
        if (navigator.clipboard && window.isSecureContext) {
            // 1) 在 HTTPS 等安全上下文里，直接用 Clipboard API
            return navigator.clipboard.writeText(text);
        } else {
            // 2) fallback 方式
            const textArea = document.createElement('textarea');
            textArea.value = text;
            // 隐藏到视口外
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            } finally {
                textArea.remove();
            }
        }
    };

    // 点击图标进行复制
    const handleCopy = async (text, email, field) => {
        if (!text) return;
        try {
            await copyToClipboard(text);
            // 复制成功后显示 confirm 图标
            setCopyState(email, field, 'confirm');
            // 1 秒后回到 idle
            setTimeout(() => {
                setCopyState(email, field, 'idle');
            }, 1000);
        } catch (err) {
            console.error("Copy failed:", err);
        }
    };

    // ------------------ 原有获取用户数据 ------------------
    const fetchUsers = async () => {
        try {
            const access_token = await getAccessToken();
            const response = await axios.get(
                'https://max1-funding-arb.uc.r.appspot.com/check_tables',
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            if (response.data && response.data.users) {
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // 打开弹窗进行修改
    const handleAddressClick = (email, addressType) => {
        setSelectedUserEmail(email);
        setSelectedAddressType(addressType);
        setInputAddress('');
        setModalStep('form');
        setErrorMessage('');
        setShowModal(true);
    };

    // 验证地址
    const validateAddress = (type, address) => {
        const addr = address.trim();
        if (!addr) return "Address cannot be empty.";

        switch (type) {
            case 'ethereum_receive_address':
            case 'bsc_receive_address':
                if (!addr.startsWith('0x') || addr.length !== 42) {
                    return "Invalid address format. Must start with '0x' and be 42 characters long.";
                }
                break;
            case 'solana_receive_address': {
                const base58Regex = /^[1-9A-HJ-NP-Za-km-z]+$/;
                if (!base58Regex.test(addr) || addr.length < 32 || addr.length > 50) {
                    return "Invalid Solana address (incorrect characters or length).";
                }
                try {
                    const decoded = bs58.decode(addr);
                    if (decoded.length !== 32) {
                        return "Invalid Solana address. Decoded address must be 32 bytes.";
                    }
                } catch (e) {
                    return "Invalid Solana address. Decoding from Base58 failed.";
                }
            }
                break;
            case 'tron_receive_address':
                if (!addr.startsWith('T')) {
                    return "Invalid TRON address. Must start with 'T'.";
                }
                break;
            case 'wire_info':
                if (!addr) {
                    return "Wire info cannot be empty.";
                }
                break;
            default:
                return "Unknown address type.";
        }

        return ""; // no error
    };

    // 输入完成后，点击添加地址按钮
    const handleAddWalletClick = () => {
        const error = validateAddress(selectedAddressType, inputAddress);
        if (error) {
            setErrorMessage(error);
            return;
        }
        setErrorMessage('');
        setModalStep('confirm');
    };

    // 确认并发送请求
    const handleConfirmAddWallet = async () => {
        try {
            const access_token = await getAccessToken();
            const requestData = {
                email: selectedUserEmail,
                [selectedAddressType]: inputAddress
            };

            await axios.post(
                'https://max1-funding-arb.uc.r.appspot.com/add_wallet',
                requestData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            // 更新成功后重新拉取
            setShowModal(false);
            fetchUsers();
        } catch (error) {
            console.error('Error adding wallet:', error);
            setErrorMessage('Failed to add wallet. Please try again later.');
        }
    };

    // ------------------ 分页逻辑 ------------------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // 以 email 首字母排序
    const sortedUsers = [...users].sort((a, b) => {
        const aFirst = a.email?.[0]?.toLowerCase() || "";
        const bFirst = b.email?.[0]?.toLowerCase() || "";
        return aFirst.localeCompare(bFirst);
    });

    const getPageNumbers = (curPage, totalPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            let left = curPage - 1;
            let right = curPage + 1;

            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }

            if (right < totalPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };

    const totalItems = sortedUsers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = sortedUsers.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };

    const pageNumbers = getPageNumbers(currentPage, totalPages);

    // ------------------ 下载 CSV ------------------
    const handleDownloadCSV = () => {
        const headers = [
            'Email',
            'Ethereum Receive Address',
            'BSC Receive Address',
            'Tron Receive Address',
            'Solana Receive Address',
            'Wire Info',
            'Create Wallet Request'
        ];

        const csvRows = sortedUsers.map(user => {
            const rowData = [
                user.email || '',
                user.ethereum_receive_address || '',
                user.bsc_receive_address || '',
                user.tron_receive_address || '',
                user.solana_receive_address || '',
                user.wire_info || '',
                user.create_wallet_requested ? 'Yes' : 'No'
            ];
            return rowData.map(field => `"${field}"`).join(',');
        });

        const csvString = [headers.join(','), ...csvRows].join('\n');
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users_table.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const addressFields = [
        'ethereum_receive_address',
        'bsc_receive_address',
        'tron_receive_address',
        'solana_receive_address',
        'wire_info'
    ];

    return (
        <div>
            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                        <tr>
                            <th>Email</th>
                            <th style={{minWidth:"550px"}}>Ethereum Receive Address</th>
                            <th style={{minWidth:"550px"}}>BSC Receive Address</th>
                            <th style={{minWidth:"550px"}}>Tron Receive Address</th>
                            <th style={{minWidth:"550px"}}>Solana Receive Address</th>
                            <th>Wire Info</th>
                            <th>Create Wallet Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((user, index) => (
                            <tr key={index}>
                                <td>{user.email || ''}</td>
                                {addressFields.map((field) => {
                                    const addressValue = user[field] || '';
                                    const curState = getCopyState(user.email, field);
                                    const iconSrc = copyIcons[curState];

                                    return (
                                        <td
                                            key={field}
                                            className="clickable-cell"
                                            style={{ cursor: 'pointer', position: 'relative' }}
                                            onClick={() => handleAddressClick(user.email, field)}
                                            title="Click to add/change address to this wallet"
                                        >
                                            {/* 显示地址 */}
                                            {addressValue}
                                            {/* 如果有地址，才显示复制按钮 */}
                                            {addressValue && addressValue !== "requested" && (
                                                <img
                                                    src={iconSrc}
                                                    alt="copy"
                                                    style={{
                                                        width: '15px',
                                                        marginLeft: '6px',
                                                        verticalAlign: 'middle',
                                                        cursor: 'pointer'
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.stopPropagation();
                                                        handleMouseEnter(user.email, field);
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.stopPropagation();
                                                        handleMouseLeave(user.email, field);
                                                    }}
                                                    onClick={(e) => {
                                                        // 阻止冒泡，以免同时触发编辑弹窗
                                                        e.stopPropagation();
                                                        handleCopy(addressValue, user.email, field);
                                                    }}
                                                />
                                            )}
                                        </td>
                                    );
                                })}
                                <td>
                                    {user.create_wallet_requested ? 'Yes' : 'No'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination & Download Button */}
            {totalPages > 1 && (
                <div className="pagination-outer-container">
                    <div className="pagination-container" style={{ marginTop: "10px" }}>
                        <button
                            className="pagination-button"
                            id="first-page-button"
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            First
                        </button>
                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>

                        {pageNumbers.map((p, i) =>
                            p === '...' ? (
                                <span key={i} className="pagination-ellipsis">
                                    ...
                                </span>
                            ) : (
                                <button
                                    key={i}
                                    className={`pagination-button ${p === currentPage ? 'active-page' : ''}`}
                                    onClick={() => handlePageChange(p)}
                                >
                                    {p}
                                </button>
                            )
                        )}

                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                        <button
                            className="pagination-button"
                            id="last-page-button"
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            Last
                        </button>
                    </div>
                    {/* 下载按钮 */}
                    <button className="tableDownloadButton" onClick={handleDownloadCSV}>
                        Download CSV
                    </button>
                </div>
            )}

            {/* Modal */}
            {showModal && (
                <div
                    className="modal d-block"
                    tabIndex="-1"
                    role="dialog"
                    style={{ background: 'rgba(0,0,0,0.5)' }}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {modalStep === 'form' && (
                                <>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Add Address for {selectedUserEmail}</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Address Type: {selectedAddressType}</p>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter new address"
                                            value={inputAddress}
                                            onChange={(e) => setInputAddress(e.target.value)}
                                        />
                                        {errorMessage && (
                                            <div className="alert alert-danger mt-2">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Close
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={handleAddWalletClick}
                                        >
                                            Add wallet
                                        </button>
                                    </div>
                                </>
                            )}

                            {modalStep === 'confirm' && (
                                <>
                                    <div className="modal-header">
                                        <h5 className="modal-title">Confirm Wallet Addition</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Email: {selectedUserEmail}</p>
                                        <p>Type: {selectedAddressType}</p>
                                        <p>New Address: {inputAddress}</p>
                                        <p>Please confirm to add this wallet address.</p>
                                        {errorMessage && (
                                            <div className="alert alert-danger mt-2">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => setShowModal(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-success"
                                            onClick={handleConfirmAddWallet}
                                        >
                                            Confirm
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UsersTable;