import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import styles from './AddEmailModal.module.css';
import { SwapMainAppContext } from '../../SwapMainAppContext';
import backImg from "../static_files/back arrow.svg";
import backHoverImg from "../static_files/back arrow hover.svg";
import { USDI_API_URL } from "../../configs/config";

function AddEmailModal({ onClose, walletAddress }) {
  const [email, setEmail] = useState('');
  const [backButtonIsHover, setBackButtonIsHover] = useState(false);
  const { showNotification } = useContext(SwapMainAppContext);

  const handleAdd = async () => {
    try {
      const response = await fetch(
        `${USDI_API_URL}/update?table=usdi_holders_info_table_solana&key=address&value=${walletAddress}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email }),
        }
      );
      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error || 'Failed to update email.');
      }
      console.log('Email updated successfully');
      showNotification("success", "Email updated successfully!");

      // "Add" => do not show again next time => onClose(true)
      onClose(true);
    } catch (err) {
      console.error('Error:', err);
      showNotification("error", "Email updated successfully!");
      // Even on failure, we just keep the modal open so user can correct input
    }
  };

  return ReactDOM.createPortal(
    <div
      className={styles.modalOverlay}
      onClick={(e) => {
        // If user clicked the background
        if (e.target === e.currentTarget) {
          // close => can show again => onClose(false)
          onClose(false);
        }
      }}
    >
      <div className={styles.modalContainer}>
        {/* 顶部标题与关闭按钮 */}
        <div className={styles.headerRow}>
          <img
            style={{
              background: 'none',
              border: 'none',
              width: "20px",
              height: "20px",
              cursor: 'pointer'
            }}
            src={backButtonIsHover ? backHoverImg : backImg}
            onMouseEnter={() => setBackButtonIsHover(true)}
            onMouseLeave={() => setBackButtonIsHover(false)}
            // Back arrow => close => can show again => onClose(false)
            onClick={() => onClose(false)}
            alt="Back"
          />
          <h2 className={styles.title}>Contact info</h2>
          {/* “×” => close => can show again => onClose(false) */}
          <div className={styles.closeBtn} onClick={() => onClose(false)}>
            &times;
          </div>
        </div>

        <div className={styles.dividerLine} />

        <p style={{ fontSize: '11px', color: '#fff', marginBottom: '14px' }}>
          Add an email address to receive notifications once the funds are transferred.
        </p>

        <label style={{ fontSize: '13px' }}>Email</label>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%' }}
        />

        <p style={{ fontSize: '11px', color: '#8D8D8D', marginBottom: '28px' }}>
          Note: Adding an email address for communication purposes is optional.
        </p>

        {/* 按钮区：No thanks / Add */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0' }}>
          {/* “No thanks” => skip next time => onClose(true) */}
          <button
            className={styles.cancelBtn}
            style={{
              width: '48%',
              backgroundColor: '#2B2B33',
              color: '#757576',
            }}
            onClick={() => onClose(true)}
          >
            No thanks
          </button>
          <button
            className={styles.confirmBtn}
            style={{ width: '48%' }}
            onClick={handleAdd}
          >
            Add
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
}

export default AddEmailModal;