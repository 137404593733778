import React, {useEffect, useState} from 'react';

const EmailUIDTxsRequestsTable = ({selectedChain, onChainSelect}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // ================== 大小写转换辅助函数 ==================
    // 1) status: 若值为 "requested"，改为 "Requested"
    const formatStatus = (status) => {
        if (status === "requested") {
            return "Requested";
        } else if (status === "executed") {
            return "Executed"
        }
        return status; // 其他保持原状
    };

    // 2) txs_type: 将首字母大写。如 "redeem" -> "Redeem"
    const formatTxsType = (type) => {
        if (!type) return "";
        return type.charAt(0).toUpperCase() + type.slice(1);
    };

    // 3) chain: 将首字母大写。如 "ethereum" -> "Ethereum"
    const formatChain = (chain) => {
        if (!chain) return "";
        return chain.charAt(0).toUpperCase() + chain.slice(1);
    };

    // 4) network: 若值为 "c2c" -> "C2C", 若 "blockchain" -> "Blockchain"
    const formatNetwork = (network) => {
        if (network === "c2c") return "C2C";
        if (network === "blockchain") return "Blockchain";
        return network; // 其他保持原状
    };

    // 5) method: "cash"->"Cash", "usdc"->"USDC", "usdt"->"USDT"
    const formatMethod = (method) => {
        if (method === "cash") return "Cash";
        if (method === "usdc") return "USDC";
        if (method === "usdt") return "USDT";
        return method; // 其他保持原状
    };

    // ================== 点击 status 时的逻辑（示例） ==================
    const handleStatusClick = async (email_uid_user_txs_id) => {
        try {
            // 1) 输入 transaction_signature
            const transaction_signature = window.prompt("Please enter the transaction signature:");
            if (!transaction_signature) {
                return; // 用户取消或未输入
            }

            // 2) 确认
            const confirmComplete = window.confirm(`Are you sure you want to complete for email_uid_user_txs_id： ${email_uid_user_txs_id}?`);
            if (!confirmComplete) {
                return;
            }

            // 3) 发请求到后端（已注释示例）
                        console.log("email_uid_user_txs_id", email_uid_user_txs_id)

            const response = await fetch(
                "https://usdi-api-jlukzyz7wa-ew.a.run.app/execute_email_uid_txs_requests",
                {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        email_uid_user_txs_id,      // 那一行的ID
                        transaction_signature      // 用户输入的签名
                    }),
                }
            );
            if (!response.ok) {
                throw new Error("Request failed. Please verify that the transaction signature was processed successfully.");
            }

            // 4) 刷新数据
            await fetchData();
            alert("Request executed successfully!");
        } catch (err) {
            alert("Error: " + err.message);
        }
    };

    // ================== 分页辅助函数 ==================
    const getPageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            let left = currentPage - 1;
            let right = currentPage + 1;

            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }
            if (right < totalPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };

    // ================== 分页状态 ==================
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // ================== 拉取数据 ==================
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                'https://usdi-api-jlukzyz7wa-ew.a.run.app/get_email_uid_txs_requests'
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setData(result);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    // 组件挂载时获取数据
    useEffect(() => {
        fetchData();
    }, []);

    // data 改变时回到第 1 页
    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // ================== 计算分页数据 ==================
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = data.slice(startIndex, endIndex);
    const pageNumbers = getPageNumbers(currentPage, totalPages);

    return (
        <div>
            {/* 如果有顶部 Tab，这里保持不变或省略，示例略去 */}

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                    <tr>
                        <th>Email UID User Request ID</th>
                        <th>Email</th>
                        <th>From Token Amount</th>
                        <th>Payment Method Name</th>
                        <th>To_Address</th>

                        <th>status</th>
                        <th>Requested Timestamp UTC</th>
                        {/*<th>Txs ID</th>*/}
                        <th>Txs Type</th>
                        <th>From Token Address</th>
                        <th>To Token Address</th>
                        <th>To Token Amount</th>
                        <th>From_Address</th>
                        <th>method</th>
                        {/*<th>Cash Txs ID</th>*/}
                        <th>chain</th>
                        <th>Network</th>
                        <th>fees %</th>
                        <th>Network Fees</th>
                        <th>Redeem Amount After Fees</th>
                        <th>ETA</th>
                        <th>Executed Timestamp</th>
                        <th>Payment Info ID</th>
                        <th>Payment Method Detail</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentItems.map((row, index) => (
                        <tr key={index}>
                            <td style={{minWidth: '120px'}}>
                                {row.email_uid_user_txs_id}
                            </td>
                            <td>{row.email}</td>
                            {row.from_token_amount ? (
                                <td>{parseFloat(row.from_token_amount).toFixed(6)}</td>
                            ) : (
                                <td></td>
                            )}
                            <td>{row.payment_method}</td>
                                                        <td>{row.to_address}</td>

                            <td
                                style={{
                                    color: row.status === 'executed' ? '#6CE3C4' : '#E3CF6C',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleStatusClick(row.email_uid_user_txs_id)}
                            >
                                {formatStatus(row.status)}
                            </td>
                            <td>{row.requested_timestamp}</td>
                            <td>{formatTxsType(row.txs_type)}</td>
                            <td>{row.from_token_address}</td>


                            <td>{row.to_token_address}</td>
                            <td>{row.to_token_amount}</td>
                            <td>{row.from_address}</td>

                            <td>{formatMethod(row.method)}</td>
                            <td>{formatChain(row.chain)}</td>
                            <td>{formatNetwork(row.network)}</td>

                            {row.fees_percent ? (
                                <td>{parseFloat(row.fees_percent).toFixed(2)}%</td>
                            ) : (
                                <td></td>
                            )}
                            {row.network_fees ? (
                                <td>{parseFloat(row.network_fees).toFixed(2)}</td>
                            ) : (
                                <td></td>
                            )}
                            {row.redeem_amount_after_fees ? (
                                <td>{parseFloat(row.redeem_amount_after_fees).toFixed(2)}</td>
                            ) : (
                                <td></td>
                            )}
                            <td>{row.eta}</td>
                            <td>{row.executed_timestamp || ''}</td>
                            <td>{row.payment_info_id}</td>
                            <td>{row.payment_info_detail}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* ================== 分页控件 ================== */}
            {totalPages > 0 && (
                <div className="pagination-container">
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        id="first-page-button"
                    >
                        First
                    </button>
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>

                    {pageNumbers.map((p, i) =>
                        p === '...' ? (
                            <span key={i} className="pagination-ellipsis">
                                ...
                            </span>
                        ) : (
                            <button
                                key={i}
                                className={`pagination-button ${p === currentPage ? 'active-page' : ''}`}
                                onClick={() => handlePageChange(p)}
                            >
                                {p}
                            </button>
                        )
                    )}

                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        id="last-page-button"
                    >
                        Last
                    </button>
                </div>
            )}
        </div>
    );
};

export default EmailUIDTxsRequestsTable;