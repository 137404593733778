// config.js

export const ENDPOINT_BASE_URL = 'https://max1-funding-arb.uc.r.appspot.com';
export const USDI_API_URL = 'https://usdi-api-jlukzyz7wa-ew.a.run.app/';

export const RPC_URL = 'https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f';
// const RPC_URL = "https://rpc.ankr.com/solana/80465c767c6a5751c4cadf0778b6917d26c2d0a9ae583d3616ffaae71191a7c4";


let configCache = {
  squadsPublicKey: '',
  usdiTokenAddress: '',
  usdtTokenAddress: '',
  usdcTokenAddress: '',
  kraTokenAddress: '',
  c2c_redeem_network_fee_percentage: '',
  c2c_squad_receiving_address:'',
  isFetched: false, // We'll rely on this to see if we need to fetch
};

// This function does the actual fetching:
async function initConfig() {
  try {
    // 1) Fetch squads public key
    {
      const res = await fetch(
        'https://usdi-api-256611876551.europe-west1.run.app/usdi_squads_public_key'
      );
      if (!res.ok) throw new Error('Failed to fetch squads public key');
      const data = await res.json();
      if (data && data.USDi_Squads_Public_Key) {
        configCache.squadsPublicKey = data.USDi_Squads_Public_Key;
      }
    }

    // 2) Fetch all Solana token addresses
    {
      const res = await fetch(
        'https://usdi-api-256611876551.europe-west1.run.app/verified_solana_token_mint_address'
      );
      if (!res.ok) throw new Error('Failed to fetch verified_solana_token_mint_address');
      const data = await res.json();
      // e.g.
      // {
      //   "verified_token_solana_usdi": "...",
      //   "verified_token_solana_usdt": "...",
      //   "verified_token_solana_usdc": "...",
      //   "verified_token_solana_kra": "..."
      // }
      if (data) {
        if (data.verified_token_solana_usdi) {
          configCache.usdiTokenAddress = data.verified_token_solana_usdi;
        }
        if (data.verified_token_solana_usdt) {
          configCache.usdtTokenAddress = data.verified_token_solana_usdt;
        }
        if (data.verified_token_solana_usdc) {
          configCache.usdcTokenAddress = data.verified_token_solana_usdc;
        }
        if (data.verified_token_solana_kra) {
          configCache.kraTokenAddress = data.verified_token_solana_kra;
        }
      }
    }

    // 3) Fetch c2c_redeem_config_solana
    {
      const res = await fetch(
        'https://usdi-api-256611876551.europe-west1.run.app/c2c_redeem_config_solana'
      );
      if (!res.ok) throw new Error('Failed to fetch c2c_redeem_config_solana');
      const data = await res.json();
      if (data && data.network_fee_percentage) {
        configCache.c2c_redeem_network_fee_percentage = data.network_fee_percentage;
      }
    }
  {
      const response = await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_c2c_receiving_address", {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},

        });
        if (!response.ok) {
            throw new Error(`Server responded with ${response.status}`);
        }
        const data = await response.json();
         if (data && data.c2c_usdi_receiving_squads_address) {
        configCache.c2c_squad_receiving_address = data.c2c_usdi_receiving_squads_address;
      }
    }
    // Mark as fetched after everything is successful
    configCache.isFetched = true;

  } catch (error) {
    console.error('Error fetching config:', error);
    throw error;
  }
}

/**
 * getConfig: Lazy-loads the config if it hasn't been fetched yet.
 */
export async function getConfig() {
  // If we haven't fetched yet, do so now
  if (!configCache.isFetched) {
    await initConfig();
  }

  // Return a snapshot of the config
  return {
    squadsPublicKey: configCache.squadsPublicKey,
    usdiTokenAddress: configCache.usdiTokenAddress,
    usdtTokenAddress: configCache.usdtTokenAddress,
    usdcTokenAddress: configCache.usdcTokenAddress,
    kraTokenAddress: configCache.kraTokenAddress,
    c2c_redeem_network_fee_percentage:
      configCache.c2c_redeem_network_fee_percentage,
    c2c_squad_receiving_address: configCache.c2c_squad_receiving_address,
  };
}