// components/USDIStats.js
import React, {useState} from 'react';
import Footer from './footer/Footer';
import Header from './header/Header';
import SmartContractTable from './usdi_tables/SmartContractTable';
import OverallUSDIPayout from './usdi_tables/OverallUsdiPayout';
import RawBlockchain from './usdi_tables/RawBlockchain';
import UserInfo from './usdi_tables/users';
import FilterExample from './usdi_tables/filterExample';
import './usdi_tables/USDIStats.css';
import UserTable from "./usdi_tables/UserTable";
import HolderInfoTableETH from "./usdi_tables/USDIHolderInfoETH";
import HolderInfoTableSolana from "./usdi_tables/USDIHolderInfoTableSolana";
import ConnectWalletHeader from "./header/ConnectWalletHeader";

const USDIStats = () => {
    const [parentStatus, setParentStatus] = useState("calculated");
    // selectedChain to decide if its ETH chain or Solana
    const [selectedChain, setSelectedChain] = useState('eth');
    const [isTransferComplete, setIsTransferComplete] = useState(false);

    // Function to handle status change in the parent component
    const handleStatusChangeInParent = (newStatus) => {
        console.log(`Status changed to ${newStatus} in the parent component`);
        setParentStatus(newStatus);
    };
    const handleChainSelect = (chain) => {
        setSelectedChain(chain);
    };
    const [amount, setAmount] = useState('');
    const [toAddress, setToAddress] = useState('');

    // 2. 用来触发交易的布尔值
    const [transactionTrigger, setTransactionTrigger] = useState(0);

    // 用于显示或隐藏 ConnectWalletHeader 里的弹窗（如果你需要手动控制）
    const [showPanel, setShowPanel] = useState(false);
    const [usdiBalance, setUsdiBalance] = useState(0)
    // 父组件想拿到钱包地址、余额等信息时，可以通过这个回调拿到
    const handleWalletConnected = (walletAddr, usdcBal, usdtBal, usdiBal) => {

        setUsdiBalance(usdiBal)
    };

    const transferComplete = () => {
        setIsTransferComplete(true);
        console.log("2222")
    };
    return (
        <div className="container">
            <div style={{marginTop: '7px'}}>
                <ConnectWalletHeader
                    isAdminHeader={true}         // 传 true 就会显示你原先的 admin header（如果不需要就传false）
                    amount={amount}             // 金额
                    toAddress={toAddress}       // 接收方地址
                    selectedToken="USDI"        // 默认转 USDI
                    transactionType="transfer"  // 让ConnectWalletHeader里识别成“transfer”类型
                    transactionTrigger={transactionTrigger} // 当它变为true，自动执行一次交易
                    onWalletConnected={handleWalletConnected} // 拿到已连接的钱包信息
                    transferComplete={transferComplete}
                    // 如果 ConnectWalletHeader 有 showPanel / setShowPanel 用于钱包弹窗，也可以传
                    showPanel={showPanel}
                    setShowPanel={setShowPanel}
                />
            </div>

            <div className="title-box d-flex justify-content-between align-items-center">
            </div>
            <h3 className="pb-3">Smart Contract Table</h3>
            <SmartContractTable
                selectedChain={selectedChain}
                onChainSelect={handleChainSelect}
            />

            <OverallUSDIPayout selectedChain={selectedChain}
                               onStatusChange={handleStatusChangeInParent}/>
            <div><h3 className="pb-3">Raw Blockchain Data</h3></div>
            <RawBlockchain selectedChain={selectedChain}/>

            {selectedChain === "eth" ? <HolderInfoTableETH selectedChain={selectedChain}/> :
                <HolderInfoTableSolana selectedChain={selectedChain} setAmount={setAmount} setToAddress={setToAddress}
                                       setTransactionTrigger={setTransactionTrigger} usdiBalance={usdiBalance}
                                       isTransferComplete={isTransferComplete} setIsTransferComplete={setIsTransferComplete}/>}
            <h3 className="pb-3">User Info</h3>
            <UserInfo/>
            <h3 className="pb-3">Filter by Address Example</h3>
            <FilterExample/>
            <h3 className="pb-3">UserTable</h3>
            <UserTable></UserTable>
            <Footer/>
        </div>
    );
};

export default USDIStats;
