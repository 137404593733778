import React, {useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import styles from './RedeemToModal.module.css';
import {shortenAddress} from '../utils'
import usdtImg from "../static_files/usdt 1.svg";
import usdcImg from "../static_files/usdc.svg";
import backImg from "../static_files/back arrow.svg";
import backHoverImg from "../static_files/back arrow hover.svg";

import {SwapMainAppContext} from "../../SwapMainAppContext";

function RedeemToModal({
    onClose,            // 父级点击“x”或点击overlay时会调用此函数，用来彻底关闭两个Modal
    onConfirm,          // 最终点击“Redeem Now”时，把收集到的数据回传给父级的回调
    selectedToken,      // 父级传入的token，比如 'USDT' 或 'USDC'
    redeemAmount,       // 父级传入的数量，比如 10.12
    recentWallets = []
}) {
    const {showNotification} = useContext(SwapMainAppContext);

    // 控制当前是第一个还是第二个Modal
    const [showDetailModal, setShowDetailModal] = useState(false);

    // 用户输入的状态
    // 1) network 默认固定为 "Solana"，禁止修改
    const [network] = useState('Solana');
    const [walletAddress, setWalletAddress] = useState('');

    const [backButtonIsHover, setBackButtonIsHover] = useState(false);

    // 处理点击“Confirm”按钮，切换到第二个Modal
    const handleFirstConfirm = () => {
        // 简单校验
        if (!walletAddress) {
            showNotification("error", "Please enter your wallet address!");
            return;
        }
        // 显示详细信息的Modal
        setShowDetailModal(true);
    };

    // 处理第二个Modal点击“Redeem Now”
    const handleRedeemNow = () => {
        // 把用户选的/输的值丢给父级
        onConfirm({
            network,
            walletAddress,
        });
    };

    // 获取当前时间并格式化成你图中看到的样子（示例：01/08/2025, 13:55:13）
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const now = new Date();
        const formatted = now.toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).replace(',', '');
        setCurrentTime(formatted);
    }, []);

    // 第一个Modal
    const renderRedeemToModal = () => {

        return (
            <div
                className={styles.modalOverlay}
                onClick={(e) => {
                    if (e.target === e.currentTarget) {
                        onClose();
                    }
                }}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.headerRow}>
                        <h2 className={styles.title}>Redeem to</h2>
                        <div className={styles.closeBtn} onClick={onClose}>&times;</div>
                    </div>

                    <div className={styles.dividerLine}/>

                    {/* 1) Network：只读不可改 */}
                    <label style={{fontSize: "12px"}}>Network</label>
                    <input
                        type="text"
                        value={network}
                        readOnly
                        style={{
                            width: '100%',
                            marginBottom: '1rem',
                        }}
                    />

                    {/* 2) Wallet Address */}
                    <label style={{fontSize: "12px"}}>Wallet Address</label>
                    <input
                        type="text"
                        placeholder="Enter your wallet address"
                        value={walletAddress}
                        onChange={(e) => setWalletAddress(e.target.value)}
                        style={{width: '100%', marginBottom: '1rem'}}
                    />

                    {/* Recents 列表 */}
                    {recentWallets.length > 0 && (
                        <div style={{marginBottom: '1rem'}}>
                            <div style={{fontSize: "12px", marginBottom: '20px'}}>Recents</div>
                            {/* Scrollable container for all addresses */}
                            <div
                                className={styles.recentWalletsScrollBar}
                                style={{
                                    maxHeight: '150px', // Enough to roughly show ~3 items
                                    overflowY: 'auto',
                                }}
                            >
                                {recentWallets.map((item, idx) => {
                                    // Support object or string format
                                    const isObjectFormat = typeof item === 'object' && item !== null;
                                    const address = isObjectFormat ? item.address : item;
                                    const walletType = isObjectFormat && item.type ? item.type : '';

                                    return (
                                        <div
                                            key={idx}
                                            style={{
                                                cursor: 'pointer',
                                                marginBottom: '10px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: '4px'
                                            }}
                                            onClick={() => setWalletAddress(address)}
                                        >
                                            {walletType ? (
                                                <div style={{fontWeight: 'bold'}}>
                                                    {walletType} Wallet
                                                </div>
                                            ) : null}
                                            <div style={{fontSize: '0.9rem'}} className={styles.recentAddress}>
                                                {address}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    <button
                        className={styles.confirmBtn}
                        onClick={handleFirstConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        );
    };

    // 第二个Modal
    const renderRedeemDetailModal = () => (
        <div
            className={styles.modalOverlay}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose();
                }
            }}
        >
            <div className={styles.modalContainer} style={{height: "572px"}}>
                <div className={styles.headerRow}>
                    {/* 返回按钮 */}
                    <img
                        style={{
                            background: 'none',
                            border: 'none',
                            width: "20px",
                            height: "20px",
                            cursor: 'pointer'
                        }}
                        src={backButtonIsHover ? backHoverImg : backImg}
                        onMouseEnter={() => setBackButtonIsHover(true)}
                        onMouseLeave={() => setBackButtonIsHover(false)}
                        onClick={() => setShowDetailModal(false)}
                        alt="Back"
                    />
                    <h2 className={styles.title}>Redeem detail</h2>
                    <div className={styles.closeBtn} onClick={onClose}>&times;</div>
                </div>

                <div style={{marginBottom: "26px"}} className={styles.dividerLine}/>

                <div style={{textAlign: 'center', marginBottom: '1rem'}}>
                    {selectedToken === 'USDT' && (
                        <img src={usdtImg} alt="USDT" width="50" height="50"/>
                    )}
                    {selectedToken === 'USDC' && (
                        <img src={usdcImg} alt="USDC" width="50" height="50"/>
                    )}
                </div>
                <div style={{fontWeight: "700px"}} className={styles.titleRow}>
                    Redeem {redeemAmount.toLocaleString('en-US',{maximumFractionDigits:6})} in {selectedToken}
                </div>
                <div className={styles.titleRow} style={{color: '#D2D2D2', marginBottom: '78px'}}>
                    {redeemAmount.toLocaleString('en-US',{maximumFractionDigits:6})} USDi
                </div>

                {/* 信息区 */}
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <span style={{color: '#BBBBBB'}}>Redeem to</span>
                    <span style={{
                        color: '#fff',
                        maxWidth: '220px',
                        textAlign: 'right'
                    }}>{shortenAddress(walletAddress)}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <span style={{color: '#BBBBBB'}}>Time</span>
                    <span style={{color: '#fff'}}>{currentTime}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <span style={{color: '#BBBBBB'}}>Network</span>
                    <span style={{color: '#fff'}}>{network}</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <span style={{color: '#BBBBBB'}}>Network fee</span>
                    <span style={{color: '#fff'}}>No fee</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '12px'}}>
                    <span style={{color: '#BBBBBB'}}>Rate</span>
                    <span style={{color: '#fff'}}>1 USDi = 1 USD</span>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.75rem'}}>
                    <span style={{color: '#BBBBBB'}}>ETA</span>
                    <span style={{color: '#fff'}}>24 H</span>
                </div>

                <div style={{marginBottom: "7px"}} className={styles.dividerLine}/>
                <p style={{color: '#666', fontSize: '0.85rem', marginBottom: '1rem'}}>
                    Notes: Please only redeem to a Solana address.
                </p>

                <button
                    className={styles.confirmBtn}
                    onClick={handleRedeemNow}
                >
                    Redeem Now
                </button>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        <>
            {
                !showDetailModal
                    ? renderRedeemToModal()
                    : renderRedeemDetailModal()
            }
        </>,
        document.getElementById('root')
    );
}

export default RedeemToModal;