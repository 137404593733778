import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import SwapMainAPP from "./tables/SwapMainApp";


// 当go to "/" 的时候先验证local storage有没有email address 存过，有的话后台验证token是否有效
const GatewayTokenVerifier2 = () => {
  const navigate = useNavigate();
  const { verifyGatewayToken } = useAuth();

  useEffect(() => {
    const gatewayToken = localStorage.getItem('gatewayToken');

    if (!gatewayToken) {
      return;
    }

    // 如果存在 token，就验证
    (async () => {
      const isValid = await verifyGatewayToken(gatewayToken);
      if (isValid) {
        // 如果验证通过，则让用户直接跳转到 /swap?token=xxx
        navigate(`/swap?token=${gatewayToken}`, { replace: true });
      }
    })();

  }, [navigate, verifyGatewayToken]);



  // 如果没有 token 或者 token 验证失败，就渲染普通的 SwapMainAPP
  return <SwapMainAPP />;
};

export default GatewayTokenVerifier2;