import React, {useContext, useEffect, useState} from 'react';
import styles from './Account.module.css';
import ConnectWalletHeader from '../header/ConnectWalletHeader';
import LeftPanel from '../SwapLeftPanel/LeftPanel';
import {SwapMainAppContext} from '../../SwapMainAppContext';
import {shortenAddress, maskAccountNumber, check_input} from '../utils';

import accountIcon from '../static_files/AccountIcon.svg';
// import infoIcon from '../static_files/info.svg';
import VenmoIcon from '../static_files/Venmo.svg';
import ZelleIcon from '../static_files/Zelle.svg';
import AlipayIcon from '../static_files/Alipay.svg';
import WireIcon from '../static_files/Wire.svg';
import USDIcon from '../static_files/usd.svg';

import {USDI_API_URL, ENDPOINT_BASE_URL} from '../../configs/config';
import AddPayment from './AddPayment';

function computeMethodMeta(method) {
    let displayInfo = '';
    let icon = USDIcon; // default icon

    switch (method.payment_method) {
        case 'Cash - Paper':
            displayInfo = maskAccountNumber(method.mobile_number) || '';
            icon = USDIcon;
            break;
        case 'Venmo':
            displayInfo = maskAccountNumber(method.account_number) || '';
            icon = VenmoIcon;
            break;
        case 'Alipay':
            displayInfo = maskAccountNumber(method.mobile_number) || '';
            icon = AlipayIcon;
            break;
        case 'Zelle':
            displayInfo = maskAccountNumber(method.mobile_number) || '';
            icon = ZelleIcon;
            break;
        case 'Bank Wire':
            displayInfo = maskAccountNumber(method.account_number) || '';
            icon = WireIcon;
            break;
        default:
            displayInfo = '';
            icon = USDIcon;
    }

    return {displayInfo, icon};
}

function Account() {
    const [showPanel, setShowPanel] = useState(false);
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [email, setEmail] = useState(null);
    const {showNotification} = useContext(SwapMainAppContext);

    // Main wallet address to display
    const [walletAddress, setWalletAddress] = useState('');
    // For the top-right button or email-based connection
    const [emailWalletAddress, setEmailWalletAddress] = useState(null);

    // Contact info from your DB
    const [contactInfo, setContactInfo] = useState({
        telegram: '--',
        twitter: '--',
        phone: '--',
        email: '--',
    });

    // Track which field is in edit mode
    const [isEditing, setIsEditing] = useState({});

    // Payment Methods
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [refetchPaymentMethods, setRefetchPaymentMethods] = useState(0);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);

    // Payment Method Removal Modal
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [pendingRemoveId, setPendingRemoveId] = useState(null);

    // Separate phone states for editing
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [phoneLocalNumber, setPhoneLocalNumber] = useState('');

    // 1) New: loading state for the four grey-text fields
    const [isWalletLoading, setIsWalletLoading] = useState(true);
    const [isPaymentInfoLoading, setIsPaymentInfoLoading] = useState(true);

    // ======= Clears user data on disconnect
    const handleClearAccountData = () => {
        setEmail(null);
        setWalletAddress('');
        setEmailWalletAddress(null);

        setContactInfo({
            telegram: '--',
            twitter: '--',
            phone: '--',
            email: '--',
        });

        setIsEditing({});
        setPaymentMethods([]);
        setRefetchPaymentMethods(0);
        setAvailablePaymentMethods([]);
        setShowRemoveModal(false);
        setPendingRemoveId(null);
    };

    // Called by <AddPayment/> after a new method is added
    const triggerRefetchPaymentMethods = () => {
        setRefetchPaymentMethods((prev) => prev + 1);
    };

    // Map to icons
    const iconMap = {
        'Bank Wire': WireIcon,
        'Cash - Paper': USDIcon,
        'Zelle': ZelleIcon,
        'Venmo': VenmoIcon,
        'Alipay': AlipayIcon,
    };

    // 2) Check localStorage at mount — if NO wallet is found => stop loading
    useEffect(() => {
        const lastWallet = localStorage.getItem("lastConnectedWallet");
        if (!lastWallet) {
            setIsWalletLoading(false);
            setIsPaymentInfoLoading(false)
        }
    }, []);

    useEffect(() => {
        if (walletAddress || email) {
            setIsWalletLoading(true);
            setPaymentMethods([])
            setIsPaymentInfoLoading(true)

        }
    }, [walletAddress, email]);

    // ========== Fetch available Payment Methods ==========
    useEffect(() => {
        async function fetchPaymentMethods() {
            try {
                const res = await fetch(
                    'https://max1-funding-arb.uc.r.appspot.com/available_payment_method'
                );
                let data = await res.json();

                let formatted = data.map((item) => ({
                    ...item,
                    icon: iconMap[item.method_name] || USDIcon,
                }));

                const desiredOrder = [
                    'Bank Wire',
                    'Cash - Paper',
                    'Zelle',
                    'Venmo',
                    'Alipay'
                ];
                formatted.sort((a, b) => {
                    return (
                        desiredOrder.indexOf(a.method_name) - desiredOrder.indexOf(b.method_name)
                    );
                });
                setAvailablePaymentMethods(formatted);

            } catch (error) {
                console.error('Error fetching payment methods:', error);
            }
        }

        fetchPaymentMethods();
    }, []);

    // ========== Fetch Payment Methods linked to this user ==========
    useEffect(() => {
        async function fetchPaymentMethods() {
            try {
                let paramKey = '';
                let paramValue = '';
                if (email) {
                    paramKey = 'email_address';
                    paramValue = email;
                } else if (
                    walletAddress &&
                    walletAddress !== "" &&
                    walletAddress !== "--" &&
                    walletAddress !== "null"
                ) {
                    paramKey = 'wallet_address';
                    paramValue = walletAddress;
                } else {
                    return; // no param
                }

                const url = `${ENDPOINT_BASE_URL}/get_payment_info?${paramKey}=${paramValue}`;
                const res = await fetch(url);
                if (!res.ok) {
                    console.error(`Error fetching payment info: ${res.status} ${res.statusText}`);
                    return;
                }
                const jsonData = await res.json();
                if (!jsonData.success) {
                    console.error('API returned success=false:', jsonData);
                    return;
                }

                const fetched = jsonData.data || [];
                setPaymentMethods(fetched);
                setIsPaymentInfoLoading(false);

            } catch (error) {
                console.error('Error fetching payment methods:', error);
                setIsPaymentInfoLoading(false);

            }
        }

        fetchPaymentMethods();
    }, [email, walletAddress, refetchPaymentMethods]);

    // ========== Restore from localStorage if user has email/wallet stored ==========
    useEffect(() => {
        const userEmail = localStorage.getItem('userEmail');
        if (userEmail) {
            setEmail(userEmail);
        }
        const storedAddr = localStorage.getItem('emailUserWalletAddress');
        if (storedAddr) {
            setWalletAddress(storedAddr);
            setEmailWalletAddress(storedAddr);
        }
    }, []);


    // ========== Fetch contact info from your DB once we know wallet/email ==========
    useEffect(() => {
        if (!walletAddress && !email) {
            return;
        }

        const fetchContactInfo = async () => {
            try {
                if (email === null) {
                    // Scenario 1: only wallet
                    if (
                        !walletAddress ||
                        walletAddress.trim() === "" ||
                        walletAddress === "null" ||
                        walletAddress === "--"
                    ) {
                        setWalletAddress("--");
                        return;
                    }

                    const res = await fetch(
                        `${USDI_API_URL}/usdi_holders_info_table_solana?address=${walletAddress}`
                    );
                    if (res.status === 404) {
                        setIsWalletLoading(false);
                        return;
                    }
                    if (!res.ok) {
                        throw new Error(`Error fetching user data: ${res.statusText}`);
                    }

                    const data = await res.json();
                    if (!data || !data[0]) {
                        setIsWalletLoading(false);
                        return;
                    }

                    let phoneVal = data[0].phone_number ?? "--";
                    setContactInfo({
                        email: data[0].email ?? "--",
                        twitter: data[0].twitter_id ?? "--",
                        phone: phoneVal,
                        telegram: data[0].telegram_id ?? "--",
                    });
                    setIsWalletLoading(false);


                } else {
                    // Scenario 2: email-based user
                    const res = await fetch(
                        `${ENDPOINT_BASE_URL}/email_users_table?email=${email}`
                    );
                    if (!res.ok) {
                        throw new Error(`Error fetching user by email: ${res.statusText}`);
                    }

                    const data = await res.json();
                    const user = data.user;
                    if (!user) {
                        setIsWalletLoading(false);

                        return;
                    }

                    setContactInfo({
                        email: user.email ?? "--",
                        twitter: user.twitter_id ?? "--",
                        phone: user.phone_number ?? "--",
                        telegram: user.telegram_id ?? "--",
                    });
                    setIsWalletLoading(false);

                }
            } catch (err) {
                console.error(err);
                showNotification(`Error fetching account info: ${err}`, "error");
                setIsWalletLoading(false);

            }
        };

        fetchContactInfo();
    }, [walletAddress, email, showNotification]);

    // ====== Remove Payment Method ======
    const handleRemoveClick = (payment_info_id) => {
        setPendingRemoveId(payment_info_id);
        setShowRemoveModal(true);
    };
    const handleConfirmRemove = () => {
        fetch("https://max1-funding-arb.uc.r.appspot.com/delete_payment_info", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({payment_info_id: pendingRemoveId}),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setRefetchPaymentMethods(prev => prev + 1);
                    showNotification('success', "Your payment information has been successfully removed.");
                } else {
                    console.error("Failed to delete:", data.error);
                    showNotification("error", data.error);
                }
            })
            .catch((error) => {
                console.error("Error deleting payment method:", error);
                showNotification("error", error);
            });
        setPendingRemoveId(null);
        setShowRemoveModal(false);
    };
    const handleCloseModal = () => {
        setPendingRemoveId(null);
        setShowRemoveModal(false);
    };

    // ====== Payment Method Add ======
    const handleAddPaymentMethod = () => {
        setShowAddPayment(true);
    };

    // ====== Toggle Edit Field ======
    const handleToggleEdit = (field) => {
        if (!isEditing[field]) {
            if (field === 'phone') {
                if (contactInfo.phone !== '--') {
                    setPhoneCountryCode('');
                    setPhoneLocalNumber('');
                } else {
                    setPhoneCountryCode('');
                    setPhoneLocalNumber('');
                }
            }
        }
        setIsEditing((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    // ====== Phone Input Handlers ======
    const handlePhoneCountryCodeChange = (e) => {
        const digits = e.target.value.replace(/\D/g, '');
        if (digits) {
            setPhoneCountryCode('+' + digits);
        } else {
            setPhoneCountryCode('');
        }
    };
    const handlePhoneLocalNumberChange = (e) => {
        const digits = e.target.value.replace(/\D/g, '');
        setPhoneLocalNumber(digits);
    };

    // ====== Save Field ======
    const handleSaveField = async (field) => {
        try {
            if (field === 'phone') {
                const codeNoSpaces = phoneCountryCode.replace(/\s+/g, '');
                const numNoSpaces = phoneLocalNumber.replace(/\s+/g, '');
                let combined = '';
                if (codeNoSpaces || numNoSpaces) {
                    combined = codeNoSpaces + (codeNoSpaces && numNoSpaces ? ' ' : '') + numNoSpaces;
                }
                if (!combined || combined === "t.me/" || combined === "x.com/") {
                    combined = '--';
                }
                if (combined !== '--') {
                    const isValid = check_input(field, combined);
                    if (!isValid) {
                        showNotification('error', `Invalid ${field} input`);
                        return;
                    }
                }
                setContactInfo((prev) => ({
                    ...prev,
                    phone: combined
                }));
                const updatePayload = {
                    phone_number: combined
                };
                const url = email === null
                    ? `${USDI_API_URL}/update?table=usdi_holders_info_table_solana&key=address&value=${walletAddress}`
                    : `${USDI_API_URL}/update?table=users&key=email&value=${email}`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(updatePayload),
                });
                if (!response.ok) {
                    const errData = await response.json();
                    throw new Error(errData.error || 'Failed to update phone.');
                }
                setIsEditing((prev) => ({...prev, [field]: false}));
                showNotification('success', 'Contact info updated!');
                return;
            }

            // For email, telegram, twitter
            let updatedValue = contactInfo[field].trim();
            if (!updatedValue || updatedValue === "t.me/" || updatedValue === "x.com/") {
                updatedValue = '--';
            }
            if (updatedValue !== contactInfo[field]) {
                setContactInfo((prev) => ({...prev, [field]: updatedValue}));
            }
            if (updatedValue !== '--') {
                const isValid = check_input(field, updatedValue);
                if (!isValid) {
                    showNotification('error', `Invalid ${field} input`);
                    return;
                }
            }
            const fieldMap = {
                email: 'email',
                twitter: 'twitter_id',
                telegram: 'telegram_id',
            };
            if (!fieldMap[field]) {
                setIsEditing((prev) => ({...prev, [field]: false}));
                return;
            }
            const updatePayload = {
                [fieldMap[field]]: updatedValue
            };
            const url = email === null
                ? `${USDI_API_URL}/update?table=usdi_holders_info_table_solana&key=address&value=${walletAddress}`
                : `${USDI_API_URL}/update?table=users&key=email&value=${email}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(updatePayload),
            });
            if (!response.ok) {
                const errData = await response.json();
                throw new Error(errData.error || 'Failed to update.');
            }
            setIsEditing((prev) => ({...prev, [field]: false}));
            showNotification('success', 'Contact info updated!');
        } catch (err) {
            console.error(err);
            showNotification('error', `Error updating ${field}: ${err.message}`);
        }
    };

    // Helper for text fields
    const handleFieldChange = (field, newValue) => {
        setContactInfo((prev) => ({
            ...prev,
            [field]: newValue.trim() === '' ? '--' : newValue,
        }));
    };

    const getButtonLabel = (field) => {
        const empty = contactInfo[field] === '--';
        const editing = !!isEditing[field];
        if (editing) {
            return 'Save';
        }
        return empty ? 'Add' : 'Edit';
    };

    return (
        <div className={styles.mainContainer}>
            <LeftPanel/>

            <div className={styles.rightPanel}>
                <ConnectWalletHeader
                    onWalletConnected={(publicKey) => setWalletAddress(publicKey)}
                    showPanel={showPanel}
                    setShowPanel={setShowPanel}
                    emailWallet={emailWalletAddress}
                    onClearAccountData={handleClearAccountData}
                />

                <div className={styles.accountTitle}>Account</div>

                <div className={styles.accountPage}>
                    {/* Top Card: Email & Wallet */}
                    <div className={styles.userInfoContainer}>
                        <div className={styles.userInfoContent}>
                            <img src={accountIcon} alt="AccountImg"/>
                            <div className={styles.userInfo}>
                                <div className={styles.userWallet} style={{fontSize: '20px'}}>
                                    {walletAddress && walletAddress !== "null" && walletAddress !== null && walletAddress !== "requested"
                                        ? shortenAddress(walletAddress)
                                        : '--'}
                                </div>
                                {/* For email display/editing */}
                                {isEditing.email ? (
                                    <input
                                        type="text"
                                        placeholder="Your Email"
                                        className={styles.modalInput}
                                        value={contactInfo.email === '--' ? '' : contactInfo.email}
                                        onChange={(e) => handleFieldChange('email', e.target.value)}
                                    />
                                ) : (
                                    /* Show blinking loader while isWalletLoading === true */
                                    email
                                        ? <div
                                            className={styles.greyText}>{contactInfo.email === '--' ? '--' : contactInfo.email}</div>
                                        : (
                                            isWalletLoading
                                                ? <span className={styles.blinkingPlaceholder}/>
                                                : <div
                                                    className={styles.greyText}>{contactInfo.email === '--' ? '--' : contactInfo.email}</div>
                                        )
                                )}
                            </div>
                        </div>
                        {/* For email user, we can't edit the email field */}
                        {email ? (
                            <div></div>
                        ) : (
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    isEditing.email
                                        ? handleSaveField('email')
                                        : handleToggleEdit('email')
                                }
                            >
                                {getButtonLabel('email')}
                            </button>
                        )}
                    </div>

                    <hr className={styles.divider}/>

                    {/* Contact Info */}
                    <div className={styles.contactInfoContainer}>
                        {isEditing.telegram ? (
                            <h2 style={{marginLeft: "14px", marginBottom: "29px"}}>Contact Info</h2>
                        ) : (
                            <h2 style={{marginLeft: "14px"}}>Contact Info</h2>
                        )}

                        {/* Telegram */}
                        <div className={styles.userInfoContainer} style={{marginBottom: '20px'}}>
                            <div className={styles.userInfoContent}>
                                <div className={styles.userInfo}>
                                    <div className={styles.userWallet}>Telegram ID</div>
                                    {isEditing.telegram ? (
                                        <input
                                            type="text"
                                            className={styles.modalInput}
                                            value={contactInfo.telegram === '--' ? '' : contactInfo.telegram}
                                            onChange={(e) => handleFieldChange('telegram', e.target.value)}
                                            onFocus={() => {
                                                if (contactInfo.telegram === '--') {
                                                    handleFieldChange('telegram', 't.me/');
                                                }
                                            }}
                                        />
                                    ) : (
                                        isWalletLoading
                                            ? <span className={styles.blinkingPlaceholder}/>
                                            : <div className={styles.greyText}>{contactInfo.telegram}</div>
                                    )}
                                </div>
                            </div>
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    isEditing.telegram
                                        ? handleSaveField('telegram')
                                        : handleToggleEdit('telegram')
                                }
                            >
                                {getButtonLabel('telegram')}
                            </button>
                        </div>

                        {/* Twitter */}
                        <div className={styles.userInfoContainer} style={{marginBottom: '20px'}}>
                            <div className={styles.userInfoContent}>
                                <div className={styles.userInfo}>
                                    <div className={styles.userWallet}>Twitter ID</div>
                                    {isEditing.twitter ? (
                                        <input
                                            type="text"
                                            className={styles.modalInput}
                                            value={contactInfo.twitter === '--' ? '' : contactInfo.twitter}
                                            onChange={(e) => handleFieldChange('twitter', e.target.value)}
                                            onFocus={() => {
                                                if (contactInfo.twitter === '--') {
                                                    handleFieldChange('twitter', 'x.com/');
                                                }
                                            }}
                                        />
                                    ) : (
                                        isWalletLoading
                                            ? <span className={styles.blinkingPlaceholder}/>
                                            : <div className={styles.greyText}>{contactInfo.twitter}</div>
                                    )}
                                </div>
                            </div>
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    isEditing.twitter
                                        ? handleSaveField('twitter')
                                        : handleToggleEdit('twitter')
                                }
                            >
                                {getButtonLabel('twitter')}
                            </button>
                        </div>

                        {/* Phone */}
                        <div className={styles.userInfoContainer}>
                            <div className={styles.userInfoContent}>
                                <div className={styles.userInfo}>
                                    <div className={styles.userWallet}>Phone Number</div>
                                    {isEditing.phone ? (
                                        <div style={{display: 'flex', gap: '7px'}}>
                                            <input
                                                type="text"
                                                placeholder="+1"
                                                className={styles.modalInput}
                                                style={{width: '55px', paddingLeft: '0px', textAlign: 'center'}}
                                                value={phoneCountryCode}
                                                onChange={handlePhoneCountryCodeChange}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Your mobile number"
                                                className={styles.modalInput}
                                                style={{width: '381px'}}
                                                value={phoneLocalNumber}
                                                onChange={handlePhoneLocalNumberChange}
                                            />
                                        </div>
                                    ) : (
                                        isWalletLoading
                                            ? <span className={styles.blinkingPlaceholder}/>
                                            : <div className={styles.greyText}>{contactInfo.phone}</div>
                                    )}
                                </div>
                            </div>
                            <button
                                className={styles.editButton}
                                onClick={() =>
                                    isEditing.phone
                                        ? handleSaveField('phone')
                                        : handleToggleEdit('phone')
                                }
                            >
                                {getButtonLabel('phone')}
                            </button>
                        </div>
                    </div>

                    {isEditing.phone ? (
                        <hr className={styles.divider2} style={{marginTop: '41px'}}/>
                    ) : (
                        <hr className={styles.divider2}/>
                    )}

                    {/* Payment Methods */}
                    <div className={styles.paymentMethodsContainer}>
                        <h2 style={{marginLeft: "14px"}}>Payment Methods</h2>
                        {paymentMethods.map((method) => {
                            const {displayInfo, icon} = computeMethodMeta(method);
                            return (
                                <div className={styles.userInfoContainer} style={{marginBottom: '23px'}}
                                     key={method.payment_info_id}>
                                    <>
                                        <div
                                            className={styles.userInfoContent}

                                        >
                                            <img src={icon} alt="AccountImg"/>
                                            <div className={styles.userInfo}>
                                                <div className={styles.userWallet}>{method.payment_method}</div>
                                                <div className={styles.greyText}>{displayInfo}</div>
                                            </div>
                                        </div>
                                        <button
                                            className={styles.removeButton}
                                            onClick={() => handleRemoveClick(method.payment_info_id)}
                                        >
                                            Remove
                                        </button>
                                    </>
                                </div>
                            );
                        })}
                        {(isWalletLoading || isPaymentInfoLoading) && (<>
                                <div className={styles.blinkingUserInfoContainer}/>
                                <div className={styles.blinkingUserInfoContainer}/>
                                <div className={styles.blinkingUserInfoContainer}/>

                            </>

                        )}
                        <button
                            className={styles.addPaymentButton}
                            style={{marginTop: '35px', marginBottom: '45px'}}
                            onClick={handleAddPaymentMethod}
                        >
                            Add a Payment Method
                        </button>

                        {paymentMethods.length === 0 && !(isWalletLoading || isPaymentInfoLoading) && (

                            <div className={styles.addPaymentInfo}>
                                Notes: Add a payment method to access the C2C network.
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Remove Payment Method Modal */}
            {showRemoveModal && (
                <>
                    <div className={styles.modalOverlay} onClick={handleCloseModal}/>
                    <div className={styles.modalContainer}>
                        <button className={styles.modalCloseBtn} onClick={handleCloseModal}>
                            &times;
                        </button>
                        <div className={styles.modalTitle}>Delete Account?</div>
                        <hr className={styles.modalDivider}/>
                        <div className={styles.modalMessage}>
                            Are you sure you want to remove this linked account?
                        </div>
                        <div className={styles.modalActions}>
                            <button className={styles.cancelBtn} onClick={handleCloseModal}>
                                Cancel
                            </button>
                            <button className={styles.confirmBtn} onClick={handleConfirmRemove}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </>
            )}

            {/* Add Payment Method */}
            {showAddPayment && (
                <AddPayment
                    onClose={() => setShowAddPayment(false)}
                    onRefetchPaymentMethods={triggerRefetchPaymentMethods}
                    availablePaymentMethods={availablePaymentMethods}
                />
            )}
        </div>
    );
}

export default Account;