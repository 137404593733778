// RedeemTab.jsx
import React, {useEffect, useState} from 'react';
import styles from '../SwapMain.module.css';

// 所有你在 Redeem 区域使用到的静态资源，确保保持同样的 import 路径:
import usdtImg from "../static_files/usdt 1.svg";
import usdcImg from "../static_files/usdc.svg";
import usdImg from "../static_files/usd.svg";
import verticalLineImg from "../static_files/verticalLine.svg";
import dropDownRightImg from "../static_files/dropdownright.svg";
import dropDownRightHoverImg from "../static_files/dropdownrightHover.svg";
import dropDownImg from "../static_files/dropdown.svg";
import dropDownHoverImg from "../static_files/dropdownHover.svg";

import copySelectorImg from "../static_files/copy-selector.svg";
import copySelectorHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/checkmark.svg";
import shareSelectorImg from "../static_files/share-selector.svg";
import shareSelectorHoverImg from "../static_files/share-selector-hover.svg";
import {formatNumberWithCommas} from "../utils";
import VenmoIcon from '../static_files/Venmo.svg';
import ZelleIcon from '../static_files/Zelle.svg';
import AlipayIcon from '../static_files/Alipay.svg';
import WireIcon from '../static_files/Wire.svg';
import USDIcon from '../static_files/usd.svg';
import AddPayment from "../Account/AddPayment";
import {ENDPOINT_BASE_URL} from "../../configs/config";

/**
 * 这是专门提取出来的“RedeemTab”组件，
 * 完全保留你在 SwapMainAPP 里 {selectedTab === 'redeem' && (...)} 部分的代码。
 * 父组件必须通过 props 将用到的所有数据、函数、状态、ref 等传进来。
 */
const RedeemTab = (props) => {
    const {
        typedMintValue,
        dynamicFontSize,
        containerRef,
        spanRef,
        inputRef,
        handleInputChange,
        handleSetMax,
        usdiBalance,
        usdcBalance,
        usdtBalance,
        selectedToken,
        shortenAddress,
        walletAddress,
        networkFeePercent,
        showTokenSelector,
        methodItemRef,
        handleMethodItemClick,
        tokenSelectorRef,
        handleSelectToken,
        handleCopy,
        justCopiedUSDC,
        justCopiedUSDT,
        handleShare,
        // 第二个下拉：
        showTokenSelector3,
        methodItemRef3,
        handleMethodItemClick3,
        tokenSelectorRef3,
        handleSelectPaymentMethod,
        iconMap,
        getPaymentInfoDisplay,
        walletAddressExists,
        handleRedeemUSDi,
        handleConnectWallet,
        usdcTokenAddress,
        usdtTokenAddress,
        setSelectedPaymentMethod,
        selectedPaymentMethod,
        refetchPaymentMethods,
        setRefetchPaymentMethods,
        paymentInfoData

    } = props;

    // const [paymentInfoData, setPaymentInfoData] = useState([]);
    const [availablePaymentMethods, setAvailablePaymentMethods] = useState([]);
    const [showAddPayment, setShowAddPayment] = useState(false);
    // const [refetchPaymentMethods, setRefetchPaymentMethods] = useState(0);

    // 如果你是邮箱登录，可以从 localStorage 或全局 context 里拿到 email
    const userEmail = localStorage.getItem("userEmail");
    // const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const methodIcon = selectedPaymentMethod
        ? iconMap[selectedPaymentMethod.payment_method] || WireIcon
        : WireIcon;

    const handleAddPaymentMethod = () => {
        setShowAddPayment(true);
    };
    // 1) 在 RedeemTab 内部自己 fetchPaymentInfo
    // useEffect(() => {
    //     const fetchPaymentInfo = async () => {
    //         if (!walletAddress) {
    //             setPaymentInfoData([]);
    //             return;
    //         }
    //         try {
    //             let paramKey, paraValue;
    //             let extraParam = ""; // Will hold the extra parameter when using wallet login
    //
    //             if (userEmail) {
    //                 // 邮箱登录
    //                 paramKey = "email_address";
    //                 paraValue = userEmail;
    //             } else {
    //                 // 钱包登录
    //                 paramKey = "wallet_address";
    //                 paraValue = walletAddress;
    //                 // Add the extra query parameter if it's a wallet login
    //                 extraParam = "&email_user_wallet_chain=solana_receive_address";
    //             }
    //
    //             const url = `${ENDPOINT_BASE_URL}/get_payment_info?${paramKey}=${paraValue}${extraParam}`;
    //             const res = await fetch(url);
    //
    //             if (!res.ok) {
    //                 console.error(`Error fetching payment info: ${res.status} ${res.statusText}`);
    //                 setPaymentInfoData([]);
    //                 return;
    //             }
    //
    //             const jsonData = await res.json();
    //             if (!jsonData.success) {
    //                 console.error("API returned success=false:", jsonData);
    //                 setPaymentInfoData([]);
    //                 return;
    //             }
    //
    //             const arr = jsonData.data || [];
    //             setPaymentInfoData(arr);
    //
    //             // 若有 default_payment === true
    //             const defaultRow = arr.find(item => item.default_payment === true);
    //             if (defaultRow) {
    //                 setSelectedPaymentMethod(defaultRow);
    //             }
    //         } catch (err) {
    //             console.error("Error fetching payment info:", err);
    //             setPaymentInfoData([]);
    //         }
    //     };
    //
    //     fetchPaymentInfo();
    // }, [walletAddress, refetchPaymentMethods]);
    // 2) 同样在 RedeemTab 内部 fetch“可用支付方式”列表
    useEffect(() => {
        async function fetchAvailablePaymentMethods() {
            try {
                const res = await fetch(
                    'https://max1-funding-arb.uc.r.appspot.com/available_payment_method'
                );
                let data = await res.json();
                let formatted = data.map((item) => ({
                    ...item,
                    icon: iconMap[item.method_name] || USDIcon,
                }));

                const desiredOrder = [
                    'Bank Wire',
                    'Cash - Paper',
                    'Zelle',
                    'Venmo',
                    'Alipay'
                ];
                formatted.sort((a, b) => {
                    return (
                        desiredOrder.indexOf(a.method_name) - desiredOrder.indexOf(b.method_name)
                    );
                });

                setAvailablePaymentMethods(formatted);
            } catch (error) {
                console.error('Error fetching payment methods:', error);
            }
        }

        fetchAvailablePaymentMethods();
    }, []);

    const triggerRefetchPaymentMethods = () => {
        setRefetchPaymentMethods(prev => prev + 1);
    };

    return (
        <div className={styles.withdrawSection}>
            <div
                className={`${styles.withdrawSectionMiddle} `}
                style={{height: selectedToken === 'CASH' ? '430px' : '343px'}}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        background: '#1c1c1c',
                        position: 'relative'
                    }}
                >
                    <div
                        ref={containerRef}
                        className={`${styles.customInputWrapper} ${styles.customInputWrapper2}`}
                        style={{
                            position: 'relative',
                            height: '150px'
                        }}
                        onClick={() => inputRef.current?.focus()}
                    >
                        <input
                            ref={inputRef}
                            type="text"
                            className={styles.withDrawBig}
                            value={formatNumberWithCommas(typedMintValue)}
                            onChange={handleInputChange}
                            style={{
                                fontSize: dynamicFontSize,
                                border: 'none',
                                background: 'transparent',
                                caretColor: '#6CE3C4',
                            }}
                        />
                        <span
                            ref={spanRef}
                            style={{
                                position: 'absolute',
                                visibility: 'hidden',
                                whiteSpace: 'pre',
                                padding: 0,
                                margin: 0
                            }}
                            onClick={() => inputRef.current?.focus()}
                        />
                        <span
                            className={styles.fakePlaceholderFont}
                            style={{
                                width: '12px',
                                background: 'transparent',
                                color: 'gray',
                                fontSize: dynamicFontSize
                            }}
                            onClick={() => inputRef.current?.focus()}
                        >
                            {' '}USDi
                        </span>

                        <button
                            className={styles.maxButton}
                            onClick={handleSetMax}
                        >
                            Max
                        </button>
                    </div>
                    <button
                        className={styles.maxButton}
                        onClick={handleSetMax}
                    >
                        Max
                    </button>
                </div>

                <div className={styles.withDrawAmount}>
                    {usdiBalance && usdiBalance !== "--"
                        ? `${usdiBalance.toLocaleString(undefined, {
                            maximumFractionDigits: 6
                        })} USDi Available`
                        : '0.00 USDi Available'
                    }
                </div>

                {/* redeem tab first token selector */}
                <div
                    className={styles.methodItem}
                    onClick={handleMethodItemClick}
                    ref={methodItemRef}
                    style={{
                        marginTop: '45px'
                    }}
                >
                    <div className={styles.withDrawItemLeft}>
                        {selectedToken === "USDT" ? (
                            <img
                                className={styles.withdrawImg}
                                src={usdtImg}
                                alt="usdtImg"
                            />
                        ) : selectedToken === "USDC" ? (
                            <img
                                className={styles.withdrawImg}
                                src={usdcImg}
                                alt="usdcImg"
                            />
                        ) : selectedToken === "CASH" ? (
                            <img
                                className={styles.withdrawImg}
                                src={usdImg}
                                alt="cashImg"
                            />
                        ) : null}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                background: "#1c1c1c"
                            }}
                        >
                            <h4>Redeem to</h4>
                            {selectedToken === "CASH" ? (
                                <h5>Cash</h5>
                            ) : (
                                <h5>{shortenAddress(walletAddress)}</h5>
                            )}
                        </div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div className={styles.withDrawItemRight}>
                            <h5>
                                {selectedToken === "CASH"
                                    ? '1-2 days'
                                    : (parseFloat(typedMintValue) || 0) >= 11000
                                        ? '1 day'
                                        : 'Instant'
                                }
                            </h5>
                            <h5>
                                {selectedToken === "CASH" ? (
                                    <h5>
                                        <span className={styles.payWithValue}>
                                            {
                                                (
                                                    Math.floor(parseFloat(typedMintValue) * (100 - networkFeePercent)) / 100
                                                ).toLocaleString('en-US', {
                                                    maximumFractionDigits: 2
                                                })
                                            }
                                        </span>
                                        {' '} USD
                                    </h5>
                                ) : (
                                    <h5>
                                        <span className={styles.payWithValue}>
                                            {parseFloat(typedMintValue).toLocaleString('en-US', {maximumFractionDigits: 6})}
                                        </span>
                                        {' '}{selectedToken}
                                    </h5>
                                )}
                            </h5>
                        </div>
                        {showTokenSelector ? (
                            <img
                                src={dropDownImg}
                                className={styles.arrowDown}
                                alt="arrow"
                                onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                            />
                        ) : (
                            <img
                                src={dropDownRightImg}
                                className={styles.arrowDown}
                                alt="arrow"
                                onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                            />
                        )}
                    </div>
                </div>

                {selectedToken === "CASH" && (
                    <img
                        className={styles.verticalLine}
                        src={verticalLineImg}
                        alt="verticalLine"
                    />
                )}

                {/* second token selector */}
                {selectedToken === "CASH" && (
                    <div
                        className={styles.methodItem}
                        onClick={handleMethodItemClick3}
                        ref={methodItemRef3}
                        style={{marginTop: '0px'}}
                    >
                        <div className={styles.withDrawItemLeft}>
                            <img
                                className={styles.withdrawImg}
                                src={methodIcon}
                                alt="paymentMethodIcon"
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around",
                                    background: "#1c1c1c"
                                }}
                            >
                                <h4>Transfer to</h4>
                                {selectedPaymentMethod ? (
                                    <h5>{selectedPaymentMethod.payment_method || '--'}</h5>
                                ) : (
                                    <h5>Select Payment Method</h5>
                                )}
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <div className={styles.withDrawItemRight}>
                                <h5 style={{color: "white"}}>
                                    {selectedPaymentMethod?.processing_time || ''}
                                </h5>
                                <h5 style={{color: "#54A792"}}>
                                    {selectedPaymentMethod?.fees || 'Fees apply'}
                                </h5>
                            </div>
                            {showTokenSelector3 ? (
                                <img
                                    src={dropDownImg}
                                    className={styles.arrowDown}
                                    alt="arrow"
                                    onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                    onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                />
                            ) : (
                                <img
                                    src={dropDownRightImg}
                                    className={styles.arrowDown}
                                    alt="arrow"
                                    onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                    onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>

            {/* Token Selector Modal (Redeem) */}
            {showTokenSelector && (
                <div
                    ref={tokenSelectorRef}
                    className={`${styles.tokenSelectorModal} ${styles.tokenSelectorModal2}`}
                >
                    <div className={styles.tokenSelectorTitleContainer}>
                        <div className={styles.tokenSelectorTitle}>Token</div>
                        <div className={styles.tokenSelectorTitle}>Balance/Address</div>
                    </div>
                    <div className={styles.tokenSelectorContentContainer}>
                        {/* Cash Row */}
                        <div
                            className={
                                selectedToken === 'CASH'
                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                    : styles.tokenSelectorContent
                            }
                            onClick={() => handleSelectToken("CASH")}
                        >
                            <div className={styles.tokenSelectorContentLeft}>
                                <img
                                    className={styles.selectorImg}
                                    src={usdImg}
                                    alt="wireTransferImg"
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <h4 style={{marginBottom: '3px'}}>CASH</h4>
                                    <h5 style={{marginBottom: '0'}}>USD Cash</h5>
                                </div>
                            </div>
                            <div className={styles.tokenSelectorWalletContainer}>
                                <div style={{textAlign: "right"}}>--</div>
                                <div>
                                    <span className={styles.tokenSelectorContentRightGrey}>C2C Network</span>
                                </div>
                            </div>
                        </div>

                        {/* USDC Row */}
                        <div
                            className={
                                selectedToken === 'USDC'
                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                    : styles.tokenSelectorContent
                            }
                            onClick={() => handleSelectToken("USDC")}
                        >
                            <div className={styles.tokenSelectorContentLeft}>
                                <img
                                    className={styles.selectorImg}
                                    src={usdcImg}
                                    alt="wireTransferImg"
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <h4 style={{marginBottom: '3px'}}>USDC</h4>
                                    <h5 style={{marginBottom: '0'}}>USD Coin</h5>
                                </div>
                            </div>
                            <div className={styles.tokenSelectorWalletContainer}>
                                {usdcBalance ? (
                                    <div
                                        className={styles.tokenSelectorContentRight}
                                        style={
                                            selectedToken === 'USDC'
                                                ? {background: '#2b3336'}
                                                : {}
                                        }
                                    >
                                        {usdcBalance.toLocaleString('en-US', {
                                            maximumFractionDigits: 6
                                        })}
                                    </div>) : (
                                    <div
                                        style={{
                                            textAlign: 'right',
                                            background: selectedToken === 'USDC'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                    >
                                        --
                                    </div>
                                )}
                                <div>
                                    <span
                                        className={styles.tokenSelectorContentRightGrey}
                                        style={{
                                            background: selectedToken === 'USDC'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                        onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                    >
                                        {shortenAddress(usdcTokenAddress)}
                                    </span>
                                    <span>
                                        {justCopiedUSDC ? (
                                            <img
                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                src={copyConfirmImg}
                                                alt="Copied!"
                                                style={{
                                                    background: selectedToken === 'USDC'
                                                        ? '#2b3336'
                                                        : 'transparent',
                                                    width: '11px',
                                                    height: '11px'
                                                }}
                                            />
                                        ) : (
                                            <img
                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                src={copySelectorImg}
                                                alt="copy img"
                                                style={{
                                                    background: selectedToken === 'USDC'
                                                        ? '#2b3336'
                                                        : 'transparent'
                                                }}
                                                onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.src = copySelectorHoverImg)
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.src = copySelectorImg)
                                                }
                                            />
                                        )}
                                    </span>
                                    <span>
                                       <img
                                           className={styles.tokenSelectorContentRightGreyIMG}
                                           src={shareSelectorImg}
                                           alt="share img"
                                           style={{
                                               background: selectedToken === 'USDC'
                                                   ? '#2b3336'
                                                   : 'transparent'
                                           }}
                                           onClick={(e) => handleShare(e, usdcTokenAddress)}
                                           onMouseEnter={(e) =>
                                               (e.currentTarget.src = shareSelectorHoverImg)
                                           }
                                           onMouseLeave={(e) =>
                                               (e.currentTarget.src = shareSelectorImg)
                                           }
                                       />

                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* USDT Row */}
                        <div
                            className={
                                selectedToken === 'USDT'
                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                    : styles.tokenSelectorContent
                            }
                            onClick={() => handleSelectToken("USDT")}
                        >
                            <div className={styles.tokenSelectorContentLeft}>
                                <img
                                    className={styles.selectorImg}
                                    src={usdtImg}
                                    alt="wireTransferImg"
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <h4 style={{marginBottom: '0px'}}>USDT</h4>
                                    <h5 style={{marginBottom: '0'}}>USDT</h5>
                                </div>
                            </div>
                            <div className={styles.tokenSelectorWalletContainer}>
                                {usdtBalance ? (
                                    <div
                                        className={styles.tokenSelectorContentRight}
                                        style={{
                                            background: selectedToken === 'USDT'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                    >
                                        {usdtBalance.toLocaleString('en-US', {
                                            maximumFractionDigits: 6
                                        })}
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            textAlign: 'right',
                                            background: selectedToken === 'USDT'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                    >
                                        --
                                    </div>
                                )}
                                <div>
                                     <span
                                         className={styles.tokenSelectorContentRightGrey}
                                         style={{
                                             background: selectedToken === 'USDT'
                                                 ? '#2b3336'
                                                 : 'transparent'
                                         }}
                                         onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                     >
                                        {shortenAddress(usdtTokenAddress)}
                                    </span>
                                    <span
                                        style={{
                                            background: selectedToken === 'USDT'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                    >
                                                            {justCopiedUSDT ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    alt="copy img"
                                                                    onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                    <span
                                        style={{
                                            background: selectedToken === 'USDT'
                                                ? '#2b3336'
                                                : 'transparent'
                                        }}
                                    >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, usdtTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Token Selector Modal 2 (Redeem) */}
            {showTokenSelector3 && (
                <div
                    ref={tokenSelectorRef3}
                    className={`${styles.tokenSelectorModal} ${styles.tokenSelectorModal3}`}
                >
                    <div className={styles.tokenSelectorTitleContainer}>
                        <div className={styles.tokenSelectorTitle}>Method</div>
                        <div className={styles.tokenSelectorTitle}>ETA/Fees</div>
                    </div>
                    <div className={styles.tokenSelectorContentContainer}>
                        {paymentInfoData.length === 0 ? (
                            <div style={{height: "7px"}}>
                            </div>
                        ) : (
                            paymentInfoData.map((item) => {
                                const isSelected = selectedPaymentMethod
                                    && selectedPaymentMethod.payment_info_id === item.payment_info_id;
                                const paymentMethodIcon = iconMap[item.payment_method] || WireIcon;
                                const displayValue = getPaymentInfoDisplay(item);

                                return (
                                    <div
                                        key={item.payment_info_id}
                                        className={
                                            isSelected
                                                ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                : styles.tokenSelectorContent
                                        }
                                        style={isSelected ? {background: '#2b3336'} : {}}
                                        onClick={() => {
                                            setSelectedPaymentMethod(item);
                                            handleSelectPaymentMethod(item);
                                        }}
                                    >
                                        <div className={styles.tokenSelectorContentLeft}>
                                            <img
                                                className={styles.selectorImg}
                                                src={paymentMethodIcon}
                                                alt="PaymentMethodIcon"
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <h4 style={{marginBottom: '3px'}}>
                                                    {item.payment_method}
                                                </h4>
                                                <h5 style={{marginBottom: '0'}}>
                                                    {displayValue}
                                                </h5>
                                            </div>
                                        </div>

                                        <div className={styles.tokenSelectorWalletContainer}>
                                            <div
                                                style={{textAlign: "right"}}>    {item?.processing_time || '1-3 biz days'}
                                            </div>
                                            <div style={{textAlign: "right"}}>
                                                <span
                                                    className={styles.tokenSelectorContentRightGreen}
                                                >
                                                        {item?.fees || 'Fees apply'}

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                        <div className={styles.addPaymentButton} onClick={handleAddPaymentMethod}>
                            Add a payment method
                        </div>
                    </div>
                </div>
            )}

            {walletAddressExists ? (
                <button onClick={handleRedeemUSDi}>
                    Redeem USDi
                </button>
            ) : (
                <button onClick={handleConnectWallet}>Connect Wallet</button>
            )}

            {showAddPayment && (
                <AddPayment
                    onClose={() => setShowAddPayment(false)}
                    onRefetchPaymentMethods={triggerRefetchPaymentMethods}
                    availablePaymentMethods={availablePaymentMethods}
                />
            )}
        </div>
    );
};

export default RedeemTab;