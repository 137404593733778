import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import {AuthProvider, useAuth} from './AuthContext';
import {SwapMainAppProvider} from "./SwapMainAppContext";
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import GatewayTokenVerifier from './GatewayTokenVerifier';
import TransactionHistory from "./tables/TransactionHistory/TransactionHistory";
import C2CNetwork from "./tables/C2C NetWork/C2CNetwork";
import EmailSwapMainAPP from "./tables/EmailSwapMainAPP/EmailSwapMainAPP";
import Account from "./tables/Account/Account";
import GatewayTokenVerifier2 from "./GatewayTokenVerifier2";

/** 保护路由：只有管理员登录后才能访问 */
const AdminProtectedRoute = ({children}) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/" />;
};


/**
 * 当访问 "/" 时，检查 localStorage 是否存在 gatewayToken。
 * - 如果存在，则重定向到 /swap?token=<gatewayToken>
 * - 如果不存在，则渲染 SwapMainAPP
 */


const App = () => {
  return (
    <AuthProvider>
      <SwapMainAppProvider>
        <Router>
          <Routes>
            {/*
              使用 GatewayTokenVerifier2 替换原来 <SwapMainAPP /> 的写法。
            */}
            <Route path="/" element={<GatewayTokenVerifier2 />} />

            <Route path="/transaction" element={<TransactionHistory />} />
            <Route path="/c2c" element={<C2CNetwork />} />
            <Route path="/account" element={<Account />} />
            <Route path="/admin" element={<LoginPage />} />

            {/* Gateway route: verify token before showing Swap */}
            <Route
              path="/swap"
              element={
                <GatewayTokenVerifier>
                    <EmailSwapMainAPP />
                 </GatewayTokenVerifier>
              }
            />

            <Route
              path="/home/*"
              element={
                <AdminProtectedRoute>
                  <HomePage />
                </AdminProtectedRoute>
              }
            />

            {/* 捕获所有未匹配的路由并重定向到 / */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </SwapMainAppProvider>
    </AuthProvider>
  );
};

export default App;