import React from 'react';
import {Route, Routes} from 'react-router-dom';
import TrackRecords from './tables/TrackRecords';
import Portfolio from './tables/Portfolio';
import Monitor from './tables/Monitor';
import './App.css';
import USDIStats from './tables/USDIStats';
import {TokenManagement} from './tables/TokenManagement';
import Swap from "./tables/SwapMainApp";
import EmailUIDTxsRequests from "./tables/EmailUIDTxsRequests";
import UserTxs from "./tables/UserTxs";

const HomePage = () => {

    return (
        <div>
            <div className="content">
                <Routes>
                    <Route path="token-management" element={<TokenManagement/>}/>
                    <Route path="track-records" element={<TrackRecords/>}/>
                    <Route path="portfolio" element={<Portfolio/>}/>
                    <Route path="monitor" element={<Monitor/>}/>
                    <Route path="user-txs" element={<UserTxs/>}/>

                    <Route path="swap" element={<Swap/>}/>
                    <Route path="usdi-stats" element={<USDIStats/>}/>
                    <Route path="email-uid-txs-requests" element={<EmailUIDTxsRequests/>}/>

                </Routes>
            </div>
        </div>
    );
};

export default HomePage;
