export const jsonToCSV = (json) => {
    const csvRows = [];
    const headers = Object.keys(json[0]);

    csvRows.push(headers.join(','));

    for (const row of json) {
        const values = headers.map(header => {
            const value = row[header];
            const escapedValue = ('' + value).replace(/"/g, '\\"');
            return `"${escapedValue}"`;
        });
        csvRows.push(values.join(','));
    }

    return csvRows.join('\n');
};

export const digitFormat = (number) => {
    return Number(number).toLocaleString('en-US')
}

export const moneyFormat = (num) => {
    const factor = 10 ** 2; // 2 decimals
    const floored = Math.floor(Number(num) * factor) / factor;

    return floored.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const moneyFormat4decimals = (num) => {
    const factor = 10 ** 4; // 4 decimals
    const floored = Math.floor(Number(num) * factor) / factor;

    return floored.toLocaleString('en-US', {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });
};

export function shortenAddress(address, startLength = 4, endLength = 4) {
    if (!address) return '';
    if (address==="requested") return"requested"
    if (address.length <= startLength + endLength) {
        return address;
    }
    const start = address.slice(0, startLength);
    const end = address.slice(-endLength);
    return `${start}..${end}`;
}

// A basic validator for each contact field.
export function check_input(fieldName, value) {
    // If the value is just '--', treat it as "no info," which is allowed:
    console.log("value", value)
    if (value === '--') {
        return true;
    }
    console.log("value==", value==="t.me/")
    switch (fieldName) {
        case 'email':
            // Basic email format check
            return /\S+@\S+\.\S+/.test(value);

        case 'twitter': {
            if (!value) return false;
            // If user typed "x.com/username", remove "x.com/" before validation
            let handle = value.startsWith('x.com/')
                ? value.slice('x.com/'.length)
                : value;
            // If they typed "@username", remove "@" as well
            handle = handle.startsWith('@') ? handle.slice(1) : handle;
            // Validate alphanumeric and underscores
            return /^[A-Za-z0-9_]+$/.test(handle);
        }

        case 'phone':
            // Allow digits, spaces, dashes, plus, parentheses
            return /^[\d\s\-\+()]+$/.test(value);

        case 'telegram': {
            if (!value) return false;
            // If user typed "t.me/username", remove "t.me/" before validation
            let handle = value.startsWith('t.me/')
                ? value.slice('t.me/'.length)
                : value;
            // If they typed "@username", remove "@" as well
            handle = handle.startsWith('@') ? handle.slice(1) : handle;
            // Validate alphanumeric and underscores
            return /^[A-Za-z0-9_]+$/.test(handle);
        }

        default:
            // For any other field, allow all (or implement specific rules if needed)
            return true;
    }
}

export async function fetchC2CNetworkConfig() {
    try {
        const response = await fetch(
            'https://usdi-api-256611876551.europe-west1.run.app/c2c_redeem_config_solana'
        );
        if (!response.ok) {
            throw new Error('Failed to fetch c2c_redeem_config_solana');
        }
        const data = await response.json();
        if (data) {
            return data[0];
        }

    } catch (error) {
        console.error('Error fetching network fee:', error);
        return null;
    }
}

export async function createWallet(currentUserData,selectedChain) {
    try {
        const response = await fetch(
            'https://usdi-api-256611876551.europe-west1.run.app/email_user_create_wallet',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: currentUserData.email,
                    selectedChain:selectedChain
                })
            }
        );
        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }
        const data = await response.json();
        console.log('Wallet creation response:', data);

    } catch (error) {
        console.error('Error creating wallet:', error);
    }
}

export function formatAmount(value) {
    const num = parseFloat(value);
    if (isNaN(num)) return value;
    return num.toString();
}


// used for typed value in the mint and redeem box
export function formatNumberWithCommas(rawValue) {
  if (!rawValue) return '';

  // 1) Keep only digits + dot
  let value = rawValue.replace(/[^\d.]/g, '');

  // 2) If multiple dots, keep only the first
  const firstDotIndex = value.indexOf('.');
  if (firstDotIndex !== -1) {
    // remove *extra* dots after the first
    const rest = value.slice(firstDotIndex + 1).replace(/\./g, '');
    value = value.slice(0, firstDotIndex + 1) + rest;
  }

  // 3) Split into [integerPart, decimalPart?]
  let [intPart, decPart] = value.split('.');

  // If user typed just ".", intPart would be "" => treat as "0"
  if (!intPart) intPart = '0';

  // 4) Parse the integer part to remove leading zeros, then format with commas
  const intNum = parseInt(intPart, 10) || 0; // parseInt('') => NaN => fallback 0
  const formattedInt = intNum.toLocaleString('en-US'); // e.g. 1000 => "1,000"

  // 5) Reattach the decimal part exactly as typed (if it existed)
  //    If user typed a dot with nothing after it, we keep "0." (helpful for partial decimals)
  if (typeof decPart !== 'undefined') {
    return formattedInt + '.' + decPart;
  } else if (value.endsWith('.')) {
    // If user typed a trailing dot (like "1000."), keep it
    return formattedInt + '.';
  }

  return formattedInt;
}


export const handleShare = (e, address) => {
    e.stopPropagation();
    window.open(`https://solscan.io/token/${address}`, "_blank");
};

export function maskAccountNumber(accountNumber) {
    if (!accountNumber) return '';
    const lastFour = accountNumber.slice(-4);
    return `***${lastFour}`;
}

// Map each chain to its display name
export const chainNameMap = {
  solana: "Solana",
  ethereum: "Ethereum",
  tron: "Tron",
  binance: "BNB"
  // you can add more mappings if needed
};

