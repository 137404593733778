// GatewayTokenVerifier.js
import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import loadingImg from '../src/tables/static_files/Loading1.svg'
import './App.css'

const GatewayTokenVerifier = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {verifyGatewayToken} = useAuth();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        let token = queryParams.get('token');

        // 如果 URL 没有 token，则尝试从 localStorage 拿一下
        if (!token) {
            token = localStorage.getItem('gatewayToken');
        }

        if (!token) {
            navigate('/');
            return;
        }


        (async () => {
            const isValid = await verifyGatewayToken(token);
            if (!isValid) {
                localStorage.removeItem("userEmail")
                localStorage.removeItem("emailUserWalletAddress")
                localStorage.removeItem("gatewayToken")
                localStorage.setItem("emailLogin", 'false')
                localStorage.removeItem("emailUserSelectedChain")
                localStorage.removeItem("isWhitelist")

                navigate('/');
            } else {
                setLoading(false);
            }
        })();
    }, [location, navigate]);
    // used to be  [location, navigate, verifyGatewayToken])
    const email = localStorage.getItem("userEmail")

    if (loading && !email) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <img className="LoadingImage" src={loadingImg} alt="Loading"/>
            </div>
        );
    }

    return children;
};

export default GatewayTokenVerifier;
