import React, {useContext, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styles from '../Account/AddPaymentInputInfo.module.css';
import VenmoIcon from '../static_files/Venmo.svg';
import ZelleIcon from '../static_files/Zelle.svg';
import AlipayIcon from '../static_files/Alipay.svg';
import WireIcon from '../static_files/Wire.svg';
import USDIcon from '../static_files/usd.svg';

import copyImg from "../static_files/copy-selector.svg";
import copyHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/checkmark.png";
import {ENDPOINT_BASE_URL} from "../../configs/config";
import {SwapMainAppContext} from "../../SwapMainAppContext";

function SettleFunds({
                         onClose,
                         paymentInfo,       // 父组件传进来的对象, 例如见你贴的各个属性
                         onConfirmTransfer, // 点击“Confirm Transfer Complete”后的回调，可选
                         selectedTx
                     }) {
    const {
        payment_method,
        recipient_name,
        recipient_address,
        bank_country,
        bank_name,
        bank_address,
        account_number,
        swift_bic_code,
        us_routing_no,
        email,
        mobile_number,
        delivery_address,
    } = paymentInfo || {};
    const {showNotification} = useContext(SwapMainAppContext);

    // Map method_name to the correct icon
    const paymentMethodIconMap = {
        'Bank Wire': WireIcon,
        'Cash - Paper': USDIcon,
        'Zelle': ZelleIcon,
        'Venmo': VenmoIcon,
        'Alipay': AlipayIcon,
    };


    useEffect(() => {
        console.log("here", selectedTx)
    }, [selectedTx]);
    // 根据 payment_method 来渲染对应的字段（只读 + 复制按钮）
    const renderFields = () => {
        switch (payment_method) {
            case 'Bank Wire':
                return (
                    <>
                        <FieldRow label="Recipient name" value={recipient_name}/>
                        <FieldRow label="Recipient address" value={recipient_address}/>
                        <FieldRow label="Bank country" value={bank_country}/>
                        <FieldRow label="Bank name" value={bank_name}/>
                        <FieldRow label="Bank address" value={bank_address}/>
                        <FieldRow label="Account number" value={account_number}/>
                        <FieldRow label="SWIFT BIC code" value={swift_bic_code}/>
                        <FieldRow label="Routing number" value={us_routing_no}/>
                    </>
                );
            case 'Cash - Paper':
                return (
                    <>
                        <FieldRow label="Mobile number" value={mobile_number}/>
                        <FieldRow label="Delivery address" value={delivery_address}/>
                    </>
                );
            case 'Venmo':
                return (
                    <>
                        <FieldRow label="Recipient name" value={recipient_name}/>
                        <FieldRow label="Venmo account" value={account_number}/>
                    </>
                );
            case 'Zelle':
                return (
                    <>
                        <FieldRow label="Recipient name" value={recipient_name}/>
                        <FieldRow label="Email" value={email}/>
                        <FieldRow label="Mobile number" value={mobile_number}/>
                    </>
                );
            case 'Alipay':
                return (
                    <>
                        <FieldRow label="Recipient name(中文姓名)" value={recipient_name}/>
                        <FieldRow label="Mobile number" value={mobile_number}/>
                        <FieldRow label="Email (Optional)" value={email}/>
                    </>
                );
            default:
                return <div>Unknown payment method.</div>;
        }
    };

    // 封装一个只读行组件，包含“复制”功能
    const FieldRow = ({label, value}) => {
        return (
            <div style={{marginBottom: '15px'}}>
                <div style={{marginBottom: '0'}}>{label}</div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                    <span style={{color: "#9B9B9B"}}>{value || '—'}</span>
                    {value && (
                        <img
                            style={{
                                width: "15px",
                                height: "15px",
                                cursor: "pointer"
                            }}
                            src={copyImg}
                            alt="copy img"
                            onMouseEnter={(e) => {
                                e.currentTarget.src = copyHoverImg;
                            }}
                            onMouseLeave={(e) => {
                                if (!e.currentTarget.getAttribute("data-copied")) {
                                    e.currentTarget.src = copyImg;
                                }
                            }}
                            onClick={(e) => {
                                const imgElement = e.currentTarget;
                                imgElement.setAttribute("data-copied", "true");
                                navigator.clipboard.writeText(value)
                                    .then(() => {
                                        imgElement.src = copyConfirmImg;
                                        imgElement.style.width = "15px";
                                        imgElement.style.height = "15px";
                                        setTimeout(() => {
                                            imgElement.src = copyImg;
                                            imgElement.removeAttribute("data-copied");
                                        }, 3000);
                                    })
                                    .catch(err => console.error("Copy failed:", err));
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };
    const handleConfirmTransfer = () => {
        fetch(`${ENDPOINT_BASE_URL}/c2c/funds-sent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: selectedTx?.id,
                address: selectedTx?.address,
                payment_info_id:selectedTx?.payment_info_id
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    console.log("Successfully sent C2C transaction notification.");
                    showNotification('success', 'User notified of C2C transaction successfully!');
                } else {
                    console.error("Failed to send C2C transaction notification:", data.msg);
                    showNotification('error', 'An error occurred while notifying the user of the C2C transaction.');
                }
            })
            .catch(err => {
                console.error("Error in fetch:", err);
                showNotification('error', 'An error occurred while notifying the user of the C2C transaction.');
            });

        // 2) Close the modal
        onClose();
    };
const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return ReactDOM.createPortal(
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.modalContainer}>
                {/* Header */}
                <div className={styles.headerRow}>
                    <h2 className={styles.title}>Settle Funds</h2>
                    <div className={styles.closeBtn} onClick={onClose}>
                        &times;
                    </div>
                </div>

                <div style={{marginBottom: '20px'}} className={styles.dividerLine}/>

                {/* 固定的文字描述 */}
                <p style={{margin: '0'}}>
                    To settle funds, please follow the user’s selected payment method and details below.
                </p>

                <div style={{marginBlock: '20px'}} className={styles.dividerLine}/>

                {/* Payment method + icon */}
                <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: '19px'}}>
                    Payment method:
                    <img
                        src={paymentMethodIconMap[payment_method] || '/icons/default.png'}
                        alt={payment_method}
                        style={{width: '20px', height: '20px'}}
                    />
                    <div>{payment_method || 'N/A'}</div>
                </div>

                {/* 根据 payment_method 动态渲染只读字段 */}
                {renderFields()}

                {/* 最下方的确认按钮 */}
                <button
                    className={styles.confirmBtn}
                    style={{marginTop: '1rem'}}
                    onClick={handleConfirmTransfer}
                >
                    Confirm Transfer Complete
                </button>
            </div>
        </div>,
        document.getElementById('root')
    );
}

export default SettleFunds;