import React, { useEffect, useState } from 'react';
import { digitFormat, moneyFormat } from "../utils";

const OverallUSDIPayout = ({ selectedChain, onStatusChange }) => {
    // --------------------------------
    // 原有 state
    // --------------------------------
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updating, setUpdating] = useState(false);
    const [status, setStatus] = useState("calculated");
    const [distributedData, setDistributedData] = useState(null);
    const [distributedError, setDistributedError] = useState(null);
    const [distributedLoading, setDistributedLoading] = useState(true);
    const [isDistributed, setIsDistributed] = useState(false);

    // --------------------------------
    // 1) Decide which URLs to use based on selectedChain
    // --------------------------------
    const triggerPayoutCalculatorUrl = selectedChain === 'solana'
        ? 'https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator_solana'
        : 'https://payout-calculator-256611876551.europe-west1.run.app/trigger-payout-calculator';

    const overallUsdIPayoutUrl = selectedChain === 'solana'
        ? 'https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout_solana'
        : 'https://usdi-api-256611876551.europe-west1.run.app/overall_usdi_payout';

    // --------------------------------
    // 2) Fetch "calculated_data" when component mounts or chain changes
    // --------------------------------
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(triggerPayoutCalculatorUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ update: false }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json();
                setData(result.calculated_data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [triggerPayoutCalculatorUrl]);

    // --------------------------------
    // 3) Fetch "overall_usdi_payout" distribution data
    // --------------------------------
    useEffect(() => {
        const fetchDistributedData = async () => {
            setDistributedLoading(true);
            setDistributedError(null);
            try {
                const response = await fetch(overallUsdIPayoutUrl);

                if (!response.ok) {
                    throw new Error('Failed to fetch distributed data');
                }

                const result = await response.json();
                setDistributedData(result);
            } catch (err) {
                setDistributedError(err);
            } finally {
                setDistributedLoading(false);
            }
        };
        fetchDistributedData();
    }, [overallUsdIPayoutUrl]);

    // --------------------------------
    // 4) Handle manual "Update" button
    // --------------------------------
    const handleUpdate = async () => {
        setUpdating(true);
        setError(null);
        try {
            const response = await fetch(triggerPayoutCalculatorUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ update: false }),
            });
            if (!response.ok) throw new Error('Failed to trigger distribution calculation');

            const result = await response.json();
            setData(result.calculated_data);

            const distResponse = await fetch(overallUsdIPayoutUrl);
            if (!distResponse.ok) throw new Error('Failed to fetch updated distributed data');

            const distResult = await distResponse.json();
            setDistributedData(distResult);
        } catch (err) {
            setError(err);
        } finally {
            setUpdating(false);
        }
    };

    // --------------------------------
    // 5) Handle "Distributed" -> distribution, then update
    // --------------------------------
    const handleStatusChange = async (newStatus) => {
        const isConfirmed = window.confirm(`Are you sure you want to change the status to ${newStatus}?`);
        if (isConfirmed) {
            setStatus(newStatus);
            onStatusChange(newStatus);

            try {
                console.log("triggerPayoutCalculatorUrl",triggerPayoutCalculatorUrl)
                const response = await fetch(triggerPayoutCalculatorUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ update: true }),
                });
                if (!response.ok) throw new Error('Failed to trigger distribution calculation');

                const updated = await response.json();
                setData(updated);

                // Refresh data to reflect changes
                await handleUpdate();
            } catch (err) {
                setError(err);
            }

            // After distribution done, revert local status or keep as is
            setStatus("calculated");
        }
    };

    // --------------------------------
    // 这里开始：只加入分页，不做其余改动
    // --------------------------------

    // 分页用的 state 与工具函数（注意：放在所有 useEffect 之后，且无条件调用）
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // 每页显示的条数

    // 生成分页按钮的辅助函数（和 RawBlockchainWithTabs 一致）
    const getPageNumbers = (current, totalPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            let left = current - 1;
            let right = current + 1;

            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }

            if (right < totalPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };

    // 历史分发记录仅当 distributedData 不为空时才排序
    const sortedDistributedData = distributedData
        ? [...distributedData].sort((a, b) => b.block_no - a.block_no)
        : [];

    // 分页相关计算
    const totalItems = sortedDistributedData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // 为避免 Hooks 顺序问题，别在此处做 “if (!distributedData) return ...” 之类的
    // 而是到最后一并在渲染时判断

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = sortedDistributedData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };

    // --------------------------------
    // 6) Render
    // --------------------------------
    // 此处的条件渲染不能动：保持和原逻辑一致，避免 hooks 顺序警告
    if (loading || distributedLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (distributedError) return <div>Error: {distributedError.message}</div>;

    return (
        <>
            <div className='button_wrapper'>
                <h3>Overall USDI Payout</h3>
                <button
                    className={`button ${updating ? 'updating' : ''}`}
                    onClick={handleUpdate}
                    disabled={updating}
                >
                    {updating ? 'Updating' : 'Update'}
                </button>
                {isDistributed && <span>Distributed</span>}
            </div>

            {data && distributedData && (
                <div className="table-responsive noto">
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                            <tr>
                                <th>Status</th>
                                <th>Block No</th>
                                <th>Total Assets Value</th>
                                <th>USDI Locked</th>
                                <th>USDI In Circulation</th>
                                <th>Distribution ID</th>
                                <th>Available Distribution Value</th>
                                <th>Distribution Value</th>
                                <th>Distribution Amount Fees</th>
                                <th>Final Distribution Amount After Management Fees</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* 保持原计算行 */}
                            <tr>
                                <td>
                                    <select
                                        value={status}
                                        onChange={(e) => handleStatusChange(e.target.value)}
                                        className="status_dropdown"
                                    >
                                        <option value="calculated">Calculated</option>
                                        <option value="distributed">Distributed</option>
                                    </select>
                                </td>
                                <td>{digitFormat(data.current_block_number)}</td>
                                <td>{moneyFormat(data.total_assets_value)}</td>
                                <td>{digitFormat(data.usdi_locked)}</td>
                                <td>{digitFormat(data.usdi_in_circulation)}</td>
                                <td>NA</td>
                                <td>{moneyFormat(data.available_distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_value)}</td>
                                <td>{moneyFormat(data.distribution_amount_of_fees)}</td>
                                <td>{moneyFormat(data.final_distribution_amount)}</td>
                            </tr>

                            {/* 历史分发记录：改成对 currentItems 做 .map */}
                            {currentItems.map((row, index) => (
                                <tr key={index}>
                                    <td>Distributed</td>
                                    <td>{digitFormat(row.block_no)}</td>
                                    <td>{moneyFormat(row.total_assets_value)}</td>
                                    <td>{digitFormat(row.usdi_locked)}</td>
                                    <td>{digitFormat(row.usdi_in_circulation)}</td>
                                    <td>{sortedDistributedData.length - (startIndex + index)}</td>
                                    <td>{moneyFormat(row.available_distribution_value)}</td>
                                    <td>{moneyFormat(row.distribution_value)}</td>
                                    <td>{moneyFormat(row.distribution_amount_fees)}</td>
                                    <td>{moneyFormat(row.final_distribution_amount_after_management_fees)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </div>
            )}
            {/* 分页按钮 */}
                    {totalPages > 0 && (
                        <div className="pagination-container" style={{ marginTop: "10px" }}>
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(1)}
                                disabled={currentPage === 1}
                                id="first-page-button"
                            >
                                First
                            </button>
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>

                            {getPageNumbers(currentPage, totalPages).map((p, i) =>
                                p === '...' ? (
                                    <span key={i} className="pagination-ellipsis">...</span>
                                ) : (
                                    <button
                                        key={i}
                                        className={`pagination-button ${
                                            p === currentPage ? 'active-page' : ''
                                        }`}
                                        onClick={() => handlePageChange(p)}
                                    >
                                        {p}
                                    </button>
                                )
                            )}

                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                                id="last-page-button"
                            >
                                Last
                            </button>
                        </div>
                    )}
        </>
    );
};

export default OverallUSDIPayout;