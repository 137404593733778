// TransactionHistory.jsx

import React, {useContext, useEffect, useState} from 'react';
import styles from './TransactionHistory.module.css';
import ConnectWalletHeader from "../header/ConnectWalletHeader";
import LeftPanel from "../SwapLeftPanel/LeftPanel";
import {useNavigate} from 'react-router-dom';

// Icons
import greyCircleSvg from "../static_files/circle.svg";
import arrowUpSvg from "../static_files/Vector Up.svg";
import arrowDownSvg from "../static_files/Vector Down.svg";
import expandSvg from "../static_files/expand.svg";
import expandHoverSvg from "../static_files/expandHover.svg";
import foldSvg from "../static_files/unexpand.svg";
import foldHoverSvg from "../static_files/unexpandHover.svg";
import uIconSvg from "../static_files/USDi.png";

import copyImg from "../static_files/copy-selector.svg";
import copyHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/add_wallet_success.svg";

import shareLinkImg from "../static_files/shareButton.svg";
import shareLineHoverImg from "../static_files/shareButtonHover.svg";
import shareImg from "../static_files/share-selector.svg";
import shareHoverImg from "../static_files/share-selector-hover.svg";

import timeline1Img from "../static_files/timeline1.svg";
import timeline2Img from "../static_files/timeline2.svg";
import timeline3Img from "../static_files/timeline3.svg";
import noTransactionImg from "../static_files/No transaction.svg";
import {shortenAddress, formatAmount} from "../utils";

// Import CSSTransition for animation
import {CSSTransition} from "react-transition-group";
import {SwapMainAppContext} from "../../SwapMainAppContext";

import C2COrderDetail from "../C2C NetWork/C2COrderDetail";
import CancelOrderDetail from "../C2C NetWork/CancelOrderDetail";

import WireIcon from "../static_files/Wire.svg";
import USDIcon from "../static_files/usd.svg";
import ZelleIcon from "../static_files/Zelle.svg";
import VenmoIcon from "../static_files/Venmo.svg";
import AlipayIcon from "../static_files/Alipay.svg";

import RequestedTransactions from "./RequestedTransactions";
import SettleFunds from "../C2C NetWork/SettleFunds";
import TransactionHistoryLoader from "./TransactionHistoryLoader";

// ★ 新增：导入 Tooltip 组件
import Tooltip from "../Tooltip/Tooltip";
import AfterSettleFunds from "../C2C NetWork/AfterSettleFunds";

const paymentMethodIconMap = {
    "Bank Wire": WireIcon,
    "Cash - Paper": USDIcon,
    "Zelle": ZelleIcon,
    "Venmo": VenmoIcon,
    "Alipay": AlipayIcon,
};

function TransactionHistory() {
    // for error messages
    const {showNotification} = useContext(SwapMainAppContext);
    const navigate = useNavigate();

    const [walletAddress, setWalletAddress] = useState("");
    const [groupedTransactions, setGroupedTransactions] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [refetchFlag, setRefetchFlag] = useState(0);
    const [showPanel, setShowPanel] = useState(false);

    // Add loading state
    const [isLoading, setIsLoading] = useState(true);

    // 新增订单详情相关状态（与 C2CNetwork 类似）
    const [showCashReceivedConfirm, setShowCashReceivedConfirm] = useState(false);
    const [kiraPrice, setKiraPrice] = useState(null);
    const [selectedTx, setSelectedTx] = useState(null);
    const [orderDetails, setOrderDetails] = useState(null);
    const [emailWalletAddress, setEmailWalletAddress] = useState(null);

    const [showCancelOrderDetail, setShowCancelOrderDetail] = useState(false);
    const [cancelOrderDetails, setCancelOrderDetails] = useState(null);

    const [hasRequestedTxs, setHasRequestedTxs] = useState(false);
    const [showSettleFunds, setShowSettleFunds] = useState(false);
    const [showAfterSettleFunds, setShowAfterSettleFunds] = useState(false);

    // ---------------- SHARE LINK TOOLTIP STATES & HANDLERS ----------------
    // 仅保留点击后显示“Link Copied”逻辑。默认显示“Share Transaction”。
    const [tooltipTextMap, setTooltipTextMap] = useState({});

    // Copy link & show "Link Copied" for 2s
    const handleShareClick = (txId) => {
        const shareUrl = `https://app.0max1.com/transaction?txId=${txId}`;

        navigator.clipboard.writeText(shareUrl)
            .then(() => {
                setTooltipTextMap((prev) => ({...prev, [txId]: "Link Copied"}));

                setTimeout(() => {
                    // 恢复为 Share Transaction
                    setTooltipTextMap((prev) => ({...prev, [txId]: "Share Transaction"}));
                }, 2000);
            })
            .catch((err) => {
                console.error("Failed to copy link:", err);
            });
    };
    const handleRedirectClick = (tx) => {
        console.log("tx!!!!!", tx)
        const redirectUrl = `https://solscan.io/tx/${tx.signature}`;
        window.open(redirectUrl, '_blank');
    };
    // ----------------------------------------------------------------------

    // If the URL has ?txId=xxx, auto-expand that transaction row
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const txIdParam = params.get("txId");
        if (txIdParam) {
            setExpanded((prev) => ({...prev, [txIdParam]: true}));
        }
    }, [groupedTransactions]);

    // Fetch and group transactions by date
    useEffect(() => {
        if (!walletAddress) return;

        const formatLocalDate = (dateStr) => {
            const dateObj = new Date(dateStr);
            if (isNaN(dateObj.getTime())) return dateStr;
            return dateObj.toLocaleString([], {
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "2-digit",
                hour12: true,
            });
        };

        async function fetchUserHistory() {
            try {
                setIsLoading(true);
                const url = `https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/get_user_history?user_address=${walletAddress}`;
                const res = await fetch(url);
                const data = await res.json();
                if (!data || !Array.isArray(data.transaction_history)) {
                    console.warn("No valid transaction_history returned");
                    setGroupedTransactions([]);
                    return;
                }

                const cashRedeemData = Array.isArray(data.cash_redeem) ? data.cash_redeem : [];

                const allTxs = data.transaction_history.map((tx) => {
                    const createdAt = new Date(tx.txs_created_timestamp + "Z");
                    const timeStr = createdAt.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                    });
                    const dateStr = createdAt.toLocaleDateString([], {month: "long", day: "numeric"});
                    const arrowDirection = tx.txs_type?.toLowerCase() === "mint" ? "down" : "up";

                    // If redeem with method cash, display as "C2C"
                    let displayType = tx.txs_type;
                    if (tx.txs_type?.toLowerCase() === "redeem" && tx.method?.toLowerCase() === "cash") {
                        displayType = "C2C";
                    }

                    const txObj = {
                        id: tx.txs_id,
                        arrowDirection,
                        type: displayType,
                        amount: formatAmount(tx.from_token_amount || 0),
                        address: tx.from_address || "",
                        chain: tx.chain?.toLowerCase() || "",
                        redeemFrom: formatAmount(tx.from_token_amount || 0),
                        timeStr,
                        dateStr,
                        createdAt, // used for sorting
                        cash_delivery_received_time: "--",
                        usdi_released_time: "--",
                        status:
                            tx.status && tx.status.toLowerCase() === "completed"
                                ? "Completed"
                                : tx.status && tx.status.toLowerCase() === "pending"
                                    ? "Pending"
                                    : tx.status && tx.status.toLowerCase() === "failed"
                                        ? "Txs Failed"
                                        : tx.status,
                        fulfill_deadline: tx.fulfill_deadline || "--",
                        collateral_ratio: 2, // Hard-coded for now
                        networkFeePercentage: tx.fees_percent * 100,
                        eta: tx.eta || "--",
                        signature: tx.transaction_signature || ''
                    };
                    // Find the matching cash_redeem record by txs_id.
                    const matchingRedeem = cashRedeemData.find((redeem) => redeem.txs_id === tx.txs_id);
                    const statusMapping = {
                        order_created: "Order Created",
                        order_fulfilled: "USDi Released",
                        cash_received: "Cash Delivered",
                        completed: "Completed",
                        pending: "Pending",
                        canceled: "Order Canceled",
                    };
                    if (matchingRedeem) {
                        txObj.usdi_released_time = matchingRedeem.usdi_released_time
                            ? formatLocalDate(matchingRedeem.usdi_released_time)
                            : "--";
                        txObj.cash_delivery_received_time = matchingRedeem.cash_delivery_received_time
                            ? formatLocalDate(matchingRedeem.cash_delivery_received_time)
                            : "--";
                        txObj.node_operator_wallet = matchingRedeem.node_operator_wallet;
                        txObj.status =
                            statusMapping[matchingRedeem.status.toLowerCase()] || matchingRedeem.status;
                        txObj.payment_info_id = matchingRedeem.payment_info_id || "--";
                        //set if its settle funds now or user has been in next
                        txObj.fund_send_notified = matchingRedeem.fund_send_notified || false;
                        txObj.requestor_contact_email = matchingRedeem.requestor_contact_email;
                         txObj.requestor_contact_mobile_number = matchingRedeem.requestor_contact_mobile_number;
                         txObj.payment_info_object = matchingRedeem.payment_info_object
                        txObj.collateral_release_squads_transaction_status = matchingRedeem.collateral_release_squads_transaction_status ||"--";
                    }
                    return txObj;
                });

                // Group transactions by date
                const groupedMap = {};
                allTxs.forEach((tx) => {
                    if (!groupedMap[tx.dateStr]) {
                        groupedMap[tx.dateStr] = [];
                    }
                    groupedMap[tx.dateStr].push(tx);
                });

                // Sort each day’s transactions (newest first)
                Object.keys(groupedMap).forEach((dateKey) => {
                    groupedMap[dateKey].sort((a, b) => b.createdAt - a.createdAt);
                });

                // Convert to an array and sort by latest day first
                const groupedArr = Object.keys(groupedMap)
                    .map((dateKey) => {
                        const latestTimestamp = groupedMap[dateKey][0].createdAt;
                        return {date: dateKey, items: groupedMap[dateKey], latestTimestamp};
                    })
                    .sort((a, b) => b.latestTimestamp - a.latestTimestamp)
                    .map(({date, items}) => ({date, items}));

                setGroupedTransactions(groupedArr);
                console.log("groupedArr",groupedArr)
                // tx.address.toLowerCase() !== walletAddress.toLowerCase() && tx.fund_send_notified===true?
            } catch (err) {
                console.error("fetchUserHistory error:", err);
            } finally {
                setIsLoading(false);
            }
        }

        fetchUserHistory();
    }, [walletAddress, refetchFlag]);

    useEffect(() => {
        // Check if localStorage has a stored address (for email-logined user)
        const storedAddress = localStorage.getItem("emailUserWalletAddress");
        const storedAddress2 = localStorage.getItem("walletAddress");

        if (storedAddress) {
            setWalletAddress(storedAddress);
            setEmailWalletAddress(storedAddress);
        } else if (!storedAddress2) {
            setIsLoading(false)
        }
    }, []);

    // useEffect(() => {
    //     console.log("selectedTx！！！", selectedTx)
    // }, [selectedTx]);
    const handleMyAssetsClick = () => {
        const token = localStorage.getItem("gatewayToken");
        if (token) {
            navigate(`/swap?token=${token}`);
        } else {
            navigate("/");
        }
    };

    function toggleExpand(txId) {
        setExpanded((prev) => ({
            ...prev,
            [txId]: !prev[txId],
        }));
    }

    const handleClearTransactions = () => {
        setGroupedTransactions([]);
    };

    const today = new Date();
    const todayStr = today.toLocaleDateString([], {month: "long", day: "numeric"});
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const yesterdayStr = yesterday.toLocaleDateString([], {month: "long", day: "numeric"});

    const handleCancelCashRedeemClick = async (tx) => {
        setSelectedTx(tx);
        try {
            const details = {
                amount: tx.amount,
                txs_id: tx.id,
            };
            setCancelOrderDetails(details);
            setShowCancelOrderDetail(true);
        } catch (error) {
            console.error("Error fetching today's price:", error);
        }
    };

    const handleCancelCashRedeem = async () => {
        const tx = selectedTx;
        if (tx.status !== "Order Created") {
            showNotification("error", "This transaction cannot be canceled in its current status.");
            return;
        }

        try {
            const res = await fetch(
                "https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/cancel_cash_redeem",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        txs_id: tx.id,
                    }),
                }
            );

            if (res.ok) {
                setRefetchFlag((prev) => prev + 1);
                showNotification("success", "Your transaction has been canceled successfully!");
            } else {
                showNotification("error", "Failed to cancel your transaction. Please try again.");
            }
        } catch (err) {
            showNotification("error", "An unexpected error occurred. Please try again.");
        }
    };

    // Handle "Cash Received" button
    const handleCashReceivedClick = async (tx, toCashValue, networkFeeValue) => {
        setSelectedTx(tx);
        try {
            const today = new Date();
            const dd = String(today.getDate()).padStart(2, "0");
            const mm = String(today.getMonth() + 1).padStart(2, "0");
            const yyyy = today.getFullYear();
            const formattedDate = `${dd}-${mm}-${yyyy}`;

            const apiUrl = `https://api.coingecko.com/api/v3/coins/kirakuru/history?date=${formattedDate}&localization=false`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (
                data &&
                data.market_data &&
                data.market_data.current_price &&
                data.market_data.current_price.usd
            ) {
                const openPrice = data.market_data.current_price.usd.toFixed(8);
                setKiraPrice(openPrice);

                const orderTips = networkFeeValue / 2;
                const collateralRatio = parseFloat(tx.collateral_ratio) || 2;
                const redeemFromVal = parseFloat(tx.redeemFrom);
                const collateralRequired = (redeemFromVal * collateralRatio) / openPrice;
                const fulfillDeadlineDate = new Date(Date.now() + 48 * 60 * 60 * 1000);
                const fulfillDeadline = fulfillDeadlineDate.toLocaleString(); // in user's local time
                const details = {
                    cashDeliveryAmount: toCashValue,
                    orderTips,
                    fulfillDeadline,
                    collateralRequired,
                    collateralRatio,
                };
                setOrderDetails(details);
                setShowCashReceivedConfirm(true);
            } else {
                throw new Error("Price data not available in API response.");
            }
        } catch (error) {
            console.error("Error fetching today's price:", error);
        }
    };

    const handleCashReceived = async () => {
        const utcTime = new Date().toISOString();
        try {
            await fetch("https://usdi-api-256611876551.europe-west1.run.app/cash_redeem/cash_received", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    txs_id: selectedTx.id,
                    cash_delivery_received_time: utcTime,
                }),
            });
            console.log("body here", selectedTx.id, utcTime)
            setRefetchFlag((prev) => prev + 1);
            showNotification("success", "Your transaction was completed!");
        } catch (error) {
            console.error("Error updating cash received:", error);
        }
    };

    const handleSettleFundsClick = (tx) => {
        setSelectedTx(tx);
        setShowSettleFunds(true);
    };
    const handleAfterSettleFundsClick = (tx) => {
        setSelectedTx(tx);
        setShowAfterSettleFunds(true);
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#04070D",
                minHeight: "100vh",
                width: "100vw",
            }}
        >
            {/* Left Panel */}
            <LeftPanel/>

            <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                <ConnectWalletHeader
                    toAddress="4dJ5ATt3BbJPrbYCZGLAXndi5DPaZZY9j1Sp8Hdh4ApH"
                    onWalletConnected={(publicKey) => {
                        setWalletAddress(publicKey);
                    }}
                    showPanel={showPanel}
                    setShowPanel={setShowPanel}
                    emailWallet={emailWalletAddress}
                    onClearTransactions={handleClearTransactions}
                />

                <div className={styles.transactionContainer}>
                    {/* 如果正在加载则显示 loader */}
                    {isLoading ? (
                        <div style={{textAlign: "left"}}>
                            <div className={styles.transactionsTitle}>Transactions</div>
                            <TransactionHistoryLoader/>
                        </div>
                    ) : (
                        <>
                            {/* 如果没有 txs 且没有 hasRequestedTxs */}
                            {groupedTransactions.length === 0 && !hasRequestedTxs ? (
                                <div className={styles.noTransactionsContainer}>
                                    <img
                                        className={styles.noTransactionsIcon}
                                        src={noTransactionImg}
                                        alt="No transactions icon"
                                    />
                                    <h2 className={styles.noTransactionsTitle}>No transactions yet</h2>
                                    <p className={styles.noTransactionsDescription}>
                                        Start your journey by minting USDi. Once you perform transactions,
                                        they’ll appear here for easy tracking and management.
                                    </p>
                                    <button
                                        className={styles.noTransactionsButton}
                                        onClick={handleMyAssetsClick}
                                    >
                                        Mint USDi
                                    </button>
                                </div>
                            ) : (
                                <>
                                {(groupedTransactions.length > 0 || hasRequestedTxs) && (
                                    <div className={styles.transactionsTitle}>Transactions</div>
                                )}
                                <RequestedTransactions
                                    onHasRequestedTxsChange={(hasTxs) => setHasRequestedTxs(hasTxs)}
                                />
                                {groupedTransactions.map((group) => (
                                    <div key={group.date}>
                                        <div className={styles.transactionsDayTitle}>
                                            {group.date === todayStr
                                                ? "Today"
                                                : group.date === yesterdayStr
                                                    ? "Yesterday"
                                                    : group.date}
                                        </div>

                                        {group.items.map((tx) => {
                                            const arrowIcon =
                                                tx.arrowDirection === "down" ? arrowDownSvg : arrowUpSvg;
                                            // If redeem with method cash => C2C => can expand if not canceled.
                                            const canExpand =
                                                tx.type.toLowerCase() === "c2c" &&
                                                tx.status.toLowerCase() !== "order canceled";

                                            const redeemFromValue = parseFloat(tx.redeemFrom);
                                            const toCashValue = Math.floor(
                                                redeemFromValue * (100 - tx.networkFeePercentage)
                                            ) / 100;
                                            const networkFeeValue = redeemFromValue - toCashValue;

                                            return (
                                                <div className={styles.transactionRowOuterContainer} key={tx.id}>
                                                    {/* Main Transaction Row */}
                                                    <div
                                                        className={`${styles.transactionRow} ${
                                                            canExpand && expanded[tx.id] ? styles.expandedRow : ""
                                                        }`}
                                                    >
                                                        <img
                                                            className={styles.directionArrow}
                                                            src={arrowIcon}
                                                            alt="arrow"
                                                        />
                                                        <span className={styles.txType}>{tx.type}</span>
                                                        <img className={styles.uIcon} src={uIconSvg}
                                                             alt="USDi icon"/>
                                                        <div className={styles.amountBox}>
                                                                <span className={styles.amount}>
                                                                    {parseFloat(tx.amount).toLocaleString("en-US", {
                                                                        maximumFractionDigits: 6,
                                                                    })}
                                                                </span>
                                                            <span className={styles.usdi}> USDi</span>
                                                        </div>
                                                        <span className={styles.to}>To:</span>
                                                        <span className={styles.toAddress}>
                                                                {shortenAddress(tx.address)}
                                                            </span>
                                                        <span className={styles.time}>{tx.timeStr}</span>
                                                        <span
                                                            className={
                                                                tx.status.toLowerCase() === "txs failed"
                                                                    ? styles.statusFailed
                                                                    : tx.status.toLowerCase() === "completed" ||
                                                                    tx.status.toLowerCase() === "cash delivered"
                                                                        ? styles.statusCompleted
                                                                        : styles.statusPending
                                                            }
                                                        >
                                                                {tx.status}
                                                            </span>

                                                        {/* ★ 新用 Tooltip 包住 share 图标 */}
                                                        {canExpand ? (
                                                            <Tooltip
                                                                text={
                                                                    tooltipTextMap[tx.id]
                                                                        ? tooltipTextMap[tx.id]
                                                                        : "Share Transaction"
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        tooltipTextMap[tx.id] === "Link Copied"
                                                                            ? copyConfirmImg
                                                                            : shareLinkImg
                                                                    }
                                                                    alt="share"
                                                                    style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        // 仅更换图标外观，不再控制 tooltip 文本
                                                                        if (tooltipTextMap[tx.id] !== "Link Copied") {
                                                                            e.currentTarget.src = shareLineHoverImg;
                                                                        }
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        if (tooltipTextMap[tx.id] !== "Link Copied") {
                                                                            e.currentTarget.src = shareLinkImg;
                                                                        }
                                                                    }}
                                                                    onClick={() => handleShareClick(tx.id)}
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                text={
                                                                    "Transaction Details"
                                                                }
                                                            >
                                                                <img
                                                                    src={shareImg}
                                                                    alt="share"
                                                                    style={{
                                                                        width: "16px",
                                                                        height: "16px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onMouseEnter={(e) => {
                                                                        // 仅更换图标外观，不再控制 tooltip 文本
                                                                        if (tooltipTextMap[tx.id] !== "Link Copied") {
                                                                            e.currentTarget.src = shareHoverImg;
                                                                        }
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        if (tooltipTextMap[tx.id] !== "Link Copied") {
                                                                            e.currentTarget.src = shareImg;
                                                                        }
                                                                    }}
                                                                    onClick={() => handleRedirectClick(tx)}
                                                                />
                                                            </Tooltip>

                                                        )}
                                                        {/* Expand/fold icon */}
                                                        {canExpand ? (
                                                            <img
                                                                className={styles.expandIcon}
                                                                src={expanded[tx.id] ? foldSvg : expandSvg}
                                                                alt="expand/fold"
                                                                onClick={() => toggleExpand(tx.id)}
                                                                onMouseEnter={(e) => {
                                                                    e.currentTarget.src = expanded[tx.id]
                                                                        ? foldHoverSvg
                                                                        : expandHoverSvg;
                                                                }}
                                                                onMouseLeave={(e) => {
                                                                    e.currentTarget.src = expanded[tx.id]
                                                                        ? foldSvg
                                                                        : expandSvg;
                                                                }}
                                                            />
                                                        ) : (
                                                            <img
                                                                className={styles.expandIcon}
                                                                src={expandSvg}
                                                                alt="expand/fold"
                                                                style={{visibility: "hidden"}}
                                                            />
                                                        )}
                                                    </div>

                                                    {/* Expanded Section with animation */
                                                    }
                                                    <CSSTransition
                                                        in={canExpand && expanded[tx.id]}
                                                        timeout={300}
                                                        classNames={{
                                                            enter: styles.expandedEnter,
                                                            enterActive: styles.expandedEnterActive,
                                                            exit: styles.expandedExit,
                                                            exitActive: styles.expandedExitActive,
                                                        }}
                                                        unmountOnExit
                                                    >
                                                        <div className={styles.expandedContainerOuter}>
                                                            <div className={styles.lineWrapper}>
                                                                <div className={styles.dividerLineFull}></div>
                                                            </div>

                                                            <div className={styles.expandedContainer}>
                                                                {/* Info Section */}
                                                                <div className={styles.expandedInfo}>
                                                                    <div className={styles.expandedSectionTitle}>
                                                                        Info
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                Redeem from:
                                                                            </span>
                                                                        <span className={styles.fetchedData}>
                                                                                {parseFloat(tx.redeemFrom).toLocaleString(
                                                                                    "en-US",
                                                                                    {
                                                                                        maximumFractionDigits: 6,
                                                                                    }
                                                                                )}{" "}
                                                                            USDi
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                To cash:
                                                                            </span>
                                                                        <span className={styles.fetchedData}>
                                                                                {toCashValue.toLocaleString("en-US", {
                                                                                    maximumFractionDigits: 2,
                                                                                    minimumFractionDigits: 2,
                                                                                })}{" "}
                                                                            USDi
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                Transaction type:
                                                                            </span>
                                                                        <span className={styles.fetchedData}>
                                                                                Redeem
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span
                                                                                className={styles.fixedLabel}>Time:</span>
                                                                        <span className={styles.fetchedData}>
                                                                                {tx.dateStr}, {tx.timeStr}
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                Network:
                                                                            </span>
                                                                        <Tooltip text={<>A decentralized crypto-to-cash
                                                                            network <br/>for on-ramp and off-ramp
                                                                            transactions</>}>
                                                                            <span style={{
                                                                                cursor: "pointer",
                                                                                borderBottom: '1.4px dotted #797979'
                                                                            }}
                                                                                  className={styles.fetchedData}>C2C</span>
                                                                        </Tooltip>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                        <Tooltip text={<>The total amount charged by the
                                                                            C2C <br/> network for processing this
                                                                            order</>}>

                                                                            <span  style={{cursor: "pointer", borderBottom: '1.4px dotted #797979'}} className={styles.fixedLabel}>
                                                                                Network fee ({tx.networkFeePercentage}%):
                                                                            </span>
                                                                        </Tooltip>
                                                                            <span className={styles.fetchedData}>
                                                                                {networkFeeValue.toLocaleString("en-US", {
                                                                                    maximumFractionDigits: 2,
                                                                                    minimumFractionDigits: 2,
                                                                                })}{" "}
                                                                                USDi
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                Rate:
                                                                            </span>
                                                                        <span className={styles.fetchedData}>
                                                                                1 USDi = 1 USD
                                                                            </span>
                                                                    </div>

                                                                    <div className={styles.expandedText}>
                                                                            <span className={styles.fixedLabel}>
                                                                                ETA:
                                                                            </span>
                                                                        <span className={styles.fetchedData}>
                                                                                {tx.eta}
                                                                            </span>
                                                                    </div>
                                                                    <div className={styles.dividerLineFull2}></div>

                                                                    <div className={styles.expandedNote}>
                                                                        Notes: First, pay the network fee to
                                                                        initiate the transaction. Then, pay the
                                                                        remaining amount upon delivery.
                                                                    </div>
                                                                </div>

                                                                {/* Progress Section */}
                                                                <div className={styles.expandedProgress}>
                                                                    <div className={styles.expandedSectionTitle}>
                                                                        Progress
                                                                    </div>
                                                                    <div className={styles.progressItemContainer}>
                                                                        <div>
                                                                            {tx.usdi_released_time === "--" ? (
                                                                                <img
                                                                                    src={timeline1Img}
                                                                                    alt="timeline"
                                                                                />
                                                                            ) : tx.cash_delivery_received_time ===
                                                                            "--" ? (
                                                                                <img
                                                                                    src={timeline2Img}
                                                                                    alt="timeline"
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={timeline3Img}
                                                                                    alt="timeline"
                                                                                />
                                                                            )}
                                                                        </div>

                                                                        <div
                                                                            className={styles.progressItemContainerLeft}
                                                                        >
                                                                            <div className={styles.progressItem}>
                                                                                Transaction Created
                                                                                <div
                                                                                    className={styles.progressItemTime}>
                                                                                    {tx.dateStr}, {tx.timeStr}
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.progressItem}>
                                                                                USDi Released
                                                                                <div
                                                                                    className={styles.progressItemTime}>
                                                                                    {tx.usdi_released_time || " "}
                                                                                </div>
                                                                            </div>
                                                                            <div className={styles.progressItem}>
                                                                                Cash Delivered
                                                                                <div
                                                                                    className={styles.progressItemTime}>
                                                                                    {tx.cash_delivery_received_time ||
                                                                                        " "}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className={styles.progressItemContainerRight}
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.operatorWalletContainer
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.operatorWalletContainerLeft
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={greyCircleSvg}
                                                                                        alt="O"
                                                                                    />
                                                                                    <div
                                                                                        className={styles.c2cWallet}>
                                                                                        {tx.address
                                                                                            ? shortenAddress(tx.address)
                                                                                            : " "}
                                                                                        <div
                                                                                            className={
                                                                                                styles.greyWalletAddress
                                                                                            }
                                                                                        >
                                                                                            C2C Ex Wallet
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className={styles.imgContainer}
                                                                                >
                                                                                    <img
                                                                                        style={{
                                                                                            width: "9.49px",
                                                                                            height: "11.88px",
                                                                                        }}
                                                                                        src={copyImg}
                                                                                        alt="copy img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src =
                                                                                                copyHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            if (
                                                                                                !e.currentTarget.getAttribute(
                                                                                                    "data-copied"
                                                                                                )
                                                                                            ) {
                                                                                                e.currentTarget.src =
                                                                                                    copyImg;
                                                                                            }
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            const imgElement =
                                                                                                e.currentTarget;
                                                                                            imgElement.setAttribute(
                                                                                                "data-copied",
                                                                                                "true"
                                                                                            );
                                                                                            navigator.clipboard
                                                                                                .writeText(tx.address)
                                                                                                .then(() => {
                                                                                                    imgElement.src =
                                                                                                        copyConfirmImg;
                                                                                                    imgElement.style.width =
                                                                                                        "10px";
                                                                                                    imgElement.style.height =
                                                                                                        "10px";
                                                                                                    setTimeout(() => {
                                                                                                        imgElement.src =
                                                                                                            copyImg;
                                                                                                        imgElement.removeAttribute(
                                                                                                            "data-copied"
                                                                                                        );
                                                                                                    }, 3000);
                                                                                                })
                                                                                                .catch((err) =>
                                                                                                    console.error(
                                                                                                        "Copy failed:",
                                                                                                        err
                                                                                                    )
                                                                                                );
                                                                                        }}
                                                                                    />
                                                                                    <img
                                                                                        src={shareImg}
                                                                                        alt="share img"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.currentTarget.src =
                                                                                                shareHoverImg;
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            e.currentTarget.src =
                                                                                                shareImg;
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            window.open(
                                                                                                `https://solscan.io/account/${tx.address}`,
                                                                                                "_blank"
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                className={
                                                                                    styles.operatorWalletContainer
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.operatorWalletContainerLeft
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={greyCircleSvg}
                                                                                        alt="O"
                                                                                    />
                                                                                    <div
                                                                                        className={styles.c2cWallet}>
                                                                                        {tx.node_operator_wallet
                                                                                            ? shortenAddress(
                                                                                                tx.node_operator_wallet
                                                                                            )
                                                                                            : "--"}
                                                                                        <div
                                                                                            className={
                                                                                                styles.greyWalletAddress
                                                                                            }
                                                                                        >
                                                                                            Node Operator Wallet
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {tx.node_operator_wallet ? (
                                                                                    <div
                                                                                        className={styles.imgContainer}
                                                                                    >
                                                                                        <img
                                                                                            style={{
                                                                                                width: "9.49px",
                                                                                                height: "11.88px",
                                                                                            }}
                                                                                            src={copyImg}
                                                                                            alt="copy img"
                                                                                            onMouseEnter={(e) => {
                                                                                                e.currentTarget.src =
                                                                                                    copyHoverImg;
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                                if (
                                                                                                    !e.currentTarget.getAttribute(
                                                                                                        "data-copied"
                                                                                                    )
                                                                                                ) {
                                                                                                    e.currentTarget.src =
                                                                                                        copyImg;
                                                                                                }
                                                                                            }}
                                                                                            onClick={(e) => {
                                                                                                const imgElement =
                                                                                                    e.currentTarget;
                                                                                                imgElement.setAttribute(
                                                                                                    "data-copied",
                                                                                                    "true"
                                                                                                );
                                                                                                navigator.clipboard
                                                                                                    .writeText(
                                                                                                        tx.node_operator_wallet
                                                                                                    )
                                                                                                    .then(() => {
                                                                                                        imgElement.src =
                                                                                                            copyConfirmImg;
                                                                                                        imgElement.style.width =
                                                                                                            "10px";
                                                                                                        imgElement.style.height =
                                                                                                            "10px";
                                                                                                        setTimeout(() => {
                                                                                                            imgElement.src =
                                                                                                                copyImg;
                                                                                                            imgElement.removeAttribute(
                                                                                                                "data-copied"
                                                                                                            );
                                                                                                        }, 3000);
                                                                                                    })
                                                                                                    .catch((err) =>
                                                                                                        console.error(
                                                                                                            "Copy failed:",
                                                                                                            err
                                                                                                        )
                                                                                                    );
                                                                                            }}
                                                                                        />
                                                                                        <img
                                                                                            src={shareImg}
                                                                                            alt="share img"
                                                                                            onMouseEnter={(e) => {
                                                                                                e.currentTarget.src =
                                                                                                    shareHoverImg;
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                                e.currentTarget.src =
                                                                                                    shareImg;
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                window.open(
                                                                                                    `https://solscan.io/account/${tx.node_operator_wallet}`,
                                                                                                    "_blank"
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                ) : (
                                                                                    <div></div>
                                                                                )}
                                                                            </div>

                                                                            {/* Payment method */}
                                                                            <div
                                                                                className={
                                                                                    styles.operatorWalletContainer
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        styles.operatorWalletContainerLeft
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            tx.payment_info_object
                                                                                                ? paymentMethodIconMap[
                                                                                                    tx.payment_info_object
                                                                                                        .payment_method
                                                                                                    ]
                                                                                                : greyCircleSvg
                                                                                        }
                                                                                        alt="O"
                                                                                    />
                                                                                    <div
                                                                                        className={styles.c2cWallet}>
                                                                                        {tx.payment_info_object
                                                                                            ? tx.payment_info_object
                                                                                                .payment_method
                                                                                            : "--"}
                                                                                        <div
                                                                                            className={
                                                                                                styles.greyWalletAddress
                                                                                            }
                                                                                        >
                                                                                            Payment Method
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {tx.status.toLowerCase() !== "completed" &&
                                                                        <div className={styles.progressButtons}>
                                                                            {tx.usdi_released_time === "--" ? (
                                                                                <>
                                                                                    <button
                                                                                        onClick={() =>
                                                                                            handleCancelCashRedeemClick(
                                                                                                tx
                                                                                            )
                                                                                        }
                                                                                        className={
                                                                                            styles.progressButton2
                                                                                        }
                                                                                    >
                                                                                        Cancel
                                                                                    </button>
                                                                                    <button
                                                                                        className={
                                                                                            styles.progressButton
                                                                                        }
                                                                                        style={{cursor: "auto"}}
                                                                                    >
                                                                                        Cash Received
                                                                                    </button>
                                                                                </>
                                                                            ) : tx.address.toLowerCase() !==
                                                                            walletAddress.toLowerCase() && tx.fund_send_notified===false &&tx.status !== "Cash Delivered" ? (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        handleSettleFundsClick(tx)
                                                                                    }
                                                                                    className={
                                                                                        styles.progressButton3
                                                                                    }
                                                                                >
                                                                                    Settle Funds Now
                                                                                </button>
                                                                            ) :tx.address.toLowerCase() !==
                                                                            walletAddress.toLowerCase() && (tx.fund_send_notified===true || tx.status === "Cash Delivered")?(
                                                                                 <button
                                                                                    onClick={() =>
                                                                                        handleAfterSettleFundsClick(tx)
                                                                                    }
                                                                                    className={
                                                                                        styles.progressButton3
                                                                                    }
                                                                                >
                                                                                    Waiting for Fund Confirmation
                                                                                </button>
                                                                            ): (
                                                                                <>
                                                                                    <button
                                                                                        className={
                                                                                            styles.progressButton
                                                                                        }
                                                                                    >
                                                                                        Dispute
                                                                                    </button>
                                                                                    <button
                                                                                        className={
                                                                                            styles.progressButton2
                                                                                        }
                                                                                        onClick={() =>
                                                                                            handleCashReceivedClick(
                                                                                                tx,
                                                                                                toCashValue,
                                                                                                networkFeeValue
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Cash Received
                                                                                    </button>
                                                                                </>
                                                                            )}
                                                                        </div>

                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CSSTransition>
                                                </div>
                                        )
                                        ;
                                        })}
                                        </div>
                                        ))
                                        }
                                            </>
                                            )}
                                    </>
                                )}
                                </div>
                                </div>

                            {showCashReceivedConfirm && orderDetails && (
                                <C2COrderDetail
                                cashDeliveryAmount={`${(
                                Math.ceil(orderDetails.cashDeliveryAmount * 100) / 100
                                ).toFixed(2)} USDi`}
                            orderTips={`${(orderDetails.orderTips / kiraPrice).toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })} KRA ($${(Math.floor(orderDetails.orderTips * 100) / 100).toFixed(2)})`}
                            fulfillDeadline={orderDetails.fulfillDeadline}
                            collateralRequired={`${(
                                Math.ceil(orderDetails.collateralRequired * 100) / 100
                            ).toLocaleString("en-US", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })} KRA`}
                            rate={kiraPrice}
                            collateralRatio={`${(orderDetails.collateralRatio * 100).toFixed(0)}%`}
                            onConfirm={async () => {
                                try {
                                    await handleCashReceived();
                                    setShowCashReceivedConfirm(false);
                                } catch (error) {
                                    console.error("Error in handleCashReceived:", error);
                                }
                            }}
                            onClose={() => {
                                setShowCashReceivedConfirm(false);
                                setOrderDetails(null);
                            }}
                            isFulfillOrder={false}
                        />
                    )}

                    {showCancelOrderDetail && cancelOrderDetails && (
                        <CancelOrderDetail
                        toAmount={`${(Math.ceil(cancelOrderDetails.amount * 100) / 100).toFixed(2)} USDi`}
                     onConfirm={async () => {
                         try {
                             await handleCancelCashRedeem();
                             setShowCancelOrderDetail(false);
                         } catch (error) {
                             console.error("Error in handleCashReceived:", error);
                         }
                     }}
                     onClose={() => {
                         setShowCancelOrderDetail(false);
                         setCancelOrderDetails(null);
                     }}
                />
                )}

                {showSettleFunds && (
                <SettleFunds
                    onClose={() => setShowSettleFunds(false)}
                    paymentInfo={selectedTx?.payment_info_object}
                    onConfirmTransfer={() => {
                        setShowSettleFunds(false);
                    }}
                    selectedTx={selectedTx}
                />
                )}
            {showAfterSettleFunds && (
                <AfterSettleFunds
                    onClose={() => setShowAfterSettleFunds(false)}
                    selectedTx={selectedTx}
                    email={selectedTx.requestor_contact_email}
                    mobile_number={selectedTx.requestor_contact_mobile_number}
                />
                )}
            </div>
            );
            }

            export default TransactionHistory;