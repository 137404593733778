// components/Table/RawBlockchainWithTabs.js

import React, {useEffect, useState} from 'react';
import {digitFormat} from '../utils';
import '../tables.css'; // Keep your existing styles

const RawBlockchainWithTabs = ({selectedChain = 'eth'}) => {
    const [ethereumData, setEthereumData] = useState([]);
    const [solanaData, setSolanaData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // 1) Fetch Ethereum data
    const fetchEthereumData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                'https://usdi-api-jlukzyz7wa-ew.a.run.app/raw_blockchain_transactions'
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            const ethereumAddress = '0xD458Ef69a04C37812ae1a7d3BbBC0DfB8f0c0C46';
            const filteredEth = result.filter(
                (item) => item.token_smart_contract_address === ethereumAddress
            );
            setEthereumData(filteredEth);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // 2) Fetch Solana data
    const fetchSolanaData = async () => {
        try {
            setLoading(true);
            const response = await fetch(
                'https://usdi-raw-transactions-256611876551.europe-west1.run.app/get-solana-transactions'
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setSolanaData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // 3) On component mount, fetch Ethereum & process events
    useEffect(() => {
        fetchEthereumData();

        const requestRefreshData = async () => {
            try {
                // Process ETH events
                let response = await fetch(
                    'https://usdi-raw-transactions-256611876551.europe-west1.run.app/process-events'
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                await response.json();
                await fetchEthereumData();

                // Process Solana
                let response2 = await fetch(
                    'https://usdi-raw-transactions-256611876551.europe-west1.run.app/process-solana-events'
                );
                if (!response2.ok) {
                    throw new Error('Network response was not ok');
                }
                await response2.json();
                await fetchSolanaData();
            } catch (error) {
                setError(error);
            }
        };

        requestRefreshData();
    }, []);

    // 4) When selectedChain changes, fetch relevant data
    useEffect(() => {
        if (selectedChain === 'eth') {
            fetchEthereumData();
        } else if (selectedChain === 'solana') {
            fetchSolanaData();
        }
    }, [selectedChain]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // ------------------ PAGINATION HELPER ------------------
    /**
     * Returns an array of page numbers (and '...' strings) for pagination UI
     * Example: getPageNumbers(20, 1) => [1, 2, 3, 4, "...", 20]
     */
    const getPageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5; // how many actual numbers to show
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            // If we can show all pages directly
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Always show first page
            pageNumbers.push(1);

            // Determine left and right of current page
            let left = currentPage - 1;
            let right = currentPage + 1;

            // Collapsing left side
            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }

            // Collapsing right side
            if (right < totalPages - 1) {
                // push all pages between left and right
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                // push pages from left up to totalPages-1
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }

            // Always show last page
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };

    // ------------------ TABLE COMPONENT ------------------
    const BlockchainTable = ({tableData, isEthereum}) => {
        // Sort descending by blockno
        const sortedData = [...tableData].sort(
            (a, b) => Number(b.blockno) - Number(a.blockno)
        );
        // Filter out rows with empty addresses
        const filteredData = sortedData.filter(
            (row) => !(row.from_address === '' && row.to_address === '')
        );

        // Pagination State
        const [currentPage, setCurrentPage] = useState(1);
        const itemsPerPage = 10;
        const totalItems = filteredData.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);

        // Whenever filtered data changes, reset to page 1
        useEffect(() => {
            setCurrentPage(1);
        }, [filteredData.length]);

        // Current items
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const currentItems = filteredData.slice(startIndex, endIndex);

        // Handle page changes
        const handlePageChange = (page) => {
            if (page < 1) page = 1;
            if (page > totalPages) page = totalPages;
            setCurrentPage(page);
        };

        // Compute page numbers to display
        const pageNumbers = getPageNumbers(currentPage, totalPages);

        // ------------------ CSV DOWNLOAD ------------------
        const handleDownloadCSV = () => {
            // 1) Construct CSV headers
            const headers = [
                'blockno',
                'date_time_utc',
                'from_address',
                'method',
                'quantity',
                'to_address',
                'transaction_hash',
                'token_smart_contract_address',
                'unix_timestamp',
            ];

            // 2) Create CSV rows from the ENTIRE filteredData (not just currentItems).
            const csvRows = filteredData.map((row) => {
                const rowData = [
                    row.blockno,
                    row.date_time_utc,
                    row.from_address,
                    row.method,
                    row.quantity,
                    row.to_address,
                    // Depending on Ethereum or Solana, pick the correct hash field
                    isEthereum ? row.transaction_hash : row.Transaction_hash,
                    row.token_smart_contract_address,
                    row.unix_timestamp,
                ];

                // Quote each field (in case it has commas) and join with commas
                return rowData
                    .map((field) => `"${field ?? ''}"`)
                    .join(',');
            });

            // 3) Join headers and rows into a single CSV string
            const csvString = [
                headers.join(','), // header row
                ...csvRows,        // data rows
            ].join('\n');

            // 4) Create a Blob from CSV string
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);

            // 5) Create a hidden <a> element, set it to the blob URL, and auto-click
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'blockchain_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        return (
            <div>
                <div className="table-responsive noto" style={{ marginBottom: "10px" }}>
                    <table className="table table-striped table-bordered mt-3">
                        <thead className="thead-dark">
                            <tr>
                                <th>blockno</th>
                                <th>date_time_utc</th>
                                <th>from_address</th>
                                <th>method</th>
                                <th>quantity</th>
                                <th>to_address</th>
                                <th>transaction_hash</th>
                                <th>token_smart_contract_address</th>
                                <th>unix_timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((row, index) => (
                                <tr key={index}>
                                    <td>{digitFormat(row.blockno)}</td>
                                    <td>{row.date_time_utc}</td>
                                    <td>{row.from_address}</td>
                                    <td>{row.method}</td>
                                    <td>{digitFormat(row.quantity)}</td>
                                    <td>{row.to_address}</td>
                                    <td>{isEthereum ? row.transaction_hash : row.Transaction_hash}</td>
                                    <td>{row.token_smart_contract_address}</td>
                                    <td>{digitFormat(row.unix_timestamp)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* PAGINATION CONTROLS */}
                {totalPages > 1 && (
                    <div className="pagination-outer-container">
                        <div className="pagination-container">
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(1)}
                                disabled={currentPage === 1}
                                id="first-page-button"
                            >
                                First
                            </button>
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>

                            {/* Numbered buttons (with possible "...") */}
                            {pageNumbers.map((p, i) =>
                                p === '...' ? (
                                    <span key={i} className="pagination-ellipsis">
                                        ...
                                    </span>
                                ) : (
                                    <button
                                        key={i}
                                        className={`pagination-button ${
                                            p === currentPage ? 'active-page' : ''
                                        }`}
                                        onClick={() => handlePageChange(p)}
                                    >
                                        {p}
                                    </button>
                                )
                            )}

                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                            <button
                                className="pagination-button"
                                onClick={() => handlePageChange(totalPages)}
                                disabled={currentPage === totalPages}
                                id="last-page-button"
                            >
                                Last
                            </button>
                        </div>

                        {/* DOWNLOAD BUTTON */}
                        <button className="tableDownloadButton" onClick={handleDownloadCSV}>
                            Download CSV
                        </button>
                    </div>
                )}
            </div>
        );
    };

    // Decide which chain to show
    return (
        <div>
            <div className="mt-3">
                {selectedChain === 'eth' && (
                    <BlockchainTable tableData={ethereumData} isEthereum={true} />
                )}
                {selectedChain === 'solana' && (
                    <BlockchainTable tableData={solanaData} isEthereum={false} />
                )}
            </div>
        </div>
    );
};

export default RawBlockchainWithTabs;