// RequestedTransactions.jsx
import React, {useState, useEffect} from 'react';
import styles from './TransactionHistory.module.css';
import {shortenAddress, formatAmount} from '../utils';
import arrowDownSvg from '../static_files/Vector Down.svg';
import arrowUpSvg from '../static_files/Vector Up.svg';
import uIconSvg from '../static_files/USDi.png';
import copyConfirmImg from "../static_files/add_wallet_success.svg";
import shareLinkImg from "../static_files/shareButton.svg";
import expandSvg from "../static_files/expand.svg";

// Helper function to parse "03/12/2025, 21:31:53" as UTC => Return local Date object
function parseCustomTimestampUTC(timestampStr) {
    // Example: "03/12/2025, 21:31:53"
    // Split by comma => ["03/12/2025", " 21:31:53"]
    const [datePart, timePart] = timestampStr.split(',');
    if (!datePart || !timePart) return null;

    // Trim and split the date (MM/DD/YYYY) and time (HH:MM:SS)
    const [month, day, year] = datePart.trim().split('/');
    const [hour, minute, second] = timePart.trim().split(':');

    // Interpret it as UTC
    const utcTimestamp = Date.UTC(
        +year,
        +month - 1, // months are 0-based in JS
        +day,
        +hour,
        +minute,
        +second
    );
    // Return a local Date object representing that UTC moment
    return new Date(utcTimestamp);
}

function RequestedTransactions({onHasRequestedTxsChange}) {
    const [requests, setRequests] = useState([]);

    useEffect(() => {
        async function fetchRequests() {
            try {
                const email = localStorage.getItem('userEmail');
                if (!email) {
                    setRequests([]);
                    return;
                }
                const url = `https://usdi-api-256611876551.europe-west1.run.app/get_email_uid_txs_requests?email=${encodeURIComponent(email)}`;
                const res = await fetch(url);
                const data = await res.json();

                console.log("data", data);
                if (Array.isArray(data)) {
                    setRequests(data);
                } else {
                    setRequests([]);
                }
            } catch (err) {
                console.error('Error fetching requested transactions:', err);
                setRequests([]);
            }
        }

        fetchRequests();
    }, []);

    // 只保留 status === 'requested' 的请求
    const filteredRequests = requests.filter(req => req.status === 'requested');

    // —— 在这里把结果“告诉”父组件 ——
    useEffect(() => {
        onHasRequestedTxsChange?.(filteredRequests.length > 0);
    }, [filteredRequests, onHasRequestedTxsChange]);

    // 如果没有任何 requested 状态的交易，则不渲染任何 UI
    if (filteredRequests.length === 0) return null;

    return (
        <div style={{marginBottom: '20px'}}>
            <div className={styles.transactionsDayTitle} style={{marginBottom: '10px'}}>
                Requested Transactions
            </div>

            {/*
        当请求数量超过2个时，添加滚动条。
        同时给外层 div 加上 scrollableContainer 以启用自定义滚动条样式。
      */}
            <div
                className={`${filteredRequests.length > 2 ? styles.scrollableContainer : ''}`}
                style={{
                    maxHeight: filteredRequests.length > 2 ? '140px' : 'auto',
                    overflowY: filteredRequests.length > 2 ? 'scroll' : 'visible',
                    paddingRight: '4px',
                }}
            >
                {filteredRequests.map((req, index) => {
                    const arrowDirection = req.txs_type?.toLowerCase() === 'mint' ? 'down' : 'up';
                    const arrowIcon = arrowDirection === 'down' ? arrowDownSvg : arrowUpSvg;
                    const amount = formatAmount(req.from_token_amount || 0);
                    const displayAddress = req.from_address || '';

                    // 将状态首字母大写，仅用于展示
                    const statusText = req.status.charAt(0).toUpperCase() + req.status.slice(1);

                    // ---- Convert UTC string to local Date and display local time ----
                    let timeStr = '';
                    try {
                        const dateObj = parseCustomTimestampUTC(req.requested_timestamp);
                        if (dateObj && !isNaN(dateObj.getTime())) {
                            timeStr = dateObj.toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            });
                        }
                    } catch (e) {
                        console.warn('Failed to parse requested_timestamp:', req.requested_timestamp);
                    }

                    // 如果 method = "cash" => "C2C"，否则就根据 txs_type 来展示
                    const displayType =
                        req.method === 'cash'
                            ? 'C2C'
                            : req.txs_type
                                ? req.txs_type.charAt(0).toUpperCase() + req.txs_type.slice(1).toLowerCase()
                                : 'N/A';

                    return (
                        <div className={styles.transactionRowOuterContainer} key={index}>
                            <div className={styles.transactionRow}>
                                <img className={styles.directionArrow} src={arrowIcon} alt="arrow"/>
                                <span className={styles.txType}>{displayType}</span>

                                <img className={styles.uIcon} src={uIconSvg} alt="USDi icon"/>
                                <div className={styles.amountBox}>
                                    <span
                                        className={styles.amount}>{parseFloat(amount).toLocaleString('en-US', {maximumFractionDigits: 6})}</span>
                                    <span className={styles.usdi}> USDi</span>
                                </div>

                                <span className={styles.to}>To:</span>
                                <span className={styles.toAddress}>{shortenAddress(displayAddress)}</span>

                                <span className={styles.time} >{timeStr}</span>
                                <span
                                    className={
                                        statusText === 'Requested'
                                            ? styles.statusRequested
                                            : styles.statusCompleted
                                    }
                                >
                                  {statusText}
                                </span>
                                {/*// Hidden placeholder if not C2C or canceled*/}
                                <div
                                    className={styles.shareIconContainer}
                                    style={{
                                        visibility: "hidden",
                                    }}
                                >
                                    <img
                                        src={shareLinkImg}
                                        alt="share"
                                        style={{
                                            width: "16px",
                                            height: "16px",
                                        }}
                                    />
                                </div>
                                <img
                                    className={styles.expandIcon}
                                    src={expandSvg}
                                    alt="expand/fold"
                                    style={{visibility: "hidden"}}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default RequestedTransactions;