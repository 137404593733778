import React, {useContext, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import styles from './AddPaymentInputInfo.module.css';
import {useAuth} from "../../AuthContext";
import {AuthContext} from "../../AuthContext";
import {SwapMainAppContext} from "../../SwapMainAppContext";

function AddPaymentInputInfo({
                                 onClose,
                                 onConfirm,
                                 paymentMethod,
                                 onRefetchPaymentMethods
                             }) {
    const {showNotification} = useContext(SwapMainAppContext);

    // (1) Country code: default is EMPTY string
    const [countryCode, setCountryCode] = useState("");

    // Existing states
    const [recipientName, setRecipientName] = useState('');
    const [recipientAddress, setRecipientAddress] = useState('');
    const [bankCountry, setBankCountry] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankAddress, setBankAddress] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [swiftBicCode, setSwiftBicCode] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');

    // (2) Mobile number state
    const [mobileNumber, setMobileNumber] = useState('');

    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [email, setEmail] = useState('');
    const [userEmailAddress, setUserEmailAddress] = useState('');
    const [userWalletAddress, setUserWalletAddress] = useState('');

    const {walletAddress} = useContext(AuthContext);
    const {gatewayUserData} = useAuth();

    useEffect(() => {
        if (gatewayUserData) {
            setUserEmailAddress(gatewayUserData.email);
        }
        if (walletAddress) {
            setUserWalletAddress(walletAddress);
        }
    }, [walletAddress, gatewayUserData]);
    useEffect(() => {
        setUserWalletAddress(localStorage.getItem("walletAddress")||'')
    }, []);

    // Handler: only allow digits, then prepend "+" if there's at least one digit
    const handleCountryCodeChange = (e) => {
        const digits = e.target.value.replace(/\D/g, ""); // 只保留数字
        if (digits.length > 0) {
            setCountryCode("+" + digits.trim());
        } else {
            // 如果用户清空了输入，则保持为空字符串
            setCountryCode("");
        }
    };

    // Handler: only allow digits for the phone number
    const handleMobileNumberChange = (e) => {
        const digits = e.target.value.replace(/\D/g, ""); // 只保留数字
        setMobileNumber(digits.trim());
    };

    const handleConfirm = async () => {
        // 1. 先移除 countryCode 和 mobileNumber 中的所有空格
        onClose();

        const cc = countryCode.replace(/\s+/g, "");
        const mn = mobileNumber.replace(/\s+/g, "");

        // 2. 用一个空格把它们组合在一起，例如：+1 1234
        //    如果 cc 或 mn 为空，会被 .trim() 去掉多余空格
        const combinedMobile = `${cc} ${mn}`.trim();

        // Basic validation based on the paymentMethod:
        switch (paymentMethod) {
            case 'Bank Wire':
                if (!recipientName || !recipientAddress || !bankCountry ||
                    !bankName || !bankAddress || !accountNumber) {
                    showNotification('error', "Please fill in all required fields for Bank Wire.");
                    return;
                }
                break;

            case 'Cash - Paper':
                if (!mn || !deliveryAddress) {
                    showNotification('error', "Please provide Mobile Number and Delivery Address for CASH-Paper.");
                    return;
                }
                break;

            case 'Venmo':
                if (!recipientName || !accountNumber) {
                    showNotification('error', "Please provide Recipient Name and make sure walletAddress is filled for Venmo.");
                    return;
                }
                break;

            case 'Zelle':
                if (!recipientName || (!email && !mn)) {
                    showNotification('error', "Please fill in Recipient Name, Email, and Mobile for Zelle.");
                    return;
                }
                break;

            case 'Alipay':
                if (!recipientName || !mn) {
                    showNotification('error', "Please fill in Recipient Name, Email, and Mobile for Alipay.");
                    return;
                }
                break;

            default:
                showNotification('error', "Unknown payment method.");
                return;
        }

        // Build payload
        const payload = {
            payment_method: paymentMethod,
            email_address: userEmailAddress || null,
            wallet_address: userWalletAddress || null,
            recipient_name: recipientName || null,
            recipient_address: recipientAddress || null,
            bank_country: bankCountry || null,
            bank_name: bankName || null,
            bank_address: bankAddress || null,
            account_number: accountNumber || null,
            swift_bic_code: swiftBicCode || null,
            us_routing_no: routingNumber || null,
            email: email || null,
            mobile_number: combinedMobile || null,
            delivery_address: deliveryAddress || null,
        };

        try {
            console.log("payload account add", payload)
            const response = await fetch(
                'https://max1-funding-arb.uc.r.appspot.com/update_payment_info',
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(payload),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to save. Please try again.');
            }

            if (onRefetchPaymentMethods) {
                showNotification('success', "Your payment information has been successfully updated.");
                onRefetchPaymentMethods();
            }
        } catch (err) {
            showNotification('error', "Failed to save. Please try again.");
        }
    };

    // Render fields based on paymentMethod
    const renderFields = () => {
        switch (paymentMethod) {
            case 'Bank Wire':
                return (
                    <>
                        <label>Recipient name</label>
                        <input
                            type="text"
                            value={recipientName}
                            placeholder="Your recipient name"
                            onChange={(e) => setRecipientName(e.target.value)}
                        />
                        <label>Recipient address</label>
                        <input
                            type="text"
                            value={recipientAddress}
                            placeholder="Your recipient address"
                            onChange={(e) => setRecipientAddress(e.target.value)}
                        />
                        <label>Bank country</label>
                        <input
                            type="text"
                            value={bankCountry}
                            placeholder="Your bank country"
                            onChange={(e) => setBankCountry(e.target.value)}
                        />
                        <label>Bank name</label>
                        <input
                            type="text"
                            value={bankName}
                            placeholder="Your bank name"
                            onChange={(e) => setBankName(e.target.value)}
                        />
                        <label>Bank address</label>
                        <input
                            type="text"
                            value={bankAddress}
                            placeholder="Your bank address"
                            onChange={(e) => setBankAddress(e.target.value)}
                        />
                        <label>Account number</label>
                        <input
                            type="text"
                            value={accountNumber}
                            placeholder="Your account number"
                            onChange={(e) => setAccountNumber(e.target.value)}
                        />
                        <label>SWIFT BIC code</label>
                        <input
                            type="text"
                            value={swiftBicCode}
                            placeholder="SWIFT/BIC (for international wires)"
                            onChange={(e) => setSwiftBicCode(e.target.value)}
                        />
                        <label>Routing number</label>
                        <input
                            type="text"
                            value={routingNumber}
                            placeholder="Routing number (for US wires)"
                            onChange={(e) => setRoutingNumber(e.target.value)}
                        />
                    </>
                );

            case 'Cash - Paper':
                return (
                    <>
                        <label>Mobile number</label>
                        <div className={styles.phoneNumber} style={{display: "flex", gap: "0.5rem"}}>
                            {/* Country code input */}
                            <input
                                type="text"
                                placeholder="+1"
                                className={styles.countryCode}
                                value={countryCode}
                                onChange={handleCountryCodeChange}
                                style={{width: "3rem"}}
                            />
                            {/* Local phone number input */}
                            <input
                                type="text"
                                placeholder="Your mobile number"
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                            />
                        </div>

                        <label>Delivery address</label>
                        <input
                            type="text"
                            value={deliveryAddress}
                            placeholder="Your delivery address"
                            onChange={(e) => setDeliveryAddress(e.target.value)}
                        />
                    </>
                );

            case 'Venmo':
                return (
                    <>
                        <label>Recipient name</label>
                        <input
                            type="text"
                            value={recipientName}
                            placeholder="Your recipient name"
                            onChange={(e) => setRecipientName(e.target.value)}
                        />
                        <label>Account ID</label>
                        <input
                            type="text"
                            value={accountNumber}
                            placeholder="Your Venmo account ID"
                            onChange={(e) => setAccountNumber(e.target.value)}
                        />
                    </>
                );

            case 'Zelle':
                return (
                    <>
                        <label>Recipient name</label>
                        <input
                            type="text"
                            value={recipientName}
                            placeholder="Your recipient name"
                            onChange={(e) => setRecipientName(e.target.value)}
                        />
                        <label>Email</label>
                        <input
                            type="text"
                            value={email}
                            placeholder="Your email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Mobile number</label>
                        <div className={styles.phoneNumber} style={{display: "flex", gap: "0.25rem"}}>
                            <input
                                type="text"
                                placeholder="+1"
                                className={styles.countryCode}
                                value={countryCode}
                                onChange={handleCountryCodeChange}
                                style={{width: "3rem"}}
                            />
                            <input
                                type="text"
                                placeholder="Your mobile number"
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                            />
                        </div>
                    </>
                );

            case 'Alipay':
                return (
                    <>
                        <label>Recipient name(中文姓名)</label>
                        <input
                            type="text"
                            value={recipientName}
                            placeholder="Your recipient name"
                            onChange={(e) => setRecipientName(e.target.value)}
                        />

                        <label>Mobile number</label>
                        <div className={styles.phoneNumber} style={{display: "flex", gap: "0.25rem"}}>
                            <input
                                type="text"
                                placeholder="+86"
                                className={styles.countryCode}
                                value={countryCode}
                                onChange={handleCountryCodeChange}
                                style={{width: "3rem"}}
                            />
                            <input
                                type="text"
                                placeholder="Your mobile number"
                                value={mobileNumber}
                                onChange={handleMobileNumberChange}
                            />
                        </div>
                        <label>Email (Optional)</label>
                        <input
                            type="text"
                            value={email}
                            placeholder="Your email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </>
                );

            default:
                return <div>Invalid Payment Method</div>;
        }
    };

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay}
         onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose();
                }
            }}>
            <div className={styles.modalContainer}>
                <div className={styles.headerRow}>
                    <h2 className={styles.title}>{paymentMethod}</h2>
                    <div className={styles.closeBtn} onClick={onClose}>
                        &times;
                    </div>
                </div>

                <div className={styles.dividerLine}/>

                {/* Render fields dynamically based on paymentMethod */}
                {renderFields()}

                <button className={styles.confirmBtn} onClick={handleConfirm}>
                    Confirm
                </button>
            </div>
        </div>,
    document.getElementById('root')
    );
}

export default AddPaymentInputInfo;