// BlinkingLoader.jsx
import React, { useEffect, useState } from "react";

function TransactionHistoryLoader() {
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 600); // 每 500 毫秒切换背景色一次
    return () => clearInterval(interval);
  }, []);

  // 这里一次性生成 20 条闪烁行
  const loaders = Array.from({ length: 15 }, (_, index) => (
    <div
      key={index}
      style={{
        maxWidth: "1156px",
        height: "60px",
        backgroundColor: isBlinking ? "#2A2A2A" : "#1C1C1C",
        borderRadius: "15px",
        marginBottom: "15px",
        transition: "background-color 0.3s ease",
      }}
    ></div>
  ));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        backgroundColor: "transparent",
      }}
    >
      {loaders}
    </div>
  );
}

export default TransactionHistoryLoader;