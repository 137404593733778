import React, {useState, useEffect} from 'react';
import '../usdi_tables/USDIStats.css';

/**
 * 普通数值，保留最多6位小数；若是整数则不带小数
 */
function formatNumber(value) {
    if (value == null || value === '') return '';
    const num = parseFloat(value);
    if (isNaN(num)) return '';
    let str = num.toFixed(6);
    str = str.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
    return str;
}

/**
 * 比率字段 (collateral_ratio、order_tips_ratio)：
 * 显示时 = value * 100 + '%'
 * 同样最多保留6位小数，如果是整数则不带小数
 */
function formatRatio(value) {
    if (value == null || value === '') return '';
    const num = parseFloat(value);
    if (isNaN(num)) return '';
    const multiplied = num * 100;
    let str = multiplied.toFixed(6);
    str = str.replace(/(\.\d*?)0+$/, '$1').replace(/\.$/, '');
    return str + '%';
}

/**
 * status => {显示文本, 颜色}
 */
function getStatusDisplay(status) {
    if (!status) return {text: '', color: '#15A6F4'};
    switch (status) {
        case 'order_created':
            return {text: 'Order Created', color: '#15A6F4'};
        case 'order_fulfilled':
            return {text: 'USDi Released', color: '#15A6F4'};
        case 'cash_received':
            return {text: 'Cash Delivered', color: '#6CE3C4'};
        default:
            return {text: status, color: '#15A6F4'};
    }
}

const CashC2CTxsHistoryTable = ({data}) => {
    const [currentData, setCurrentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);

    // ========== 排序 + 数据初始化 ==========
    useEffect(() => {
        try {
            const sorted = (data || []).slice().sort((a, b) => {
                const aId = parseFloat(a.txs_id) || 0;
                const bId = parseFloat(b.txs_id) || 0;
                return bId - aId;
            });
            setCurrentData(sorted);
        } catch (err) {
            setError(err);
        }
    }, [data]);

    // 数据变化后重置分页
    useEffect(() => {
        setCurrentPage(1);
    }, [currentData]);

    // ========== 分页 ==========
    const itemsPerPage = 10;
    const totalItems = currentData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = currentData.slice(startIndex, endIndex);

    const getPageNumbers = (curPage, totPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];
        if (totPages <= maxPageButtons) {
            for (let i = 1; i <= totPages; i++) pageNumbers.push(i);
        } else {
            pageNumbers.push(1);
            let left = curPage - 1;
            let right = curPage + 1;
            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }
            if (right < totPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totPages);
        }
        return pageNumbers;
    };

    const pageNumbers = getPageNumbers(currentPage, totalPages);

    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };

    // ========== CSV 下载逻辑 ==========
    // 为导出 CSV 添加新的列
    const csvColumns = [
        {
            header: 'Cash Redeem ID',
            getValue: (row) => row.cash_redeem_id
        },
        {
            header: 'Status',
            getValue: (row) => {
                const {text} = getStatusDisplay(row.status);
                return text;
            }
        },
        {
            header: 'Txs ID',
            getValue: (row) => row.txs_id
        },
        {
            header: 'Cash Delivery Received Time',
            getValue: (row) => row.cash_delivery_received_time
        },
        {
            header: 'C2C Ex Wallet',
            getValue: (row) => row.c2c_ex_wallet
        },
        {
            header: 'Node Operator Wallet',
            getValue: (row) => row.node_operator_wallet
        },
        {
            header: 'Collateral Ratio',
            getValue: (row) => formatRatio(row.collateral_ratio)
        },
        {
            header: 'USDi Released Time',
            getValue: (row) => row.usdi_released_time
        },
        {
            header: 'Kra Rate',
            getValue: (row) => formatNumber(row.kra_rate)
        },
        {
            header: 'Fulfill Deadline',
            getValue: (row) => row.fulfill_deadline
        },
        {
            header: 'Collateral Required',
            getValue: (row) => formatNumber(row.collateral_required)
        },
        {
            header: 'Order Tips Ratio',
            getValue: (row) => formatRatio(row.order_tips_ratio)
        },
        {
            header: 'Order Tips',
            getValue: (row) => formatNumber(row.order_tips)
        },
        {
            header: 'Order Tips Dollar Value',
            getValue: (row) => formatNumber(row.order_tips_dollar_value)
        },

        {
            header: 'Payment Info ID',
            getValue: (row) => row.payment_info_id
        },
        {
            header: 'Fund Send Notified',
            getValue: (row) => row.fund_send_notified
        },
        {
            header: 'Requestor Contact Email',
            getValue: (row) => row.requestor_contact_email
        },
        {
            header: 'Requestor Contact Mobile Number',
            getValue: (row) => row.requestor_contact_mobile_number
        },
        // ===== 新增的 CSV 列 =====
        {
            header: 'Collateral Release Squads Tx Index',
            getValue: (row) => row.collateral_release_squads_transaction_index
        },
        {
            header: 'Collateral Release Squads Tx Status',
            getValue: (row) => row.collateral_release_squads_transaction_status
        }
    ];

    const handleDownloadCSV = () => {
        const headerLine = csvColumns.map((col) => col.header).join(',');

        const dataLines = currentData.map((row) =>
            csvColumns
                .map((col) => {
                    const val = col.getValue(row) ?? '';
                    return `"${val}"`;
                })
                .join(',')
        );

        const csvContent = [headerLine, ...dataLines].join('\n');
        const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'cash_c2c_txs_history.csv');
        link.click();
    };

    // ========== 渲染 ==========
    if (error) {
        return <div>Error in Table: {error.message}</div>;
    }

    return (
        <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <h4 className="pb-2">CASH C2C Txs History</h4>
                <button className="tableDownloadButton" onClick={handleDownloadCSV}>
                    Download CSV
                </button>
            </div>

            <div className="table-responsive noto mt-3" style={{paddingTop: '10px'}}>
                <table className="table table-striped table-bordered">
                    <thead className="thead-dark">
                    <tr>
                        <th>Cash Redeem ID</th>
                        <th>Txs ID</th>
                        <th>Status</th>

                        <th>Cash Delivery Received Time</th>
                        <th>C2C Ex Wallet</th>
                        <th>Node Operator Wallet</th>
                        <th>Collateral Ratio</th>
                        <th>USDi Released Time</th>
                        <th>Kra Rate</th>
                        <th>Fulfill Deadline</th>
                        <th>Collateral Required</th>
                        <th>Order Tips Ratio</th>
                        <th>Order Tips</th>
                        <th>Order Tips Dollar Value</th>
                        <th>Payment Info ID</th>
                        <th>Fund Send Notified</th>
                        <th>Requestor Contact Email</th>
                        <th>Requestor Contact Mobile Number</th>
                        {/* ===== 新增的显示列 ===== */}
                        <th>Collateral Release Squads Tx Index</th>
                        <th>Collateral Release Squads Tx Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentItems.map((row, idx) => {
                        const {text: statusText, color: statusColor} = getStatusDisplay(row.status);
                        return (
                            <tr key={idx}>
                                <td>{row.cash_redeem_id}</td>

                                <td>{row.txs_id}</td>
                                <td style={{color: statusColor}}>{statusText}</td>

                                <td>{row.cash_delivery_received_time}</td>
                                <td>{row.c2c_ex_wallet}</td>
                                <td>{row.node_operator_wallet}</td>
                                <td>{formatRatio(row.collateral_ratio)}</td>
                                <td>{row.usdi_released_time}</td>
                                <td>{formatNumber(row.kra_rate)}</td>
                                <td>{row.fulfill_deadline}</td>
                                <td>{formatNumber(row.collateral_required)}</td>
                                <td>{formatRatio(row.order_tips_ratio)}</td>
                                <td>{formatNumber(row.order_tips)}</td>
                                <td>{formatNumber(row.order_tips_dollar_value)}</td>
                                <td>{row.payment_info_id}</td>
                                <td>{row.fund_send_notified}</td>
                                <td>{row.requestor_contact_email}</td>
                                <td>{row.requestor_contact_mobile_number}</td>
                                {/* ===== 新增的两列 ===== */}
                                <td>{row.collateral_release_squads_transaction_index}</td>
                                <td>{row.collateral_release_squads_transaction_status}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>

            {totalPages > 1 && (
                <div className="pagination-container">
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        id="first-page-button"
                    >
                        First
                    </button>

                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>

                    {pageNumbers.map((p, i) =>
                            p === '...' ? (
                                <span key={i} className="pagination-ellipsis">
                ...
              </span>
                            ) : (
                                <button
                                    key={i}
                                    className={`pagination-button ${p === currentPage ? 'active-page' : ''}`}
                                    onClick={() => handlePageChange(p)}
                                >
                                    {p}
                                </button>
                            )
                    )}

                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                    <button
                        className="pagination-button"
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        id="last-page-button"
                    >
                        Last
                    </button>
                </div>
            )}
        </div>
    );
};

export default CashC2CTxsHistoryTable;