import React, {useEffect, useState} from 'react';
import {digitFormat, moneyFormat, moneyFormat4decimals} from '../utils';
import './USDIStats.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';

const HolderInfoTableETH = (selectedChain) => {

    // ------------------ Existing state ------------------
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [updatingDWS, setUpdatingDWS] = useState(false); // State for updating DWS
    const [calculating, setCalculating] = useState(false);
    const [error, setError] = useState(null);
    const [addressToUserIdMap, setAddressToUserIdMap] = useState({});
    const [historicalData, setHistoricalData] = useState({});
    const [hoveredAddress, setHoveredAddress] = useState(null);

    // ------------------ Endpoints ------------------
    const USDI_API = 'https://usdi-api-jlukzyz7wa-ew.a.run.app';
    const Distribution_USDI = 'https://distribution-calculator-256611876551.europe-west1.run.app';

    const holdersInfoUrl = `${USDI_API}/usdi_holders_info_table`;
    const rawBlockchainTxUrl = `${USDI_API}/raw_blockchain_transactions`;
    const getUserBalanceEndpoint = `${Distribution_USDI}/get_user_balance`;
    const adminTriggerEndpoint = `${Distribution_USDI}/admin-trigger`;
    const userBalanceBlocksUpdateEndpoint = `${Distribution_USDI}/user_balance_blocks_update`;
    const calculationTriggerEndpoint = `${Distribution_USDI}/calculation-trigger`;

    // ------------------ Existing functions ------------------
    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(holdersInfoUrl);
            if (!response.ok) throw new Error('Network response was not ok');
            const result = await response.json();
            setData(result);

            const rawResponse = await fetch(rawBlockchainTxUrl);
            if (!rawResponse.ok) throw new Error('Network response was not ok');
            const rawResult = await rawResponse.json();

            // Build a map of address => numeric userId
            const addressMap = {};
            let userId = 1;
            rawResult.forEach((transaction) => {
                const normalizedAddress = transaction.to_address.toLowerCase().slice(0, 42);
                if (!addressMap[normalizedAddress]) {
                    addressMap[normalizedAddress] = userId++;
                }
            });
            setAddressToUserIdMap(addressMap);

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchHistoricalData = async (address) => {
        try {
            const url = `${getUserBalanceEndpoint}?user_address=${address}`;
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch historical data');
            const result = await response.json();
            console.log("RESULT HERE", result);
            console.log("fetch data from ", getUserBalanceEndpoint, address);

            setHistoricalData((prevData) => ({
                ...prevData,
                [address]: result.data,
            }));
        } catch (error) {
            console.error('Error fetching historical data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        handleUpdate();
        // eslint-disable-next-line
    }, [selectedChain]);

    const handleUpdate = async () => {
        try {
            setUpdating(true);
            const response = await fetch(adminTriggerEndpoint, {
                method: 'POST',
            });
            if (response.ok) {
                fetchData();
            } else {
                throw new Error('Failed to trigger distribution calculation');
            }
        } catch (err) {
            setError(err);
        } finally {
            setUpdating(false);
        }
    };

    const handleUpdateDWS = async () => {
        let start_block = prompt('Enter Start Block Number:');
        let end_block = prompt('Enter End Block Number:');

        start_block = start_block ? start_block.replace(/,/g, '') : '';
        end_block = end_block ? end_block.replace(/,/g, '') : '';

        if (!start_block || !end_block) {
            alert('Both start block and end block numbers are required!');
            return;
        }

        try {
            setUpdatingDWS(true);
            const response = await fetch(userBalanceBlocksUpdateEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start_block: start_block,
                    end_block: end_block,
                }),
            });

            if (response.ok) {
                alert('DWS updated successfully.');
            } else {
                const errorText = await response.text();
                throw new Error(`Error ${response.status}: ${errorText}`);
            }
        } catch (err) {
            setError(err);
            alert(`Error: ${err.message}`);
        } finally {
            setUpdatingDWS(false);
        }
    };


    const handleCalculate = async () => {
        let block_number = prompt('Enter Block Number:');
        let final_distribution_amount = prompt('Enter Final Distribution Amount:');

        block_number = parseInt(block_number.replace(/,/g, ''), 10);
        final_distribution_amount = parseFloat(final_distribution_amount.replace(/,/g, ''));

        if (!block_number || !final_distribution_amount) {
            alert('Both fields are required!');
            return;
        }

        try {
            setCalculating(true);
            const response = await fetch(calculationTriggerEndpoint, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    block_number: Number(block_number),
                    final_distribution_amount: Number(final_distribution_amount),
                }),
            });

            if (response.ok) {
                console.log("called here5");
                fetchData();
            } else {
                throw new Error('Failed to calculate distribution');
            }
        } catch (err) {
            setError(err);
        } finally {
            setCalculating(false);
        }
    };

    // ------------------ PAGINATION: ------------------
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Helper to generate page numbers with "..."
    const getPageNumbers = (curPage, totalPages) => {
        const maxPageButtons = 5;
        const pageNumbers = [];

        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            pageNumbers.push(1);
            let left = curPage - 1;
            let right = curPage + 1;

            if (left > 2) {
                pageNumbers.push('...');
            } else {
                left = 2;
            }

            if (right < totalPages - 1) {
                for (let i = left; i <= right; i++) {
                    pageNumbers.push(i);
                }
                pageNumbers.push('...');
            } else {
                for (let i = left; i <= totalPages - 1; i++) {
                    pageNumbers.push(i);
                }
            }
            pageNumbers.push(totalPages);
        }
        return pageNumbers;
    };

    // ------------------ Render Logic ------------------
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    // Sort by userId
    let sortedData = data
        .map((row) => ({
            ...row,
            userId: addressToUserIdMap[row.address.toLowerCase().slice(0, 42)] || Infinity,
        }))
        .sort((a, b) => a.userId - b.userId);

    // Now do pagination on the sorted data
    const totalItems = sortedData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = sortedData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page < 1) page = 1;
        if (page > totalPages) page = totalPages;
        setCurrentPage(page);
    };

    const pageNumbers = getPageNumbers(currentPage, totalPages);

    // ------------------ Download CSV ------------------
    const handleDownloadCSV = () => {
        const headers = [
            'User Unique ID',
            'Address',
            'Current Block Number',
            'Last Block Number',
            'Status',
            'BNP',
            'Cum DWS',
            'Balance of USDI',
            'DWS',
            'New Cumulative DWS',
            'CumDWS Weight %',
            'User Undistributed USDI',
            'Cumulative Distributed USDI',
            'New Cumulative DWS After Distribution'
        ];

        // Use sortedData for the entire CSV
        const csvRows = sortedData.map((row) => {
            return [
                row.userId !== Infinity ? row.userId : 'N/A',
                row.address,
                row.current_block_number,
                row.last_block_number,
                row.status,
                row.bnp,
                row.cum_dws,
                row.balance_of_usdi,
                row.dws,
                row.new_cum_dws,
                row.cum_dws_weight_percent,
                row.user_undistributed_usdi,
                row.cum_distributed_usdi,
                0.00 // same as table's hard-coded "0.00"
            ].map(field => `"${field ?? ''}"`).join(',');
        });

        const csvString = [headers.join(','), ...csvRows].join('\n');

        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'holder_info_eth.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div className="button_wrapper">
                <h3>USDI Holders Info</h3>
                <button
                    className={`button ${updating ? 'updating' : ''}`}
                    onClick={handleUpdate}
                    disabled={updating}
                >
                    {updating ? 'Updating' : 'Update'}
                </button>
            </div>

            <div className="table-responsive noto">
                <table className="table table-striped table-bordered mt-3">
                    <thead className="thead-dark">
                        <tr>
                            <th>User Unique ID</th>
                            <th>Address</th>
                            <th>Current Block Number</th>
                            <th>Last Block Number</th>
                            <th>Status</th>
                            <th>BNP</th>
                            <th>
                                Cum DWS
                                <FontAwesomeIcon
                                    icon={faSyncAlt}
                                    spin={updatingDWS}
                                    onClick={handleUpdateDWS}
                                    className="update-icon"
                                />
                            </th>
                            <th>Balance of USDI</th>
                            <th>DWS</th>
                            <th>New Cumulative DWS</th>
                            <th>CumDWS Weight %</th>
                            <th>
                                User Undistributed USDI
                                <FontAwesomeIcon
                                    icon={faSyncAlt}
                                    spin={calculating}
                                    onClick={handleCalculate}
                                    className="update-icon"
                                />
                            </th>
                            <th>
                                Cumulative Distributed USDI
                            </th>
                            <th>New Cumulative DWS After Distribution</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((row, index) => (
                            <tr key={index}>
                                <td>{row.userId !== Infinity ? row.userId : 'N/A'}</td>
                                <td>{row.address}</td>
                                <td>{digitFormat(row.current_block_number)}</td>
                                <td>{digitFormat(row.last_block_number)}</td>
                                <td>{row.status}</td>
                                <td>{digitFormat(row.bnp)}</td>
                                <td
                                    onMouseEnter={() => {
                                        setHoveredAddress(row.address);
                                        if (!historicalData[row.address]) {
                                            fetchHistoricalData(row.address);
                                        }
                                    }}
                                    onMouseLeave={() => setHoveredAddress(null)}
                                    style={{ position: 'relative', cursor: 'pointer' }}
                                >
                                    {digitFormat(row.cum_dws)}
                                    {hoveredAddress === row.address && historicalData[row.address] && (
                                        <div className="popup">
                                            <table className="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Block Number</th>
                                                        <th>Cum DWS</th>
                                                        <th>Balance</th>
                                                        <th>DWS</th>
                                                        <th>BNP</th>
                                                        <th>Undistributed USDI</th>
                                                        <th>Cumulative Distributed USDI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {historicalData[row.address].map((histRow, idx) => (
                                                        <tr key={idx}>
                                                            <td>{digitFormat(histRow.block_number)}</td>
                                                            <td>{digitFormat(histRow.cum_dws)}</td>
                                                            <td>{moneyFormat(histRow.balance)}</td>
                                                            <td>{digitFormat(histRow.dws)}</td>
                                                            <td>{digitFormat(histRow.bnp)}</td>
                                                            <td>
                                                                {histRow.undistributed_usdi !== null && histRow.undistributed_usdi !== undefined
                                                                    ? moneyFormat4decimals(histRow.undistributed_usdi)
                                                                    : '--'}
                                                            </td>
                                                            <td>
                                                                {histRow.cum_distributed_usdi !== null && histRow.cum_distributed_usdi !== undefined
                                                                    ? moneyFormat4decimals(histRow.cum_distributed_usdi)
                                                                    : '--'}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </td>
                                <td>{moneyFormat(row.balance_of_usdi)}</td>
                                <td>{digitFormat(row.dws)}</td>
                                <td>{digitFormat(row.new_cum_dws)}</td>
                                <td>{moneyFormat4decimals(row.cum_dws_weight_percent)}%</td>
                                <td>{moneyFormat4decimals(row.user_undistributed_usdi)}</td>
                                <td>{moneyFormat4decimals(row.cum_distributed_usdi)}</td>
                                <td>0.00</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination bar + Download button */}
            {totalPages > 0 && (
                <div className="pagination-outer-container">
                    <div className="pagination-container" style={{ marginTop: "10px" }}>
                        <button
                            className="pagination-button"
                            id="first-page-button"
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage === 1}
                        >
                            First
                        </button>
                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>

                        {pageNumbers.map((p, i) =>
                            p === '...' ? (
                                <span key={i} className="pagination-ellipsis">
                                    ...
                                </span>
                            ) : (
                                <button
                                    key={i}
                                    className={`pagination-button ${p === currentPage ? 'active-page' : ''}`}
                                    onClick={() => handlePageChange(p)}
                                >
                                    {p}
                                </button>
                            )
                        )}

                        <button
                            className="pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                        <button
                            className="pagination-button"
                            id="last-page-button"
                            onClick={() => handlePageChange(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            Last
                        </button>
                    </div>
                    {/* 下载按钮 */}
                    <button className="tableDownloadButton" onClick={handleDownloadCSV}>
                        Download CSV
                    </button>
                </div>
            )}
        </>
    );
};

export default HolderInfoTableETH;