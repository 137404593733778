import React from 'react';
import ReactDOM from 'react-dom';
import styles from '../Account/AddPaymentInputInfo.module.css';
import waitingImg from "../static_files/step 2.svg";
import successImg from "../static_files/copy success.svg";

function AfterSettleFunds({ onClose, mobile_number, email, selectedTx, waitingState }) {
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const hasMobile = Boolean(mobile_number);
    const hasEmail = Boolean(email);
    const hasContactInfo = hasMobile || hasEmail;

    // 1) 根据 waitingState 确定两张图标
    let step1Img = waitingImg;
    let step2Img = waitingImg;
    if (selectedTx.cash_delivery_received_time !== "--" &&selectedTx.collateral_release_squads_transaction_status !== "executed") {
        step1Img = successImg;
        step2Img = waitingImg;
    } else if (selectedTx.collateral_release_squads_transaction_status === "executed") {
        step1Img = successImg;
        step2Img = successImg;
    }

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.modalContainer}>
                {/* Header */}
                <div className={styles.headerRow}>
                    <h2 className={styles.title}>Waiting for Fund Confirmation</h2>
                    <div className={styles.closeBtn} onClick={onClose}>
                        &times;
                    </div>
                </div>

                <div style={{marginBottom: '20px'}} className={styles.dividerLine}/>

                {/* 主要文案 */}
                <p style={{margin: '0', fontSize:'14px'}}>
                    The collateral will be automatically released once the requestor confirms fund receipt.
                </p>

                <div style={{marginBlock: '20px'}} className={styles.dividerLine}/>

                <p style={{marginBottom: '3px', fontSize:'14px'}}>Next Step:</p>

                {/* 2) 第一步：在 greyLine 前面放第一个图标 */}
                <div className={styles.greyLine}>
                    <img
                        src={step1Img}
                        alt=""
                        style={{marginRight:'7px', verticalAlign:'middle', width: "12px", height: "12px"}}
                    />
                    1. The requestor needs to confirm they’ve received the funds
                </div>

                {/* 3) 在两张图之间放一个竖线 */}
                <div
                    style={{
                        width: '1px',
                        height: '12px',
                        backgroundColor: '#777878',
                        margin: '-3.9px 0 -4px 5.73px',
                        zIndex:'999'
                    }}
                />

                {/* 4) 第二步：在 greyLine 前面放第二个图标 */}
                <div className={styles.greyLine}>
                    <img
                        src={step2Img}
                        alt=""
                        style={{marginRight:'7px', verticalAlign:'middle', width: "12px", height: "12px"}}
                    />
                    2. Once confirmed, the system will automatically release the collateral
                </div>

                <div className={styles.greyLine} style={{marginBottom: '0'}}>
                    Consider contacting the requestor to prompt confirmation if there’s a delay.
                </div>

                <div style={{marginBlock: '20px'}} className={styles.dividerLine}/>

                {/* 仅当至少有一个联系方式时，才显示标题和详情 */}
                {hasContactInfo && (
                    <>
                        <p style={{marginBlock: '9px', fontSize:'14px'}}>Requestor Contact:</p>
                        {hasMobile && <p style={{margin: '0', fontSize:'14px'}}>Mobile number&nbsp; &nbsp; {mobile_number}</p>}
                        {hasEmail && <p style={{margin: '0', fontSize:'14px'}}>Email&nbsp; &nbsp; {email}</p>}
                    </>
                )}
            </div>
        </div>,
        document.getElementById('root')
    );
}

export default AfterSettleFunds;