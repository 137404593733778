import React, {useState, useEffect, useRef, useContext} from 'react';
import styles from './EmailSwapMainAPP.module.css';
import LineChart from "../linechart/LineCharts";
import usdiImg from "../static_files/USDi.png";
import usdtImg from "../static_files/usdt 1.svg";
import usdcImg from "../static_files/usdc.svg";
import approxImg from "../static_files/Text.svg";
import {fetchPortfolioTrackRecordsRoundFourDigit} from "../linechart/fetchDailyPortfolioData";
import TransactionDetailModal from "../transaction_detail/TransactionDetail";
/* Normal copy icon & hover version */
import copySelectorImg from "../static_files/copy-selector.svg";
import copySelectorHoverImg from "../static_files/copy-selector-hover.svg";
import copyConfirmImg from "../static_files/checkmark.svg";
import copyCircleImg from "../static_files/copyEmail.svg";
import copyCircleHoverImg from '../static_files/copyEmail Hover.svg'

import downImg from "../static_files/downEmail.svg"
import timeImg from "../static_files/timeEmail.svg"
import verticalLineImg from '../static_files/verticalLine.svg'
import shareSelectorImg from "../static_files/share-selector.svg";
import shareSelectorHoverImg from "../static_files/share-selector-hover.svg";
import addAccountImg from '../static_files/add_wallet.svg';
import addAccountHoverImg from '../static_files/add_wallet_hover.svg';

import addAccountSuccessImg from "../static_files/add_wallet_success.svg"
import CheckImg from '../static_files/AcceptOrder.svg';

import dropDownRightImg from "../static_files/dropdownright.svg"
import dropDownRightHoverImg from "../static_files/dropdownrightHover.svg"

import dropDownImg from "../static_files/dropdown.svg"
import dropDownHoverImg from "../static_files/dropdownHover.svg"
import solanaImg from "../static_files/solanaEmail.svg"
import ethImg from "../static_files/eth.svg"
import BscImg from "../static_files/Binance Smart Chain.svg"
import TronImg from "../static_files/Tron.svg"
import walletCreationImg from "../static_files/walletCreation.svg"

import ConnectWalletHeader from "../header/ConnectWalletHeader";
import LeftPanel from "../SwapLeftPanel/LeftPanel";
import {SwapMainAppContext} from "../../SwapMainAppContext";

import {motion, LayoutGroup} from 'framer-motion';
import {createWallet, shortenAddress, chainNameMap} from '../utils'

import {useAuth} from "../../AuthContext";
import {QRCodeCanvas} from 'qrcode.react';

import {useSearchParams} from 'react-router-dom';
import {Connection, PublicKey} from "@solana/web3.js";
import copyInnerImg from "../static_files/copy inner.svg";
import shareInnerImg from "../static_files/share inner.svg";
import copyInnerHoverImg from "../static_files/copy inner hover.svg";
import shareInnerHoverImg from "../static_files/share inner hover.svg";
import {ENDPOINT_BASE_URL, USDI_API_URL} from "../../configs/config";
import RedeemTab from "../RedeemTab/RedeemTab";
import WireIcon from "../static_files/Wire.svg";
import USDIcon from "../static_files/usd.svg";
import ZelleIcon from "../static_files/Zelle.svg";
import VenmoIcon from "../static_files/Venmo.svg";
import AlipayIcon from "../static_files/Alipay.svg";
import RedeemToModal from "../RedeemTab/RedeemToModal";
import Tooltip from "../Tooltip/Tooltip"

const RPC_URL = "https://solitary-boldest-thunder.solana-mainnet.quiknode.pro/7e14722814e9fc29ad81532c2da66c2ffbe3781f";

const iconMap = {
    'Bank Wire': WireIcon,
    'Cash - Paper': USDIcon,
    'Zelle': ZelleIcon,
    'Venmo': VenmoIcon,
    'Alipay': AlipayIcon,
};
const SwapMainAPP = () => {
    const {gatewayUserData} = useAuth();
    const [usdiValue, setUsdiValue] = useState(null);
    const [usdiHoldersData, setUsdiHoldersData] = useState();
    const [cumulativeInterest, SetCumulativeInterest] = useState(0);
    const [accruedInterest, setAccruedInterest] = useState(0)
    const [holderDataLoading, setHolderDataLoading] = useState(true)

    const [selectedTab, setSelectedTab] = useState('mint'); // "mint" | "redeem"
    const [ImpliedApy, setImpliedApr] = useState(0);
    const [displayedApy, setDisplayedApy] = useState("Implied APY");
    const [displayedAPYTooltip, setDisplayedAPYTooltip] = useState("");

    const [currentUserData, setCurrentUserData] = useState(null);
    const [txTrigger, setTxTrigger] = useState(0);
    const [userRedeemRequestTrigger, setUserRedeemRequestTrigger] = useState(0);
    // Wallet data 连接钱包后的pub key在这
    const [walletAddress, setWalletAddress] = useState(null);
    const [usdtBalance, setUsdtBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);
    const [usdiBalance, setUsdiBalance] = useState("--");
    // use to open connect wallet side panel
    const [showPanel, setShowPanel] = useState(false);

    // Which token is selected ("USDC" or "USDT" or "CASH" from redeem)
    const [selectedToken, setSelectedToken] = useState("USDC");

    //
    const [selectedChain, setSelectedChain] = useState("")
    const [selectedChainTrigger, setSelectedChainTrigger] = useState(0)

    // Show/hide token selector modal
    const [showTokenSelector, setShowTokenSelector] = useState(false);
    const [showTokenSelector2, setShowTokenSelector2] = useState(false);
    const methodItemRef = useRef(null);
    const tokenSelectorRef = useRef(null); // for clicking somewhere else to close it
    const methodItemRef2 = useRef(null);
    const tokenSelectorRef2 = useRef(null); // for clicking somewhere else to close it

    const [showTokenSelector3, setShowTokenSelector3] = useState(false);
    const methodItemRef3 = useRef(null);
    const [paymentInfoData, setPaymentInfoData] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const tokenSelectorRef3 = useRef(null);

    // Single input for both Mint and Redeem tabs
    const [typedMintValue, setTypedMintValue] = useState("0");

    // Final “confirmed” amount to pass to ConnectWalletHeader
    const [confirmedAmount, setConfirmedAmount] = useState("0");
    const [justCopiedUSDC, setJustCopiedUSDC] = useState(false);
    const [justCopiedUSDT, setJustCopiedUSDT] = useState(false);
    // for email login in use
    const [justCopiedWallet, setJustCopiedWallet] = useState(false);

    // Font-size + container ref + hidden measuring span + input ref
    const [dynamicFontSize, setDynamicFontSize] = useState(100);
    const containerRef = useRef(null);  // to get container width
    const spanRef = useRef(null);       // hidden measuring <span>
    const inputRef = useRef(null);      // actual input

    // Min/Max for font
    const MIN_FONT_SIZE = 30;
    const MAX_FONT_SIZE = 100;

    const [isWalletLoading, setIsWalletLoading] = useState(true);

    const {
        showNotification, networkFeePercent,
        usdtTokenAddress,
        usdcTokenAddress,
        usdiTokenAddress,
        squadsPublicKey, // amount > 11000
    } = useContext(SwapMainAppContext);


    // for transaction detail page - > after cash redeem , show modal let user confirm
    const [showTransactionDetailModal, setShowTransactionDetailModal] = useState(false);
    const [redeemValueForModal, setRedeemValueForModal] = useState(0);
    const [localTimeTransaction, setLocalTimeTransaction] = useState(null);
    const [UTCTimeTransaction, setUTCTimeTransaction] = useState(null);
    // for redeem to modal page - > after none cash redeem , show modal let user enter wallet address to redeem to and confirm
    const [showRedeemToModal, setShowRedeemToModal] = useState(false);
    const [redeemToAddress, setRedeemToAddress] = useState("");
    // For create wallet Modal
    const [showCreateWalletModal, setShowCreateWalletModal] = useState(false);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // 储存 user email login的token
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token'); // 比如从 ?token=xxx 里拿到 token
    const [selectedChainAddress, setSelectedChainAddress] = useState(null);

    // 用来发送给 record-redeem transaction
    const [networkFeeAmount, setNetworkFeeAmount] = useState(0);
    const [toAmount, setToAmount] = useState(0);
    const [networkFeePercent1, setNetworkFeePercent1] = useState(0);
    const [eta, setEta] = useState(0);


    const [recentWallets, setRecentWallets] = useState([]);
    const userEmail = localStorage.getItem("userEmail");

    const [refetchPaymentMethods, setRefetchPaymentMethods] = useState(0);

    async function fetchRecentWallets() {
        if (!userEmail) return;  // 如果没有 email，就不用请求
        try {
            const res = await fetch(`${USDI_API_URL}/email_uid_get_recent_wallets`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: userEmail})
            });
            if (!res.ok) {
                console.error("Error fetching recent wallets:", res.status, res.statusText);
                return;
            }
            const data = await res.json();
            // 后端返回 { "recents": ["0x...", "0x..."] }
            if (data.recents) {
                setRecentWallets(data.recents); // 直接存成字符串数组也行
            }
        } catch (err) {
            console.error("Fetch recents error:", err);
        }
    }

    // 3) 在组件挂载（或 userEmail 变化）时调用
    useEffect(() => {
        fetchRecentWallets();
    }, [userEmail]);
    const handleNetworkValuesUpdate = (feeAmount, toAmt, feePercent, eta) => {
        setNetworkFeeAmount(feeAmount);
        setToAmount(toAmt);
        setNetworkFeePercent1(feePercent);
        setEta(eta)
    };


    useEffect(() => {
        function getReceiveAddressByChain(userData) {
            if (selectedChain === 'solana') {
                setSelectedChainAddress(userData?.solana_receive_address);
            } else if (selectedChain === 'ethereum') {
                setSelectedChainAddress(userData?.ethereum_receive_address);
            } else if (selectedChain === 'tron') {
                setSelectedChainAddress(userData?.tron_receive_address);
            } else if (selectedChain === 'binance') {
                setSelectedChainAddress(userData?.bsc_receive_address);
            }
        }

        getReceiveAddressByChain(gatewayUserData)
    }, [selectedChain]);

    useEffect(() => {
        if (token) {
            localStorage.setItem('gatewayToken', token);
            localStorage.setItem('emailLogin', 'true');
        }
    }, [token]);
    useEffect(() => {
        if (selectedTab === "mint") {
            setSelectedToken("USDC")
        } else {
            setSelectedToken("CASH")
        }
    }, [selectedTab]);
    // ------------------------ Utility: generateQRCodeValue ------------------------
    // Handle window resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Customization options
    const qrOptions = {
        bgColor: "#ffffff",
        fgColor: "#2d3436",
        level: "H", // Error correction level (L, M, Q, H)
        includeMargin: false,
        imageSettings: {
            src: selectedToken === "USDT" ? usdtImg : usdcImg,
            excavate: true,
            width: 40,
            height: 40
        }
    };
    const generateQRCodeValue = (chain, token, address) => {
        switch (chain) {
            case 'solana':
                return `solana:${address}?token=${token}`;
            case 'ethereum':
                return `ethereum:${address}?token=${token}`;
            case 'tron':
                return `tron:${address}?token=${token}`;
            case 'binance':
                return `binance:${address}?token=${token}`;
            default:
                return `${chain}:${address}?token=${token}`;
        }
    };
    const qrValue = generateQRCodeValue(selectedChain, selectedToken, walletAddress);

    let qrSize;
    if (windowWidth < 1000) {
        qrSize = 121;
    } else if (windowWidth < 1200) {
        qrSize = 125;
    } else if (windowWidth < 1300) {
        qrSize = 130;
    } else if (windowWidth < 1400) {
        qrSize = 150;
    } else {
        qrSize = 195; // Default for window width >= 1300px
    }
    // ------------------------ FETCH DATA  ------------------------
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Promise.any([
                    fetchPortfolioTrackRecordsRoundFourDigit().catch(e => null),
                ]);
                if (data) {
                    const maxApy = Math.max(data.impliedApr, data.ninetyDayApy, data.thirtyDayApy);
                    setUsdiValue(data.totalValue / data.usdiInCirculation);
                    setImpliedApr(maxApy);
                    setUsdiValue(data.totalValue / data.usdiInCirculation);
                    setImpliedApr(maxApy);
                    let maxApyType = "";
                    let tooltip = "";

                    if (maxApy === data.impliedApr) {
                        maxApyType = "Implied APY";
                        tooltip = <>The estimated annual <br/> percentage yield based<br/> on current rates</>;

                    } else if (maxApy === data.ninetyDayApy) {
                        maxApyType = "90-Day APY";
                        tooltip =
                            <>The annual percentage yield<br/> calculated based on the past<br/> 90 days'
                                performance</>;
                    } else {
                        maxApyType = "30-Day APY";
                        tooltip =
                            <>The annual percentage yield<br/> calculated based on the past<br/> 30 days'
                                performance</>;
                    }

                    setDisplayedApy(maxApyType)
                    setDisplayedAPYTooltip(tooltip);

                } else {
                    console.error("Failed to fetch one or more data endpoints");
                }

                const response = await fetch('https://usdi-api-jlukzyz7wa-ew.a.run.app/usdi_holders_info_table_solana');
                if (!response.ok) throw new Error('Network response was not ok');
                const result = await response.json();
                setUsdiHoldersData(result);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);


    // If we have user data from DB + holders, sum up USDi
    useEffect(() => {
        if (usdiHoldersData && currentUserData) {
            const userAddresses = [
                currentUserData.bsc_receive_address,
                currentUserData.ethereum_receive_address,
                currentUserData.tron_receive_address,
                currentUserData.solana_receive_address
            ].filter(Boolean);
            const uniqueAddresses = [...new Set(userAddresses.map(a => a.toLowerCase()))];
            let totalDistributedUsdi = 0;
            let totalAccruedInterest = 0
            for (const holder of usdiHoldersData) {
                const holderAddress = holder.address.toLowerCase();
                if (uniqueAddresses.includes(holderAddress)) {
                    const DistributedUsdi = parseFloat(holder.cum_distributed_usdi) || 0;
                    totalDistributedUsdi += DistributedUsdi;
                    const Accrued = parseFloat(holder.accrued_interest) || 0;
                    totalAccruedInterest += Accrued;
                }
            }
            SetCumulativeInterest(totalDistributedUsdi);
            setAccruedInterest(totalAccruedInterest)
            setHolderDataLoading(false)

        } else {
            SetCumulativeInterest(0);
            setAccruedInterest(0)
        }
    }, [usdiHoldersData, currentUserData]);

    // get usdi balance for email login user
    useEffect(() => {
        const fetchUSDiBalance = async () => {
            if (!walletAddress) return;

            try {
                const connection = new Connection(RPC_URL, "confirmed");
                const userPubkey = new PublicKey(walletAddress);
                const usdiMintPubkey = new PublicKey("CXbKtuMVWc2LkedJjATZDNwaPSN6vHsuBGqYHUC4BN3B");

                const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
                    userPubkey,
                    {mint: usdiMintPubkey}
                );

                if (tokenAccounts.value.length > 0) {
                    const tokenInfo = tokenAccounts.value[0].account.data.parsed.info.tokenAmount;
                    setUsdiBalance(tokenInfo.uiAmount);
                    setIsWalletLoading(false)
                } else {
                    setUsdiBalance(0);
                    setIsWalletLoading(false)
                }
            } catch (error) {
                console.error("Error fetching USDi balance:", error);
            }
        };
        fetchUSDiBalance();
    }, [walletAddress]);


    // 1) 在 RedeemTab 内部自己 fetchPaymentInfo
    useEffect(() => {
        const fetchPaymentInfo = async () => {

            try {
                let paramKey, paraValue;
                let extraParam = ""; // Will hold the extra parameter when using wallet login
                if (userEmail) {
                    // 邮箱登录
                    paramKey = "email_address";
                    paraValue = userEmail;
                }

                const url = `${ENDPOINT_BASE_URL}/get_payment_info?${paramKey}=${paraValue}${extraParam}`;
                const res = await fetch(url);

                if (!res.ok) {
                    console.error(`Error fetching payment info: ${res.status} ${res.statusText}`);
                    setPaymentInfoData([]);
                    return;
                }

                const jsonData = await res.json();
                if (!jsonData.success) {
                    console.error("API returned success=false:", jsonData);
                    setPaymentInfoData([]);
                    return;
                }

                const arr = jsonData.data || [];
                setPaymentInfoData(arr);

                // 若有 default_payment === true
                const defaultRow = arr.find(item => item.default_payment === true);
                if (defaultRow) {
                    setSelectedPaymentMethod(defaultRow);
                }
            } catch (err) {
                console.error("Error fetching payment info:", err);
                setPaymentInfoData([]);
            }
        };

        fetchPaymentInfo();
    }, [refetchPaymentMethods]);


    useEffect(() => {
        if (currentUserData) {
            switch (selectedChain) {
                // for email user using transaction c2c .. use
                // localStorage.setItem('emailUserWalletAddress', currentUserData.xx);
                case "solana":
                    setWalletAddress(currentUserData.solana_receive_address);
                    // setIsWalletLoading(false)
                    localStorage.setItem('emailUserWalletAddress', currentUserData.solana_receive_address);
                    setSelectedChainTrigger((prev) => prev + 1); // 发送给header, 改变钱包地址
                    break;
                case "ethereum":
                    setWalletAddress(currentUserData.ethereum_receive_address);
                    // setIsWalletLoading(false)
                    localStorage.setItem('emailUserWalletAddress', currentUserData.ethereum_receive_address);
                    setSelectedChainTrigger((prev) => prev + 1); // 发送给header, 改变钱包地址
                    break;
                case "tron":
                    setWalletAddress(currentUserData.tron_receive_address);
                    // setIsWalletLoading(false)
                    localStorage.setItem('emailUserWalletAddress', currentUserData.tron_receive_address);
                    setSelectedChainTrigger((prev) => prev + 1); // 发送给header, 改变钱包地址
                    break;
                case "binance":
                    setWalletAddress(currentUserData.bsc_receive_address);
                    // setIsWalletLoading(false)
                    localStorage.setItem('emailUserWalletAddress', currentUserData.bsc_receive_address);
                    setSelectedChainTrigger((prev) => prev + 1); // 发送给header, 改变钱包地址
                    break;
                default:
                    setWalletAddress(currentUserData.solana_receive_address);
                    // setIsWalletLoading(false)
                    // for email user using transaction c2c .. use
                    localStorage.setItem('emailUserWalletAddress', currentUserData.solana_receive_address);
                    setSelectedChainTrigger((prev) => prev + 1); // 发送给header, 改变钱包地址

            }
            // 没有wallet address，不显示余额
            const tempAddress = localStorage.getItem("emailUserWalletAddress")
            console.log("tempAddress", tempAddress, selectedChain)

            // ToDo 目前selected chain = eth 先不做
            if (tempAddress === "null" || tempAddress === null || tempAddress === "requested" || selectedChain !== 'solana') {
                setIsWalletLoading(false)
                setUsdiBalance(0)
            }
        }

    }, [selectedChain, currentUserData]);
    useEffect(() => {
        setCurrentUserData(gatewayUserData)
    }, [gatewayUserData]);
    // 用户选择了链之后刷新回来会保存
    useEffect(() => {
        if (selectedChain) {
            localStorage.setItem("emailUserSelectedChain", selectedChain)
        }
    }, [selectedChain]);
    useEffect(() => {
        if (localStorage.getItem("emailUserSelectedChain")) {
            setSelectedChain(localStorage.getItem("emailUserSelectedChain"))
        } else {
            setSelectedChain("solana")
        }
    }, []);
    useEffect(() => {

        function handleClickOutside(e) {
            if (!showTokenSelector) return;  // If dropdown not open, do nothing
            if (
                methodItemRef.current?.contains(e.target) ||
                tokenSelectorRef.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector(false);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTokenSelector]);
    useEffect(() => {
        function handleClickOutside2(e) {

            if (!showTokenSelector2) return;  // If dropdown not open, do nothing
            if (
                methodItemRef2.current?.contains(e.target) ||
                tokenSelectorRef2.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector2(false);
        }

        document.addEventListener('mousedown', handleClickOutside2);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside2);
        };
    }, [showTokenSelector2]);
    useEffect(() => {
        function handleClickOutside3(e) {
            if (!showTokenSelector3) return;
            if (
                methodItemRef3.current?.contains(e.target) ||
                tokenSelectorRef3.current?.contains(e.target)
            ) {
                return;
            }
            setShowTokenSelector3(false);
        }

        document.addEventListener('mousedown', handleClickOutside3);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside3);
        };
    }, [showTokenSelector3]);
    // ------------------------ MINT & REDEEM ------------------------

    // Email 用户只需要发送request到后台，不用真正交易
    const handleRedeemUSDi = () => {
        const redeemValueAsNumber = parseFloat(typedMintValue);
        if (isNaN(redeemValueAsNumber) || redeemValueAsNumber <= 0) {
            showNotification('error', "Please enter a valid amount to mint.");
            return;
        }
        if (selectedToken === "CASH" && !selectedPaymentMethod) {
            showNotification('warning', "Please select a payment method.");
            return;
        }
        // TODO change it back
        // if (selectedToken === "CASH" && confirmedAmount < 100) {
        //     showNotification('warning', `Minimum for cash redemption: 100 USDi`);
        //     return
        // }
        if (selectedToken === "CASH") {
            getLocalAndUTCTime();
            setRedeemValueForModal(redeemValueAsNumber);
            setShowTransactionDetailModal(true);
        } else {
            getLocalAndUTCTime();
            setRedeemValueForModal(redeemValueAsNumber);
            setShowRedeemToModal(true);
        }
        // setConfirmedAmount(redeemValueAsNumber);
        // setUserRedeemRequestTrigger((prev) => prev + 1);

    };


    useEffect(() => {

        const handleUserSendRedeemRequest = async () => {
            const time = getLocalAndUTCTime()
            let payment_method_id;
            let network_fees;
            let percentage;
            let redeem_amount_after_fees;
            let sent_eta;
            const send_email = localStorage.getItem("userEmail")
            if (!send_email) {
                showNotification("error", "Redeem Failed, please log in again and try again")
                return
            }
            if (selectedToken === "CASH") {
                if (!selectedPaymentMethod) {
                    return
                }
                payment_method_id = selectedPaymentMethod.payment_info_id
                network_fees = networkFeeAmount
                percentage = networkFeePercent1
                redeem_amount_after_fees = toAmount
                sent_eta = eta
            } else {
                payment_method_id = ""
                network_fees = ""
                percentage = ""
                redeem_amount_after_fees = ""
                sent_eta = ""
            }
            // 2. Prepare request body
            const requestData = {
                requested_timestamp: time || "",
                txs_type: "redeem",
                from_token_address: "usdi",
                from_token_amount: confirmedAmount || "",
                to_token_address: selectedToken.toLowerCase() || "",
                from_address:
                    walletAddress || localStorage.getItem("emailUserWalletAddress") || "",
                to_address: redeemToAddress,
                method: selectedToken.toLowerCase() || "",
                chain: localStorage.getItem("emailUserSelectedChain") || "",
                fees_percent: percentage,
                network_fees: network_fees,
                redeem_amount_after_fees: redeem_amount_after_fees,
                email: localStorage.getItem("userEmail") || "",
                payment_info_id: payment_method_id,
                eta: sent_eta
            };

            // 3. Adjust network & ETA logic based on method
            if (selectedPaymentMethod === "cash") {
                requestData.network = "c2c";
            } else {
                requestData.network = "blockchain";
            }
            try {
                const res = await fetch(
                    "https://usdi-api-256611876551.europe-west1.run.app/record_email_uid_txs_requests",
                    {
                        method: "POST",
                        headers: {"Content-Type": "application/json"},
                        body: JSON.stringify(requestData)
                    }
                );

                if (!res.ok) {
                    throw new Error(`Server responded with status ${res.status}`);
                }


                showNotification("success", "Your redeem request was processed successfully.");

                // record cash 之后设置选择的payment method 设置为默认支付方式
                const response2 = await fetch("https://max1-funding-arb.uc.r.appspot.com/set_default_payment", {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({
                        email_address: send_email,
                        payment_info_id: payment_method_id,
                    })
                });


            } catch (error) {
                console.error("Redeem error:", error);
                showNotification(
                    "error",
                    "An error occurred while processing your redeem request."
                );
            }
        };
        if (confirmedAmount !== "0") { // not sure 会不会误触发

            handleUserSendRedeemRequest()
        }
    }, [userRedeemRequestTrigger])

    // ------------------------ Show/hide token selector ------------------------
    const handleMethodItemClick = (e) => {
        e.stopPropagation();
        setShowTokenSelector(prev => !prev);
    };
    const handleMethodItemClick2 = (e) => {
        e.stopPropagation();
        setShowTokenSelector2(prev => !prev);
    };
    const handleMethodItemClick3 = (e) => {
        e.stopPropagation();
        setShowTokenSelector3(prev => !prev);
    };

    function getPaymentInfoDisplay(item) {
        switch (item.payment_method) {
            case 'Zelle':
                return item.email || item.mobile_number || 'No info';
            case 'Venmo':
                return item.account_number || 'No account number';
            case 'Alipay':
            case 'Cash - Paper':
                return item.mobile_number || 'No mobile';
            case 'Bank Wire':
                return item.account_number || 'No account';
            default:
                return 'No data';
        }
    }

    // 用户选中某条 payment
    const handleSelectPaymentMethod = (info) => {
        setSelectedPaymentMethod(info);
        setShowTokenSelector3(false);
    };

    useEffect(() => {
        if (showTokenSelector) {
            setShowTokenSelector3(false);
        }
    }, [showTokenSelector]);

    useEffect(() => {
        if (showTokenSelector3) {
            setShowTokenSelector(false);
        }
    }, [showTokenSelector3]);


    const handleCreateWalletClick = (manualSelectedChain = selectedChain) => {
        // Show the modal
        setShowCreateWalletModal(true);
        createWallet(currentUserData, manualSelectedChain);

    };

    const handleCloseModal = () => {
        setShowCreateWalletModal(false);
        const token = localStorage.getItem('gatewayToken');
        if (token) {
            window.location.href = `/swap?token=${token}`;
        } else {
            window.location.href = '/';
        }

    };

    function getChainDisplayName(chain) {
        return chainNameMap[chain] || chain;
    }

    // ------------------------ COPY FUNCTION ------------------------
    const handleCopy = (e, address, token) => {
        e.stopPropagation();
        navigator.clipboard.writeText(address).then(() => {
            if (token === "USDC") {
                setJustCopiedUSDC(true);
                setTimeout(() => setJustCopiedUSDC(false), 2000);
            } else if (token === "USDT") {
                setJustCopiedUSDT(true);
                setTimeout(() => setJustCopiedUSDT(false), 2000);
            } else {
                setJustCopiedWallet(true);
                setTimeout(() => setJustCopiedWallet(false), 2000);
            }
        });
    };
    const [justCopied, setJustCopied] = useState(false);
    const handleCopyUSDI = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(usdiTokenAddress).then(() => {
            setJustCopied(true);
            setTimeout(() => setJustCopied(false), 2000);
        });
    };

    // ------------------------ SHARE FUNCTION ------------------------
    const handleShare = (e, address, chain = "solana") => {
        e.stopPropagation();
        let url;
        if (chain === "solana") {
            url = `https://solscan.io/token/${address}`;
        } else if (chain === "ethereum") {
            url = `https://etherscan.io/token/${address}`;
        } else if (chain === "tron") {
            url = `https://tronscan.org/#/token/${address}`;
        } else if (chain === "binance") {
            url = `https://bscscan.com/token/${address}`;
        } else {
            // fallback if an unknown chain is provided
            url = `https://solscan.io/token/${address}`;
        }
        window.open(url, "_blank");
    };

    // ------------------------ SELECT TOKEN LOGIC ------------------------
    const handleSelectToken = (token) => {
        if (!justCopiedUSDC && !justCopiedUSDT) {
            setSelectedToken(token);
            setShowTokenSelector(false);
        }
    };
    const handleSelectChain = (chain) => {
        if (!justCopiedUSDC && !justCopiedUSDT) {
            setSelectedChain(chain);
            setShowTokenSelector2(false);
        }
    };

    // ------------------------ “Max” button & validation ------------------------
    const handleSetMax = () => {
        if (selectedTab === 'mint') {
            if (selectedToken === 'USDT') {
                setTypedMintValue(String(usdtBalance));
            } else {
                setTypedMintValue(String(usdcBalance));
            }
        } else if (selectedTab === 'redeem') {
            setTypedMintValue(String(usdiBalance));
        }
    };

    // ------------------------ Handle input changes (decimal limit) ------------------------
    const handleInputChange = (e) => {
        let val = e.target.value;
        val = val.replace(/[^\d.]/g, '');
        if (val === '.0') {
            val = '0.'; // specifically handle ".0"
        } else if (val.startsWith('.') && val !== '.') {
            val = '0' + val;
        }
        const firstDotIndex = val.indexOf('.');
        if (firstDotIndex !== -1) {
            val =
                val.slice(0, firstDotIndex + 1) +
                val.slice(firstDotIndex + 1).replace(/\./g, '');
        }
        const dotIndex = val.indexOf('.');
        if (dotIndex !== -1) {
            const fractional = val.slice(dotIndex + 1, dotIndex + 1 + 6);
            val = val.slice(0, dotIndex + 1) + fractional;
        }

        if (val === '.') {
            setTypedMintValue('0.');
            return;
        }

        if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
            val = val.replace(/^0+/, '');
            if (val === '') {
                val = '0';
            }
        }

        if (!val.endsWith('.')) {
            const numericVal = parseFloat(val);
            if (!isNaN(numericVal)) {
                let maxVal = 0;
                if (selectedTab === 'mint') {
                    maxVal = selectedToken === 'USDT' ? usdtBalance : usdcBalance;
                } else if (selectedTab === 'redeem') {
                    maxVal = usdiBalance;
                }

                if (numericVal > maxVal) {
                    val = maxVal.toFixed(6).replace(/\.?0+$/, '');
                }
            } else {
                val = '0';
            }
        }
        setTypedMintValue(val);
    };
    // for pass in to connect wallet header to open connect wallet side panel
    const handleConnectWallet = () => {
        setShowPanel(true);
    };

    /// For transaction Time
    function getLocalAndUTCTime() {
        const now = new Date();  // Current moment in time
        // --- Local Time ---
        const localYear = now.getFullYear();
        const localMonth = String(now.getMonth() + 1).padStart(2, '0');
        const localDay = String(now.getDate()).padStart(2, '0');

        const localHours = String(now.getHours()).padStart(2, '0');
        const localMinutes = String(now.getMinutes()).padStart(2, '0');
        const localSeconds = String(now.getSeconds()).padStart(2, '0');
        const localTime = `${localMonth}/${localDay}/${localYear}, ${localHours}:${localMinutes}:${localSeconds}`;

        // --- UTC Time (same moment) ---
        const utcYear = now.getUTCFullYear();
        const utcMonth = String(now.getUTCMonth() + 1).padStart(2, '0');
        const utcDay = String(now.getUTCDate()).padStart(2, '0');

        const utcHours = String(now.getUTCHours()).padStart(2, '0');
        const utcMinutes = String(now.getUTCMinutes()).padStart(2, '0');
        const utcSeconds = String(now.getUTCSeconds()).padStart(2, '0');
        const utcTime = `${utcMonth}/${utcDay}/${utcYear}, ${utcHours}:${utcMinutes}:${utcSeconds}`;

        // Return as an array or object
        setLocalTimeTransaction(localTime)
        setUTCTimeTransaction(utcTime)
        return utcTime
    }


    // ------------------------ Force re-mount with new font size via key ------------------------
    // Called each time AutosizeInput calculates a width
    // ------------------------ UseEffect to measure text & adjust font size & input width ------------------------
    useEffect(() => {
        if (!containerRef.current || !spanRef.current || !inputRef.current) return;

        // 1) Mirror the input's text
        spanRef.current.textContent = typedMintValue === '' ? '0' : typedMintValue;

        // 2) Match the input's exact font styles so measurement is accurate
        //    (Assuming you have fontSize in dynamicFontSize, but also replicate font-family, weight, etc.)
        spanRef.current.style.fontSize = `${dynamicFontSize}px`;
        spanRef.current.style.fontFamily = getComputedStyle(inputRef.current).fontFamily;
        spanRef.current.style.fontWeight = getComputedStyle(inputRef.current).fontWeight;
        spanRef.current.style.lineHeight = getComputedStyle(inputRef.current).lineHeight;
        spanRef.current.style.letterSpacing = getComputedStyle(inputRef.current).letterSpacing;

        // 3) Use getBoundingClientRect() for a more reliable measurement
        const rect = spanRef.current.getBoundingClientRect();
        spanRef.current.textContent = "USDi"
        const rect_usdi = spanRef.current.getBoundingClientRect();
        const usdi_width = rect_usdi.width
        // added for comma for 20
        let textWidth
        if (parseFloat(typedMintValue) >= 1000) {
            textWidth = rect.width + 20;
        } else if (parseFloat(typedMintValue) >= 1000000) {
            textWidth = rect.width + 40;
        } else {
            textWidth = rect.width
        }
        const containerWidth = containerRef.current.offsetWidth - usdi_width - 30 - 58 - 40;
        // Subtract any side icons/padding.
        // (Don't change your existing logic here, just an example.)

        // 4) If text is too wide, shrink font
        if (textWidth > containerWidth && dynamicFontSize > MIN_FONT_SIZE) {
            const ratio = containerWidth / textWidth;
            let newFont = Math.floor(dynamicFontSize * ratio);
            if (newFont < MIN_FONT_SIZE) newFont = MIN_FONT_SIZE;
            setDynamicFontSize(newFont);
            return;
        }

        // 5) If text is small, optionally grow
        if (textWidth < containerWidth * 0.8 && dynamicFontSize < MAX_FONT_SIZE) {
            const newFont = Math.min(MAX_FONT_SIZE, dynamicFontSize + 2);
            setDynamicFontSize(newFont);
            return;
        }

        // 6) Finally, set the input's width to the measured textWidth + buffer
        inputRef.current.style.width = `${textWidth}px`;
    }, [typedMintValue, dynamicFontSize, selectedTab]);


    // ------------------------ RENDER ------------------------
    return (
        <LayoutGroup>
            <div
                className={styles.outerContainer}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: '#04070D',
                    height: '150%',
                    width: '100vw'
                }}
            >
                {/*for  cash redeem confirm modal*/}
                {showTransactionDetailModal && (
                    <TransactionDetailModal
                        fromAmount={redeemValueForModal}
                        fromCurrency="USDi"
                        toCurrency="USD Cash"
                        transactionType="Redeem"
                        network="C2C"
                        networkFeePercent={selectedPaymentMethod.fees}
                        rate="1 USDi = 1 USD"
                        eta={selectedPaymentMethod.processing_time}
                        notes="Note: First, pay the network fee to initiate the transaction. Then, pay the remaining amount upon delivery."
                        localTime={localTimeTransaction}
                        // When user confirms, finish the redeem:
                        onConfirm={() => {
                            setConfirmedAmount(redeemValueForModal);
                            // setTxTrigger((prev) => prev + 1);
                            setUserRedeemRequestTrigger((prev) => prev + 1);
                            setTypedMintValue("0");
                            setShowTransactionDetailModal(false);
                        }}
                        onClose={() => setShowTransactionDetailModal(false)}
                        onNetworkValuesUpdate={handleNetworkValuesUpdate} // 用来给 redeem_request
                    />
                )}
                {/*for non cash redeem confirm modal*/}
                {showRedeemToModal && (
                    <RedeemToModal
                        localTime={localTimeTransaction}
                        redeemAmount={redeemValueForModal}
                        selectedToken={selectedToken}
                        recentWallets={recentWallets}
                        onClose={() => setShowRedeemToModal(false)}
                        onConfirm={({network, walletAddress}) => {
                            setConfirmedAmount(redeemValueForModal);
                            setUserRedeemRequestTrigger((prev) => prev + 1);
                            setTypedMintValue("0");
                            setShowRedeemToModal(false);
                            setRedeemToAddress(walletAddress) // 子传来的enter的地址

                        }}
                    />
                )}
                {/* LEFT PANEL */}
                <LeftPanel/>

                {/* RIGHT PANEL */}
                <div className={styles.rightPanel} style={{display: "flex", flexDirection: "column"}}>
                    <ConnectWalletHeader
                        amount={confirmedAmount}
                        transactionType={selectedTab}
                        transactionTrigger={txTrigger}
                        toAddress={squadsPublicKey} // amount > 11000
                        selectedToken={selectedToken}
                        onWalletConnected={(publicKey, usdtLeft, usdcLeft, usdiLeft) => {
                            // setWalletAddress(publicKey);
                            setUsdtBalance(usdtLeft);
                            setUsdcBalance(usdcLeft);
                            setUsdiBalance(usdiLeft);
                        }}
                        emailWallet={walletAddress}
                        transactionTime={UTCTimeTransaction}
                        showPanel={showPanel}
                        setShowPanel={setShowPanel}
                        selectedChainTrigger={selectedChainTrigger} // used when user select chain, the header will change the wallet address
                    />

                    <div className={styles.container}>
                        {/* LEFT CONTAINER */}
                        <div className={styles.leftContainer}>
                            <div className={styles.balanceSection}>
                                <div className={styles.chartWrapper}>
                                    <LineChart
                                        chartBgColor="#1c1c1c"
                                        maxWidth="481px"
                                        availableUSDI={usdiBalance}
                                        usdiValue={usdiValue}
                                        isLoading={isWalletLoading}
                                    />
                                </div>

                                <div className={styles.usdiInfo}>
                                    <div className={styles.usdiHeader}>
                                        <div className={styles.usdiItemLeft}>
                                            <img className={styles.usdiImage} src={usdiImg} alt="USDi"/>
                                            <p>USDi</p>
                                            <span>
                                                            {justCopied ? (
                                                                <img
                                                                    className={styles.copyImg}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        marginLeft: '8px',

                                                                        marginRight: '2.856px',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.copyImg}
                                                                    src={copyInnerImg}
                                                                    alt="copy img"
                                                                    style={{
                                                                        marginLeft: '8px',
                                                                        marginRight: '4px',
                                                                        cursor: "pointer",
                                                                        width: "12.356px",
                                                                        height: "15.451px"
                                                                    }}
                                                                    onClick={(e) => handleCopyUSDI(e, usdiTokenAddress)}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copyInnerHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copyInnerImg)
                                                                    }
                                                                />)}
                                                        </span>
                                            <span>
                                                            <img
                                                                className={styles.copyImg}

                                                                src={shareInnerImg}
                                                                alt="share img"
                                                                onClick={(e) => handleShare(e, usdiTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareInnerHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareInnerImg)
                                                                }
                                                            />
                                                        </span>
                                        </div>
                                        <div className={styles.innerUsdiApy}>
                                            <div className={styles.usdiBox}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{marginBottom: '25px'}}
                                                >
                                                    Balance
                                                </div>
                                                <div className={styles.usdiValue2}>
                                                    {isWalletLoading ? <span style={{
                                                            height: "22px",
                                                            marginTop: "0px",
                                                            width: "40px"
                                                        }} className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || usdiBalance === 0
                                                            ? '--' : usdiBalance.toLocaleString('en-US', {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2
                                                            })}

                                                </div>
                                                <div style={{background: '#1c1c1c'}}>
                                                    {isWalletLoading ? <span style={{
                                                            height: "12px",
                                                            borderRadius: '2px',
                                                            width: "35px"
                                                        }} className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || usdiBalance === 0
                                                            ? '--' :
                                                            !(usdiBalance && usdiValue) ? <span style={{
                                                                height: "12px",
                                                                borderRadius: '2px',
                                                                width: "35px"
                                                            }} className={styles.blinkingPlaceholder}/> : (
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'end',
                                                                        background: '#1c1c1c'
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{background: '#1c1c1c'}}
                                                                        src={approxImg}
                                                                        alt="≈"
                                                                    />
                                                                    <span className={styles.usdiValue}>
                                                                {`$${(usdiBalance * usdiValue).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}`}
                                                            </span>
                                                                </div>
                                                            )}
                                                </div>
                                            </div>
                                            <div className={styles.usdiItemRight}>
                                                <div
                                                    className={styles.usdiAPYtitle}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "right",
                                                        cursor: 'pointer', borderBottom: '1.4px dotted #797979'
                                                    }}
                                                ><Tooltip text={displayedAPYTooltip}>
                                                    {displayedApy}
                                                </Tooltip>
                                                </div>
                                                <h5>
                                                    {ImpliedApy !== undefined && ImpliedApy !== null && !isNaN(ImpliedApy) && ImpliedApy !== 0
                                                        ? `${Number(ImpliedApy).toFixed(2)}%`
                                                        : <span className={styles.blinkingPlaceholder}/>}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.lineSeparator}></div>
                                    <div className={styles.usdiStats}>
                                        <Tooltip
                                            text={<>The current value of one USDi <br/> unit based on total assets
                                            </>}>
                                            <div style={{cursor: 'pointer'}} className={styles.usdiDataWrapper}>
                                                <h4>
                                                    {usdiValue
                                                        ? `${parseFloat(usdiValue).toFixed(2)} `
                                                        : <span className={styles.blinkingPlaceholder}/>
                                                    }
                                                </h4>
                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>NAV per USDi</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>NAV per </span>
                                                        <span className={styles.leftBottomStats}>USDi</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={
                                            <>The total interest earned <br/> and paid out to date </>}>
                                            <div style={{cursor: 'pointer'}}
                                                 className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper2}`}>
                                                <h4>
                                                    {isWalletLoading ? <span className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || cumulativeInterest === 0
                                                            ? '--' : <>
                                                                {parseFloat(cumulativeInterest).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}
                                                                <span className={styles.usdiLabel}> USDi</span>
                                                            </>}
                                                </h4>
                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>Cumulative Interest</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>Cumulative </span>
                                                        <span className={styles.leftBottomStats}>Interest</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                        <Tooltip text={
                                            <>Interest earned but not yet <br/> distributed or reduced by fees </>}>
                                            <div style={{cursor: 'pointer'}}
                                                 className={`${styles.usdiDataWrapper} ${styles.usdiDataWrapper3}`}>
                                                <h4>
                                                    {isWalletLoading || holderDataLoading ?
                                                        <span className={styles.blinkingPlaceholder}/> :
                                                        !walletAddress || accruedInterest === 0
                                                            ? '--' : !(accruedInterest) ?
                                                                <span className={styles.blinkingPlaceholder}/> :
                                                                `${(accruedInterest).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                    minimumFractionDigits: 2
                                                                })}`}<span className={styles.usdiLabel}> USDi</span>
                                                </h4>
                                                {windowWidth > 1379 || 561 < windowWidth < 1000 ? (
                                                    <span className={styles.leftBottomStats}>Accrued Interest</span>
                                                ) : (
                                                    <>
                                                        <span className={styles.leftBottomStats}>Accrued </span>
                                                        <span className={styles.leftBottomStats}>Interest</span>
                                                    </>
                                                )}
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* RIGHT CONTAINER */}
                        <div className={styles.rightContainer}>

                            <div className={styles.tabs}>
                                <button
                                    className="relative"
                                    onClick={() => {
                                        if (selectedTab === 'redeem') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('mint');
                                    }}
                                >
                                    {selectedTab === 'mint' && (
                                        // <motion.div layoutId="active-pill" className={styles.tapPill}    />)}
                                        <motion.div layoutId="active-pill" className={styles.tapPill}
                                                    transition={{type: "spring", duration: 0.6}}/>)}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'mint' ? styles.nonSelectTabText : ''}`}
                                    > Mint</span>
                                </button>
                                <button
                                    className='relative'
                                    onClick={() => {
                                        if (selectedTab === 'mint') {
                                            setTypedMintValue("0");
                                        }
                                        setSelectedTab('redeem');
                                    }}
                                >
                                    {selectedTab === 'redeem' && (
                                        // <motion.div layoutId="active-pill" className={styles.tapPill}    />)}
                                        <motion.div layoutId="active-pill" className={styles.tapPill}
                                                    transition={{type: "spring", duration: 0.6}}/>)}
                                    <span
                                        className={`${styles.tabText} ${selectedTab !== 'redeem' ? styles.nonSelectTabText : ''}`}
                                    >
                                      Redeem
                                    </span>
                                </button>

                            </div>


                            {/* -------- MINT TAB -------- */}
                            {selectedTab === 'mint' && (<div
                                className={styles.mintContainer}
                                style={{height: !walletAddress ? '487px' : '583px'}}
                            >
                                {/* -------- MINT TAB First Selector -------- */}
                                <div
                                    className={styles.methodItem2}
                                    onClick={handleMethodItemClick}
                                    ref={methodItemRef}
                                >
                                    <div
                                        // ref={methodItemRef}
                                        // onClick={handleMethodItemClick}
                                        className={styles.withDrawItemLeft}
                                    >
                                        {selectedToken === "USDT" ? (
                                            <img
                                                // ref={methodItemRef}
                                                // onClick={handleMethodItemClick}
                                                className={styles.withdrawImg2}
                                                src={usdtImg}
                                                alt="usdtImg"
                                            />
                                        ) : selectedToken === "USDC" ? (
                                            <img
                                                // ref={methodItemRef}
                                                // onClick={handleMethodItemClick}
                                                className={styles.withdrawImg2}
                                                src={usdcImg}
                                                alt="usdcImg"
                                            />
                                        ) : null}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-around",
                                                background: "#1c1c1c"
                                            }}
                                            // onClick={handleMethodItemClick}
                                            // ref={methodItemRef}
                                        >
                                            <h4>Mint with {selectedToken}</h4>


                                            {walletAddress ? <h5>{shortenAddress(walletAddress)}</h5> :
                                                <h5> &nbsp;</h5>
                                            }
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div
                                            className={styles.withDrawItemRight}
                                        >
                                            <h5>
                                                1 day
                                            </h5>

                                            <h5
                                                // onClick={handleMethodItemClick}
                                                // ref={methodItemRef}
                                            >
                                                {selectedToken === "CASH" ? (
                                                    <h5><span
                                                        className={styles.payWithValue}
                                                    >
                                                        {Number(
                                                            (
                                                                Math.floor(parseFloat(typedMintValue) * 0.98 * 100) / 100
                                                            ).toFixed(2)
                                                        )}
                                                    </span>
                                                        {' '} USD</h5>
                                                ) : (
                                                    <h5> <span
                                                        className={styles.payWithValue}
                                                    >
                                                            {typedMintValue.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                    </span>
                                                        {' '}{selectedToken}</h5>)}

                                            </h5>
                                        </div>
                                        {showTokenSelector ? (
                                            <img
                                                src={dropDownImg}
                                                className={styles.arrowDown}
                                                alt="arrow"
                                                onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                                onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                            />
                                        ) : (
                                            <img
                                                src={dropDownRightImg}
                                                className={styles.arrowDown}
                                                alt="arrow"
                                                onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                                onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                            />
                                        )}
                                    </div>
                                </div>
                                {/* -------- Vertical line-------- */}

                                <img className={styles.verticalLine} src={verticalLineImg}></img>
                                {/* -------- MINT TAB Second Selector-------- */}
                                <div
                                    className={styles.methodItem2}
                                    onClick={handleMethodItemClick2}
                                    ref={methodItemRef2}
                                >
                                    <div
                                        // ref={methodItemRef2}
                                        // onClick={handleMethodItemClick2}
                                        className={styles.withDrawItemLeft}
                                    >
                                        {selectedChain === "solana" ? (
                                            <img
                                                // ref={methodItemRef2}
                                                // onClick={handleMethodItemClick2}
                                                className={styles.withdrawImg2}
                                                src={solanaImg}
                                                alt="solanaImg"
                                            />
                                        ) : selectedChain === "ethereum" ? (
                                            <img
                                                // ref={methodItemRef2}
                                                // onClick={handleMethodItemClick2}
                                                className={styles.withdrawImg2}
                                                src={ethImg}
                                                alt="ethImg"
                                            />
                                        ) : selectedChain === "tron" ? (
                                            <img
                                                // ref={methodItemRef2}
                                                // onClick={handleMethodItemClick2}
                                                className={styles.withdrawImg2}
                                                src={TronImg}
                                                alt="cashImg"
                                            />
                                        ) : selectedChain === "binance" ? (
                                            <img
                                                // ref={methodItemRef2}
                                                // onClick={handleMethodItemClick2}
                                                className={styles.withdrawImg2}
                                                src={BscImg}
                                                alt="BscImg"
                                            />
                                        ) : null}
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-around",
                                                background: "#1c1c1c"
                                            }}
                                            // onClick={handleMethodItemClick2}
                                            // ref={methodItemRef2}
                                        >
                                            <h4>Blockchain</h4>
                                            {
                                                selectedChain === "solana" ? (
                                                    <h5>Solana</h5>
                                                ) : selectedChain === "ethereum" ? (
                                                    <h5>Ethereum</h5>
                                                ) : selectedChain === "tron" ? (
                                                    <h5>Tron</h5>
                                                ) : selectedChain === "binance" ? (
                                                    <h5>Binance Smart Chain</h5>
                                                ) : (
                                                    <h5>Other or Unknown</h5>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <div
                                            className={styles.withDrawItemRight}
                                        >
                                            <h5 style={{color: "#fff"}}>
                                                $5,000
                                            </h5>

                                            <h5
                                                // onClick={handleMethodItemClick2} ref={methodItemRef2}
                                            >
                                                Minimum
                                            </h5>
                                        </div>
                                        {showTokenSelector2 ? (
                                            <img
                                                src={dropDownImg}
                                                className={styles.arrowDown}
                                                alt="arrow"
                                                onMouseEnter={(e) => (e.currentTarget.src = dropDownHoverImg)}
                                                onMouseLeave={(e) => (e.currentTarget.src = dropDownImg)}
                                            />
                                        ) : (
                                            <img
                                                src={dropDownRightImg}
                                                className={styles.arrowDown}
                                                alt="arrow"
                                                onMouseEnter={(e) => (e.currentTarget.src = dropDownRightHoverImg)}
                                                onMouseLeave={(e) => (e.currentTarget.src = dropDownRightImg)}
                                            />
                                        )}
                                    </div>
                                </div>


                                <div className={styles.lineSeparator2}></div>
                                {/* QR code section*/}
                                {/* Determine if email login has no wallet register to it*/}
                                {walletAddress && walletAddress !== "requested" ? <div className={styles.qrContainer}>
                                        <div className={styles.qrTextContainer}>
                                            <div className={styles.qrText1}> Deposit {selectedToken}</div>
                                            <div className={styles.qrGreytext}> on the {selectedChain} network</div>
                                            <div className={styles.copyContainer}>
                                                <div>
                                                    <div className={styles.qrText2}> Your Address</div>
                                                    <div
                                                        className={styles.qrGreytext2}> {shortenAddress(walletAddress)}</div>
                                                </div>
                                                <img
                                                    className={styles.copyBtn2}
                                                    src={justCopiedWallet ? copyConfirmImg : copyCircleImg}
                                                    alt="Copy"
                                                    onMouseEnter={(e) => {
                                                        if (!justCopiedWallet) e.currentTarget.src = copyCircleHoverImg;
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.currentTarget.src = justCopiedWallet ? copyConfirmImg : copyCircleImg;
                                                    }}
                                                    onClick={(e) => handleCopy(e, walletAddress)}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.QRCODE}>
                                            <QRCodeCanvas
                                                value={qrValue}
                                                size={qrSize}
                                                {...qrOptions}
                                                style={{borderRadius: '10px'}}
                                            />
                                        </div>
                                    </div>

                                    : <div className={`${styles.qrContainer} ${styles.qrContainer2}`}>
                                        <div className={styles.qrTextContainer}>
                                            <div className={styles.qrText1}> No Wallet Found</div>
                                            <div className={styles.qrGreytext1}> Create one to get started</div>
                                            <div className={styles.qrGreytext3}> Exchange ratio: 1 USDT=1 USDi</div>
                                        </div>
                                        {
                                            selectedChainAddress ? (
                                                <img className={styles.walletCreateProgress} src={walletCreationImg}
                                                     alt="img"/>
                                            ) : (
                                                <button onClick={() => setShowTokenSelector2(true)}>
                                                    Create Your Wallet Now
                                                </button>
                                            )
                                        }
                                    </div>
                                }

                                <div className={styles.lineSeparator3}></div>
                                {walletAddress ?
                                    <div className={styles.bottomContainer}><img src={downImg} alt="download"/>
                                        <div>
                                            This address can only receive native {selectedToken} on
                                            the {getChainDisplayName(selectedChain)} network.
                                            Don’t send {selectedToken} on any other network or it may be lost.
                                        </div>
                                    </div> : <div/>}
                                {walletAddress ?
                                    <div className={styles.bottomContainer}><img src={timeImg} alt="download"/>
                                        <div>Allow 1 day for processing</div>
                                    </div> :
                                    <div className={styles.bottomContainer1}>
                                        <div>Notes: Generate your first wallet on Solana to get started. Additional
                                            networks
                                            available: Ethereum, TRON, and BNB Chain.
                                        </div>
                                    </div>}

                                {/* Token Selector Modal (Mint) */}
                                {showTokenSelector && (
                                    <div ref={tokenSelectorRef} className={styles.tokenSelectorModal}>
                                        <div className={styles.tokenSelectorTitleContainer}>
                                            <div className={styles.tokenSelectorTitle}>Token</div>
                                            <div className={styles.tokenSelectorTitle}>Balance/Address</div>
                                        </div>
                                        <div className={styles.tokenSelectorContentContainer}>
                                            {/* USDC Row */}
                                            <div
                                                className={
                                                    selectedToken === 'USDC'
                                                        ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                        : styles.tokenSelectorContent
                                                }
                                                style={
                                                    selectedToken === 'USDC'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                                onClick={() => handleSelectToken("USDC")}
                                            >
                                                <div
                                                    className={styles.tokenSelectorContentLeft}
                                                    style={
                                                        selectedToken === 'USDC'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                >
                                                    <img
                                                        className={styles.selectorImg}
                                                        src={usdcImg}
                                                        alt="wireTransferImg"
                                                        style={
                                                            selectedToken === 'USDC'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            background: selectedToken === 'USDC'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        <h4
                                                            style={{
                                                                marginBottom: '3px',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            USDC
                                                        </h4>
                                                        <h5
                                                            style={{
                                                                marginBottom: '0',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            USD Coin
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.tokenSelectorWalletContainer}
                                                    style={
                                                        selectedToken === 'USDC'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                >
                                                    {usdcBalance ? (
                                                        <div
                                                            className={styles.tokenSelectorContentRight}
                                                            style={
                                                                selectedToken === 'USDC'
                                                                    ? {background: '#2b3336'}
                                                                    : {}
                                                            }
                                                        >
                                                            {usdcBalance.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            --
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            background: selectedToken === 'USDC'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                        >
                                                            {shortenAddress(usdcTokenAddress)}
                                                        </span>
                                                        {/* Copy icon or check icon */}
                                                        <span
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            {justCopiedUSDC ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    alt="copy img"
                                                                    style={{
                                                                        background: selectedToken === 'USDC'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    onClick={(e) => handleCopy(e, usdcTokenAddress, 'USDC')}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                background: selectedToken === 'USDC'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedToken === 'USDC'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, usdcTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* USDT Row */}
                                            <div
                                                className={
                                                    selectedToken === 'USDT'
                                                        ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                        : styles.tokenSelectorContent
                                                }
                                                style={
                                                    selectedToken === 'USDT'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                                onClick={() => handleSelectToken("USDT")}
                                            >
                                                <div
                                                    className={styles.tokenSelectorContentLeft}
                                                    style={
                                                        selectedToken === 'USDT'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                >
                                                    <img
                                                        className={styles.selectorImg}
                                                        src={usdtImg}
                                                        alt="wireTransferImg"
                                                        style={
                                                            selectedToken === 'USDT'
                                                                ? {background: '#2b3336'}
                                                                : {}
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        <h4
                                                            style={{
                                                                marginBottom: '3px',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            USDT
                                                        </h4>
                                                        <h5
                                                            style={{
                                                                marginBottom: '0',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            USDT
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div
                                                    className={styles.tokenSelectorWalletContainer}
                                                    style={{
                                                        background: selectedToken === 'USDT'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >
                                                    {usdtBalance ? (
                                                        <div
                                                            className={styles.tokenSelectorContentRight}
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            {usdtBalance.toLocaleString('en-US', {maximumFractionDigits: 6})}
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                textAlign: 'right',
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            --
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            background: selectedToken === 'USDT'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        <span
                                                            className={styles.tokenSelectorContentRightGrey}
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                            onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                        >
                                                            {shortenAddress(usdtTokenAddress)}
                                                        </span>
                                                        {/* Copy or check icon */}
                                                        <span
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            {justCopiedUSDT ? (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copyConfirmImg}
                                                                    alt="Copied!"
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent',
                                                                        width: '11px',
                                                                        height: '11px'
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    className={styles.tokenSelectorContentRightGreyIMG}
                                                                    src={copySelectorImg}
                                                                    style={{
                                                                        background: selectedToken === 'USDT'
                                                                            ? '#2b3336'
                                                                            : 'transparent'
                                                                    }}
                                                                    alt="copy img"
                                                                    onClick={(e) => handleCopy(e, usdtTokenAddress, 'USDT')}
                                                                    onMouseEnter={(e) =>
                                                                        (e.currentTarget.src = copySelectorHoverImg)
                                                                    }
                                                                    onMouseLeave={(e) =>
                                                                        (e.currentTarget.src = copySelectorImg)
                                                                    }
                                                                />
                                                            )}
                                                        </span>
                                                        <span
                                                            style={{
                                                                background: selectedToken === 'USDT'
                                                                    ? '#2b3336'
                                                                    : 'transparent'
                                                            }}
                                                        >
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedToken === 'USDT'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, usdtTokenAddress)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* -------- second modal -------- */} {/* -------- second modal  -------- */}{showTokenSelector2 && (
                                <div ref={tokenSelectorRef2} className={styles.tokenSelectorModal2}>
                                    <div className={styles.tokenSelectorTitleContainer}>
                                        <div className={styles.tokenSelectorTitle}>CryptoNetwork</div>
                                        <div className={styles.tokenSelectorTitle}></div>
                                    </div>
                                    <div className={styles.tokenSelectorContentContainer}>
                                        {/* solana Row */}
                                        <div
                                            className={
                                                selectedChain === 'solana'
                                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                    : styles.tokenSelectorContent
                                            }
                                            style={
                                                selectedChain === 'solana'
                                                    ? {background: '#2b3336'}
                                                    : {}
                                            }
                                            onClick={() => handleSelectChain("solana")}
                                        >
                                            <div
                                                className={styles.tokenSelectorContentLeft}
                                                style={
                                                    selectedChain === 'solana'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <img
                                                    className={styles.selectorImg}
                                                    src={solanaImg}
                                                    alt="wireTransferImg"
                                                    style={
                                                        selectedChain === 'solana'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        background: selectedChain === 'solana'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            marginBottom: '3px',
                                                            background: selectedChain === 'solana'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        Solana
                                                    </h4>
                                                    <h5
                                                        style={{
                                                            marginBottom: '0',
                                                            background: selectedChain === 'solana'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {shortenAddress(currentUserData.solana_receive_address)}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div
                                                className={styles.tokenSelectorWalletContainer}
                                                style={
                                                    selectedChain === 'solana'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <div
                                                    style={{
                                                        background: selectedChain === 'solana'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >

                                                    <span
                                                        style={{
                                                            background: selectedChain === 'solana'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >    {currentUserData.solana_receive_address === 'requested' ? (
                                                            <img
                                                                className={styles.tokenSelectorContentRightGreyIMG2}
                                                                src={addAccountSuccessImg}
                                                                alt="Success img"
                                                                style={{
                                                                    background: selectedChain === 'solana' ? '#2b3336' : 'transparent'
                                                                }}
                                                            />
                                                        ) :
                                                        currentUserData.solana_receive_address ? (<img
                                                                className={styles.tokenSelectorContentRightGreyIMG2}
                                                                src={shareSelectorImg}
                                                                alt="share img"
                                                                style={{
                                                                    background: selectedChain === 'solana'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleShare(e, currentUserData.solana_receive_address, selectedChain)}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = shareSelectorHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = shareSelectorImg)
                                                                }
                                                            />) :
                                                            (<img
                                                                className={styles.tokenSelectorContentRightGreyIMG2}
                                                                src={addAccountImg}
                                                                alt="add account img"
                                                                style={{
                                                                    background: selectedChain === 'solana'
                                                                        ? '#2b3336'
                                                                        : 'transparent'
                                                                }}
                                                                onClick={(e) => handleCreateWalletClick('solana')}
                                                                onMouseEnter={(e) =>
                                                                    (e.currentTarget.src = addAccountHoverImg)
                                                                }
                                                                onMouseLeave={(e) =>
                                                                    (e.currentTarget.src = addAccountImg)
                                                                }
                                                            />)}

                                                        </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* end of solana Row start eth */}
                                        <div
                                            className={
                                                selectedChain === 'ethereum'
                                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                    : styles.tokenSelectorContent
                                            }
                                            style={
                                                selectedChain === 'ethereum'
                                                    ? {background: '#2b3336'}
                                                    : {}
                                            }
                                            onClick={() => handleSelectChain("ethereum")}
                                        >
                                            <div
                                                className={styles.tokenSelectorContentLeft}
                                                style={
                                                    selectedChain === 'ethereum'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <img
                                                    className={styles.selectorImg}
                                                    src={ethImg}
                                                    alt="ethImg"
                                                    style={
                                                        selectedChain === 'ethereum'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        background: selectedChain === 'ethereum'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            marginBottom: '3px',
                                                            background: selectedChain === 'ethereum'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        Ethereum
                                                    </h4>
                                                    <h5
                                                        style={{
                                                            marginBottom: '0',
                                                            background: selectedChain === 'ethereum'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {shortenAddress(currentUserData.ethereum_receive_address)}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div
                                                className={styles.tokenSelectorWalletContainer}
                                                style={
                                                    selectedChain === 'ethereum'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <div
                                                    style={{
                                                        background: selectedChain === 'ethereum'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >

                                                    <span
                                                        style={{
                                                            background: selectedChain === 'ethereum'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                         {currentUserData.ethereum_receive_address === 'requested' ? (
                                                                 <img
                                                                     className={styles.tokenSelectorContentRightGreyIMG2}
                                                                     src={addAccountSuccessImg}
                                                                     alt="Success img"
                                                                     style={{
                                                                         background: selectedChain === 'ethereum' ? '#2b3336' : 'transparent'
                                                                     }}
                                                                 />
                                                             ) :
                                                             currentUserData.ethereum_receive_address ? (<img
                                                                     className={styles.tokenSelectorContentRightGreyIMG2}
                                                                     src={shareSelectorImg}
                                                                     alt="share img"
                                                                     style={{
                                                                         background: selectedChain === 'ethereum'
                                                                             ? '#2b3336'
                                                                             : 'transparent'
                                                                     }}
                                                                     onClick={(e) => handleShare(e, currentUserData.ethereum_receive_address, "ethereum")}
                                                                     onMouseEnter={(e) =>
                                                                         (e.currentTarget.src = shareSelectorHoverImg)
                                                                     }
                                                                     onMouseLeave={(e) =>
                                                                         (e.currentTarget.src = shareSelectorImg)
                                                                     }
                                                                 />) :
                                                                 (<img
                                                                     className={styles.tokenSelectorContentRightGreyIMG2}
                                                                     src={addAccountImg}
                                                                     alt="add account img"
                                                                     style={{
                                                                         background: selectedChain === 'ethereum'
                                                                             ? '#2b3336'
                                                                             : 'transparent'
                                                                     }}
                                                                     onClick={(e) => handleCreateWalletClick('ethereum')}
                                                                     onMouseEnter={(e) =>
                                                                         (e.currentTarget.src = addAccountHoverImg)
                                                                     }
                                                                     onMouseLeave={(e) =>
                                                                         (e.currentTarget.src = addAccountImg)
                                                                     }
                                                                 />)}

                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*{end ETH row}*/}
                                        {/* tron Row */}
                                        <div
                                            className={
                                                selectedChain === 'tron'
                                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                    : styles.tokenSelectorContent
                                            }
                                            style={
                                                selectedChain === 'tron'
                                                    ? {background: '#2b3336'}
                                                    : {}
                                            }
                                            onClick={() => handleSelectChain("tron")}
                                        >
                                            <div
                                                className={styles.tokenSelectorContentLeft}
                                                style={
                                                    selectedChain === 'tron'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <img
                                                    className={styles.selectorImg}
                                                    src={TronImg}
                                                    alt="tronImg"
                                                    style={
                                                        selectedChain === 'tron'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        background: selectedChain === 'tron'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            marginBottom: '3px',
                                                            background: selectedChain === 'tron'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        Tron
                                                    </h4>
                                                    <h5
                                                        style={{
                                                            marginBottom: '0',
                                                            background: selectedChain === 'tron'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {shortenAddress(currentUserData.tron_receive_address)}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div
                                                className={styles.tokenSelectorWalletContainer}
                                                style={
                                                    selectedChain === 'tron'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <div
                                                    style={{
                                                        background: selectedChain === 'tron'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >

                                                    <span
                                                        style={{
                                                            background: selectedChain === 'tron'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                           {currentUserData.tron_receive_address === 'requested' ? (
                                                                   <img
                                                                       className={styles.tokenSelectorContentRightGreyIMG2}
                                                                       src={addAccountSuccessImg}
                                                                       alt="Success img"
                                                                       style={{
                                                                           background: selectedChain === 'tron' ? '#2b3336' : 'transparent'
                                                                       }}
                                                                   />
                                                               ) :
                                                               currentUserData.tron_receive_address ? (<img
                                                                       className={styles.tokenSelectorContentRightGreyIMG2}
                                                                       src={shareSelectorImg}
                                                                       alt="share img"
                                                                       style={{
                                                                           background: selectedChain === 'tron'
                                                                               ? '#2b3336'
                                                                               : 'transparent'
                                                                       }}
                                                                       onClick={(e) => handleShare(e, currentUserData.tron_receive_address, 'tron')}
                                                                       onMouseEnter={(e) =>
                                                                           (e.currentTarget.src = shareSelectorHoverImg)
                                                                       }
                                                                       onMouseLeave={(e) =>
                                                                           (e.currentTarget.src = shareSelectorImg)
                                                                       }
                                                                   />) :
                                                                   (<img
                                                                       className={styles.tokenSelectorContentRightGreyIMG2}
                                                                       src={addAccountImg}
                                                                       alt="add account img"
                                                                       style={{
                                                                           background: selectedChain === 'tron'
                                                                               ? '#2b3336'
                                                                               : 'transparent'
                                                                       }}
                                                                       onClick={(e) => handleCreateWalletClick('tron')}
                                                                       onMouseEnter={(e) =>
                                                                           (e.currentTarget.src = addAccountHoverImg)
                                                                       }
                                                                       onMouseLeave={(e) =>
                                                                           (e.currentTarget.src = addAccountImg)
                                                                       }
                                                                   />)}

                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*END Tron Row, start Binance*/}
                                        {/* solana Row */}
                                        <div
                                            className={
                                                selectedChain === 'binance'
                                                    ? `${styles.tokenSelectorContent} ${styles.noHover}`
                                                    : styles.tokenSelectorContent
                                            }
                                            style={
                                                selectedChain === 'binance'
                                                    ? {background: '#2b3336'}
                                                    : {}
                                            }
                                            onClick={() => handleSelectChain("binance")}
                                        >
                                            <div
                                                className={styles.tokenSelectorContentLeft}
                                                style={
                                                    selectedChain === 'binance'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <img
                                                    className={styles.selectorImg}
                                                    src={BscImg}
                                                    alt="bscImg"
                                                    style={
                                                        selectedChain === 'binance'
                                                            ? {background: '#2b3336'}
                                                            : {}
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        background: selectedChain === 'binance'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >
                                                    <h4
                                                        style={{
                                                            marginBottom: '3px',
                                                            background: selectedChain === 'binance'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        Binance Smart Chain
                                                    </h4>
                                                    <h5
                                                        style={{
                                                            marginBottom: '0',
                                                            background: selectedChain === 'binance'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                        {shortenAddress(currentUserData.bsc_receive_address)}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div
                                                className={styles.tokenSelectorWalletContainer}
                                                style={
                                                    selectedChain === 'binance'
                                                        ? {background: '#2b3336'}
                                                        : {}
                                                }
                                            >
                                                <div
                                                    style={{
                                                        background: selectedChain === 'binance'
                                                            ? '#2b3336'
                                                            : 'transparent'
                                                    }}
                                                >

                                                    <span
                                                        style={{
                                                            background: selectedChain === 'binance'
                                                                ? '#2b3336'
                                                                : 'transparent'
                                                        }}
                                                    >
                                                                 {currentUserData.bsc_receive_address === 'requested' ? (
                                                                     // 情况 1: requested
                                                                     <img
                                                                         className={styles.tokenSelectorContentRightGreyIMG2}
                                                                         src={addAccountSuccessImg}
                                                                         alt="Success img"
                                                                         style={{
                                                                             background: selectedChain === 'binance' ? '#2b3336' : 'transparent'
                                                                         }}
                                                                     />
                                                                 ) : currentUserData.bsc_receive_address ? (
                                                                     // 情况 2: 有实际地址
                                                                     <img
                                                                         className={styles.tokenSelectorContentRightGreyIMG2}
                                                                         src={shareSelectorImg}
                                                                         alt="share img"
                                                                         style={{
                                                                             background: selectedChain === 'binance' ? '#2b3336' : 'transparent'
                                                                         }}
                                                                         onClick={(e) => handleShare(e, currentUserData.bsc_receive_address, 'binance')}
                                                                         onMouseEnter={(e) => (e.currentTarget.src = shareSelectorHoverImg)}
                                                                         onMouseLeave={(e) => (e.currentTarget.src = shareSelectorImg)}
                                                                     />
                                                                 ) : (
                                                                     <img
                                                                         className={styles.tokenSelectorContentRightGreyIMG2}
                                                                         src={addAccountImg}
                                                                         alt="add account img"
                                                                         style={{
                                                                             background: selectedChain === 'binance' ? '#2b3336' : 'transparent'
                                                                         }}
                                                                         onClick={(e) => handleCreateWalletClick('binance')}
                                                                         onMouseEnter={(e) => (e.currentTarget.src = addAccountHoverImg)}
                                                                         onMouseLeave={(e) => (e.currentTarget.src = addAccountImg)}
                                                                     />
                                                                 )}
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end binance row*/}
                                    </div>
                                </div>
                            )}
                            </div>)}

                            {/* -------- REDEEM TAB -------- */}
                            {selectedTab === 'redeem' && (
                                <RedeemTab
                                    typedMintValue={typedMintValue}
                                    dynamicFontSize={dynamicFontSize}
                                    containerRef={containerRef}
                                    spanRef={spanRef}
                                    inputRef={inputRef}
                                    handleInputChange={handleInputChange}
                                    handleSetMax={handleSetMax}
                                    paymentInfoData={paymentInfoData}

                                    usdiBalance={usdiBalance}
                                    usdcBalance={usdcBalance}
                                    usdtBalance={usdtBalance}
                                    selectedToken={selectedToken}
                                    shortenAddress={shortenAddress}
                                    walletAddress={walletAddress}
                                    networkFeePercent={networkFeePercent}
                                    showTokenSelector={showTokenSelector}
                                    methodItemRef={methodItemRef}
                                    handleMethodItemClick={handleMethodItemClick}
                                    tokenSelectorRef={tokenSelectorRef}
                                    handleSelectToken={handleSelectToken}
                                    handleCopy={handleCopy}
                                    justCopiedUSDC={justCopiedUSDC}
                                    justCopiedUSDT={justCopiedUSDT}
                                    handleShare={handleShare}
                                    // 下拉3
                                    showTokenSelector3={showTokenSelector3}
                                    methodItemRef3={methodItemRef3}
                                    handleMethodItemClick3={handleMethodItemClick3}
                                    tokenSelectorRef3={tokenSelectorRef3}
                                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                                    iconMap={iconMap}
                                    getPaymentInfoDisplay={getPaymentInfoDisplay}
                                    walletAddressExists={!!walletAddress}
                                    handleRedeemUSDi={handleRedeemUSDi}
                                    handleConnectWallet={handleConnectWallet}
                                    usdcTokenAddress={usdcTokenAddress}
                                    usdtTokenAddress={usdtTokenAddress}
                                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    refetchPaymentMethods={refetchPaymentMethods}
                                    setRefetchPaymentMethods={setRefetchPaymentMethods}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* Create Wallet Modal  */}
                {showCreateWalletModal && (
                    <>
                        {/* Overlay (click to close) */}
                        <div
                            className={styles.modalOverlay}
                            onClick={handleCloseModal}
                        ></div>

                        {/* Modal content */}
                        <div className={styles.modalContainer}>
                            {/* Close "X" */}
                            <button
                                className={styles.closeButton}
                                onClick={handleCloseModal}
                            >
                                &times;
                            </button>

                            <div className={styles.modalTitle}>Transaction detail</div>
                            <hr className={styles.modalDivider}/>

                            <img
                                src={CheckImg}
                                alt="Check Mark"
                                className={styles.checkIcon}
                            />

                            <h2 className={styles.requestReceivedTitle}>Request Received</h2>

                            <p className={styles.requestReceivedText}>
                                Your wallet will be ready within 1 <strong>business day</strong>.
                                <br/>
                                Check back tomorrow.
                            </p>
                        </div>
                    </>
                )}
            </div>
        </LayoutGroup>
    );
};

export default SwapMainAPP;
