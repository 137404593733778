import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from './AuthContext';
import './App.css';
import EnterImage from './Enter.svg';
import mobileImage from './Rectangle 309.png';
import {FaEye, FaEyeSlash} from 'react-icons/fa'; // Import eye icons

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false); // Track password visibility
    const auth = useAuth();
    const navigate = useNavigate();

    // not sure what is used for
    // useEffect(() => {
    //     auth.logout();
    // }, []);

    const handleLogin = (e) => {
        e.preventDefault();
        if (auth.login(email, password)) {
            navigate('/home/usdi-stats');
        } else {
            setError('Invalid email or invitation code');
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <img className="mobile-image" src={mobileImage} alt="Login"/>
                <div className="green-header"><h2>Early Access</h2></div>
                <p>Enter your invitation code to gain early access</p>
                <form onSubmit={handleLogin}>
                    <div className="input-form">
                        <label>Email Address</label>
                        <input
                            type="email" // Set type to email for better validation and autocomplete support
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email" // Enable email autocomplete
                        />
                    </div>

                    <div className="input-form password-field">
                        <label>Invitation Code</label>
                        <div className="password-input-wrapper">
                            <input
                                type={"text"} // Switch between text and password for visibility
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete="current-password" // Enable password autocomplete
                            />

                        </div>
                    </div>

                    {error && <p className="error">{error}</p>}
                    <button type="submit"><img className="enter" src={EnterImage} alt=''/></button>
                </form>
            </div>
            <div className="login-image">
                <img src="/images/login.png" alt="Login"/>
            </div>
        </div>
    );
};

export default LoginPage;