import React, {useEffect, useState} from 'react';
import styles from './AddPayment.module.css';
import ReactDOM from 'react-dom';

import expandSvg from '../static_files/arrowRight.svg';
import expandHoverSvg from '../static_files/arrowRight Hover.svg';
import AddPaymentInputInfo from './AddPaymentInputInfo';


function AddPayment({onClose, onRefetchPaymentMethods, availablePaymentMethods = []}) {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showAddPayment, setShowAddPayment] = useState(true);

    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    // When user clicks a row
    const handlePaymentRowClick = (row) => {
        setSelectedRow(row);
        setSelectedPaymentMethod(row.method_name)
        setShowModal(true);
        // onClose();
        setShowAddPayment(false);

    };
    const handleClose = () => {
        setShowModal(false)
        onClose()
    }

    // Fetch payment methods from backend
    useEffect(() => {
        setPaymentMethods(availablePaymentMethods);
    }, [availablePaymentMethods]);

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay}
        onClick={(e) => {
                if (e.target === e.currentTarget) {
                    onClose();
                }
            }}>
            {showAddPayment && (
                <div className={styles.modalContainer}>
                    <div className={styles.headerRow}>
                        <h2 className={styles.title}>Add a payment method</h2>
                        <div className={styles.closeBtn} onClick={onClose}>
                            &times;
                        </div>
                    </div>

                    <div className={styles.dividerLine}/>

                    <div className={styles.paymentList}>
                        {paymentMethods.map((method, index) => (
                            /* Single container div for each row, with a unique key */
                            <div key={method.id || index}>
                                <div
                                    className={styles.paymentRow}
                                    onClick={() => handlePaymentRowClick(method)}
                                >
                                    {/* Left part: Icon + method name + details */}
                                    <div className={styles.leftPart}>
                                        <img
                                            src={method.icon}
                                            alt={method.method_name}
                                            className={styles.iconStyle}
                                        />
                                        <div>
                                            <div className={styles.methodName}>{method.method_name}</div>
                                            <div className={styles.details}>
                                              <span style={{color: '#ffffff'}}>
                                                ETA: {method.processing_time}
                                              </span>
                                                <br/>
                                                Fees: {method.fees}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Right part: limit + label + arrow */}
                                    <div className={styles.rightPart}>
                                        <div>
                                            <div className={styles.limitText}>{method.amount_limit}</div>
                                            <div className={styles.amountLabel}>Amount limit</div>
                                        </div>
                                        <img
                                            src={expandSvg}
                                            alt="expand"
                                            className={styles.expandIcon}
                                            onMouseEnter={(e) => {
                                                e.currentTarget.src = expandHoverSvg;
                                            }}
                                            onMouseLeave={(e) => {
                                                e.currentTarget.src = expandSvg;
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Divider line between rows, skip it after the last row */}
                                {index < paymentMethods.length - 1 && (
                                    <div className={styles.rowDivider}/>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={styles.footerRow}>
                        Learn about our buy/sell fees{' '}
                        <a href="https://docs.kira.trading/kira-club/c2c-network/payment-methods-and-fees"
                           target="_blank" rel="noreferrer">
                            here
                        </a>
                        .
                    </div>
                </div>)}

            {/* Show the modal if user clicked a row */}
            {showModal && (
                <AddPaymentInputInfo
                    rowId={selectedRow?.id}
                    onClose={handleClose}
                    paymentMethod={selectedRow.method_name}
                    onRefetchPaymentMethods={onRefetchPaymentMethods}
                />
            )}
        </div>,
        document.getElementById('root')
    );
}

export default AddPayment;