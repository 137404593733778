import React, {createContext, useContext, useState, useMemo, useEffect} from 'react';
import {useNavigate} from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(
        () => JSON.parse(localStorage.getItem('isAuthenticated')) || false
    );
    const [isGatewayAuthenticated, setIsGatewayAuthenticated] = useState(false);
    const [gatewayUserData, setGatewayUserData] = useState(null);


    //在connect wallet header 连接钱包后
    const [walletAddress, setWalletAddress] = useState(() => {
        return localStorage.getItem('walletAddress') || '';
    });


    useEffect(() => {
        if (walletAddress) {
            localStorage.setItem('walletAddress', walletAddress);
        } else {
            localStorage.removeItem('walletAddress');
        }
    }, [walletAddress]);


    function disconnect() {
        setWalletAddress('');
    }

    // Admin login flow
    const login = (username, password) => {
        if (username === 'arb@0max1.com' && password === 'Bitcoin110') {
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', JSON.stringify(true));
            localStorage.setItem('tokenExpiry', Date.now() + 60 * 1000);
            return true;
        }
        return false;
    };


    const logout = async () => {
        const email = gatewayUserData?.email;
        if (isGatewayAuthenticated) {
            try {
                const response = await fetch('https://max1-funding-arb.uc.r.appspot.com/logout', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({email})
                });
                if (!response.ok) {
                    const result = await response.json();
                    console.error('Failed to logout:', result.msg);
                    return false

                } else {
                    setGatewayUserData(null);
                    localStorage.removeItem('gatewayToken')
                    localStorage.removeItem('userEmail');
                    localStorage.removeItem('GATEWAY_TOKEN_FIRST_LOAD');
                    localStorage.removeItem('emailUserWalletAddress');
                    localStorage.removeItem('userEmail')
                    localStorage.setItem('emailLogin', 'false');
                    window.location.href = "/";
                    return true
                }
            } catch (error) {
                console.error('Error while logging out:', error);
                return false
            }
        } else {
            // 别的email 用户异地登录，点击logout。清空本地
            console.log("shouldn't  got here")
            setIsAuthenticated(false);
            setIsGatewayAuthenticated(false);
            setGatewayUserData(null);
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refresh_token');
            setGatewayUserData(null);
            localStorage.removeItem('gatewayToken')
            localStorage.removeItem('userEmail');
            localStorage.removeItem('GATEWAY_TOKEN_FIRST_LOAD');
            localStorage.removeItem('emailUserWalletAddress');
            localStorage.removeItem('userEmail')
            localStorage.setItem('emailLogin', 'false');
            window.location.href = "/";
            return true
        }
    };

    // email login
    const verifyGatewayToken = async (token) => {
        try {
            const response = await fetch('https://max1-funding-arb.uc.r.appspot.com/verify_token', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({token})
            });
            if (!response.ok) {
                setIsGatewayAuthenticated(false);
                setGatewayUserData(null);
                return false;
            }

            const result = await response.json();

            if (result.success) {
                setIsGatewayAuthenticated(true);
                setGatewayUserData(result.data || null);
                localStorage.removeItem('walletAddress');
                localStorage.removeItem('lastConnectedWallet');
                localStorage.setItem("userEmail", result.data.email || null);
                if (result.data.solana_receive_address && result.data.solana_receive_address !== "requested") {
                    localStorage.setItem("emailUserWalletAddress", result.data.solana_receive_address)
                } else if (result.data.ethereum_receive_address && result.data.ethereum_receive_address !== "requested") {
                    localStorage.setItem("emailUserWalletAddress", result.data.ethereum_receive_address)
                } else if (result.data.bsc_receive_address && result.data.bsc_receive_address !== "requested") {
                    localStorage.setItem("emailUserWalletAddress", result.data.bsc_receive_address)

                } else if (result.data.tron_receive_address && result.data.tron_receive_address !== "requested") {
                    localStorage.setItem("emailUserWalletAddress", result.data.tron_receive_address)
                }
                return true;
            } else {
                setIsGatewayAuthenticated(false);
                setGatewayUserData(null);
                return false;
            }
        } catch (error) {
            console.error('Error verifying gateway token:', error);
            setIsGatewayAuthenticated(false);
            setGatewayUserData(null);
            return false;
        }
    };

    // 使用 useMemo 缓存 gatewayUserData，当 email 不变时返回相同对象
    const memoizedGatewayUserData = useMemo(() => gatewayUserData, [gatewayUserData?.email]);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            isGatewayAuthenticated,
            gatewayUserData: memoizedGatewayUserData,
            login,
            logout,
            verifyGatewayToken,
            walletAddress,
            setWalletAddress,
            disconnect
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);