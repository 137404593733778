import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './MobileMenu.module.css';

import inviteImg from '../static_files/Invite-friends.svg';
import inviteImgGreen from '../static_files/Invite-friends green.svg';
import transactionImg from '../static_files/Transactions.svg';
import transactionImgGreen from '../static_files/Transactions green.svg';
import myAssetsImg from '../static_files/My Assets.svg';
import myAssetsImgGreen from '../static_files/My Assets Green.svg';

// Normal icons for C2C & Account (no "green" versions)
import c2cImg from '../static_files/C2C Network.svg';
import accountImg from '../static_files/Account_leftPanel.svg';

function SideMenu({ isOpen, onOverlayClick, stopPropagation }) {
  const location = useLocation();
  const navigate = useNavigate();

  if (!isOpen) {
    return null; // If closed, return nothing
  }

  // Same logic as in LeftPanel for My Assets
  const handleMyAssetsClick = () => {
    const token = localStorage.getItem('gatewayToken');
    if (token) {
      // e.g. "/swap?token=XYZ"
      navigate(`/swap?token=${token}`);
    } else {
      // Fallback to "/" if no token in storage
      navigate('/');
    }
  };

  // Simple navigations for other pages
  const handleTransactionsClick = () => {
    navigate('/transaction');
  };

  const handleC2CClick = () => {
    navigate('/c2c');
  };

  // If you have a dedicated invite route, adjust accordingly
  const handleInviteClick = () => {
    navigate('/invite');
  };

  const handleAccountClick = () => {
    navigate('/account');
  };

  // Helper: Decide if route is active for icon highlight
  // In your LeftPanel code, My Assets was at "/" or "/swap"
  // so we mark them active if path is "/" or starts with "/swap"
  const isMyAssetsActive =
    location.pathname === '/' || location.pathname.startsWith('/swap');

  return (
    <>
      {/* Overlay that covers the entire screen */}
      <div className={styles.sideMenuOverlay} onClick={onOverlayClick} />

      {/* Side Menu container */}
      <div
        className={`${styles.sideMenuContainer} ${isOpen ? styles.open : ''}`}
        onClick={stopPropagation}
      >
        {/* My Assets */}
        <div
          className={`${styles.menuItem} ${
            isMyAssetsActive ? styles.selected : ''
          }`}
          onClick={handleMyAssetsClick}
        >
          <img
            src={isMyAssetsActive ? myAssetsImgGreen : myAssetsImg}
            alt="My Assets"
          />
          My Assets
        </div>

        {/* Transactions */}
        <div
          className={`${styles.menuItem} ${
            location.pathname === '/transaction' ? styles.selected : ''
          }`}
          onClick={handleTransactionsClick}
        >
          <img
            src={
              location.pathname === '/transaction'
                ? transactionImgGreen
                : transactionImg
            }
            alt="Transactions"
          />
          Transactions
        </div>

        {/* C2C Network */}
        <div
          className={`${styles.menuItem} ${
            location.pathname === '/c2c' ? styles.selected : ''
          }`}
          onClick={handleC2CClick}
        >
          <img src={c2cImg} alt="C2C Network" />
          C2C Network
        </div>

        {/* Invite Friends */}
        <div
          className={`${styles.menuItem} ${
            location.pathname === '/invite' ? styles.selected : ''
          }`}
          onClick={handleInviteClick}
        >
          <img
            src={
              location.pathname === '/invite' ? inviteImgGreen : inviteImg
            }
            alt="Invite Friends"
          />
          Invite Friends
        </div>

        {/* Account (normal icon only) */}
        <div
          className={`${styles.menuItem} ${
            location.pathname === '/account' ? styles.selected : ''
          }`}
          onClick={handleAccountClick}
        >
          <img src={accountImg} alt="Account" />
          Account
        </div>
      </div>
    </>
  );
}

export default SideMenu;