import React, {useState, useEffect} from 'react';
import styles from './CancelOrderDetail.module.css';

import CancelIcon from '../static_files/CancelOrder.svg';
import USDIcon from '../static_files/usd.svg';
import USDiIcon from "../static_files/usdi.svg";
import Tooltip from "../Tooltip/Tooltip"

const FETCH_URL = "https://usdi-api-256611876551.europe-west1.run.app/c2c_redeem_config_solana";

function CancelOrderDetail({
                               toAmount,
                               onConfirm,
                               onClose,
                           }) {
    // 用于存储从接口拿到的取消费率（单位: %，比如 0.5 代表 0.5%）
    const [cancelFeeRate, setCancelFeeRate] = useState(null);

    // 组件加载时，调用后端接口获取取消费率
    useEffect(() => {
        async function fetchCancelFee() {
            try {
                const response = await fetch(FETCH_URL);
                if (!response.ok) {
                    throw new Error(`Error fetching cancel fee config: ${response.statusText}`);
                }
                const data = await response.json();
                const feeFromApi = data[0]?.redeem_cancel_fee_percentage;

                console.log("Fee from API:", feeFromApi); // 调试用

                setCancelFeeRate(parseFloat(feeFromApi)); // 把字符串转成数字
            } catch (error) {
                console.error("Failed to fetch cancel fee:", error);
            }
        }

        fetchCancelFee();
    }, []);

    let cancellationFee = null;
    if (cancelFeeRate !== null) {
        const amountNum = parseFloat(toAmount) || 0;
        const feeValue = amountNum * (cancelFeeRate / 100);
        cancellationFee = feeValue;
    }

    // 你也可以做一个专门的函数来格式化时间
    function getFormattedTime() {
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const hour = String(now.getHours()).padStart(2, '0');
        const min = String(now.getMinutes()).padStart(2, '0');
        const sec = String(now.getSeconds()).padStart(2, '0');

        return `${month}/${day}/${year}, ${hour}:${min}:${sec}`;
    }
  const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick}>
            <div className={styles.transactionDetailModal}>

                {/* Modal header */}
                <div className={styles.modalHeader}>
                    <h2>Transaction detail</h2>
                    <div
                        className={styles.closeBtn}
                        onClick={onClose}
                        aria-label="Close"
                    >
                        ×
                    </div>
                </div>

                <div className={styles.lineWrapper}>
                    <div className={styles.dividerLineFull}></div>
                </div>

                {/* Body content */}
                <div className={styles.modalBody}>
                    <div className={styles.statusSection}>
                        <div className={styles.statusImageContainer}>
                            <img
                                src={CancelIcon}
                                alt="Canceled"
                                className={styles.statusIcon}
                            />
                        </div>
                        <div className={styles.statusText}>Cancel</div>


                    </div>

                    {/* 交易金额区块 */}
                    <div className={styles.transactionAmounts}>
                        <div className={styles.transactionFrom}>
                            <span className={styles.label}>From</span>
                            <div className={styles.amountRow}>
                                <img src={USDiIcon} alt="From"/>
                                <span className={styles.greenAmount}>-{parseFloat(toAmount).toLocaleString('en-US',{maximumFractionDigits:6})} USDi</span>
                            </div>
                        </div>
                         <div className={styles.lineWrapper}>
                    <div className={styles.dividerLineInner}></div>
                </div>
                        <div className={styles.transactionTo}>
                            <span className={styles.label}>To</span>
                            <div className={styles.amountRow}>
                                <img src={USDIcon} alt="Tips"/>
                                <span className={styles.amount}>+{(parseFloat(toAmount) * 0.995).toLocaleString('en-US',{maximumFractionDigits:2})} USD Cash</span>
                            </div>
                        </div>
                    </div>

                    {/* 交易详情区块 */}
                    <div className={styles.transactionDetails}>
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Transaction type</span>
                            <span className={styles.detailValue}>Redeem</span>
                        </div>

                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Time</span>
                            <span className={styles.detailValue}>{getFormattedTime()}</span>
                        </div>

                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Network</span>
                             <Tooltip text={<>A decentralized crypto-to-cash network <br/>for on-ramp and off-ramp
                                    transactions</>}>
                            <span  style={{cursor: "pointer", borderBottom: '1.4px dotted #797979'}} className={styles.detailValue}>C2C</span>
                                </Tooltip>
                        </div>

                        {/* 取消费 */}
                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Cancellation Fee</span>
                            <span className={styles.detailValue}>
                {/* 如果还在加载，或者获取失败，就显示 "..." 或 "N/A" */}
                                {cancellationFee !== null ? cancellationFee.toLocaleString('en-US',{maximumFractionDigits:2}) : '...'} USDi
              </span>
                        </div>

                        <div className={styles.detailRow}>
                            <span className={styles.detailLabel}>Rate</span>
                            <span className={styles.detailValue}>1 USDi = 1 USD</span>
                        </div>
                    </div>

                    <div className={styles.lineWrapper}>
                        <div className={styles.dividerLineFull2}></div>
                    </div>

                    <div className={styles.transactionNotes}>
                             <p>Notes: A 0.5% cancellation fee applies.</p>
                    </div>
                </div>

                {/* Modal footer */}
                <div className={styles.modalFooter}>
                    <button
                        type="button"
                        onClick={onConfirm}
                        className={styles.confirmBtn}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CancelOrderDetail;