import React, {useState} from 'react';
import Footer from './footer/Footer';
import Header from './header/Header';

import './usdi_tables/USDIStats.css';

import EmailUIDTxsRequestsTable from "./usdi_tables/EmailUIDTxsRequestsTable";
import ConnectWalletHeader from "./header/ConnectWalletHeader";

const EmailUIDTxsRequests = () => {
    const [parentStatus, setParentStatus] = useState("calculated");
    // selectedChain to decide if its ETH chain or Solana
    const [selectedChain, setSelectedChain] = useState('eth');

    // Function to handle status change in the parent component
    const handleStatusChangeInParent = (newStatus) => {
        console.log(`Status changed to ${newStatus} in the parent component`);
        setParentStatus(newStatus);
    };

    const [showPanel, setShowPanel] = useState(false);

    const handleChainSelect = (chain) => {
        setSelectedChain(chain);
    };
    return (
        <div className="container">
            <div style={{marginTop: '7px'}}>
                <ConnectWalletHeader
                    isAdminHeader={true}         // 传 true 就会显示你原先的 admin header（如果不需要就传false）
                    showPanel={showPanel}
                    setShowPanel={setShowPanel}
                />
            </div>
            <div className="title-box d-flex justify-content-between align-items-center">
            </div>
            <h3 className="pb-3">Email UID txs request Table</h3>
            <EmailUIDTxsRequestsTable
                selectedChain={selectedChain}
                onChainSelect={handleChainSelect}
            />
            <Footer/>
        </div>
    );
};

export default EmailUIDTxsRequests;
